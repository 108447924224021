import {
  QueryFunction,
  QueryKey,
  UseQueryDefinedReturnType,
  UseQueryOptions,
  UseQueryReturnType,
  useQuery
} from '@tanstack/vue-query'
import { omit } from 'lodash'
import { UnwrapRef, watch } from 'vue'

import { errorNotification } from '@/utils/notifications'

export function useFetch<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, UnwrapRef<TQueryKey>>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData' | 'placeholderData'
  > & {
    errorNotificationText?: string
    showErrorNotification?: boolean
  }
): UseQueryReturnType<TData, TError>

export function useFetch<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, UnwrapRef<TQueryKey>>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData' | 'placeholderData'
  > & {
    errorNotificationText?: string
    showErrorNotification?: boolean
  } & (
      | { initialData: TQueryFnData | (() => TQueryFnData) }
      | { placeholderData: TQueryFnData | (() => TQueryFnData) }
    )
): UseQueryDefinedReturnType<TData, TError>

export function useFetch<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, UnwrapRef<TQueryKey>>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: TQueryFnData | (() => TQueryFnData)
    errorNotificationText?: string
    showErrorNotification?: boolean
  }
):
  | UseQueryDefinedReturnType<TData, TError>
  | UseQueryReturnType<TData, TError> {
  const useQueryData = useQuery(queryKey, queryFn, {
    ...omit(options, ['errorNotificationText', 'showErrorNotification'])
  })

  options = {
    ...options,
    showErrorNotification:
      options?.showErrorNotification !== undefined
        ? options?.showErrorNotification
        : true
  }

  watch(
    () => useQueryData.isError.value,
    () => {
      if (useQueryData.isError?.value && options?.showErrorNotification) {
        errorNotification(options?.errorNotificationText)
      }
    },
    { immediate: true }
  )

  if (options?.initialData || options?.placeholderData)
    return useQueryData as UseQueryDefinedReturnType<TData, TError>
  return useQueryData
}
