<template>
  <form-item-wrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
    :query-selector-function="item => item.click()"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
      >
        <div class="form-item-text">{{ formattedTime || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ setEditMode }">
      <a-form-model-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :prop="prop"
      >
        <a-time-picker
          v-model="inputValue"
          :auto-focus="autoFocus"
          v-bind="$attrs"
          v-on="$listeners"
          @openChange="isOpen => setEditMode(isOpen)"
        />
      </a-form-model-item>
    </template>
  </form-item-wrapper>
</template>
<script>
import FormItemWrapper from '@/components/common/forms/FormItemWrapper'
import moment from 'moment'
import HInputMixin from '@/mixins/HInputMixin'

export default {
  components: { FormItemWrapper },
  extends: HInputMixin,
  props: {
    value: {
      type: [String, Object]
    }
  },
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.prop && this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    formattedTime() {
      const format = this.$attrs.format || 'HH:mm'
      if (this.inputValue) {
        return moment(this.inputValue, format).format(format)
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  --time-picker-width: 100%;

  .ant-time-picker {
    width: var(--time-picker-width) !important;
  }
}
</style>
