import { Route } from '@/types/route'

export const settingsTabRoutes: Route[] = [
  {
    path: 'settings',
    name: 'change-settings',
    meta: {
      icon: 'setting',
      title: 'Settings',
      tab: 'administration',
      permissions: ['core.change_sitesettings']
    },
    component: () =>
      import('@/views/AdminPanel/Settings/Settings/SettingsView.vue')
  },
  {
    path: 'school-years',
    name: 'settings-school-years',
    meta: {
      icon: 'calendar',
      title: 'School Years',
      tab: 'administration',
      permissions: ['periods.change_schoolyear']
    },
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolYears/SchoolYearsView.vue')
  },
  {
    path: 'weekend-planner',
    name: 'settings-weekend-planner',
    meta: {
      icon: 'schedule',
      title: 'Weekend Planner',
      tab: 'administration',
      permissions: ['core.change_calendarevent']
    },
    component: () =>
      import(
        '@/views/AdminPanel/Settings/WeekendPlanner/WeekendPlannerView.vue'
      )
  },
  {
    path: 'school-break',
    name: 'settings-school-break',
    meta: {
      icon: 'carry-out',
      title: 'School Breaks',
      tab: 'administration',
      permissions: ['school_breaks.view_schoolbreak']
    },
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolBreak/SchoolBreakView.vue')
  },
  {
    path: 'health-card',
    name: 'settings-health-card',
    meta: {
      icon: 'calendar',
      title: 'Health Card',
      tab: 'administration',
      permissions: ['allergies.view_allergy']
    },
    component: () =>
      import('@/views/AdminPanel/Settings/HealhCard/HealthCardView.vue')
  },
  {
    path: 'merits-demerits-management',
    name: 'settings-merits-demerits-management',
    meta: {
      icon: 'rise',
      title: 'Merits & Demerits Management',
      tab: 'administration',
      permissions: ['merits.change_merititem']
    },
    component: () =>
      import(
        '@/views/AdminPanel/Settings/MeritsDemeritsManagement/MeritsDemeritsManagementView.vue'
      )
  }
]

const settingsNotTabRoutes: Route[] = [
  {
    path: 'school-years/add',
    name: 'settings-school-years-add',
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolYearForm/index.vue'),
    meta: {
      title: 'School Years Add',
      tab: 'administration',
      permissions: ['periods.add_schoolyear']
    }
  },
  {
    path: 'school-years/:yearId',
    name: 'settings-school-years-edit',
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolYearForm/index.vue'),
    meta: {
      title: 'School Years Form',
      tab: 'administration',
      permissions: ['periods.change_schoolyear']
    }
  },
  {
    path: 'merits-demerits-management/:id',
    name: 'settings-merits-demerits-management-edit-item',
    component: () =>
      import(
        '@/views/AdminPanel/Settings/MeritsDemeritsManagement/MeritsDemeritsManagementEditView.vue'
      ),
    meta: {
      title: 'Edit Merit & Demerit',
      tab: 'administration',
      permissions: ['merits.change_merititem']
    }
  }
]

export const settingsRoutes: Route[] = [
  {
    path: 'settings',
    redirect: 'settings/settings',
    name: 'settings',
    component: () => import('@/views/AdminPanel/Settings/SettingsView.vue'),
    meta: {
      title: 'Settings',
      tab: 'administration',
      permissions: ['core.change_sitesettings']
    },
    children: settingsTabRoutes
  },
  {
    path: 'settings',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'administration',
      permissions: ['core.change_sitesettings']
    },
    children: settingsNotTabRoutes
  }
]
