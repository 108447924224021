import { Route } from '@/types/route'

export const roomManagementTabRoutes: Route[] = [
  {
    path: 'student-accommodation',
    name: 'student-accommodation',
    component: () =>
      import(
        '@/views/RoomManagement/StudentAccommodation/StudentAccommodationView.vue'
      ),
    meta: {
      icon: 'calendar',
      title: 'Student accommodation',
      tab: 'boarding',
      permissions: ['boarding_accommodations.view_accommodation']
    }
  },
  {
    path: 'room-booking',
    name: 'room-booking',
    component: () =>
      import('@/views/RoomManagement/RoomBooking/RoomBookingView.vue'),
    meta: {
      icon: 'book',
      title: 'Room booking',
      tab: 'boarding',
      permissions: ['houses.view_houseroom']
    }
  }
]

export const roomManagementRoutes = [
  {
    name: 'room-management',
    path: 'room-management',
    redirect: 'room-management/student-accommodation',
    component: () => import('@/views/RoomManagement/RoomManagementView.vue'),
    meta: {
      title: 'Room management',
      tab: 'boarding'
    },
    children: roomManagementTabRoutes
  }
]
