import { Route } from '@/types/route'

export const studentContactsRoutes: Route[] = [
  {
    name: 'student-contact-list',
    path: 'student-contacts',
    component: () =>
      import('@/views/StudentContacts/StudentContactListView.vue'),
    meta: {
      title: 'Student Contacts',
      permissions: ['parents.view_parent'],
      tab: 'students'
    }
  },
  {
    name: 'student-contact-add',
    path: 'student-contact/add',
    component: () => import('@/views/StudentContacts/StudentContactForm.vue'),
    meta: {
      title: 'Student Contact add',
      permissions: ['parents.change_parent'],
      tab: 'students'
    }
  },
  {
    name: 'student-contact-details',
    path: 'student-contact/:studentContactId',
    component: () =>
      import('@/views/StudentContacts/StudentContactDetails.vue'),
    meta: {
      title: 'Student Contact details',
      permissions: ['parents.view_parent'],
      tab: 'students'
    }
  },
  {
    name: 'student-contact-edit',
    path: 'student-contact/:studentContactId/edit',
    component: () => import('@/views/StudentContacts/StudentContactForm.vue'),
    meta: {
      title: 'Student Contact edit',
      permissions: ['parents.change_parent'],
      tab: 'students'
    }
  }
]
