import { Route } from '@/types/route'

export const studentDetailsRoute: Route[] = [
  {
    path: 'mission-control',
    name: 'mission-control',
    component: () =>
      import('@/views/StudentDetails/MissionControlDetails/index.vue'),
    meta: {
      title: 'Mission Control',
      permissions: ['mission_control.view_missioncontrol'],
      tab: 'students'
    },
    children: [
      {
        path: 'edit-focus',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Focus/Edit/index.vue'
          ),
        meta: {
          title: 'Edit FocusForm',
          permissions: ['mission_control.change_missioncontrol'],
          tab: 'students'
        }
      },
      {
        path: 'edit-personal',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Personal/Edit/index.vue'
          ),
        meta: {
          title: 'Edit PersonalForm',
          permissions: ['mission_control.change_missioncontrol'],
          tab: 'students'
        }
      },
      {
        path: 'edit-achievements',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Personal/Edit/AchievementList/index.vue'
          ),
        meta: {
          title: 'Edit Achievements',
          permissions: ['achievements.change_studentachievement'],
          tab: 'students'
        }
      },
      {
        path: 'edit-internships',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Personal/InternshipEditView.vue'
          ),
        meta: {
          title: 'Edit Internships',
          permissions: ['internships.change_studentinternship'],
          tab: 'students'
        }
      },
      {
        path: 'edit-tae-projects',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/TAE/Edit/ProjectList/index.vue'
          ),
        meta: {
          title: 'Edit TAE Project',
          permissions: ['mission_control.change_taeproject'],
          tab: 'students'
        }
      },
      {
        path: 'edit-tae-experiences',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/TAE/Edit/ExperienceList/index.vue'
          ),
        meta: {
          title: 'Edit TAE Project',
          permissions: ['mission_control.change_studenttaeexperience'],
          tab: 'students'
        }
      },
      {
        path: 'edit-exams',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Exams/Edit/ExamList/index.vue'
          ),
        meta: {
          title: 'Edit Exams',
          permissions: ['exams.change_studentexternalexam'],
          tab: 'students'
        }
      },
      {
        path: 'edit-transcripts',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Exams/Edit/TranscriptList/index.vue'
          ),
        meta: {
          title: 'Edit Transcripts',
          permissions: ['exams.change_transcript'],
          tab: 'students'
        }
      },
      {
        path: 'edit-exam-recommendations',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Exams/Edit/Recommendations/index.vue'
          ),
        meta: {
          title: 'Edit Exam Recommendations',
          permissions: ['exams.change_exam'],
          tab: 'students'
        }
      },
      {
        path: 'edit-recommendations',
        component: () =>
          import(
            '@/views/StudentDetails/MissionControlDetails/Recommendations/Edit/index.vue'
          ),
        meta: {
          title: 'Edit Recommendations',
          permissions: ['mission_control.change_missioncontrol'],
          tab: 'students'
        }
      }
    ]
  },
  {
    path: 'courses',
    component: () => import('@/views/StudentDetails/Courses/index.vue'),
    meta: {
      title: 'Student courses',
      permissions: ['grades.view_accgrade'],
      tab: 'students'
    }
  },
  {
    path: 'behaviour',
    name: 'student-behaviour',
    redirect: 'behaviour/merit',
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        path: 'merit',
        name: 'student-merit-list',
        component: () =>
          import('@/views/StudentDetails/Behaviour/Merit/index.vue'),
        meta: {
          title: 'Merit',
          permissions: ['merits.view_merit'],
          tab: 'students'
        }
      },
      {
        path: 'comments',
        component: () =>
          import('@/views/StudentDetails/Behaviour/Comments/index.vue'),
        meta: {
          title: 'Comments',
          permissions: ['permissions.view_studentcomments'],
          tab: 'students'
        }
      }
    ],
    meta: {
      title: 'Behaviour',
      permissions: ['merits.view_merit'],
      tab: 'students'
    }
  },
  {
    path: 'attendances',
    component: () =>
      import(
        '@/views/StudentDetails/AcademicAttendances/AcademicAttendances.vue'
      ),
    meta: {
      title: 'Academic Attendances',
      permissions: ['permissions.view_attendance_report'],
      tab: 'students'
    }
  },
  {
    path: 'details',
    component: () =>
      import('@/views/StudentDetails/Details/StudentBasicInformation.vue'),
    meta: {
      title: 'Student details',
      permissions: ['students.view_student'],
      tab: 'students'
    }
  },
  {
    path: 'health',
    name: 'student-health-details',
    component: () => import('@/views/StudentDetails/Health/StudentHealth.vue'),
    meta: {
      title: 'Health card',
      permissions: ['allergies.view_studentallergy'],
      tab: 'students'
    }
  },
  {
    path: 'idp-plans',
    name: 'student-idp-plans',
    component: () =>
      import('@/views/StudentDetails/IdpPlanList/IdpPlanList.vue'),
    meta: {
      title: 'Students idp plan',
      permissions: ['students_idp.view_studentidpplan'],
      tab: 'students'
    }
  },
  {
    path: 'idp-plans/create',
    component: () => import('@/views/StudentDetails/IdpPlanCreate/index.vue'),
    name: 'idp-create',
    meta: {
      title: 'Create students IDP Plan',
      permissions: ['students_idp.add_studentidpplan'],
      tab: 'students'
    }
  },
  {
    path: 'idp-plans/:id',
    component: () => import('@/views/StudentDetails/IdpPlanCreate/index.vue'),
    name: 'idp-edit',
    meta: {
      title: 'Edit students IDP Plan',
      permissions: ['students_idp.change_studentidpplan'],
      tab: 'students'
    }
  },
  {
    path: 'idp-plans/:id/details',
    component: () => import('@/views/StudentDetails/IdpPlanDetails/index.vue'),
    name: 'idp-details',
    meta: {
      title: 'Preview IDP Plan',
      permissions: ['students_idp.view_studentidpplan'],
      tab: 'students'
    }
  }
]

export const studentsRoutes = [
  {
    name: 'students-list',
    path: 'students',
    component: () => import('@/views/StudentList/StudentList.vue'),
    meta: {
      title: 'Students',
      permissions: ['students.view_student']
    }
  },
  {
    name: 'student-details',
    path: 'students/:studentId',
    redirect: '/students/:studentId/details',
    component: () => import('@/views/StudentDetails/index.vue'),
    children: studentDetailsRoute
  },
  {
    name: 'edit-student',
    path: 'students-edit-student/:studentId',
    component: () => import('@/views/StudentDetails/StudentForm.vue'),
    meta: {
      title: 'Student edit',
      permissions: ['students.change_student'],
      tab: 'students'
    }
  },
  {
    name: 'add-student',
    path: 'students-add-student',
    component: () => import('@/views/StudentDetails/StudentForm.vue'),
    meta: {
      title: 'Student add',
      permissions: ['students.add_student']
    }
  }
]
