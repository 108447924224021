<template>
  <a-layout-header class="header">
    <a-skeleton
      v-if="loading"
      class="skeleton"
      active
      :title="false"
      :paragraph="{ rows: 1 }"
    />
    <div v-else class="wrapper">
      <div class="title-wrapper">
        <slot name="title">
          <h1 data-cy="page-header-title">
            {{ title }}
            <sup v-if="superscriptText">({{ superscriptText }})</sup>
          </h1>
        </slot>

        <p v-if="subtitle" class="t-label subtitle">
          {{ subtitle }}
        </p>
      </div>
      <a-button
        v-if="addButton"
        data-cy="add-item-button"
        type="primary"
        icon="plus"
        @click="changeRoute(addButton.route)"
      >
        {{ addButton.label }}
      </a-button>
      <a-button
        v-if="editButton"
        type="primary"
        @click="changeRoute(editButton.route)"
      >
        {{ editButton.label }}
      </a-button>
      <slot />
    </div>
  </a-layout-header>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean
    },
    title: {
      type: String,
      required: true
    },
    superscript: {
      type: [String, Number],
      required: false
    },
    subtitle: {
      type: String,
      default: ''
    },
    addButton: {
      type: Object,
      required: false
    },
    editButton: {
      type: Object,
      required: false
    }
  },
  computed: {
    superscriptText() {
      return this.superscript !== undefined
        ? this.superscript
        : this.$store.state.paginationTotal
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: unset;
  padding: 0;
  margin-bottom: 24px;

  h1 {
    line-height: 38px;
    margin: 0;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.subtitle {
  line-height: 1;
  margin-bottom: 0;
  position: absolute;
  bottom: 36px;
  font-weight: 600;
}

::v-deep sup {
  font-size: 66%;
}

::v-deep {
  .skeleton {
    ul {
      margin: 0;

      li {
        height: 38px;
      }
    }
  }
}
</style>
