export function useModal() {
  const bodyElement = document.querySelector('body')
  const widthStyle = 'width: calc(100% - 15px)'

  const isScrollVisible = (element: HTMLElement) =>
    element.scrollHeight > element.clientHeight

  const addModalVisibilityListener = () => {
    const observer = new MutationObserver(() => {
      const body = document.querySelector('body')
      const header = document.querySelector('.header')

      if (!body || !header) return

      if (
        isScrollVisible(body) &&
        body.getAttribute('style')?.includes(widthStyle)
      ) {
        header.setAttribute('style', widthStyle)
      } else {
        header.setAttribute('style', '')
      }
    })

    if (!bodyElement) return

    observer.observe(bodyElement, {
      attributes: true
    })

    return observer
  }

  const removeModalVisibilityListener = (observer: MutationObserver) =>
    observer && observer.disconnect()

  return { addModalVisibilityListener, removeModalVisibilityListener }
}
