<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view v-if="$route.meta.public" />
      <div v-else>
        <transition name="fade-up">
          <router-view v-if="resourcesLoaded" />
          <Loader v-else />
        </transition>
      </div>
    </a-config-provider>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'

import { useBoardingRotaNotification } from '@/composables/useBoardingRotaNotification'
import { useModal } from '@/composables/useModal'
import VersionChecker from '@/mixins/VersionChecker.vue'

let modalVisibilityObserver

const { checkStatus, setTaskId, createInfoNotification } =
  useBoardingRotaNotification()

const { addModalVisibilityListener, removeModalVisibilityListener } = useModal()

onMounted(() => {
  const boardingRotaRequestId = localStorage.getItem('boardingRotaRequestId')

  if (boardingRotaRequestId) {
    setTaskId(Number(boardingRotaRequestId))
    createInfoNotification()
    checkStatus()
  }

  modalVisibilityObserver = addModalVisibilityListener()
})

onUnmounted(() => {
  removeModalVisibilityListener(modalVisibilityObserver)
})
</script>

<script>
import en_GB from 'ant-design-vue/lib/locale-provider/en_GB'
import { every } from 'lodash'

import Loader from '@/components/common/Loader.vue'

export default {
  components: {
    Loader
  },
  mixins: [VersionChecker],
  data() {
    return {
      locale: en_GB
    }
  },
  computed: {
    resourcesLoaded() {
      return every([
        this.userInfo.id,
        this.$store.state.aims.length,
        this.$store.state.grades.length,
        this.$store.state.quarters.length,
        this.$store.state.semesters.length,
        this.$store.state.years.length
      ])
    }
  },
  async created() {
    if (window.localStorage.getItem('token')) {
      await this.$store.dispatch('fetchUserInfo')
    }
    const user = this.$store.state.userInfo
    if (user.is_staff && user.profiles.length > 1) {
      // safeModeDisabled already exsist in localStorage.
      if (window.localStorage.getItem('safeModeDisabled')) {
        this.$store.commit(
          'setSafeModeDisabled',
          JSON.parse(window.localStorage.getItem('safeModeDisabled'))
        )
      } else {
        // Set safeModeDisabled in localStorage if user has switch button for restricted mode.
        window.localStorage.setItem('safeModeDisabled', true)
        this.$store.commit('setSafeModeDisabled', true)
      }
    } else {
      window.localStorage.removeItem('safeModeDisabled')
    }

    if (user.id) {
      this.$store.dispatch('fetchCommonData')
    }
  }
}
</script>
