import { Route } from '@/types/route'

const flags = ['FEATURE_BOARDING_ROTA'] as const

export type FeatureFlag = typeof flags[number]

const availableFeature = import.meta.env.VITE_FEATURE_FLAGS
  ? import.meta.env.VITE_FEATURE_FLAGS.split(', ')
  : []

export function isFeatureEnabled(featureName: FeatureFlag) {
  return availableFeature.includes(featureName)
}

export function checkIsRouteWithFeatureEnabled(route: Route) {
  return route.meta.featureFlag
    ? isFeatureEnabled(route.meta.featureFlag)
    : true
}
