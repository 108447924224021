import { Route } from '@/types/route'

export const disciplineTabRoutes: Route[] = [
  {
    path: 'student-category-reports',
    name: 'student-category-reports',
    meta: {
      icon: 'calendar',
      title: 'Category reports',
      tab: 'boarding',
      permissions: ['discipline.view_studentcategoryreport']
    },
    component: () =>
      import(
        '@/views/Discipline/StudentCategoryReport/StudentCategoryReportView.vue'
      )
  },
  {
    name: 'merits-and-demerits-list',
    path: 'merits',
    component: () =>
      import('@/views/Discipline/MeritsDemerits/MeritsDemeritsView.vue'),
    meta: {
      icon: 'rise',
      title: 'Merits & Demerits',
      tab: 'boarding',
      permissions: ['merits.view_merit']
    }
  },
  {
    name: 'merits-exchange-list',
    path: 'merits-exchange',
    component: () =>
      import('@/views/Discipline/MeritsExchange/MeritsExchangeView.vue'),
    meta: {
      icon: 'gift',
      title: 'Merits exchange',
      tab: 'boarding',
      permissions: ['merits_exchange.view_meritreward']
    }
  },
  {
    name: 'boarding-comment-list',
    path: 'boarding-comments',
    component: () => import('@/views/Discipline/BoardingCommentList/index.vue'),
    meta: {
      icon: 'message',
      title: 'Boarding Comments',
      tab: 'boarding',
      permissions: ['boarding_comments.view_boardingcomment']
    }
  },
  {
    name: 'recovery-list',
    path: 'recovery',
    component: () => import('@/views/Discipline/Recovery/RecoveryView.vue'),
    meta: {
      icon: 'hourglass',
      title: 'Absence & lunchtime recovery',
      tab: 'boarding',
      permissions: ['recovery.view_recovery']
    }
  }
]

const disciplineNotTabRoutes = [
  {
    name: 'student-category-report-details',
    path: 'student-category-reports/:reportId',
    component: () =>
      import(
        '@/views/Discipline/StudentCategoryReport/Details/CategoryReportDetailsView.vue'
      ),
    meta: {
      title: 'Student category report detail',
      tab: 'boarding',
      permissions: ['discipline.view_studentcategoryreport']
    }
  },
  {
    path: 'recovery/:recoveryId',
    name: 'recovery-edit',
    component: () => import('@/views/Discipline/Recovery/RecoveryEditView.vue'),
    meta: {
      title: 'Edit Recovery',
      tab: 'boarding',
      permissions: ['recovery.change_recovery']
    }
  }
]

export const disciplineRoutes = [
  {
    name: 'discipline',
    path: 'discipline',
    component: () => import('@/views/Discipline/DisciplineView.vue'),
    meta: {
      title: 'Discipline',
      tab: 'boarding'
    },
    children: disciplineTabRoutes
  },
  {
    path: 'discipline',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'boarding'
    },
    children: disciplineNotTabRoutes
  }
]
