export type AbacusInvoiceProduct = {
  id: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  abacus_id: number
  name: string
}

export type AbacusInvoiceProductRequest = {
  /**
   * @min -2147483648
   * @max 2147483647
   */
  abacus_id: number
  /** @minLength 1 */
  name: string
}

export type Absence = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date?: string
  comment?: string
  /**
   * * `late` - Late
   * * `absent_excused` - Absent excused
   * * `absent_unexcused` - Absent unexcused
   * * `sick` - Sick
   */
  absence_type: AbsenceTypeEnum
  sick_report: string
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  added_by?: string
  last_modified_by?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  source: SourceEnum
  source_display: string
}

export type AbsenceRequest = {
  students: string[]
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date?: string
  comment?: string
  /**
   * * `late` - Late
   * * `absent_excused` - Absent excused
   * * `absent_unexcused` - Absent unexcused
   * * `sick` - Sick
   */
  absence_type: AbsenceTypeEnum
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  added_by?: string
  last_modified_by?: string
}

/**
 * * `late` - Late
 * * `absent_excused` - Absent excused
 * * `absent_unexcused` - Absent unexcused
 * * `sick` - Sick
 */
export type AbsenceTypeEnum =
  | 'late'
  | 'absent_excused'
  | 'absent_unexcused'
  | 'sick'

export type AcademicAttendance = {
  /** @format uuid */
  id: string
  /** @format uuid */
  lesson: string
  student: StudentInAttendance
  is_present?: boolean
  is_late?: boolean
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  is_marked_automatically: boolean
  /** @format date-time */
  modified: string
  /** @format date-time */
  marked_at?: string | null
}

export type AcademicAttendanceAlert = {
  /** @format uuid */
  id: string
  teacher: string
  course: string
  /** @format date */
  lesson_date: string
  lesson_time: string
  /**
   * * `unresolved` - Unresolved
   * * `resolved` - Resolved
   */
  status?: AcademicAttendanceAlertStatusEnum
  is_read?: boolean
  is_notification_sent?: boolean
}

export type AcademicAttendanceAlertRequest = {
  /** @format date */
  lesson_date: string
  /** @minLength 1 */
  lesson_time: string
  /**
   * * `unresolved` - Unresolved
   * * `resolved` - Resolved
   */
  status?: AcademicAttendanceAlertStatusEnum
  is_read?: boolean
  is_notification_sent?: boolean
}

/**
 * * `unresolved` - Unresolved
 * * `resolved` - Resolved
 */
export type AcademicAttendanceAlertStatusEnum = 'unresolved' | 'resolved'

export type AcademicAttendanceRequest = {
  is_present?: boolean
  is_late?: boolean
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  unlock_edit?: boolean
  /** @format date-time */
  marked_at?: string | null
}

export type AccGrade = {
  id: number
  quarter?: number | null
  aim: number | null
  semester?: number | null
  student_aim_for_course: number
  /** @maxLength 2000 */
  comment?: string
  /** @format uuid */
  course: string
  grade: string | null
  target_grade_status: TargetGradeStatusEnum
  weighted_percents: number | null
  course_str: string
  student_str: string
  /** @format uuid */
  student: string
  target_grade: string | null
  teacher_str: string
  teacher_id: string
  custom_grade?: CustomGradeEnum | BlankEnum
}

export type AccGradeRequest = {
  quarter?: number | null
  semester?: number | null
  /** @maxLength 2000 */
  comment?: string
  /** @format uuid */
  course: string
  /** @format uuid */
  student: string
  custom_grade?: CustomGradeEnum | BlankEnum
}

/**
 * * `green_paper` - Green paper
 * * `pink_paper` - Pink paper
 * * `breaks` - Breaks
 * * `prompt` - Prompt
 * * `use_laptop` - Use of laptop
 * * `reader_pen` - Reader pen
 * * `own_room` - Own room
 */
export type AccessArrangementsEnum =
  | 'green_paper'
  | 'pink_paper'
  | 'breaks'
  | 'prompt'
  | 'use_laptop'
  | 'reader_pen'
  | 'own_room'

export type Accommodation = {
  id: number
  /** @format uuid */
  student: string
  student_str: string
  room: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  /**
   * * `isolation` - Isolation
   * * `quarantine` - Quarantine
   * * `sleepover` - Sleepover
   */
  temporary_movement_type: TemporaryMovementTypeEnum
  temporary_movement_type_display: string
  house_str: string
  house: number
  room_str: string
  room_code: string
  room_name: string
  /** @format date-time */
  deleted: string | null
  is_room_only_for_student?: boolean
}

export type AccommodationRequest = {
  /** @format uuid */
  student: string
  room: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  /**
   * * `isolation` - Isolation
   * * `quarantine` - Quarantine
   * * `sleepover` - Sleepover
   */
  temporary_movement_type: TemporaryMovementTypeEnum
  is_room_only_for_student?: boolean
  added_by?: string
  last_modified_by?: string
}

export type AccountOverdueEnum =
  | 'settled'
  | 'first_reminder'
  | 'second_reminder'
  | 'app_locked'

export type Achievement = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type AchievementAndEffortProgressComment = {
  /** @format uuid */
  id: string
  positive_comments?: string[]
  to_be_improved_comments?: string[]
}

export type AchievementAndEffortProgressCommentRequest = {
  positive_comments?: string[]
  to_be_improved_comments?: string[]
}

export type ActivityMove = {
  /** @format uuid */
  activity: string
  hour: number
  hour_display: string
  day_display: string
  day: string
  duration: string
}

export type AdminAbsenceRecovery = {
  id: string
  polymorphic_ctype: number | null
  /** @format date-time */
  datetime_from: string
  /** @format date-time */
  datetime_to: string
  school_programme: SchoolProgrammeRecovery
  programme_attendance_report: ProgrammeAttendanceReportRecovery
  assigned_to_str: string
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /** via annotate */
  location: string
  is_editable: boolean
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminAbsenceRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminAdditionalAusgang = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  leave_details: string
  /** @maxLength 128 */
  absence_name: string
  participants: MeritRewardParticipant[]
  decision_comment?: string
  /** @format uuid */
  campus_absence?: string | null
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminAlertLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  display_hour: string
  course: string
  teacher: string
}

export type AdminAlertLessonRequest = {
  /** @format date */
  date: string
}

export type AdminAlertSchoolBreak = {
  id: number
  /** @maxLength 100 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

export type AdminAlertSchoolBreakRequest = {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

export type AdminApproveIdpPlan = {
  /** @format date */
  parent_approval_deadline?: string | null
  parent_approval_needed?: boolean
}

export type AdminApproveIdpPlanRequest = {
  /** @format date */
  parent_approval_deadline?: string | null
  parent_approval_needed?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoard = {
  id: string
  widgets: AdminWidget[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 128 */
  name: string
  status: AdminBoardStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  widgets_str: string[]
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_value?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoardRequest = {
  widgets: AdminWidgetRequest[]
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_value?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoardSchedule = {
  id: number
  name: string
  /** @format date */
  publication_date: string
  boards: string[]
  board_details: AdminSimpleBoard[]
  recurring?: Recurring | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminBoardScheduleRequest = {
  /** @minLength 1 */
  name: string
  /** @format date */
  publication_date: string
  boards: string[]
  recurring?: RecurringRequest | null
}

/**
 * * `unpublished` - Unpublished
 * * `published` - Published
 */
export type AdminBoardStatusEnum = 'unpublished' | 'published'

export type AdminBoardingAnnouncementWidget = {
  id: string
  /** @default "#FD8F5E" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @maxLength 90 */
  text: string
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminBoardingAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  text: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminBookTheCafe = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  /** @maxLength 128 */
  movie: string
  participants: MeritRewardParticipant[]
  decision_comment?: string
  /** @format uuid */
  campus_absence?: string | null
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminCasualAnyday = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  decision_comment?: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminCategoryUpgrade = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  decision_comment?: string
  category_before_upgrade: string
  category_after_upgrade: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminCelebrationWidget = {
  id: string
  /** @default "#FF607F" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCelebrationWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCeleryTaskStatus = {
  id: number
  /** @format uuid */
  uuid: string
  timetable: number | null
  /** @format date-time */
  started_at: string
  /** @format date-time */
  ended_at: string | null
  execution_time: string
  state: StateEnum
  history: AdminCeleryTaskStatusHistory[]
}

export type AdminCeleryTaskStatusHistory = {
  id: number
  state: StateEnum
  done_in: number
  /**
   * time when state was changed
   * @format date-time
   */
  timestamp: string
  /**
   * in seconds
   * @format decimal
   * @pattern ^-?\d{0,9}(?:\.\d{0,2})?$
   */
  execution_time: string
}

export type AdminCeleryTaskStatusList = {
  id: number
  /** @format uuid */
  uuid: string
  timetable: number | null
  /** @format date-time */
  started_at: string
  /** @format date-time */
  ended_at: string | null
  execution_time: string
  state: StateEnum
  /** via annotate */
  latest_state: string | null
}

export type AdminCityBrunchZurich = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  participants: MeritRewardParticipant[]
  decision_comment?: string
  /** @format uuid */
  campus_absence?: string | null
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type AdminClockWidget = {
  id: string
  /** @default "#000000" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminClockWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCongratulationsWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminCongratulationsWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminDashboardUser = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventAgendaWidget = {
  id: string
  /** @default "#5965EF" */
  bg_color: string
  agenda_items: AgendaItem[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @maxLength 90 */
  header: string
  /** @format date */
  date_from: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from: string
  /** @format time */
  time_to: string
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventAgendaWidgetRequest = {
  agenda_items: AgendaItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header: string
  /** @format date */
  date_from: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from: string
  /** @format time */
  time_to: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWidget = {
  id: string
  bg_color: string
  link?: AdminLinkWithQRCode | null
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @maxLength 90 */
  header: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWidgetRequest = {
  /** @minLength 1 */
  bg_color: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWithPhotoWidget = {
  id: string
  bg_color: string
  link?: AdminLinkWithQRCode | null
  photo_data: Image
  photo_in_my_rosenberg_idp_data: Image
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @maxLength 100 */
  header: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  crop_my_rosenberg_idp_photo_separately?: boolean
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  polymorphic_ctype: number | null
  photo: string
  photo_in_my_rosenberg_idp?: string | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminEventInfoWithPhotoWidgetRequest = {
  /** @minLength 1 */
  bg_color: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 100
   */
  header: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  crop_my_rosenberg_idp_photo_separately?: boolean
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /** @minLength 1 */
  photo: string
  /** @minLength 1 */
  photo_in_my_rosenberg_idp?: string | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminInfoboardsSettings = {
  id: number
  /** Generate automated alerts about teacher's covers */
  generate_teacher_cover_alerts?: boolean
  /** Generate automated alerts about school breaks (1 week before start date) */
  generate_school_break_alerts?: boolean
  /** Show 2 most recent articles */
  show_recent_articles?: boolean
}

export type AdminInfoboardsSettingsRequest = {
  /** Generate automated alerts about teacher's covers */
  generate_teacher_cover_alerts?: boolean
  /** Generate automated alerts about school breaks (1 week before start date) */
  generate_school_break_alerts?: boolean
  /** Show 2 most recent articles */
  show_recent_articles?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalItem = {
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  article_url: AdminLinkWithQRCode
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalItemRequest = {
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  article_url: AdminLinkWithQRCodeRequest
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalWidget = {
  id: string
  /** @default "#EBCFCF" */
  bg_color: string
  journal_items: AdminJournalItem[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminJournalWidgetRequest = {
  journal_items: AdminJournalItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminLinkWithQRCode = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * @format uri
   * @maxLength 200
   */
  url: string
  /** @format uri */
  qr_code: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminLinkWithQRCodeRequest = {
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  url: string
}

export type AdminLunchtimeRecovery = {
  id: string
  polymorphic_ctype: number | null
  /** @format date-time */
  datetime_from: string
  /** @format date-time */
  datetime_to: string
  school_programme: SchoolProgrammeRecovery
  programme_attendance_report: ProgrammeAttendanceReportRecovery
  assigned_to_str: string
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /** via annotate */
  location: string
  is_editable: boolean
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminLunchtimeRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype: RecoveryResourcetypeEnum
}

export type AdminMenuWidget = {
  id: string
  /** @default "#00AC69" */
  bg_color: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminMenuWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminMeritReward =
  | AdminCasualAnyday
  | AdminRoomService
  | AdminBookTheCafe
  | AdminCityBrunchZurich
  | AdminAdditionalAusgang
  | AdminCategoryUpgrade
  | AdminStopTheClock

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminMessage = {
  id: number
  /**
   * * `alert` - Alert
   * * `notice` - Notice
   */
  type: MessageTypeEnum
  name: string
  content: string
  /** @format date */
  display_to: string
  is_created_manually?: boolean
  author?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  lesson: AdminAlertLesson
  school_break: AdminAlertSchoolBreak
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminMessageRequest = {
  /**
   * * `alert` - Alert
   * * `notice` - Notice
   */
  type: MessageTypeEnum
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  content: string
  /** @format date */
  display_to: string
  is_created_manually?: boolean
  author?: string
}

export type AdminMysteryLinkWidget = {
  id: string
  /** @default "#9561E8" */
  bg_color: string
  approved_mystery_links: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminMysteryLinkWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminParentApproveIdpPlanRequest = {
  /** @minLength 1 */
  parent_approve_comment: string
}

export type AdminPosterWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  image_data: Image
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  image: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminPosterWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @minLength 1 */
  image: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminQuarter = {
  id: number
  /** @maxLength 32 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester?: number
}

export type AdminQuarterRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester?: number
}

export type AdminRecovery = AdminAbsenceRecovery | AdminLunchtimeRecovery

export type AdminRecoveryRequest =
  | AdminAbsenceRecoveryRequest
  | AdminLunchtimeRecoveryRequest

export type AdminRoomService = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  house: string
  room: string
  decision_comment?: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSchoolYear = {
  id: number
  /** @maxLength 32 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semesters: AdminSemester[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSchoolYearRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semesters: AdminSemesterRequest[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSemester = {
  id: number
  /** @maxLength 32 */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  year?: number | null
  quarters: AdminQuarter[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminSemesterRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  year?: number | null
  quarters: AdminQuarterRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminSimpleBoard = {
  id: string
  /** @maxLength 128 */
  name: string
  widgets?: AdminWidget[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminSimpleBoardRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  widgets?: AdminWidgetRequest[]
}

export type AdminSiteSettings = {
  id: number
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

export type AdminSiteSettingsRequest = {
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

export type AdminStopTheClock = {
  id: string
  /**
   * * `pending` - Pending
   * * `pending_attendees` - Pending attendees
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status?: MeritRewardStatusEnum
  student: string
  /** @format date-time */
  created: string
  decision_comment?: string
  original_date: string
  prolonged_date: string
  /** @format uuid */
  campus_absence?: string | null
  participants: SimpleCampusAbsenceStudent[]
  leave_details: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminStudentAnnouncement = {
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  emojis?: string[]
  /** @maxLength 100 */
  text: string
  /** @maxLength 7 */
  bg_color?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  verbose_name: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AdminStudentAnnouncementWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  approved_student_announcements: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentApplicationMedication = {
  id: number
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  medicine_name: string
  dosage: string
  reason_and_diagnosis: string
  /**
   * * `new` - New
   * * `added` - Added
   * * `ignored` - Ignored
   */
  status?: AdminStudentApplicationMedicationStatusEnum
  /** @format date-time */
  processed_at?: string | null
  /** @format uuid */
  student?: string | null
  medicine_plan?: string | null
  /** @format uuid */
  processed_by?: string | null
}

export type AdminStudentApplicationMedicationRequest = {
  /** @minLength 1 */
  medicine_name: string
  /** @minLength 1 */
  dosage: string
  /** @minLength 1 */
  reason_and_diagnosis: string
  /**
   * * `new` - New
   * * `added` - Added
   * * `ignored` - Ignored
   */
  status?: AdminStudentApplicationMedicationStatusEnum
  /** @format date-time */
  processed_at?: string | null
  /** @format uuid */
  student?: string | null
  /** @minLength 1 */
  medicine_plan?: string | null
  /** @format uuid */
  processed_by?: string | null
}

/**
 * * `new` - New
 * * `added` - Added
 * * `ignored` - Ignored
 */
export type AdminStudentApplicationMedicationStatusEnum =
  | 'new'
  | 'added'
  | 'ignored'

export type AdminStudentArt = {
  id: number
  image: string
  image_data: Image
  /** @maxLength 255 */
  name: string
  /** @maxLength 255 */
  author: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminStudentArtGalleryWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  arts: AdminStudentArt[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminStudentArtGalleryWidgetRequest = {
  arts: AdminStudentArtRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentArtRequest = {
  /** @minLength 1 */
  image: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  author: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

export type AdminStudentContent =
  | AdminStudentMysteryLink
  | AdminStudentAnnouncement
  | AdminStudentPic

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminStudentMysteryLink = {
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  link: AdminLinkWithQRCode
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  verbose_name: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminStudentPic = {
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: Image
  /** @maxLength 50 */
  title?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  verbose_name: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AdminStudentsPicWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  approved_student_pics: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminStudentsPicWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminTeacher = {
  id: string
  username: string
  /** @format email */
  email: string
  first_name: string
  last_name: string
  full_name: string
  /** @maxLength 16 */
  acronym: string
  phone_number?: string
  departments?: number[]
  departments_str: string[]
  preferred_rooms?: number[]
  preferred_rooms_str: string[]
  two_factor_auth?: boolean
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  custom_schedule?: Record<string, any>
  is_active: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type AdminTeacherRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @minLength 1
   * @maxLength 16
   */
  acronym: string
  /** @minLength 1 */
  phone_number?: string
  departments?: number[]
  preferred_rooms?: number[]
  two_factor_auth?: boolean
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  custom_schedule?: Record<string, any>
  is_active: boolean
}

export type AdminTimetable =
  | TimetableExam
  | TimetableLesson
  | TimetableSchoolProgramme
  | TimetableRotaActivity

export type AdminTimetableCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  type: string
  level: string
  departments: string[]
}

export type AdminTimetableCourseRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  departments: string[]
}

export type AdminTimetableLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  end: string
  display_hour: string
  course: AdminTimetableCourse
  room: AdminTimetableRoom
  is_archived?: boolean
  /** @format uuid */
  teacher: string
  /** @format uuid */
  teacher_cover?: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  teacher_details: SimpleUser
  teacher_cover_details?: SimpleUser | null
  co_teacher_1_details: SimpleUser
  co_teacher_2_details: SimpleUser
}

export type AdminTimetableLessonRequest = {
  /** @format date */
  date: string
  is_archived?: boolean
  /** @format uuid */
  teacher: string
  /** @format uuid */
  teacher_cover?: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
}

export type AdminTimetableLessonRoomAssignment = {
  room?: number | null
}

export type AdminTimetableLessonRoomAssignmentRequest = {
  room?: number | null
}

export type AdminTimetableLessonUpdate = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  end: string
  is_archived: boolean
  /** @format uuid */
  teacher: string
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2: string | null
  /** @format uuid */
  teacher_cover?: string | null
}

export type AdminTimetableLessonUpdateRequest = {
  /** @format uuid */
  teacher_cover?: string | null
}

/**
 * * `Exam` - Exam
 * * `Lesson` - Lesson
 * * `SchoolProgramme` - SchoolProgramme
 * * `Rota` - Rota
 */
export type AdminTimetableResourcetypeEnum =
  | 'Exam'
  | 'Lesson'
  | 'SchoolProgramme'
  | 'Rota'

export type AdminTimetableRoom = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}

export type AdminTimetableRoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}

export type AdminTypes = {
  label: string
  resourcetype: ResourcetypeEnum
  polymorphic_ctype: number
  initial_data: AdminWidget
  /** @default "" */
  require_module: string
  thumbnail: string | null
  allowed_layouts: string
  use_default_bg_color: string
}

export type AdminUser = {
  /** @format uuid */
  id: string
  academichod: UserAcademicHODProfile | null
  academicadmin: UserAcademicAdminProfile | null
  idpadmin: UserIdpAdminProfile | null
  admin: UserAdminProfile | null
  teacher: UserTeacherProfile | null
  boardingdirector: UserBoardingDirectorProfile | null
  healthcoordinator: UserHealthCoordinatorProfile | null
  headofhouse: HeadOfHouseProfile | null
  housetutor: HouseTutorProfile | null
  nightguard: NightGuardProfile | null
  viewer: ViewerProfile | null
  campusheadteam: CampusHeadTeamProfile | null
  propertymanager: PropertyManagerProfile | null
  infoboardeditor: InfoboardEditorProfile | null
  behaviourmanagement: BehaviourManagementProfile | null
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string
  first_name: string
  last_name: string
  /** @format email */
  email: string
  phone_number: string
  two_factor_auth?: boolean
  full_name: string
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  profiles: string[]
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
  can_login_as_student: boolean
}

export type AdminUserChangeRequest = {
  academichod?: UserAcademicHODProfileRequest | null
  academicadmin?: UserAcademicAdminProfileRequest | null
  idpadmin?: UserIdpAdminProfileRequest | null
  admin?: UserAdminProfileRequest | null
  teacher?: UserTeacherProfileRequest | null
  boardingdirector?: UserBoardingDirectorProfileRequest | null
  healthcoordinator?: UserHealthCoordinatorProfileRequest | null
  headofhouse?: HeadOfHouseProfileRequest | null
  housetutor?: HouseTutorProfileRequest | null
  nightguard?: NightGuardProfileRequest | null
  viewer?: ViewerProfileRequest | null
  campusheadteam?: CampusHeadTeamProfileRequest | null
  propertymanager?: PropertyManagerProfileRequest | null
  infoboardeditor?: InfoboardEditorProfileRequest | null
  behaviourmanagement?: BehaviourManagementProfileRequest | null
  /** @minLength 1 */
  password1?: string
  /** @minLength 1 */
  password2?: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  phone_number: string
  two_factor_auth?: boolean
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
}

export type AdminUserList = {
  /** @format uuid */
  id: string
  full_name: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups?: number[]
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  /** @format date-time */
  last_login?: string | null
  staff_type?: StaffTypeEnum | BlankEnum
}

export type AdminUserPasswordChange = {
  /** @maxLength 128 */
  new_password1: string
  /** @maxLength 128 */
  new_password2: string
}

export type AdminUserPasswordChangeRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  new_password1: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  new_password2: string
}

export type AdminUserRequest = {
  /** @minLength 1 */
  password1: string
  /** @minLength 1 */
  password2: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  phone_number: string
  two_factor_auth?: boolean
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
}

export type AdminVariantManualCongratulations = {
  id: number
  /**
   * * `wreath` - Wreath
   * * `cap` - Cap
   */
  variant: VariantEnum
  images: string[]
  images_data: Image[]
  /** @format uuid */
  student: string
  student_str: string
  /** @maxLength 400 */
  message: string
  /** @maxLength 128 */
  course_name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

export type AdminVariantManualCongratulationsRequest = {
  /**
   * * `wreath` - Wreath
   * * `cap` - Cap
   */
  variant: VariantEnum
  images: string[]
  /** @format uuid */
  student: string
  /**
   * @minLength 1
   * @maxLength 400
   */
  message: string
  /** @maxLength 128 */
  course_name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminVariantManualCongratulationsWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  items: AdminVariantManualCongratulations[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  polymorphic_ctype: number | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AdminVariantManualCongratulationsWidgetRequest = {
  items: AdminVariantManualCongratulationsRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type AdminWidget =
  | AdminBoardingAnnouncementWidget
  | AdminCelebrationWidget
  | AdminEventAgendaWidget
  | AdminCongratulationsWidget
  | AdminPosterWidget
  | AdminStudentArtGalleryWidget
  | AdminEventInfoWidget
  | AdminEventInfoWithPhotoWidget
  | AdminJournalWidget
  | AdminMysteryLinkWidget
  | AdminMenuWidget
  | AdminStudentAnnouncementWidget
  | AdminStudentsPicWidget
  | AdminVariantManualCongratulationsWidget
  | AdminClockWidget

export type AdminWidgetRequest =
  | AdminBoardingAnnouncementWidgetRequest
  | AdminCelebrationWidgetRequest
  | AdminEventAgendaWidgetRequest
  | AdminCongratulationsWidgetRequest
  | AdminPosterWidgetRequest
  | AdminStudentArtGalleryWidgetRequest
  | AdminEventInfoWidgetRequest
  | AdminEventInfoWithPhotoWidgetRequest
  | AdminJournalWidgetRequest
  | AdminMysteryLinkWidgetRequest
  | AdminMenuWidgetRequest
  | AdminStudentAnnouncementWidgetRequest
  | AdminStudentsPicWidgetRequest
  | AdminVariantManualCongratulationsWidgetRequest
  | AdminClockWidgetRequest

export type AgendaItem = {
  id: number
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  activity: string
  location?: string
}

export type AgendaItemRequest = {
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  /** @minLength 1 */
  activity: string
  location?: string
}

export type Aim = {
  id: number
  /** @maxLength 64 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  is_default?: boolean
}

export type AllergenKindOption = {
  label: string
  value: string
  allergens: StringOption[]
}

export type Allergy = {
  id: number
  /** @format date-time */
  created: string
  type: string
  subtypes?: string[]
}

export type AllergyChoicesResponse = {
  reactions: AllergyReactionOption[]
  allergen_kinds: AllergenKindOption[]
  medicines: StringOption[]
  medicine_locations: StringOption[]
  immediate_responses: StringOption[]
}

export type AllergyReactionOption = {
  label: string
  value: string
  mild_reactions: StringOption[]
  severe_reactions: StringOption[]
}

export type AllergyRequest = {
  /** @minLength 1 */
  type: string
  subtypes?: string[]
}

/**
 * * `reserved` - reserved
 * * `social_room` - social_room
 * * `duty_room` - duty_room
 * * `storage` - storage
 * * `artisan_private_room` - artisan_private_room
 * * `student_accommodation` - student_accommodation
 * * `not_assigned` - not_assigned
 */
export type AnnotatedHouseRoomStatusChoices =
  | 'reserved'
  | 'social_room'
  | 'duty_room'
  | 'storage'
  | 'artisan_private_room'
  | 'student_accommodation'
  | 'not_assigned'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AppMysteryLink = {
  id: string
  student_data: StudentWithAvatar
  link: LinkWithQRCode
  status: StudentContentStatusEnum
  /** @format date-time */
  created: string
  /** @format date-time */
  published_at: string | null
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AppMysteryLinkRequest = {
  student?: string
  link: LinkWithQRCodeRequest
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AppStatusVersion = {
  ios_minimum_required_version?: string
  ios_minimum_recommended_version?: string
  android_minimum_required_version?: string
  android_minimum_recommended_version?: string
  parent_ios_minimum_required_version?: string
  parent_ios_minimum_recommended_version?: string
  parent_android_minimum_required_version?: string
  parent_android_minimum_recommended_version?: string
}

export type AppStudentAnnouncement = {
  id: string
  student_data: StudentWithAvatar
  emojis: string[]
  /** @maxLength 100 */
  text: string
  /** @maxLength 7 */
  bg_color?: string
  status: StudentContentStatusEnum
  /** @format date-time */
  created: string
  /** @format date-time */
  published_at: string | null
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AppStudentAnnouncementRequest = {
  student?: string
  emojis: string[]
  /**
   * @minLength 1
   * @maxLength 100
   */
  text: string
  /** @maxLength 7 */
  bg_color?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AppStudentPic = {
  id: string
  student_data: StudentWithAvatar
  image: string
  image_details: PublicImage
  /** @maxLength 50 */
  title?: string
  status: StudentContentStatusEnum
  /** @format date-time */
  created: string
  /** @format date-time */
  published_at: string | null
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type AppStudentPicRequest = {
  student?: string
  /** @minLength 1 */
  image: string
  /** @maxLength 50 */
  title?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype: StudentContentResourcetypeEnum
}

export type Appointment = {
  id: number
  /** @format date-time */
  created: string
  /**
   * * `hospital` - Hospital
   * * `doctor` - Doctor
   */
  appointment_type?: AppointmentTypeEnum
  appointment_type_label: string
  doctor?: number | null
  doctor_name?: string
  doctor_str: string
  hospital?: number | null
  hospital_name?: string
  hospital_str: string
  /** @format date-time */
  date_and_time: string
  report: string
  diagnosis: string
  recommendations: string
  status: AppointmentStatusEnum
  duration_in_minutes: number
  /** @format date-time */
  end_of_visit: string
  cancellation_reason: string
}

export type AppointmentCancellation = {
  cancellation_reason?: string
}

export type AppointmentCancellationRequest = {
  /** @minLength 1 */
  cancellation_reason?: string
}

export type AppointmentRequest = {
  /**
   * * `hospital` - Hospital
   * * `doctor` - Doctor
   */
  appointment_type?: AppointmentTypeEnum
  doctor?: number | null
  doctor_name?: string
  hospital?: number | null
  hospital_name?: string
  /** @format date-time */
  date_and_time: string
  /** @minLength 1 */
  report: string
  /** @format date-time */
  end_of_visit: string
}

/**
 * * `new` - New
 * * `cancelled` - Cancelled
 * * `after_the_visit` - After the visit
 */
export type AppointmentStatusEnum = 'new' | 'cancelled' | 'after_the_visit'

/**
 * * `hospital` - Hospital
 * * `doctor` - Doctor
 */
export type AppointmentTypeEnum = 'hospital' | 'doctor'

export type ApproveEnrolment = {
  enrolment: number
}

export type ApproveEnrolmentRequest = {
  enrolment: number
}

export type ApproveStudentContent = {
  /** @format date */
  display_to: string | null
}

export type AssignStudentToSectionRequest = {
  target_group: number
  idp_section_enrollments: number[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Assignment = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  description: string
  type: number
  /** @format date */
  due_date: string
  /** @format date-time */
  created: string
  /** @format uuid */
  lesson?: string | null
  /** @format uuid */
  course: string
  course_str: string
  teacher_str: string
  /** @format uuid */
  teacher: string | null
  student_items: StudentAssignmentItem[]
  is_homework?: boolean
  quarter?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AssignmentGrade = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  assignment: AssignmentInStudentAssignmentItem
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AssignmentGradeRequest = {
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  assignment: AssignmentInStudentAssignmentItemRequest
}

export type AssignmentInStudentAssignmentItem = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  description: string
  type: number
  /** @format date */
  due_date: string
  type_str: string
}

export type AssignmentInStudentAssignmentItemRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /** @minLength 1 */
  description: string
  type: number
  /** @format date */
  due_date: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type AssignmentRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /** @minLength 1 */
  description: string
  type: number
  /** @format date */
  due_date: string
  /** @format uuid */
  lesson?: string | null
  /** @format uuid */
  course: string
  student_items: StudentAssignmentItemRequest[]
  is_homework?: boolean
  quarter?: number
}

export type AssignmentType = {
  id: number
  /** @maxLength 64 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
}

export type Attachment = {
  id: string
  /** @format uri */
  file: string
  user?: string
  file_name: string
}

export type AttachmentRequest = {
  /** @format binary */
  file: File
  user?: string
}

export type AuthTokenRequest = {
  /** @minLength 1 */
  username: string
  /** @minLength 1 */
  password: string
}

export type AvailableIntegerFilter = {
  query_param: string
  label: string
  choices: IntegerOption[]
}

export type AvailableStringFilter = {
  query_param: string
  label: string
  choices: StringOption[]
}

export type AvailableStringFilterHealthCheckStudent = {
  query_param: string
  label: string
  choices: HealthCheckStudentOption[]
}

export type AvailableStringFilterHouseRoomBookedFor = {
  query_param: string
  label: string
  choices: HouseRoomBookedForOption[]
}

export type AvailableStudent = {
  /** @format uuid */
  id: string
  full_name: string
  student_grade_level: number
  student_house: string
  student_category: string
  student_category_label: string
}

export type AvailableStudentForSchoolProgrammeAvailableFilters = {
  houses: AvailableIntegerFilter
  grade_levels: AvailableIntegerFilter
  ages: AvailableIntegerFilter
  categories: AvailableIntegerFilter
}

export type AvailableStudents = {
  value: string
  label: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Basket = {
  id: number
  courses: CourseInBasket[]
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** Basket type */
  choice_type: ChoiceTypeEnum
  name?: string
  key: string
  is_ignored?: boolean
  /** @format double */
  credit_count: number
  include_extra_basket?: boolean
  idp_plan?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type BasketRequest = {
  courses: CourseInBasketRequest[]
  /**
   * @min 0
   * @max 2147483647
   */
  order?: number
  /** Basket type */
  choice_type: ChoiceTypeEnum
  name?: string
  /** @minLength 1 */
  key: string
  is_ignored?: boolean
  include_extra_basket?: boolean
  /** @minLength 1 */
  idp_plan?: string | null
}

export type BehaviourAndAttitudeProgressComment = {
  /** @format uuid */
  id: string
  positive_comments?: string[]
  to_be_improved_comments?: string[]
}

export type BehaviourAndAttitudeProgressCommentRequest = {
  positive_comments?: string[]
  to_be_improved_comments?: string[]
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type BehaviourManagementProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type BehaviourManagementProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type BillingReport = {
  /** @format date-time */
  created: string
  /** @maxLength 120 */
  name: string
  timeframe_str: string
  author_str: string
  id: string
}

export type BillingReportCreate = {
  /** @maxLength 120 */
  name: string
  all_students: boolean
  students: string[]
  /**
   * * `quarter` - Quarter
   * * `school_year` - School Year
   */
  timeframe_type: TimeframeTypeEnum
  product_types: ProductTypesEnum[]
  quarter?: number | null
  school_year?: number | null
  grade_levels?: number[]
  author?: string
  items?: BillingReportItem[] | null
  id: string
}

export type BillingReportCreateRequest = {
  /**
   * @minLength 1
   * @maxLength 120
   */
  name: string
  all_students: boolean
  students: string[]
  /**
   * * `quarter` - Quarter
   * * `school_year` - School Year
   */
  timeframe_type: TimeframeTypeEnum
  product_types: ProductTypesEnum[]
  quarter?: number | null
  school_year?: number | null
  grade_levels?: number[]
  author?: string
  items?: BillingReportItemRequest[] | null
}

export type BillingReportItem = {
  /** @format uuid */
  student: string
  description?: string
  /**
   * * `quarter` - Quarter
   * * `week` - Week
   * * `year` - Year
   */
  quantity_unit: QuantityUnitEnum
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price: string
  total_price_currency: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  unit_price: string
  unit_price_currency: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  quantity: number
  /**
   * * `academic_individual` - Academic Individual
   * * `sports` - Sports
   * * `co_curricular` - Co-Curricular
   * * `graduation_fee` - Graduation Fee
   */
  product_type: ProductTypeEnum
  product_type_display: string
  product_subtype?: string
  details?: Record<string, any>
  plural_quantity_unit: string
  abacus_invoice_product?: number | null
  abacus_invoice_product_name?: string
}

export type BillingReportItemRequest = {
  /** @format uuid */
  student: string
  description?: string
  /**
   * * `quarter` - Quarter
   * * `week` - Week
   * * `year` - Year
   */
  quantity_unit: QuantityUnitEnum
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  total_price: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  unit_price: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  quantity: number
  /**
   * * `academic_individual` - Academic Individual
   * * `sports` - Sports
   * * `co_curricular` - Co-Curricular
   * * `graduation_fee` - Graduation Fee
   */
  product_type: ProductTypeEnum
  product_subtype?: string
  details?: Record<string, any>
  abacus_invoice_product?: number | null
  abacus_invoice_product_name?: string
}

export type BillingReportRequest = {
  /**
   * @minLength 1
   * @maxLength 120
   */
  name: string
}

export type BlankEnum = ''

/**
 * * `0-` - 0-
 * * `0+` - 0+
 * * `a-` - A-
 * * `a+` - A+
 * * `b-` - B-
 * * `b+` - B+
 * * `ab-` - AB-
 * * `ab+` - AB+
 * * `unknown` - Unknown
 */
export type BloodTypeEnum =
  | '0-'
  | '0+'
  | 'a-'
  | 'a+'
  | 'b-'
  | 'b+'
  | 'ab-'
  | 'ab+'
  | 'unknown'

/**
 * * `boarding_director` - Boarding Director
 * * `campus_head` - Campus Head
 * * `deputy_campus_head_main` - Deputy Campus Head (Main)
 * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
 * * `head_of_house` - Head of House
 * * `house_tutor` - House Tutor
 */
export type BoardingArtisanRoleEnum =
  | 'boarding_director'
  | 'campus_head'
  | 'deputy_campus_head_main'
  | 'deputy_campus_head_senior'
  | 'head_of_house'
  | 'house_tutor'

export type BoardingAttendance = {
  /** @format uuid */
  id: string
  mark?: BoardingAttendanceMarkEnum | BlankEnum
  student_str: string
  /** @format uuid */
  student: string
  is_at_home?: boolean
  is_at_class?: boolean
  is_at_exam?: boolean
  boarding_status?: string
  category: string
  category_week_ago: string
  category_str: string
  category_week_ago_str: string
}

/**
 * * `present` - Present
 * * `late` - Late
 * * `sick` - Sick
 * * `absent_excused` - Absent exc.
 * * `absent_unexcused` - Absent unexc.
 */
export type BoardingAttendanceMarkEnum =
  | 'present'
  | 'late'
  | 'sick'
  | 'absent_excused'
  | 'absent_unexcused'

export type BoardingAttendanceReport = {
  id: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  tutor_str: string
  status_display: string
  house?: number | null
  house_str: string
  /** @format date-time */
  created: string
  next_report: string
  previous_report: string
  for_senior_campus?: boolean
}

export type BoardingAttendanceReportList = {
  id: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  tutor_str: string
  status_display: string
  /** @format date-time */
  created: string
  house?: number | null
  house_str: string
}

export type BoardingAttendanceReportRequest = {
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  house?: number | null
  for_senior_campus?: boolean
}

/**
 * * `new` - New
 * * `incomplete` - Incomplete
 * * `finished` - Finished
 */
export type BoardingAttendanceReportStatusEnum =
  | 'new'
  | 'incomplete'
  | 'finished'

export type BoardingAttendanceRequest = {
  mark?: BoardingAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student: string
  is_at_home?: boolean
  is_at_class?: boolean
  is_at_exam?: boolean
  /** @minLength 1 */
  boarding_status?: string
}

export type BoardingComment = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  added_by?: string
  /** @format uuid */
  added_by_id: string | null
  last_modified_by?: string
  /**
   * @min 0
   * @max 32767
   */
  semester?: SemesterEnum | NullEnum | null
  /** @format uuid */
  student: string
  school_year: number
  current_quarter: number
  content: string
}

export type BoardingCommentRequest = {
  added_by?: string
  last_modified_by?: string
  /**
   * @min 0
   * @max 32767
   */
  semester?: SemesterEnum | NullEnum | null
  /** @format uuid */
  student: string
  school_year: number
  current_quarter: number
  /** @minLength 1 */
  content: string
}

export type BusyInSlotRequestRequest = {
  /**
   * * `0` - Monday
   * * `1` - Tuesday
   * * `2` - Wednesday
   * * `3` - Thursday
   * * `4` - Friday
   */
  day: DayEnum
  /**
   * * `0` - 8:00-8:40
   * * `1` - 8:45-9:25
   * * `2` - 9:30-10:10
   * * `3` - 10:30-11:10
   * * `4` - 11:15-11:55
   * * `5` - 13:00-13:40
   * * `6` - 13:45-14:25
   * * `7` - 14:30-15:10
   * * `8` - 15:15-15:55
   * * `9` - 16:00-16:40
   * * `10` - 16:45-17:25
   * * `11` - 17:30-18:10
   * * `12` - 18:15-18:55
   * * `13` - 19:00-19:40
   * * `14` - 19:40-20:30
   * * `15` - 20:30-21:00
   */
  hour: HourEnum
  /** @min 1 */
  duration: number
}

export type BusyInSlotResponse = {
  busy_teachers_ids: string[]
  busy_rooms_ids: number[]
}

export type CalendarEvent = {
  id: number
  event_type_display: string
  /** @format date */
  date: string
  /**
   * * `community_weekend` - Community weekend
   * * `academic_tests` - Academic tests
   */
  event_type: EventTypeEnum
}

export type CalendarEventRequest = {
  /** @format date */
  date: string
  /**
   * * `community_weekend` - Community weekend
   * * `academic_tests` - Academic tests
   */
  event_type: EventTypeEnum
}

export type Callback = {
  /**
   * * `success` - Success
   * * `infeasible` - Infeasible
   * * `error` - Error
   * * `optimizing` - Optimizing
   */
  status: CallbackStatusEnum
  timetable_path?: string
  external_id: string
  is_optimal?: boolean | null
}

export type CallbackRequest = {
  /**
   * * `success` - Success
   * * `infeasible` - Infeasible
   * * `error` - Error
   * * `optimizing` - Optimizing
   */
  status: CallbackStatusEnum
  timetable_path?: string
  /** @minLength 1 */
  external_id: string
  is_optimal?: boolean | null
}

/**
 * * `success` - Success
 * * `infeasible` - Infeasible
 * * `error` - Error
 * * `optimizing` - Optimizing
 */
export type CallbackStatusEnum =
  | 'success'
  | 'infeasible'
  | 'error'
  | 'optimizing'

/** Adds nested create feature */
export type CampusAbsence = {
  /** @format uuid */
  id: string
  created_by?: string
  last_modified_by?: string
  campus_absence_students?: CampusAbsenceStudent[]
  quarter: number
  status: CampusAbsenceStatusEnum
  status_label: string
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  campus_absence_type: CampusAbsenceTypeEnum
  campus_absence_type_label: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  campus_absence_subtype_label: string
  leave_details: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  check_in: boolean
  check_out: boolean
  disable_students_length_validation?: boolean
  supervisors?: (string | null)[]
  house?: number | null
  has_comment: string
  merit_reward: string
  prolonged_by: string
}

export type CampusAbsenceChangeStatus = {
  /**
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status: CampusAbsenceStatusEnum
}

export type CampusAbsenceChangeStatusRequest = {
  /**
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status: CampusAbsenceStatusEnum
}

export type CampusAbsenceCheckInOut = {
  check_in: boolean
  check_out: boolean
}

export type CampusAbsenceCheckInOutRequest = {
  check_in: boolean
  check_out: boolean
}

export type CampusAbsenceComment = {
  /** @format uuid */
  id: string
  author?: string
  content: string
  /** @format date-time */
  modified: string
  can_edit: boolean
}

export type CampusAbsenceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content: string
  reject?: boolean
}

export type CampusAbsenceLog = {
  id: number
  action: string
  description: string
  /** @format date-time */
  timestamp: string
}

/** Adds nested create feature */
export type CampusAbsenceRequest = {
  created_by?: string
  last_modified_by?: string
  campus_absence_students?: CampusAbsenceStudentRequest[]
  quarter: number
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  campus_absence_type: CampusAbsenceTypeEnum
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  /** @minLength 1 */
  leave_details: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  disable_students_length_validation?: boolean
  supervisors?: (string | null)[]
  house?: number | null
}

/**
 * * `requested` - Pending
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `canceled_by_student` - Canceled by student
 * * `done` - Done
 * * `cancelled` - Cancelled
 */
export type CampusAbsenceStatusEnum =
  | 'requested'
  | 'approved'
  | 'rejected'
  | 'canceled_by_student'
  | 'done'
  | 'cancelled'

export type CampusAbsenceStudent = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_data: StudentSimple
  saved_category: number | null
  saved_category_week_ago: number | null
  saved_homework_status: string | null
  saved_current_week_ausgangs_count: Record<string, any>
  homework_status: string
  category: string
  category_week_ago: string
}

export type CampusAbsenceStudentRequest = {
  /** @format uuid */
  student: string
}

/**
 * * `afternoon_ausgang` - Afternoon Ausgang
 * * `evening_ausgang` - Evening Ausgang
 * * `evening_campus_ausgang` - Evening - Campus Ausgang
 * * `birthday_ausgang` - Birthday Ausgang
 * * `family_visit` - Family visit
 * * `on_board` - On board Ausgang
 * * `prolonged_ausgang` - Prolonged Ausgang
 * * `campus` - Campus
 * * `offsite` - Offsite
 * * `offsite_overnight` - Offsite, Overnight
 * * `official_appointment` - Official appointment
 * * `specialist_doctor_appointment` - Specialist Doctor Appointment
 * * `programme` - Programme
 * * `house_evening` - House evening
 * * `term_break` - Term Break
 * * `school_trip` - School Trip
 * * `weekend_programme` - Weekend Programme
 * * `other` - Other
 * * `book_the_cafe` - Book the Cafe
 * * `city_brunch_zurich` - City Brunch Zurich
 * * `additional_ausgang` - Additional Ausgang
 */
export type CampusAbsenceSubTypeEnum =
  | 'afternoon_ausgang'
  | 'evening_ausgang'
  | 'evening_campus_ausgang'
  | 'birthday_ausgang'
  | 'family_visit'
  | 'on_board'
  | 'prolonged_ausgang'
  | 'campus'
  | 'offsite'
  | 'offsite_overnight'
  | 'official_appointment'
  | 'specialist_doctor_appointment'
  | 'programme'
  | 'house_evening'
  | 'term_break'
  | 'school_trip'
  | 'weekend_programme'
  | 'other'
  | 'book_the_cafe'
  | 'city_brunch_zurich'
  | 'additional_ausgang'

/**
 * * `ausgang` - Ausgang
 * * `external_exam` - External exam
 * * `official` - Official
 * * `school_programme` - School Programme
 * * `extra_ausgang` - Extra Ausgang
 */
export type CampusAbsenceTypeEnum =
  | 'ausgang'
  | 'external_exam'
  | 'official'
  | 'school_programme'
  | 'extra_ausgang'

export type CampusAbsenceWarning = {
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  students: string[]
  /** @format uuid */
  excluded_campus_absence?: string | null
}

export type CampusAbsenceWarningRequest = {
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  students: string[]
  /** @format uuid */
  excluded_campus_absence?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type CampusHeadTeamProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type CampusHeadTeamProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * * `no_cancellation` - Don't cancel classes
 * * `msl_hsl_enrolled` - Cancel all the MSL and HSL classes for the students enrolled in this exam session.
 * * `msl_hsl_enrolled_and_not_enrolled` - Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).
 * * `enrolled` - Cancel all the classes for the students enrolled in this exam session.
 * * `enrolled_and_not_enrolled` - Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session).
 */
export type CancelClassTypeEnum =
  | 'no_cancellation'
  | 'msl_hsl_enrolled'
  | 'msl_hsl_enrolled_and_not_enrolled'
  | 'enrolled'
  | 'enrolled_and_not_enrolled'

export type CancelLesson = {
  id: number
  lesson: TimetableLesson
  related_object_content_type: number | null
  /** Python model class name */
  related_object_content_type_model: string
  related_object_id: string
  cancellation_reason_label: string
}

/**
 * * `0` - U
 * * `1` - S
 * * `2` - D
 * * `3` - T
 */
export type CapacityEnum = 0 | 1 | 2 | 3

export type Catalog = {
  id: number
  name: string
}

export type CatalogRequest = {
  /** @minLength 1 */
  name: string
}

export type ChangeAvatar = {
  /** @format uri */
  current_avatar: string
}

export type ChangeAvatarRequest = {
  /** @format binary */
  avatar: File | null
}

export type ChangeCoTeacher1InSectionRequest = {
  /** @format uuid */
  co_teacher_1: string
  target_group: number
  apply_in_quarters: QuarterShortNameEnum[]
}

export type ChangeCoTeacher2InSectionRequest = {
  /** @format uuid */
  co_teacher_2: string
  target_group: number
  apply_in_quarters: QuarterShortNameEnum[]
}

export type ChangeEndDateMedicineInPlan = {
  /** @format date-time */
  end_date: string
}

export type ChangeEndDateMedicineInPlanRequest = {
  /** @format date-time */
  end_date: string
}

export type ChangeTeacherInSectionRequest = {
  /** @format uuid */
  teacher: string
  target_group: number
  apply_in_quarters: QuarterShortNameEnum[]
}

export type ChangesInSectionsRequest = {
  enrolments: AssignStudentToSectionRequest[]
  teachers: ChangeTeacherInSectionRequest[]
  co_teachers_1: ChangeCoTeacher1InSectionRequest[]
  co_teachers_2: ChangeCoTeacher2InSectionRequest[]
}

export type CheckClashesRequest = {
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
}

/**
 * * `default` - Default
 * * `private` - Private
 * * `addon` - Addon
 * * `free_choice` - Free Choice
 * * `exam` - Exam
 * * `higher_level` - Higher Level
 */
export type ChoiceTypeEnum =
  | 'default'
  | 'private'
  | 'addon'
  | 'free_choice'
  | 'exam'
  | 'higher_level'

export type ChronicCondition = {
  id: number
  /** @format date-time */
  created: string
  type: string
  subtypes?: string[]
}

export type ChronicConditionChoicesResponse = {
  condition_types: ConditionTypeOption[]
  impacts_on_daily_routine: StringOption[]
  watch_out_for: StringOption[]
  emergency_medicine_applications: StringOption[]
  emergency_medicine_locations: StringOption[]
  iep_contacts: StringOption[]
  immediate_responses: StringOption[]
}

export type ChronicConditionRequest = {
  /** @minLength 1 */
  type: string
  subtypes?: string[]
}

export type ClashWithExamEC = {
  id: string
  course_name: string
  /** @format date */
  date?: string | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
}

/**
 * * `regular` - Regular
 * * `sport` - Sport
 */
export type ClassTypeEnum = 'regular' | 'sport'

/**
 * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
 * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
 * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
 * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
 */
export type ClassificationEnum = 'l1' | 'l2' | 'l3' | 'l4'

/**
 * * `mistake` - Sick report made by mistake
 * * `student_not_sick` - Student not sick
 * * `student_recovered` - Student recovered
 */
export type CloseReasonEnum =
  | 'mistake'
  | 'student_not_sick'
  | 'student_recovered'

export type CloseSickReport = {
  id: string
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type CloseSickReportRequest = {
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type CodeRequestResponse = {
  /** @format email */
  email: string
  cannot_use_sms_login: boolean
}

export type Comment = {
  related_id: string
  related_author: string
  author_first_name: string
  author_last_name: string
  author_profiles: string[]
  /** @format uuid */
  student_id: string
  student_first_name: string
  student_last_name: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  comment_type: string
  is_weekly: boolean
  related_course: string
  course_name: string
  content: string
}

export type Competency = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type CompetencyRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

export type Condition = {
  id: number
  /** @format uuid */
  health_check: string
  /**
   * * `unchanged` - Unchanged
   * * `better` - Better
   * * `worse` - Worse
   */
  status: ConditionStatusEnum
  new_symptoms?: NewSymptomsEnum[]
  new_symptoms_appeared?: boolean
}

export type ConditionRequest = {
  /** @format uuid */
  health_check: string
  /**
   * * `unchanged` - Unchanged
   * * `better` - Better
   * * `worse` - Worse
   */
  status: ConditionStatusEnum
  new_symptoms?: NewSymptomsEnum[]
  new_symptoms_appeared?: boolean
}

/**
 * * `unchanged` - Unchanged
 * * `better` - Better
 * * `worse` - Worse
 */
export type ConditionStatusEnum = 'unchanged' | 'better' | 'worse'

export type ConditionTypeOption = {
  label: string
  value: string
  subtypes: StringOption[]
}

/**
 * * `leave_request` - Leave Request
 * * `boarding` - Boarding
 * * `rooming` - Rooming
 * * `health` - Health
 * * `meal_plans` - Meal Plans
 * * `idp` - IDP (R) - Individual Development Plan
 * * `academic` - Academic
 * * `university_application` - University Application
 * * `sport` - Sport
 * * `co_curricular` - Co-Curricular
 * * `accounting` - Accounting
 * * `app_helpdesk` - App Helpdesk
 * * `other` - Other topics
 */
export type ContactMessageSubjectEnum =
  | 'leave_request'
  | 'boarding'
  | 'rooming'
  | 'health'
  | 'meal_plans'
  | 'idp'
  | 'academic'
  | 'university_application'
  | 'sport'
  | 'co_curricular'
  | 'accounting'
  | 'app_helpdesk'
  | 'other'

/**
 * * `parent` - Parent
 * * `legal_guardian` - Legal Guardian
 * * `in_legal_guardian_absence` - In Legal Guardian Absence
 */
export type ContactTypeEnum =
  | 'parent'
  | 'legal_guardian'
  | 'in_legal_guardian_absence'

export type CopyTalentEnrichment = {
  source_timetable: number
  target_timetable: number
}

export type CopyTalentEnrichmentRequest = {
  source_timetable: number
  target_timetable: number
}

export type CountedLessonsParams = {
  /** @format date */
  date_from: string
  /** @format date */
  date_to: string
}

export type CourseDepartment = {
  id: number
  /** @maxLength 32 */
  name: string
}

export type CourseDescription = {
  description?: string
}

export type CourseDetail = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  /** @maxLength 128 */
  code: string
  types?: CourseType[]
  preparation_for_types?: CourseType[]
  levels?: CourseLevel[]
  /** @format double */
  credits: number | null
  departments?: CourseDepartment[]
  rooms?: CourseRoom[]
  teachers: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  difficulty: string
  previous_courses: number[]
  next_courses: number[]
  group?: CourseGroup
  families?: CourseFamily[]
  intelligences?: CourseIntelligence[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  /** RPS-6527 */
  is_payable?: boolean
}

export type CourseEnrollment = {
  /** @format uuid */
  id: string
  name: string
  teacher_str: string
  teacher: string
  is_talent_and_enrichment: string
  enrollment_id: number
  student: EnrolledStudent
  quarter: number
  course: EnrolledCourse
}

export type CourseFamily = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseFamilyRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseGroup = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseGroupRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CourseInBasket = {
  id: number
  requested_course?: RequestedCourse | null
  /** @format uuid */
  course?: string | null
  quarters: IdpSectionEnrollment[]
  basket?: number
  apply_for_all_quarters?: boolean
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type CourseInBasketRequest = {
  requested_course?: RequestedCourseRequest | null
  /** @format uuid */
  course?: string | null
  quarters: IdpSectionEnrollmentRequest[]
  basket?: number
  apply_for_all_quarters?: boolean
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

export type CourseInBasketUpdateDepartment = {
  id: number
  requested_course: number | null
  /** @format uuid */
  course: string | null
  quarters: number[]
  basket: number
  apply_for_all_quarters: boolean
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

export type CourseInBasketUpdateDepartmentRequest = {
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

export type CourseInExamSession = {
  exam_id: string
  date: string
  start_time: string
  end_time: string
  course_id: string
  course_name: string
}

export type CourseInIdp = {
  /** @format uuid */
  id: string
  name: string
  code: string
  departments: string[]
  departments_for_mission_control: string[]
  types: string[]
  levels: string[]
  group: string | null
  subject: string | null
  number_of_lessons: number | null
  is_talent_and_enrichment: string
  next_course_ids: string[]
  performed_in_quarters: QuarterShortNameEnum[]
}

export type CourseIntelligence = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseIntelligenceRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseLevel = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseLevelRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseList = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  types: string[]
  preparation_for_types?: string[]
  department_names: string[]
  /** @maxLength 128 */
  code: string
  version: number
  departments: number[]
  is_latest_version?: boolean
  levels: CourseLevel[]
  readable_filter_option: string
  /** RPS-6527 */
  is_payable?: boolean
}

export type CourseMissionControlDepartment = {
  /** @format uuid */
  course: string
  /**
   * * `ENG` - ENG
   * * `MAT` - MAT
   * * `SCI` - SCI
   * * `MLA` - MLA
   * * `SSD` - SSD
   * * `ART` - ART
   */
  mission_control_department: MissionControlDepartmentEnum
}

export type CourseMissionControlDepartmentRequest = {
  /** @format uuid */
  course: string
  /**
   * * `ENG` - ENG
   * * `MAT` - MAT
   * * `SCI` - SCI
   * * `MLA` - MLA
   * * `SSD` - SSD
   * * `ART` - ART
   */
  mission_control_department: MissionControlDepartmentEnum
}

export type CoursePlanner = {
  /** @format uuid */
  id: string
  number_of_lessons?: number | null
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 128 */
  name: string
  code: string
  /** @default 1 */
  version: number
  is_latest_version?: boolean
  /** @format double */
  credits: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  difficulty: string
  number: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  old_is_exam_course_flag: boolean
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  legacy_department?: number | null
  group: string
  subject: string
  departments: number[]
  rooms?: number[]
  families: string[]
  intelligences: string[]
  types: string[]
  preparation_for_types?: string[]
  levels: string[]
}

export type CoursePlannerList = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  code: string
  /** @maxLength 128 */
  name: string
  is_latest_version?: boolean
  version: number
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
}

export type CoursePlannerRequest = {
  number_of_lessons?: number | null
  previous_courses?: string[]
  related_courses?: string[]
  course_request?: number | null
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  is_latest_version?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /** @minLength 1 */
  difficulty: string
  /** @minLength 1 */
  number: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  legacy_department?: number | null
  /** @minLength 1 */
  group: string
  /** @minLength 1 */
  subject: string
  departments: number[]
  rooms?: number[]
  families: string[]
  intelligences: string[]
  types: string[]
  preparation_for_types?: string[]
  levels: string[]
}

export type CoursePlannerResponse = {
  /** @format uuid */
  id: string
  number_of_lessons?: number | null
  previous_courses: string[]
  next_courses: string[]
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 128 */
  name: string
  code: string
  /** @default 1 */
  version: number
  is_latest_version?: boolean
  /** @format double */
  credits: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  difficulty: string
  number: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  old_is_exam_course_flag: boolean
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  legacy_department?: number | null
  group: string
  subject: string
  departments: number[]
  rooms?: number[]
  families: string[]
  intelligences: string[]
  types: string[]
  preparation_for_types?: string[]
  levels: string[]
}

export type CourseRoom = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}

export type CourseSubject = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseSubjectRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseType = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseTypeRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CurrentLesson = {
  /** @format uuid */
  id: string
  students: string[]
  course: string
}

export type CurrentLocation = {
  /** @format uuid */
  id: string
  current_location: string
  boarding_status: string
}

/**
 * * `EI` - Grade Exempt: Illness, Credit Awarded
 * * `EF` - Grade Exempt: Force-Majeure, Credit Awarded
 * * `EO` - Grade Exempt: Other, Credit Awarded
 * * `IC` - Incomplete
 * * `SC` - Successfully Completed
 * * `CA` - Successfully Completed & Credit Awarded
 */
export type CustomGradeEnum = 'EI' | 'EF' | 'EO' | 'IC' | 'SC' | 'CA'

/**
 * * `0` - Monday
 * * `1` - Tuesday
 * * `2` - Wednesday
 * * `3` - Thursday
 * * `4` - Friday
 */
export type DayEnum = 0 | 1 | 2 | 3 | 4

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type DayMenu = {
  id: number
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date */
  date: string
  is_filled?: boolean
  is_skipped?: boolean
  is_published: boolean
  /** @maxLength 120 */
  lunch_starter?: string
  /** @maxLength 120 */
  lunch_soup?: string
  /** @maxLength 120 */
  lunch_main_course?: string
  /** @maxLength 120 */
  lunch_dessert?: string
  /** @maxLength 120 */
  lunch_starter_vege?: string
  /** @maxLength 120 */
  lunch_soup_vege?: string
  /** @maxLength 120 */
  lunch_main_course_vege?: string
  /** @maxLength 120 */
  lunch_dessert_vege?: string
  /** @maxLength 120 */
  dinner_starter?: string
  /** @maxLength 120 */
  dinner_soup?: string
  /** @maxLength 120 */
  dinner_main_course?: string
  /** @maxLength 120 */
  dinner_dessert?: string
  /** @maxLength 120 */
  dinner_starter_vege?: string
  /** @maxLength 120 */
  dinner_soup_vege?: string
  /** @maxLength 120 */
  dinner_main_course_vege?: string
  /** @maxLength 120 */
  dinner_dessert_vege?: string
  /** @maxLength 120 */
  breakfast_special?: string
  /** @maxLength 120 */
  breakfast_special_vege?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type DayMenuRequest = {
  /** @format date */
  date: string
  is_filled?: boolean
  is_skipped?: boolean
  /** @maxLength 120 */
  lunch_starter?: string
  /** @maxLength 120 */
  lunch_soup?: string
  /** @maxLength 120 */
  lunch_main_course?: string
  /** @maxLength 120 */
  lunch_dessert?: string
  /** @maxLength 120 */
  lunch_starter_vege?: string
  /** @maxLength 120 */
  lunch_soup_vege?: string
  /** @maxLength 120 */
  lunch_main_course_vege?: string
  /** @maxLength 120 */
  lunch_dessert_vege?: string
  /** @maxLength 120 */
  dinner_starter?: string
  /** @maxLength 120 */
  dinner_soup?: string
  /** @maxLength 120 */
  dinner_main_course?: string
  /** @maxLength 120 */
  dinner_dessert?: string
  /** @maxLength 120 */
  dinner_starter_vege?: string
  /** @maxLength 120 */
  dinner_soup_vege?: string
  /** @maxLength 120 */
  dinner_main_course_vege?: string
  /** @maxLength 120 */
  dinner_dessert_vege?: string
  /** @maxLength 120 */
  breakfast_special?: string
  /** @maxLength 120 */
  breakfast_special_vege?: string
}

export type DegreeAim = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type DegreeAimRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

export type Department = {
  id: number
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @maxLength 32 */
  name: string
  /** @maxLength 32 */
  label?: string
  is_talent_and_enrichment?: boolean
}

export type DepartmentRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name: string
  /** @maxLength 32 */
  label?: string
  is_talent_and_enrichment?: boolean
}

/**
 * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
 * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
 * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
 * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
 */
export type DietTypeEnum = 'l1' | 'l2' | 'l3' | 'l4'

export type DinningAndSickMealAttendance = {
  dining: MealPreferenceCount
  sick: MealPreferenceCount
}

/**
 * * `idp_app` - IDP app
 * * `parents_app` - Parents app
 */
export type DisplayInEnum = 'idp_app' | 'parents_app'

export type Doctor = {
  id: number
  /** @maxLength 256 */
  name: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  appointment_count: string
}

export type DoctorDiagnosis = {
  id: number
  diagnosis: string
  recommendations: string
  /**
   * * `new` - New
   * * `cancelled` - Cancelled
   * * `after_the_visit` - After the visit
   */
  status?: AppointmentStatusEnum
}

export type DoctorDiagnosisRequest = {
  /** @minLength 1 */
  diagnosis: string
  /** @minLength 1 */
  recommendations: string
  /**
   * * `new` - New
   * * `cancelled` - Cancelled
   * * `after_the_visit` - After the visit
   */
  status?: AppointmentStatusEnum
}

export type DoctorRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

/**
 * * `capsule` - capsule
 * * `drop` - drop
 * * `puff` - puff
 * * `sachet` - sachet
 * * `table_spoon` - table spoon
 * * `tablet` - tablet
 * * `tea_spoon` - tea spoon
 * * `finger_tip` - finger tip unit (cream)
 */
export type DosageFormEnum =
  | 'capsule'
  | 'drop'
  | 'puff'
  | 'sachet'
  | 'table_spoon'
  | 'tablet'
  | 'tea_spoon'
  | 'finger_tip'

export type DraftActivityCreate = {
  /** @format uuid */
  id: string
  teacher_display: string
  co_teacher_1_display: string
  co_teacher_2_display: string
  room_display: string
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /** @format double */
  duration?: number
  is_archived?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  is_private: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  fet_id?: number | null
  is_moved: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  /** @format uuid */
  course: string
  /**
   * Artisan
   * @format uuid
   */
  teacher: string
  room?: number | null
  timetable: number
  section?: number | null
  from_optimization?: number | null
  /** @format uuid */
  separated_from?: string | null
  students: string[]
}

export type DraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /** @format double */
  duration?: number
  is_archived?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  fet_id?: number | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  /** @format uuid */
  course: string
  /**
   * Artisan
   * @format uuid
   */
  teacher: string
  room?: number | null
  timetable: number
  section?: number | null
  from_optimization?: number | null
  /** @format uuid */
  separated_from?: string | null
  students: string[]
}

export type DraftActivityList = {
  /** @format uuid */
  id: string
  date: string
  start: string
  end: string
  start_date: string
  end_date: string
  /** @format uuid */
  course: string
  course_str: string
  users: SimpleUser[]
  /**
   *  type
   * @default "lesson"
   */
  _type: string
  display_hour: string
  room?: number | null
  room_str: string
  teacher: SimpleUser
  co_teacher_1?: SimpleUser | null
  co_teacher_2?: SimpleUser | null
}

export type DraftActivityListRequest = {
  room?: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type DraftRotaActivity = {
  id: string
  /** @format uuid */
  user: string
  quarter: number
  rota_type: RotaTypeEnum
  rota_timetable: number
  /**
   *  type
   * @default "rota"
   */
  _type: string
  date: string
  start: string
  end: string
  start_date: string
  end_date: string
  role: string
  /** @default "" */
  house_name: string
  /** @format uuid */
  team: string | null
  team_str: string
}

export type DrinkGive = {
  id: number
  was_given: boolean
  /** @format uuid */
  health_check: string
  /** @format date-time */
  created: string
}

export type DrinkGiveRequest = {
  was_given: boolean
  /** @format uuid */
  health_check: string
}

/**
 * * `topical` - Topical (Skin)
 * * `oral` - Oral
 * * `nasal` - Nasal
 * * `injection` - Injection
 */
export type EmergencyMedicineApplicationsEnum =
  | 'topical'
  | 'oral'
  | 'nasal'
  | 'injection'

/**
 * * `student` - Student (at all times)
 * * `house` - House
 * * `BDT` - BDT
 */
export type EmergencyMedicineLocationEnum = 'student' | 'house' | 'BDT'

export type EnrolledCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  difficulty: string
}

export type EnrolledStudent = {
  /** @format uuid */
  id: string
  full_name: string
}

export type Enrollment = {
  id: number
  student: string
  student_str: string
  /** @default true */
  is_active?: boolean
  number_of_lessons: number
  /** @default false */
  is_private?: boolean
  quarter: string
}

export type EnrollmentRequest = {
  id: number
  /** @default true */
  is_active?: boolean
  number_of_lessons: number
  /** @default false */
  is_private?: boolean
}

/**
 * * `pending` - Pending
 * * `requested` - Requested
 * * `waiting_parental_approve` - Waiting parental approve
 * * `rejected` - Rejected
 * * `approved` - Approved
 * * `leave_absent` - Leave - Absent
 */
export type EnrolmentStatusEnum =
  | 'pending'
  | 'requested'
  | 'waiting_parental_approve'
  | 'rejected'
  | 'approved'
  | 'leave_absent'

/**
 * * `week` - Weekly
 * * `quarter` - Quarterly
 * * `semester` - Semesterly
 * * `year` - Yearly
 */
export type EscalationExpireAfterEnum = 'week' | 'quarter' | 'semester' | 'year'

/**
 * * `community_weekend` - Community weekend
 * * `academic_tests` - Academic tests
 */
export type EventTypeEnum = 'community_weekend' | 'academic_tests'

export type Exam = {
  id: string
  /** @format uuid */
  course: string
  course_str: string
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  location_str: string
  is_published: boolean
  test_invigilators?: string[]
  test_invigilators_str: string
  saa_invigilators?: string[]
  saa_invigilators_str: string
  users: string
  teachers?: string[]
  teachers_str: string
  grade_levels?: number[]
  students: ExamStudent[]
  has_any_data: string
  has_all_required_data: string
  is_read_only: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  exam_session_id: string
}

export type ExamClash = {
  id: number
  group: ExamClashGroupEnum
  exam_id: string
  /** @format uuid */
  user_id: string | null
  /** @format uuid */
  student_id: string | null
  location_id: number | null
  user_details: SimpleUser
  student_details: SimpleUser
  location_details: LocationEC
  clash_with_exam_details: ClashWithExamEC
}

/**
 * * `student` - Student
 * * `location` - Location
 * * `teacher` - Teacher
 * * `saa_invigilator` - Saa Invigilator
 * * `test_invigilator` - Test Invigilator
 */
export type ExamClashGroupEnum =
  | 'student'
  | 'location'
  | 'teacher'
  | 'saa_invigilator'
  | 'test_invigilator'

export type ExamClashPreview = {
  for_exam_id?: string | null
  /** @format date */
  date: string
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
  location_id: number
  test_invigilators_ids?: string[]
  saa_invigilators_ids?: string[]
  students_ids?: string[]
  exam_ids_to_ignore?: string[]
}

export type ExamClashPreviewRequest = {
  for_exam_id?: string | null
  /** @format date */
  date: string
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
  location_id: number
  test_invigilators_ids?: string[]
  saa_invigilators_ids?: string[]
  students_ids?: string[]
  exam_ids_to_ignore?: string[]
}

export type ExamClashes = {
  id: string
  course: string
  /** @format date */
  date?: string | null
  /** @format time */
  start_hour: string
  /** @format time */
  end_hour: string
  /** @default "exams" */
  clash_type: string
}

export type ExamCourses = {
  id: string
  /** @format uuid */
  course: string
}

export type ExamCoursesRequest = {
  /** @format uuid */
  course: string
}

export type ExamEnrolledStudent = {
  id: number
  is_marked_automatically: boolean
  is_marked: boolean
  /** @format date-time */
  marked_at: string | null
  is_present?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  is_academic_honesty?: boolean
  is_disturbance?: boolean
  disturbance_merit_note?: string
  academic_honesty_merit_note?: string
  student: ExamStudent
  grade_level: number
  exam: string
  note_content: string
}

export type ExamEnrolledStudentRequest = {
  is_present?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  is_academic_honesty?: boolean
  is_disturbance?: boolean
  disturbance_merit_note?: string
  academic_honesty_merit_note?: string
}

/**
 * * `25` - 25% extra time
 * * `50` - 50% extra time
 * * `100` - 100% extra time
 */
export type ExamExtraTimeEnum = 25 | 50 | 100

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamForm = {
  id: string
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  test_invigilators?: string[]
  saa_invigilators?: string[]
  teachers?: string[]
  is_published: boolean
  keep_published?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamFormRequest = {
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  test_invigilators?: string[]
  saa_invigilators?: string[]
  teachers?: string[]
  exam_courses: ExamCoursesRequest[]
  exams_students: Record<string, any>[]
  keep_published?: boolean
}

export type ExamScore = {
  id: number
  idp_plan?: string | null
  /**
   * * `SAT_MAT` - SAT MAT
   * * `SAT_ENG` - SAT ENG
   * * `IELTS` - IELTS
   * * `WIDA` - WIDA
   * * `MAP` - MAP
   */
  name: NameEnum
  /**
   * @min 0
   * @max 100
   */
  score: number
}

export type ExamScoreRequest = {
  /** @minLength 1 */
  idp_plan?: string | null
  /**
   * * `SAT_MAT` - SAT MAT
   * * `SAT_ENG` - SAT ENG
   * * `IELTS` - IELTS
   * * `WIDA` - WIDA
   * * `MAP` - MAP
   */
  name: NameEnum
  /**
   * @min 0
   * @max 100
   */
  score: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamSession = {
  id: string
  name: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type: ExamTypeEnum
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  quarter: number
  departments: number[]
  grade_levels: number[]
  cancellation_dates: ExamSessionCancellationDate[]
  /** @default 0 */
  exams_count: string
  /** @default 0 */
  published_exams_count: string
  has_exam_ready_to_publish: string
  next_session: string
  previous_session: string
}

export type ExamSessionCancellationDate = {
  id: number
  /**
   * * `no_cancellation` - Don't cancel classes
   * * `msl_hsl_enrolled` - Cancel all the MSL and HSL classes for the students enrolled in this exam session.
   * * `msl_hsl_enrolled_and_not_enrolled` - Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).
   * * `enrolled` - Cancel all the classes for the students enrolled in this exam session.
   * * `enrolled_and_not_enrolled` - Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session).
   */
  cancel_class_type?: CancelClassTypeEnum
  /** @format date */
  date: string
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  cancel_for_years: number[]
}

export type ExamSessionCancellationDateRequest = {
  /**
   * * `no_cancellation` - Don't cancel classes
   * * `msl_hsl_enrolled` - Cancel all the MSL and HSL classes for the students enrolled in this exam session.
   * * `msl_hsl_enrolled_and_not_enrolled` - Cancel all the MSL and HSL classes for all the students from selected years (enrolled and not enrolled in this exam session).
   * * `enrolled` - Cancel all the classes for the students enrolled in this exam session.
   * * `enrolled_and_not_enrolled` - Cancel all the classes for all the students from selected years (enrolled and not enrolled in this exam session).
   */
  cancel_class_type?: CancelClassTypeEnum
  /** @format date */
  date: string
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  cancel_for_years: number[]
}

export type ExamSessionList = {
  id: string
  name: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type: ExamTypeEnum
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  quarter: number
  /** @default 0 */
  exams_count: string
  /** @default 0 */
  published_exams_count: string
  has_read_only_exam: string
  has_exam_ready_to_publish: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type ExamSessionRequest = {
  /** @minLength 1 */
  name: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type: ExamTypeEnum
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  quarter: number
  departments: number[]
  grade_levels: number[]
  cancellation_dates: ExamSessionCancellationDateRequest[]
}

export type ExamSessionSimpleList = {
  id: string
  name: string
}

export type ExamStudent = {
  /** @format uuid */
  id: string
  full_name: string
  is_at_home?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  access_arrangements_labels: string
}

export type ExamStudentRequest = {
  is_at_home?: boolean
  saa_student?: boolean
  is_statemented?: boolean
}

/**
 * * `quarter` - Quarter exams
 * * `academic` - Academic
 * * `external` - External exams
 */
export type ExamTypeEnum = 'quarter' | 'academic' | 'external'

/**
 * * `ACT` - ACT
 * * `A_LEVEL` - A Level (GCE), iGCSE
 * * `AP` - Advanced Placement
 * * `CAMBRIDGE` - CAMBRIDGE
 * * `GCSE` - GCSE
 * * `GMAT` - GMAT
 * * `IAL` - International A Level
 * * `IB` - International Baccalaureate
 * * `IELTS` - IELTS
 * * `MAP` - MAP
 * * `SAT_ENG` - SAT ENG
 * * `SAT_MAT` - SAT MAT
 * * `WIDA` - WIDA
 */
export type ExternalExamTypeEnum =
  | 'ACT'
  | 'A_LEVEL'
  | 'AP'
  | 'CAMBRIDGE'
  | 'GCSE'
  | 'GMAT'
  | 'IAL'
  | 'IB'
  | 'IELTS'
  | 'MAP'
  | 'SAT_ENG'
  | 'SAT_MAT'
  | 'WIDA'

export type FavouriteStudentsViewSetting = {
  id: number
  user?: string
  /** @maxLength 128 */
  name: string
  data: Record<string, any>
}

export type FavouriteStudentsViewSettingRequest = {
  user?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  data: Record<string, any>
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type FavouriteWidget = {
  type: string
  img_path: string
}

export type Feed = PublicWidget | StudentContent

export type FileForStudent = {
  id: number
  /** @maxLength 100 */
  name: string
  author?: string
  author_str: string
  /** @format uri */
  file?: string
  /** @format date-time */
  created: string
  str: string
  filename: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  display_in: DisplayInEnum[]
}

export type FileForStudentChoices = {
  display_in: StringOption[]
}

export type FileForStudentSwaggerRequest = {
  /** @maxLength 100 */
  name: string
  author?: string
  /** @minLength 1 */
  file?: string
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  link?: string
  display_in: DisplayInEnum[]
}

export type FilterRepresentationResponseSchema = {
  resourcetype: AvailableStringFilter
  student: AvailableStringFilter
  status: AvailableStringFilter
  house: AvailableIntegerFilter
}

export type FirstContact = {
  id: number
  symptoms: SymptomsEnum[]
  details: string
  has_covid_symptoms: boolean
  symptoms_display: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  temperature?: string | null
}

export type FirstContactRequest = {
  symptoms: SymptomsEnum[]
  /** @minLength 1 */
  details: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  temperature?: string | null
}

export type FoodGive = {
  id: number
  was_given: boolean
  /** @format uuid */
  health_check: string
  /** @format date-time */
  created: string
}

export type FoodGiveRequest = {
  was_given: boolean
  /** @format uuid */
  health_check: string
}

/**
 * * `male` - Male
 * * `female` - Female
 */
export type ForGenderEnum = 'male' | 'female'

export type FreeSlot = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  display_hour: string
  start: string
  end: string
  course: string
}

export type GPAForCourse = {
  id: number
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
   */
  unweighted_value: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
   */
  weighted_value: string
  grade: string | null
  /** @format uuid */
  course: string
  course_str: string
  teacher: string
  teacher_str: string
}

/**
 * * `male` - Male
 * * `female` - Female
 */
export type GenderEnum = 'male' | 'female'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTask = {
  id: number
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  status: GenerationTaskStatusEnum
  stop_request_sent?: boolean
  best_timetable: number | null
  optimize_only_late_slots?: boolean
  status_display: string
  /** @format date-time */
  created: string
  assign_rooms?: boolean
  quarter: number
  /**
   * @min 0
   * @max 15
   */
  late_hour?: LateHourEnum
  late_hour_display: string
  is_finished?: boolean
  students?: string[]
  fail_reason_display: string
  timetables: string
  generate_blank_timetable?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTaskRequest = {
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  stop_request_sent?: boolean
  optimize_only_late_slots?: boolean
  assign_rooms?: boolean
  quarter: number
  /**
   * @min 0
   * @max 15
   */
  late_hour?: LateHourEnum
  is_finished?: boolean
  students?: string[]
  generate_blank_timetable?: boolean
}

/**
 * * `new` - New
 * * `server_obtained` - Server has been obtained
 * * `generation_in_progress` - Generation in progress
 * * `failed` - Generation has failed
 * * `success` - Success
 */
export type GenerationTaskStatusEnum =
  | 'new'
  | 'server_obtained'
  | 'generation_in_progress'
  | 'failed'
  | 'success'

export type GradeLevel = {
  id: number
  /** @maxLength 64 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  stage?: StageEnum | NullEnum | null
  stage_label: string
}

export type HaveStaffUsersClashes = {
  have_clashes: boolean
}

export type HaveStudentsClashes = {
  have_clashes: boolean
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type HeadOfHouseProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type HeadOfHouseProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type HealthCheck = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /** @format date-time */
  slot_start: string
  /** @format date-time */
  slot_end: string
  comment: string
  is_completed?: boolean
  added_after_time?: boolean
  check_type_str: string
  /**
   * * `room` - room check
   * * `campus` - campus check
   */
  check_type?: HealthCheckTypeEnum
  sick_report: SickReportInHealthCheck
  has_medicine: string
  has_fever: boolean
  current_location: string
}

export type HealthCheckAvailableFilters = {
  student: AvailableStringFilterHealthCheckStudent
  check_type: AvailableStringFilter
}

export type HealthCheckComment = {
  id: number
  /** @format uuid */
  health_check: string
  content: string
  /** @format date-time */
  created: string
}

export type HealthCheckCommentRequest = {
  /** @format uuid */
  health_check: string
  /** @minLength 1 */
  content: string
}

export type HealthCheckList = {
  /** @format uuid */
  id: string
  sick_report_id: string
  /** @format uuid */
  student: string
  student_str: string
  /** @format date-time */
  slot_start: string
  /** @format date-time */
  slot_end: string
  comment: string
  is_completed?: boolean
  added_after_time?: boolean
  check_type_str: string
  /**
   * * `room` - room check
   * * `campus` - campus check
   */
  check_type?: HealthCheckTypeEnum
  has_medicine: string
  current_location: string
}

export type HealthCheckLog = {
  id: number
  str: string
  set_after_time?: boolean
  /** @format uuid */
  added_by?: string | null
  added_by_str: string
  /** @format date-time */
  created: string
  /** @format date-time */
  slot_start: string
  /** @format date-time */
  slot_end: string
}

export type HealthCheckStudentOption = {
  label: string
  value: string
  searchable_content: string
}

/**
 * * `room` - room check
 * * `campus` - campus check
 */
export type HealthCheckTypeEnum = 'room' | 'campus'

export type HighSchoolGPA = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  unweighted_value: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  weighted_value: string
}

export type Hospital = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type HospitalRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

/**
 * * `0` - 8:00-8:40
 * * `1` - 8:45-9:25
 * * `2` - 9:30-10:10
 * * `3` - 10:30-11:10
 * * `4` - 11:15-11:55
 * * `5` - 13:00-13:40
 * * `6` - 13:45-14:25
 * * `7` - 14:30-15:10
 * * `8` - 15:15-15:55
 * * `9` - 16:00-16:40
 * * `10` - 16:45-17:25
 * * `11` - 17:30-18:10
 * * `12` - 18:15-18:55
 * * `13` - 19:00-19:40
 * * `14` - 19:40-20:30
 * * `15` - 20:30-21:00
 */
export type HourEnum =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15

export type House = {
  id: number
  /** @maxLength 128 */
  name: string
  tutors: string[]
  generate_reports?: boolean
  generate_reports_at_study_time?: boolean
  generate_reports_at_the_weekend?: boolean
  generate_night_attendance_reports?: boolean
  is_senior_campus?: boolean
  is_junior_campus?: boolean
}

export type HouseList = {
  id: number
  /** @maxLength 128 */
  name: string
  tutors_data: SimpleUser[]
}

export type HouseRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  tutors: string[]
  generate_reports?: boolean
  generate_reports_at_study_time?: boolean
  generate_reports_at_the_weekend?: boolean
  generate_night_attendance_reports?: boolean
  is_senior_campus?: boolean
  is_junior_campus?: boolean
}

export type HouseRoom = {
  id: number
  str: string
  code: string
  house: number
  name: string
  can_handle_isolation: boolean
  house_str: string
  reserved_slots: string
  available_slots: string
  is_reserved_now_for_student_only: boolean
  student_which_reserves_now_info: string
  status: AnnotatedHouseRoomStatusChoices
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
}

export type HouseRoomAvailableFilters = {
  booked_for: AvailableStringFilterHouseRoomBookedFor
  house: AvailableStringFilter
  id: AvailableStringFilter
  code: AvailableStringFilter
  status: AvailableStringFilter
}

export type HouseRoomBookedForOption = {
  label: string
  value: string
  is_student: boolean
  searchable_content: string
}

export type HouseRoomList = {
  id: number
  str: string
  code: string
  house: number
  name: string
  can_handle_isolation: boolean
  house_str: string
  reserved_slots: string
  available_slots: string
  is_reserved_now_for_student_only: boolean
  student_which_reserves_now_info: string
  status: AnnotatedHouseRoomStatusChoices
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
  is_currently_booked: boolean
  prebooked_date: string
  is_taken_by_booking: string
  places_taken: number
  booked_by: string
  occupied_places_by_accommodations: HouseRoomOccupiedPlacesByAccommodations[]
  occupied_places_by_bookings: HouseRoomOccupiedPlacesByBookings[]
}

export type HouseRoomOccupiedPlacesByAccommodations = {
  id: number
  /** @format uuid */
  occupier_id: string
  occupier_str: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  is_room_only_for_student?: boolean
}

export type HouseRoomOccupiedPlacesByBookings = {
  id: number
  /** @format uuid */
  occupier_id: string | null
  occupier_str: string
  /** @format date */
  start_date: string
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  is_occupier_student: string
}

export type HouseRoomRequest = {
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type HouseTutorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type HouseTutorProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type IdTokenOAuth2InputRequest = {
  /** @minLength 1 */
  provider?: string
  /** @minLength 1 */
  redirect_uri?: string
  /** @minLength 1 */
  id_token: string
}

export type IdpPlanChangesAddedAction = {
  /** * `added` - added */
  action: IdpPlanChangesAddedActionActionEnum
  course_id: string
  course_name: string
  quarters: string[]
  is_private: boolean
}

/** * `added` - added */
export type IdpPlanChangesAddedActionActionEnum = 'added'

export type IdpPlanChangesDeletedAction = {
  /** * `deleted` - deleted */
  action: IdpPlanChangesDeletedActionActionEnum
  course_id: string
  course_name: string
  quarters: string[]
}

/** * `deleted` - deleted */
export type IdpPlanChangesDeletedActionActionEnum = 'deleted'

export type IdpPlanChangesLessonsNumberChangedAction = {
  /** * `lessons_number_changed` - lessons_number_changed */
  action: IdpPlanChangesLessonsNumberChangedActionActionEnum
  course_id: string
  course_name: string
  quarters: string[]
  from_hours: number
  to_hours: number
}

/** * `lessons_number_changed` - lessons_number_changed */
export type IdpPlanChangesLessonsNumberChangedActionActionEnum =
  'lessons_number_changed'

export type IdpPlanChangesPrivacyChangedAction = {
  /** * `privacy_changed` - privacy_changed */
  action: IdpPlanChangesPrivacyChangedActionActionEnum
  course_id: string
  course_name: string
  quarters: string[]
  to_private: boolean
}

/** * `privacy_changed` - privacy_changed */
export type IdpPlanChangesPrivacyChangedActionActionEnum = 'privacy_changed'

/**
 * * `new` - New
 * * `draft` - Draft
 * * `rejected` - Rejected
 * * `waits_for_admin_approval` - In admin approval
 * * `in_parental_approval` - In parental approval
 * * `is_discussion` - In discussion
 * * `approved` - Approved
 * * `automatically_approved` - Automatically approved
 * * `updated` - Updated
 * * `archived` - Archived
 */
export type IdpPlanStatusEnum =
  | 'new'
  | 'draft'
  | 'rejected'
  | 'waits_for_admin_approval'
  | 'in_parental_approval'
  | 'is_discussion'
  | 'approved'
  | 'automatically_approved'
  | 'updated'
  | 'archived'

export type IdpSectionEnroll = {
  id: number
  section?: number | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons?: number
}

export type IdpSectionEnrollRequest = {
  section?: number | null
  /** @format uuid */
  course?: string | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons?: number
}

export type IdpSectionEnrollment = {
  id: number
  /** @format uuid */
  student: string
  student_str: string
  quarter: number
  section?: number | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons: number
  is_private?: boolean
  /** @format date-time */
  section_deleted_at?: string | null
  course_in_basket?: number | null
  /** @format uuid */
  section_deleted_by?: string | null
}

export type IdpSectionEnrollmentRequest = {
  /** @format uuid */
  student: string
  quarter: number
  section?: number | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons: number
  is_private?: boolean
  /** @format date-time */
  section_deleted_at?: string | null
  course_in_basket?: number | null
  /** @format uuid */
  section_deleted_by?: string | null
}

/**
 * * `teacher` - Teacher
 * * `admin` - Admin
 */
export type IepContactEnum = 'teacher' | 'admin'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Image = {
  id: string
  ppoi?: string
  thumbnail: string
  catalogs?: number[]
  /** @format date-time */
  created: string
  /** @format date-time */
  created_at: string
  /** @maxLength 7 */
  dominant_color?: string
  /** @maxLength 128 */
  title?: string
  /** @maxLength 128 */
  alt?: string
  file_url: string
  /** @format uri */
  file?: string
  filename: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ImageRequest = {
  /** @minLength 1 */
  ppoi?: string
  catalogs?: number[]
  /**
   * @minLength 1
   * @maxLength 7
   */
  dominant_color?: string
  /** @maxLength 128 */
  title?: string
  /** @maxLength 128 */
  alt?: string
  /** @format binary */
  file?: File
}

/**
 * * `medicine_and_emergency` - Apply Medicine + Contact Emergency Services (Dial 122)
 * * `emergency` - Contact Emergency Services (Dial 122)
 * * `report_to_health_coordinator` - Immediate Report to Health Coordinator + BDT
 */
export type ImmediateResponseEnum =
  | 'medicine_and_emergency'
  | 'emergency'
  | 'report_to_health_coordinator'

/**
 * * `avoid mild physical exercise` - Avoid Mild Physical Exercise
 * * `avoid intensive physical exercise` - Avoid Intensive Physical Exercise
 * * `no impact` - No Impact
 * * `concentration` - Concentration
 * * `fatigue` - Fatigue
 * * `headache` - Headache
 * * `body ache or pain` - Body Ache or Pain
 * * `cognitive issues` - Cognitive issues
 * * `flashing lights (incl. movies & video games)` - Flashing Lights (incl. Movies & Video Games)
 */
export type ImpactEnum =
  | 'avoid mild physical exercise'
  | 'avoid intensive physical exercise'
  | 'no impact'
  | 'concentration'
  | 'fatigue'
  | 'headache'
  | 'body ache or pain'
  | 'cognitive issues'
  | 'flashing lights (incl. movies & video games)'

export type IncompleteAssignmentCount = {
  me: number
  my_teams: number
}

/** Used only for correct schema display in TaskViewSet.count_incomplete() */
export type IncompleteTaskCount = {
  daily: number
  assignment: IncompleteAssignmentCount
  teams: IncompleteTaskPerTeamCount
  status: IncompleteTaskPerStatusCount
}

export type IncompleteTaskPerStatusCount = {
  overdue: number
  close_deadline: number
  unscheduled: number
}

export type IncompleteTaskPerTeamCount = {
  '2f53d039-567a-43a7-8d3e-9c7a16bd1ae7': number
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type InfoboardEditorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type InfoboardEditorProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type IntegerOption = {
  label: string
  value: number
}

export type Interest = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type InterestRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

export type Internship = {
  id: number
  /** @maxLength 256 */
  name: string
  is_hidden?: boolean
}

export type InternshipAttachment = {
  id: number
  /** @format uri */
  file: string
  file_name: string
}

export type InterventionProgressComment = {
  /** @format uuid */
  id: string
  comment: string | null
}

export type InterventionProgressCommentRequest = {
  /** @minLength 1 */
  comment: string | null
}

export type Knox = {
  token: string
}

/**
 * * `0` - 8:00-8:40
 * * `1` - 8:45-9:25
 * * `2` - 9:30-10:10
 * * `3` - 10:30-11:10
 * * `4` - 11:15-11:55
 * * `5` - 13:00-13:40
 * * `6` - 13:45-14:25
 * * `7` - 14:30-15:10
 * * `8` - 15:15-15:55
 * * `9` - 16:00-16:40
 * * `10` - 16:45-17:25
 * * `11` - 17:30-18:10
 * * `12` - 18:15-18:55
 * * `13` - 19:00-19:40
 * * `14` - 19:40-20:30
 * * `15` - 20:30-21:00
 */
export type LateHourEnum =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15

/**
 * * `square` - Square
 * * `framed` - Framed
 * * `fullscreen` - Fullscreen
 */
export type LayoutEnum = 'square' | 'framed' | 'fullscreen'

export type LeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * * `in` - Check-in
   * * `out` - Check-out
   */
  check_type: LeaveCheckTypeEnum
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format uuid */
  leave_request: string
}

export type LeaveCheckComplete = {
  /** @format date-time */
  date: string
}

export type LeaveCheckCompleteRequest = {
  /** @format date-time */
  date: string
}

export type LeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * * `in` - Check-in
   * * `out` - Check-out
   */
  check_type: LeaveCheckTypeEnum
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format uuid */
  leave_request: string
}

/**
 * * `in` - Check-in
 * * `out` - Check-out
 */
export type LeaveCheckTypeEnum = 'in' | 'out'

export type LeaveRequest = {
  /** @format uuid */
  id: string
  check_out?: RelatedLeaveCheck
  check_in?: RelatedLeaveCheck
  /** @format uuid */
  student: string
  student_str: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  get_leave_type_display: string
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  get_request_type_display: string
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  get_status_display: string
  history_items: LeaveRequestHistoryItem[]
  check_out_is_completed: string
  check_in_is_completed: string
  has_siblings_not_completed: string
  student_message?: string
  has_clashes?: boolean
  clashes_resolved?: boolean
  approve_url: string
}

export type LeaveRequestClashes = {
  /** @format uuid */
  id: string
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  request_type_label: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  leave_type_label: string
  /** @format date-time */
  check_out_date: string
  /** @format date-time */
  check_in_date: string | null
  /** @default "leave_requests" */
  clash_type: string
}

export type LeaveRequestHistoryItem = {
  /** @format uuid */
  id: string
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  /** @format date-time */
  created: string
  /**
   * * `create` - Created
   * * `change` - Changed
   * * `email_send` - E-mail to parent
   * * `contact_person_approve` - Approved by parent
   * * `contact_person_reject` - Rejected by parent
   * * `academic_approve` - Approved by academic
   * * `academic_reject` - Rejected by academic
   * * `academic_requested` - Moved to requested by academic
   * * `bdt_approve` - Approved by boarding
   * * `bdt_reject` - Rejected by boarding
   * * `bdt_requested` - Moved to requested by boarding
   * * `student_cancel` - Canceled by student
   */
  log_type: LeaveRequestHistoryItemLogTypeEnum
  get_log_type_display: string
  description: string
  comment?: string
}

/**
 * * `create` - Created
 * * `change` - Changed
 * * `email_send` - E-mail to parent
 * * `contact_person_approve` - Approved by parent
 * * `contact_person_reject` - Rejected by parent
 * * `academic_approve` - Approved by academic
 * * `academic_reject` - Rejected by academic
 * * `academic_requested` - Moved to requested by academic
 * * `bdt_approve` - Approved by boarding
 * * `bdt_reject` - Rejected by boarding
 * * `bdt_requested` - Moved to requested by boarding
 * * `student_cancel` - Canceled by student
 */
export type LeaveRequestHistoryItemLogTypeEnum =
  | 'create'
  | 'change'
  | 'email_send'
  | 'contact_person_approve'
  | 'contact_person_reject'
  | 'academic_approve'
  | 'academic_reject'
  | 'academic_requested'
  | 'bdt_approve'
  | 'bdt_reject'
  | 'bdt_requested'
  | 'student_cancel'

export type LeaveRequestHistoryItemRequest = {
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  /**
   * * `create` - Created
   * * `change` - Changed
   * * `email_send` - E-mail to parent
   * * `contact_person_approve` - Approved by parent
   * * `contact_person_reject` - Rejected by parent
   * * `academic_approve` - Approved by academic
   * * `academic_reject` - Rejected by academic
   * * `academic_requested` - Moved to requested by academic
   * * `bdt_approve` - Approved by boarding
   * * `bdt_reject` - Rejected by boarding
   * * `bdt_requested` - Moved to requested by boarding
   * * `student_cancel` - Canceled by student
   */
  log_type: LeaveRequestHistoryItemLogTypeEnum
  /** @minLength 1 */
  description: string
  comment?: string
}

export type LeaveRequestRequest = {
  check_out?: RelatedLeaveCheckRequest
  check_in?: RelatedLeaveCheckRequest
  /** @format uuid */
  student: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  student_message?: string
  has_clashes?: boolean
  clashes_resolved?: boolean
}

/**
 * * `approve` - Approve
 * * `reject` - Reject
 * * `requested` - Requested
 */
export type LeaveRequestReviewActionEnum = 'approve' | 'reject' | 'requested'

/**
 * * `tentative` - Pending parental
 * * `confirmed_contact_person` - Pending academic
 * * `confirmed_academic` - Pending boarding
 * * `changed` - Changed
 * * `confirmed` - Approved
 * * `declined` - Rejected
 * * `declined_contact_person` - Rejected by contact person
 * * `declined_academic` - Rejected by academic
 * * `canceled` - Canceled by student
 * * `expired` - Expired
 */
export type LeaveRequestStatusEnum =
  | 'tentative'
  | 'confirmed_contact_person'
  | 'confirmed_academic'
  | 'changed'
  | 'confirmed'
  | 'declined'
  | 'declined_contact_person'
  | 'declined_academic'
  | 'canceled'
  | 'expired'

/**
 * * `0` - Weekend
 * * `1` - Holiday
 * * `2` - Other
 */
export type LeaveRequestTypeEnum = 0 | 1 | 2

/**
 * * `first_parent` - First Parent
 * * `second_parent` - Second Parent
 * * `both_parents` - Both Parents
 * * `relative` - Relative
 * * `other` - Other
 */
export type LegalGuardianRelationEnum =
  | 'first_parent'
  | 'second_parent'
  | 'both_parents'
  | 'relative'
  | 'other'

export type LessonClashes = {
  /** @format uuid */
  id: string
  course: string
  /** @format date */
  date: string
  start_hour: string
  end_hour: string
  /** @default "lessons" */
  clash_type: string
}

export type LinkWithQRCode = {
  id: string
  /**
   * @format uri
   * @maxLength 200
   */
  url: string
}

export type LinkWithQRCodeRequest = {
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  url: string
}

export type LocationEC = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
}

export type LogEntry = {
  id: number
  action: string
  request_id: string
  /** @format uuid */
  actor?: string | null
  /** Actor representation */
  actor_repr: string
  str: string
  object_id: string
  /** Object representation */
  object_repr: string
  additional_data?: Record<string, any>
  /** Remote address */
  remote_addr?: string | null
  /** @format date-time */
  timestamp: string
  changes: string
}

export type LoginLinkResponse = {
  token: string
  expiry: string
  user: LoginLinkUser
}

export type LoginLinkUser = {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number: string
}

export type LoginResponse = {
  token: string
  expiry: string
}

export type LoginUserResponse = {
  phone_number: string
  can_login_as_student: boolean
}

export type ManyEnrolmentsToApproved = {
  enrolments: number[]
}

export type ManyEnrolmentsToApprovedRequest = {
  enrolments: number[]
}

export type ManyEnrolmentsToRejected = {
  enrolments: number[]
  rejection_comment: string
}

export type ManyEnrolmentsToRejectedRequest = {
  enrolments: number[]
  /** @minLength 1 */
  rejection_comment: string
}

export type ManyEnrolmentsToRequested = {
  enrolments: number[]
}

export type ManyEnrolmentsToRequestedRequest = {
  enrolments: number[]
}

export type ManyPeriodGPASerializer = Record<
  string,
  {
    id: number
    /**
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
     */
    unweighted_value: string
    /**
     * @format decimal
     * @pattern ^-?\d{0,6}(?:\.\d{0,4})?$
     */
    weighted_value: string
    period: string
    gpa_for_courses: GPAForCourse[]
  }[]
>

export type ManyStaffUsersClashes = Record<
  string,
  {
    lessons: LessonClashes[]
    exams: ExamClashes[]
    programmes: ProgrammeClashes[]
  }
>

export type ManyStudentsClashes = Record<
  string,
  {
    lessons: LessonClashes[]
    exams: ExamClashes[]
    programmes: ProgrammeClashes[]
    leave_requests: LeaveRequestClashes[]
  }
>

export type MarkHomeworkAsSentStudent = {
  is_marked_as_sent_by_student?: boolean
}

export type MarkHomeworkAsSentStudentRequest = {
  is_marked_as_sent_by_student?: boolean
}

export type MealAttendanceResponse = {
  breakfast: DinningAndSickMealAttendance
  lunch: DinningAndSickMealAttendance
  dinner: DinningAndSickMealAttendance
  is_academic_saturday: boolean
  /** @format date-time */
  last_update: string
}

export type MealAttendanceStudent = {
  /** @maxLength 256 */
  name?: string
  classification: ClassificationEnum
  /** @default "student" */
  role: string
}

export type MealPreferenceCount = {
  /** @min 0 */
  l1: number
  /** @min 0 */
  l2: number
  /** @min 0 */
  l3: number
  /** @min 0 */
  l4: number
  users: MealAttendanceStudent[]
}

export type Medicine = {
  id: number
  created_by?: string
  /** @maxLength 255 */
  name: string
  prescription?: boolean
  tags: string
}

export type MedicineGiven = {
  /** @format uuid */
  id: string
  /** @format uuid */
  health_check: string
  /** @format uuid */
  scheduled_medicine?: string | null
  optional_medicine?: string | null
}

export type MedicineGivenRequest = {
  /** @format uuid */
  health_check: string
  /** @format uuid */
  scheduled_medicine?: string | null
  /** @minLength 1 */
  optional_medicine?: string | null
}

/** Adds nested create feature */
export type MedicineInPlan = {
  id: string
  created_by?: string
  /** @format uuid */
  student: string
  medicine: number
  medicine_name: string
  medicine_prescription: boolean
  /** @maxLength 255 */
  medicine_strength: string
  medicine_plan?: string
  /** @format date-time */
  start_date: string
  /** Is medicine only sick report */
  is_med_only_sick_report: boolean
  /** @format date-time */
  end_date: string
  /**
   * * `daily` - Daily
   * * `every_x_days` - Every "X" days
   * * `monthly` - Monthly
   * * `weekly` - Weekly
   */
  repeat_form: RepeatFormEnum
  repeat_form_label: string
  /**
   * @min 0
   * @max 2147483647
   */
  repeat_every: number
  comment?: string
  intakes?: MedicineIntakePlan[]
  is_archived: string
  student_str: string
  is_temporary: boolean
  sick_report_id: string | null
  is_optional?: boolean
}

/** Adds nested create feature */
export type MedicineInPlanRequest = {
  created_by?: string
  /** @format uuid */
  student: string
  medicine: number
  /**
   * @minLength 1
   * @maxLength 255
   */
  medicine_strength: string
  /** @minLength 1 */
  medicine_plan?: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   * * `daily` - Daily
   * * `every_x_days` - Every "X" days
   * * `monthly` - Monthly
   * * `weekly` - Weekly
   */
  repeat_form: RepeatFormEnum
  /**
   * @min 0
   * @max 2147483647
   */
  repeat_every: number
  comment?: string
  intakes?: MedicineIntakePlanRequest[]
  is_optional?: boolean
}

export type MedicineIntakePlan = {
  id: string
  medicine_in_plan?: string
  /** @format time */
  intake_time: string
  formatted_intake_time: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  dose: string
  /**
   * * `capsule` - capsule
   * * `drop` - drop
   * * `puff` - puff
   * * `sachet` - sachet
   * * `table_spoon` - table spoon
   * * `tablet` - tablet
   * * `tea_spoon` - tea spoon
   * * `finger_tip` - finger tip unit (cream)
   */
  dosage_form: DosageFormEnum
  dosage_form_label: string
}

export type MedicineIntakePlanRequest = {
  /** @minLength 1 */
  medicine_in_plan?: string
  /** @format time */
  intake_time: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  dose: string
  /**
   * * `capsule` - capsule
   * * `drop` - drop
   * * `puff` - puff
   * * `sachet` - sachet
   * * `table_spoon` - table spoon
   * * `tablet` - tablet
   * * `tea_spoon` - tea spoon
   * * `finger_tip` - finger tip unit (cream)
   */
  dosage_form: DosageFormEnum
}

/**
 * * `student` - Student (at all times)
 * * `house` - House
 * * `BDT` - BDT
 */
export type MedicineLocationEnum = 'student' | 'house' | 'BDT'

/** Adds nested create feature */
export type MedicinePlan = {
  id: string
  /** @format date-time */
  created: string
  created_by?: string
  /** @format uuid */
  student: string
  medicines: MedicineInPlan[]
  sick_report?: string | null
  is_temporary?: boolean
  /** Is medicine only sick report */
  is_med_only_sick_report?: boolean
}

/** Adds nested create feature */
export type MedicinePlanRequest = {
  created_by?: string
  /** @format uuid */
  student: string
  medicines: MedicineInPlanRequest[]
  /** @minLength 1 */
  sick_report?: string | null
  is_temporary?: boolean
  student_application_medication?: number | null
  /** Is medicine only sick report */
  is_med_only_sick_report?: boolean
}

export type MedicineRequest = {
  created_by?: string
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string
  prescription?: boolean
}

export type MedicinesInHealthCheck = {
  /** @format uuid */
  id: string
  /** @format date-time */
  should_be_given_at: string
  was_given?: boolean
  /** @format date-time */
  given_at?: string | null
  /** @format uuid */
  given_by?: string | null
  medicine_name: string
  medicine_strength: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Merit = {
  id: number
  /** @format uuid */
  student: string
  /** @format uuid */
  course?: string | null
  note: string
  merit_item: number
  merit_item_details: MeritItem
  /** @format uuid */
  author: string | null
  author_str: string
  /** @format date-time */
  created: string
  course_str: string
  lesson_date_str: string
  is_academic_absence_or_lateness_demerit: boolean
  student_str: string
  status: string
  status_display: string
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  /** @format double */
  alcohol_level?: number | null
  /** @format double */
  value?: number
  merit_type_display: string
  reviewed_by_str: string
  escalated_merit_item: MeritItem
  sanction_message: string
  escalated_sanction: NextLastSanctionEnum
  escalated_sanction_display: string
  /** @format date-time */
  reviewed_at: string | null
  house_room?: number | null
  house_room_str: string | null
  source_object_id: string
  is_for_exam_attendance: string
  added_on: string
  rejection_reason: string
  electronics_involved: boolean
  week: string
  iso_year: string
}

export type MeritConfirm = {
  id: number
  sanction_message?: string
  /** @format date */
  suspension_date_from?: string | null
  /** @format date */
  suspension_date_to?: string | null
}

export type MeritConfirmRequest = {
  sanction_message?: string
  /** @format date */
  suspension_date_from?: string | null
  /** @format date */
  suspension_date_to?: string | null
}

export type MeritFilterOptions = {
  student_options: StringOption[]
  author_options: StringOption[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritItem = {
  id: number
  /**
   * * `respect` - Respect
   * * `community` - Community
   * * `safety` - Safety
   * * `health` - Health
   */
  type: MeritItemTypeEnum
  name: string
  /**
   * * `attitude` - Attitude
   * * `kindness` - Kindness
   * * `service` - Service
   * * `language` - Language
   * * `electronics` - Electronics
   * * `dress` - Dress
   * * `late` - Late
   * * `night` - Night
   * * `room` - Room
   * * `absence` - Absence
   * * `leave_request` - Leave Request
   * * `ausgang` - Ausgang
   * * `deception` - Deception
   * * `transport` - Transport
   * * `private_home` - Private Home
   * * `deliveries` - Deliveries
   * * `safety` - Safety
   * * `facilities` - Facilities
   * * `aggression` - Aggression
   * * `recklessness` - Recklessness
   * * `covid` - Covid
   * * `smoking` - Smoking
   * * `alcohol` - Alcohol
   * * `drugs` - Drugs
   * * `possession` - Possession
   * * `reputation` - Reputation
   * * `refusal` - Refusal
   * * `abusive_behaviour` - Abusive Behaviour
   */
  subtype: SubtypeEnum
  /**
   * * `merit` - Merit
   * * `verbal_commendation` - Verbal commendation
   * * `written_commendation` - Written commendation
   * * `certificate` - Certificate
   * * `verbal_warning` - Verbal warning
   * * `written_warning` - Written warning
   * * `ultimatum` - Ultimatum
   * * `suspension` - Suspension
   * * `exclusion` - Exclusion
   */
  sanction: NextLastSanctionEnum
  sanction_display: string
  /** @format double */
  merit_factor?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  immediate_downgrade?: StudentCategoryEnum | NullEnum | null
  /**
   * * `week` - Weekly
   * * `quarter` - Quarterly
   * * `semester` - Semesterly
   * * `year` - Yearly
   */
  escalation_expire_after?: EscalationExpireAfterEnum
  immediate_downgrade_display: string
  escalation_expire_after_display: string
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  profiles_that_can_review?: string[]
  profiles_that_can_add?: string[]
  system_generated: boolean
  allow_to_select?: boolean
  all_profiles_can_add?: boolean
  is_automatic_approve?: boolean
  next_merit_item?: number | null
  next_last_sanction?: NextLastSanctionEnum | BlankEnum
  has_grade_based_escalation_factor?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  pri_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  msl_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  hsl_escalation_factor?: number | null
  /** @format date */
  due_date: string
}

export type MeritItemAvailableFilters = {
  escalation_expire_after: AvailableStringFilter
  sanction: AvailableStringFilter
}

export type MeritItemBehaviour = {
  id: number
  name: string
  type: MeritItemTypeEnum
  subtype: SubtypeEnum
  merit_type: MeritTypeEnum
  sanction: NextLastSanctionEnum
  require_alcohol_level: boolean
  profiles_that_can_review: string[]
  profiles_that_can_add: string[]
  system_generated: boolean
  is_automatic_approve: boolean
}

export type MeritItemOptions = {
  types: StringOption[]
  subtypes: StringOption[]
  merit_types: StringOption[]
  profiles: StringOption[]
  sanctions: SanctionsOption
  escalation_expire_after_options: StringOption[]
  categories: IntegerOption[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritItemRequest = {
  /**
   * * `respect` - Respect
   * * `community` - Community
   * * `safety` - Safety
   * * `health` - Health
   */
  type: MeritItemTypeEnum
  /** @minLength 1 */
  name: string
  /**
   * * `attitude` - Attitude
   * * `kindness` - Kindness
   * * `service` - Service
   * * `language` - Language
   * * `electronics` - Electronics
   * * `dress` - Dress
   * * `late` - Late
   * * `night` - Night
   * * `room` - Room
   * * `absence` - Absence
   * * `leave_request` - Leave Request
   * * `ausgang` - Ausgang
   * * `deception` - Deception
   * * `transport` - Transport
   * * `private_home` - Private Home
   * * `deliveries` - Deliveries
   * * `safety` - Safety
   * * `facilities` - Facilities
   * * `aggression` - Aggression
   * * `recklessness` - Recklessness
   * * `covid` - Covid
   * * `smoking` - Smoking
   * * `alcohol` - Alcohol
   * * `drugs` - Drugs
   * * `possession` - Possession
   * * `reputation` - Reputation
   * * `refusal` - Refusal
   * * `abusive_behaviour` - Abusive Behaviour
   */
  subtype: SubtypeEnum
  /**
   * * `merit` - Merit
   * * `verbal_commendation` - Verbal commendation
   * * `written_commendation` - Written commendation
   * * `certificate` - Certificate
   * * `verbal_warning` - Verbal warning
   * * `written_warning` - Written warning
   * * `ultimatum` - Ultimatum
   * * `suspension` - Suspension
   * * `exclusion` - Exclusion
   */
  sanction: NextLastSanctionEnum
  /** @format double */
  merit_factor?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  immediate_downgrade?: StudentCategoryEnum | NullEnum | null
  /**
   * * `week` - Weekly
   * * `quarter` - Quarterly
   * * `semester` - Semesterly
   * * `year` - Yearly
   */
  escalation_expire_after?: EscalationExpireAfterEnum
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  profiles_that_can_review?: string[]
  profiles_that_can_add?: string[]
  allow_to_select?: boolean
  all_profiles_can_add?: boolean
  is_automatic_approve?: boolean
  next_merit_item?: number | null
  next_last_sanction?: NextLastSanctionEnum | BlankEnum
  has_grade_based_escalation_factor?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  pri_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  msl_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  hsl_escalation_factor?: number | null
}

/**
 * * `respect` - Respect
 * * `community` - Community
 * * `safety` - Safety
 * * `health` - Health
 */
export type MeritItemTypeEnum = 'respect' | 'community' | 'safety' | 'health'

export type MeritReject = {
  rejection_reason: string
}

export type MeritRejectRequest = {
  /** @minLength 1 */
  rejection_reason: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type MeritRequest = {
  /** @format uuid */
  student: string
  /** @format uuid */
  course?: string | null
  /** @minLength 1 */
  note: string
  merit_item: number
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type: MeritTypeEnum
  /** @format double */
  alcohol_level?: number | null
  /** @format double */
  value?: number
  house_room?: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MeritRewardApprove = {
  /** Adds nested create feature */
  campus_absence?: CampusAbsence
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MeritRewardApproveRequest = {
  /** Adds nested create feature */
  campus_absence?: CampusAbsenceRequest
}

export type MeritRewardParticipant = {
  id: number
  /** @format uuid */
  student: string
  student_data: StudentSimple
  homework_status: string
  category: string
  category_week_ago: string
  ausgangs_this_week: string
}

export type MeritRewardParticipantUpdate = {
  /**
   * * `rejected` - rejected
   * * `accepted` - accepted
   */
  status: MeritRewardParticipantUpdateStatusEnum
}

export type MeritRewardParticipantUpdateRequest = {
  /**
   * * `rejected` - rejected
   * * `accepted` - accepted
   */
  status: MeritRewardParticipantUpdateStatusEnum
}

/**
 * * `rejected` - rejected
 * * `accepted` - accepted
 */
export type MeritRewardParticipantUpdateStatusEnum = 'rejected' | 'accepted'

export type MeritRewardReject = {
  decision_comment: string
}

export type MeritRewardRejectRequest = {
  /** @minLength 1 */
  decision_comment: string
}

/**
 * * `CasualAnyday` - CasualAnyday
 * * `RoomService` - RoomService
 * * `BookTheCafe` - BookTheCafe
 * * `CityBrunchZurich` - CityBrunchZurich
 * * `AdditionalAusgang` - AdditionalAusgang
 * * `CategoryUpgrade` - CategoryUpgrade
 * * `StopTheClock` - StopTheClock
 */
export type MeritRewardResourcetypeEnum =
  | 'CasualAnyday'
  | 'RoomService'
  | 'BookTheCafe'
  | 'CityBrunchZurich'
  | 'AdditionalAusgang'
  | 'CategoryUpgrade'
  | 'StopTheClock'

export type MeritRewardStatusCounts = {
  all: number
  pending: number
  pending_attendees: number
  approved: number
  rejected: number
  done: number
  cancelled: number
}

/**
 * * `pending` - Pending
 * * `pending_attendees` - Pending attendees
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `done` - Done
 * * `cancelled` - Cancelled
 */
export type MeritRewardStatusEnum =
  | 'pending'
  | 'pending_attendees'
  | 'approved'
  | 'rejected'
  | 'done'
  | 'cancelled'

export type MeritStatistics = {
  merits_count: number
  demerits_count: number
  /**
   * * `4` - S
   * * `3` - E
   * * `2` - A
   * * `1` - C
   * * `0` - not assigned
   */
  current_category?: StudentCategoryEnum
  /**
   * * `4` - S
   * * `3` - E
   * * `2` - A
   * * `1` - C
   * * `0` - not assigned
   */
  potential_category?: StudentCategoryEnum
}

/**
 * * `merit` - Merit
 * * `demerit` - Demerit
 */
export type MeritTypeEnum = 'merit' | 'demerit'

/**
 * * `alert` - Alert
 * * `notice` - Notice
 */
export type MessageTypeEnum = 'alert' | 'notice'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MissionControl = {
  id: number
  current_aim_str: string
  level_str: string
  degree_aim_1: DegreeAim
  degree_aim_1_id?: number | null
  degree_destination_1: NationalityEnum | BlankEnum
  degree_aim_2: DegreeAim
  degree_aim_2_id?: number | null
  degree_destination_2: NationalityEnum | BlankEnum
  estimated_completion_display: string
  years_remaining: string
  career_goal: string
  interests_objs: Interest[]
  interests: number[]
  skills_objs: Skill[]
  skills: number[]
  competencies_objs: Competency[]
  competencies: number[]
  saa_student: string
  is_statemented: string
  exam_extra_time: string
  access_arrangements: string
  /** @format date */
  estimated_completion?: string | null
  recommendations?: string
  /** @format uuid */
  student: string
}

/**
 * * `ENG` - ENG
 * * `MAT` - MAT
 * * `SCI` - SCI
 * * `MLA` - MLA
 * * `SSD` - SSD
 * * `ART` - ART
 */
export type MissionControlDepartmentEnum =
  | 'ENG'
  | 'MAT'
  | 'SCI'
  | 'MLA'
  | 'SSD'
  | 'ART'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type MissionControlRequest = {
  degree_aim_1_id?: number | null
  degree_destination_1: NationalityEnum | BlankEnum
  degree_aim_2_id?: number | null
  degree_destination_2: NationalityEnum | BlankEnum
  interests: number[]
  skills: number[]
  competencies: number[]
  /** @format date */
  estimated_completion?: string | null
  recommendations?: string
  /** @format uuid */
  student: string
}

export type MultipleExam = {
  ids: string[]
}

export type MultipleExamRequest = {
  ids: string[]
}

/**
 * * `SAT_MAT` - SAT MAT
 * * `SAT_ENG` - SAT ENG
 * * `IELTS` - IELTS
 * * `WIDA` - WIDA
 * * `MAP` - MAP
 */
export type NameEnum = 'SAT_MAT' | 'SAT_ENG' | 'IELTS' | 'WIDA' | 'MAP'

/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia (Federated States of)
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export type NationalityEnum =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'CV'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'KP'
  | 'MK'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'KR'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW'

/**
 * * `af` - Afrikaans
 * * `ar` - Arabic
 * * `ar-dz` - Algerian Arabic
 * * `ast` - Asturian
 * * `az` - Azerbaijani
 * * `bg` - Bulgarian
 * * `be` - Belarusian
 * * `bn` - Bengali
 * * `br` - Breton
 * * `bs` - Bosnian
 * * `ca` - Catalan
 * * `ckb` - Central Kurdish (Sorani)
 * * `cs` - Czech
 * * `cy` - Welsh
 * * `da` - Danish
 * * `de` - German
 * * `dsb` - Lower Sorbian
 * * `el` - Greek
 * * `en` - English
 * * `en-au` - Australian English
 * * `en-gb` - British English
 * * `eo` - Esperanto
 * * `es` - Spanish
 * * `es-ar` - Argentinian Spanish
 * * `es-co` - Colombian Spanish
 * * `es-mx` - Mexican Spanish
 * * `es-ni` - Nicaraguan Spanish
 * * `es-ve` - Venezuelan Spanish
 * * `et` - Estonian
 * * `eu` - Basque
 * * `fa` - Persian
 * * `fi` - Finnish
 * * `fr` - French
 * * `fy` - Frisian
 * * `ga` - Irish
 * * `gd` - Scottish Gaelic
 * * `gl` - Galician
 * * `he` - Hebrew
 * * `hi` - Hindi
 * * `hr` - Croatian
 * * `hsb` - Upper Sorbian
 * * `hu` - Hungarian
 * * `hy` - Armenian
 * * `ia` - Interlingua
 * * `id` - Indonesian
 * * `ig` - Igbo
 * * `io` - Ido
 * * `is` - Icelandic
 * * `it` - Italian
 * * `ja` - Japanese
 * * `ka` - Georgian
 * * `kab` - Kabyle
 * * `kk` - Kazakh
 * * `km` - Khmer
 * * `kn` - Kannada
 * * `ko` - Korean
 * * `ky` - Kyrgyz
 * * `lb` - Luxembourgish
 * * `lt` - Lithuanian
 * * `lv` - Latvian
 * * `mk` - Macedonian
 * * `ml` - Malayalam
 * * `mn` - Mongolian
 * * `mr` - Marathi
 * * `ms` - Malay
 * * `my` - Burmese
 * * `nb` - Norwegian Bokmål
 * * `ne` - Nepali
 * * `nl` - Dutch
 * * `nn` - Norwegian Nynorsk
 * * `os` - Ossetic
 * * `pa` - Punjabi
 * * `pl` - Polish
 * * `pt` - Portuguese
 * * `pt-br` - Brazilian Portuguese
 * * `ro` - Romanian
 * * `ru` - Russian
 * * `sk` - Slovak
 * * `sl` - Slovenian
 * * `sq` - Albanian
 * * `sr` - Serbian
 * * `sr-latn` - Serbian Latin
 * * `sv` - Swedish
 * * `sw` - Swahili
 * * `ta` - Tamil
 * * `te` - Telugu
 * * `tg` - Tajik
 * * `th` - Thai
 * * `tk` - Turkmen
 * * `tr` - Turkish
 * * `tt` - Tatar
 * * `udm` - Udmurt
 * * `ug` - Uyghur
 * * `uk` - Ukrainian
 * * `ur` - Urdu
 * * `uz` - Uzbek
 * * `vi` - Vietnamese
 * * `zh-hans` - Simplified Chinese
 * * `zh-hant` - Traditional Chinese
 */
export type NativeLanguageEnum =
  | 'af'
  | 'ar'
  | 'ar-dz'
  | 'ast'
  | 'az'
  | 'bg'
  | 'be'
  | 'bn'
  | 'br'
  | 'bs'
  | 'ca'
  | 'ckb'
  | 'cs'
  | 'cy'
  | 'da'
  | 'de'
  | 'dsb'
  | 'el'
  | 'en'
  | 'en-au'
  | 'en-gb'
  | 'eo'
  | 'es'
  | 'es-ar'
  | 'es-co'
  | 'es-mx'
  | 'es-ni'
  | 'es-ve'
  | 'et'
  | 'eu'
  | 'fa'
  | 'fi'
  | 'fr'
  | 'fy'
  | 'ga'
  | 'gd'
  | 'gl'
  | 'he'
  | 'hi'
  | 'hr'
  | 'hsb'
  | 'hu'
  | 'hy'
  | 'ia'
  | 'id'
  | 'ig'
  | 'io'
  | 'is'
  | 'it'
  | 'ja'
  | 'ka'
  | 'kab'
  | 'kk'
  | 'km'
  | 'kn'
  | 'ko'
  | 'ky'
  | 'lb'
  | 'lt'
  | 'lv'
  | 'mk'
  | 'ml'
  | 'mn'
  | 'mr'
  | 'ms'
  | 'my'
  | 'nb'
  | 'ne'
  | 'nl'
  | 'nn'
  | 'os'
  | 'pa'
  | 'pl'
  | 'pt'
  | 'pt-br'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'sl'
  | 'sq'
  | 'sr'
  | 'sr-latn'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'te'
  | 'tg'
  | 'th'
  | 'tk'
  | 'tr'
  | 'tt'
  | 'udm'
  | 'ug'
  | 'uk'
  | 'ur'
  | 'uz'
  | 'vi'
  | 'zh-hans'
  | 'zh-hant'

/**
 * * `cold` - Cold
 * * `flu` - Flu
 * * `stomach` - Stomach
 * * `allergy` - Allergy
 * * `teeth` - Teeth
 * * `headache` - Headache
 * * `fatigue` - Fatigue
 * * `nausea` - Nausea
 * * `menstruation_pain` - Menstruation pain
 * * `fever` - Fever
 * * `covid_isolation` - Covid - isolation
 * * `covid_quarantine` - Covid - quarantine
 * * `other` - Other
 */
export type NewSymptomsEnum =
  | 'cold'
  | 'flu'
  | 'stomach'
  | 'allergy'
  | 'teeth'
  | 'headache'
  | 'fatigue'
  | 'nausea'
  | 'menstruation_pain'
  | 'fever'
  | 'covid_isolation'
  | 'covid_quarantine'
  | 'other'

/**
 * * `merit` - Merit
 * * `verbal_commendation` - Verbal commendation
 * * `written_commendation` - Written commendation
 * * `certificate` - Certificate
 * * `verbal_warning` - Verbal warning
 * * `written_warning` - Written warning
 * * `ultimatum` - Ultimatum
 * * `suspension` - Suspension
 * * `exclusion` - Exclusion
 */
export type NextLastSanctionEnum =
  | 'merit'
  | 'verbal_commendation'
  | 'written_commendation'
  | 'certificate'
  | 'verbal_warning'
  | 'written_warning'
  | 'ultimatum'
  | 'suspension'
  | 'exclusion'

export type NightAttendance = {
  /** @format uuid */
  id: string
  mark?: NightAttendanceMarkEnum | BlankEnum
  student_str: string
  /** @format uuid */
  student: string
  house_name: string
  room_id: string
  room_name: string
  has_comment: string
  is_at_home?: boolean
  is_at_campus_absence?: boolean
  /** @format time */
  bedtime?: string | null
  merit_note: string
  merit_electronics_involved: string
  is_marking_disabled: string
  boarding_status?: string
  is_room_replacement: string
}

export type NightAttendanceComment = {
  /** @format uuid */
  id: string
  author?: string
  author_str: string
  content: string
  /** @format date-time */
  modified: string
}

export type NightAttendanceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content: string
  mark_disturbance?: boolean
}

/**
 * * `present` - Present
 * * `disturbance` - Disturbance
 * * `absent` - Absent
 */
export type NightAttendanceMarkEnum = 'present' | 'disturbance' | 'absent'

export type NightAttendanceReport = {
  id: number
  /** @format date-time */
  created: string
  status: BoardingAttendanceReportStatusEnum
  night_guard?: string
  night_guard_str: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender: ForGenderEnum
  for_gender_display: string
  status_display: string
  has_disruption?: boolean
  next_report: string
  previous_report: string
}

export type NightAttendanceReportList = {
  id: number
  /** @format date-time */
  created: string
  status: BoardingAttendanceReportStatusEnum
  night_guard_str: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender?: ForGenderEnum
  for_gender_display: string
  status_display: string
  has_disruption?: boolean
}

export type NightAttendanceReportRequest = {
  night_guard?: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender: ForGenderEnum
  has_disruption?: boolean
}

export type NightAttendanceRequest = {
  mark?: NightAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student: string
  /** @minLength 1 */
  disturbance_note: string
  electronics_involved: boolean
  is_at_home?: boolean
  is_at_campus_absence?: boolean
  /** @format time */
  bedtime?: string | null
  /** @minLength 1 */
  boarding_status?: string
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type NightGuardProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type NightGuardProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Notification = {
  /** @format uuid */
  id: string
  body: string
  was_read: boolean
  sender: string
  notification_type: NotificationTypeEnum
  notification_type_display: string
  related_model: string | null
  related_object_id: string | null
  notification_class_name: string
  sent_at: string
  /** @format date-time */
  created: string
  extra_data: Record<string, any>
}

export type NotificationClassName = {
  /** @maxItems 20 */
  notification_class_name: string[]
}

export type NotificationClassNameRequest = {
  /** @maxItems 20 */
  notification_class_name: string[]
}

export type NotificationSettings = {
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
}

export type NotificationSettingsRequest = {
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
}

/**
 * * `campus` - Campus
 * * `academic` - Academic
 * * `education` - Education
 * * `reminders` - Reminders
 * * `dashboard` - Dashboard
 */
export type NotificationTypeEnum =
  | 'campus'
  | 'academic'
  | 'education'
  | 'reminders'
  | 'dashboard'

export type NullEnum = null

export type ObjectTypeEnum = 'leave_request' | 'campus_absence'

export type OptionalMedicinesInHealthCheck = {
  id: string
  medicine_name: string
  /** @maxLength 255 */
  medicine_strength: string
  medicine?: number | null
  medicine_plan: string
}

export type PaginatedAbacusInvoiceProductList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AbacusInvoiceProduct[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Absence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAcademicAttendanceAlertList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AcademicAttendanceAlert[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAcademicAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AcademicAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAccGradeList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AccGrade[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAccommodationList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Accommodation[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAchievementList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Achievement[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedActivityMoveList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ActivityMove[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminBoardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminBoard[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminBoardScheduleList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminBoardSchedule[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminCeleryTaskStatusListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminCeleryTaskStatusList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminMeritRewardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminMeritReward[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminMessageList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminMessage[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminRecoveryList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminRecovery[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminSchoolYearList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminSchoolYear[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminStudentContentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminStudentContent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminTeacherList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminTeacher[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminTimetableLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminTimetableLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminUserListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminUserList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminWidgetList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminWidget[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAimList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Aim[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAllergyList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Allergy[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAssignmentGradeList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AssignmentGrade[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAssignmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Assignment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAttachmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Attachment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAvailableStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AvailableStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedBillingReportList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: BillingReport[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedBoardingAttendanceReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: BoardingAttendanceReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedBoardingCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: BoardingComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCalendarEventList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CalendarEvent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCampusAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CampusAbsence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCancelLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CancelLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCatalogList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Catalog[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedChronicConditionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ChronicCondition[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Comment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCompetencyList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Competency[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseFamilyList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseFamily[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseGroupList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseGroup[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseIntelligenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseIntelligence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseLevelList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseLevel[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCoursePlannerListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CoursePlannerList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseSubjectList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseSubject[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseTypeList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseType[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDayMenuList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DayMenu[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDegreeAimList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DegreeAim[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDepartmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Department[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDoctorList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Doctor[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftActivityListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftActivityList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftRotaActivityList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftRotaActivity[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedExamEnrolledStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ExamEnrolledStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedExamList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Exam[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedFavouriteWidgetList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: FavouriteWidget[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedFeedList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Feed[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedFileForStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: FileForStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedGenerationTaskList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: GenerationTask[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedGradeLevelList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: GradeLevel[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedHealthCheckListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: HealthCheckList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedHospitalList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Hospital[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedHouseListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: HouseList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedHouseRoomListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: HouseRoomList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedImageList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Image[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedInterestList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Interest[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedInternshipList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Internship[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedLeaveRequestList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: LeaveRequest[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedLogEntryList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: LogEntry[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMedicineInPlanList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MedicineInPlan[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMedicineList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Medicine[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMedicinePlanList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MedicinePlan[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMeritItemList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MeritItem[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMeritList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Merit[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedMissionControlList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: MissionControl[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedNightAttendanceReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: NightAttendanceReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedNotificationList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Notification[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentCoursesList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ParentCourses[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentIdpPlanStatusHistoryList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ParentIdpPlanStatusHistory[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentLeaveRequestList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ParentLeaveRequest[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Parent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentMessageList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ParentMessage[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentReportCardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ParentReportCard[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedParentsFileList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ParentsFile[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPotentialOptimizationList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PotentialOptimization[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedProgrammeAttendanceReportList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ProgrammeAttendanceReport[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedProgrammeStudentAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ProgrammeStudentAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedProgressCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ProgressComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPublicNextLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PublicNextLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedQuarterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Quarter[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  current_period?: Quarter
}

export type PaginatedReportCardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ReportCard[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedRequestedCourseList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: RequestedCourse[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedRoomList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Room[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolBreakList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolBreak[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolProgrammeListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolProgrammeList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSchoolYearList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolYear[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  current_period?: SchoolYear
}

export type PaginatedSectionListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SectionList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSemesterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Semester[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  current_period?: Semester
}

export type PaginatedSickReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SickReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSimpleSectionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SimpleSection[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSkillList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Skill[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStringOptionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StringOption[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentAcademicAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentAcademicAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentAchievementList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentAchievement[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentCampusAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCampusAbsence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentCategoryReportListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCategoryReportList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentContentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentContent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentCourseRequirementList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCourseRequirement[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentCoursesList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentCourses[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentDayMenuList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentDayMenu[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentExternalExamList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentExternalExam[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentHomeworkList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentHomework[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentIdpPlanListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentIdpPlanList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentInternshipList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentInternship[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentLeaveRequestSwaggerList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentLeaveRequestSwagger[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentMeritListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentMeritList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentMeritRewardList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentMeritReward[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentMessageList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentMessage[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentSchoolProgrammeListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentSchoolProgrammeList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentTAEExperienceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentTAEExperience[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentTAEProjectList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentTAEProject[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentTranscriptList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentTranscript[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStudentsFileList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StudentsFile[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTAEExperienceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TAEExperience[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTaskList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Task[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTeacherTimetableListLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TeacherTimetableListLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTeamList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Team[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Timetable[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableLogList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TimetableLog[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableMentoringList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: TimetableMentoring[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTutorList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Tutor[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedWeeklyCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: WeeklyComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type Parent = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  full_name: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /**
   * @format email
   * @maxLength 254
   */
  secondary_email?: string
  /** @maxLength 128 */
  phone_number: string
  /**
   * * `miss` - Miss
   * * `ms` - Ms.
   * * `mr` - Mr.
   * * `mrs` - Mrs.
   * * `title` - Title
   * * `other` - Other
   */
  salutation: SalutationEnum
  salutation_other?: string
  occupation?: string
  /**
   * * `parent` - Parent
   * * `legal_guardian` - Legal Guardian
   * * `in_legal_guardian_absence` - In Legal Guardian Absence
   */
  contact_type?: ContactTypeEnum
  contact_leave_request?: boolean
  is_allowed_to_visit?: boolean
  is_app_locked: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  contacts?: ParentStudentContact[]
}

export type ParentBasket = {
  id: number
  name: string
  courses: ParentCourseInBasket[]
}

export type ParentContactMessage = {
  author?: string
  /** @format uuid */
  student: string
  message: string
  /**
   * * `leave_request` - Leave Request
   * * `boarding` - Boarding
   * * `rooming` - Rooming
   * * `health` - Health
   * * `meal_plans` - Meal Plans
   * * `idp` - IDP (R) - Individual Development Plan
   * * `academic` - Academic
   * * `university_application` - University Application
   * * `sport` - Sport
   * * `co_curricular` - Co-Curricular
   * * `accounting` - Accounting
   * * `app_helpdesk` - App Helpdesk
   * * `other` - Other topics
   */
  subject: ContactMessageSubjectEnum
}

export type ParentContactMessageRequest = {
  author?: string
  /** @format uuid */
  student: string
  /** @minLength 1 */
  message: string
  /**
   * * `leave_request` - Leave Request
   * * `boarding` - Boarding
   * * `rooming` - Rooming
   * * `health` - Health
   * * `meal_plans` - Meal Plans
   * * `idp` - IDP (R) - Individual Development Plan
   * * `academic` - Academic
   * * `university_application` - University Application
   * * `sport` - Sport
   * * `co_curricular` - Co-Curricular
   * * `accounting` - Accounting
   * * `app_helpdesk` - App Helpdesk
   * * `other` - Other topics
   */
  subject: ContactMessageSubjectEnum
}

export type ParentContactMessageSubjects = {
  label: string
  /**
   * * `leave_request` - Leave Request
   * * `boarding` - Boarding
   * * `rooming` - Rooming
   * * `health` - Health
   * * `meal_plans` - Meal Plans
   * * `idp` - IDP (R) - Individual Development Plan
   * * `academic` - Academic
   * * `university_application` - University Application
   * * `sport` - Sport
   * * `co_curricular` - Co-Curricular
   * * `accounting` - Accounting
   * * `app_helpdesk` - App Helpdesk
   * * `other` - Other topics
   */
  value: ContactMessageSubjectEnum
}

export type ParentCourse = {
  /** @format uuid */
  id: string
  name: string
  code: string
}

export type ParentCourseInBasket = {
  id: number
  course: ParentCourse
  quarters: ParentIdpSectionEnrollment[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentCourses = {
  id: string
  name: string
  teacher: UserInCourse
  grades: ParentGrade[]
  current_grade: ParentGrade
  is_private: boolean
}

export type ParentDiscussIdpPlanRequest = {
  /** @minLength 1 */
  message: string
}

export type ParentGrade = {
  period_short_name: string
  grade: string
  weighted_percents: number | null
  target_grade: string | null
  target_percents: number | null
  /**
   * * `pending` - Pending
   * * `much_above` - Much Above
   * * `above` - Above
   * * `below` - Below
   * * `much_below` - Much Below
   */
  target_grade_status?: TargetGradeStatusEnum
}

export type ParentIdpPlan = {
  id: string
  /** @format uuid */
  student: string
  status: IdpPlanStatusEnum
  status_display: string
  version: number
  /** Last approved version */
  is_active: boolean
  year: SchoolYear
  baskets?: ParentBasket[]
  /** @format date-time */
  parent_approved_at: string | null
  /** @format uuid */
  parent_approve_author: string | null
  parent_approve_author_str: string
  /** @format date */
  parent_approval_deadline: string | null
  /** @format uuid */
  parent_message_author: string | null
  parent_message_author_str: string
  /** @format date-time */
  parent_message_created_at: string | null
  notifications_mark_as_read: number | null
}

export type ParentIdpPlanChanges =
  | IdpPlanChangesAddedAction
  | IdpPlanChangesDeletedAction
  | IdpPlanChangesPrivacyChangedAction
  | IdpPlanChangesLessonsNumberChangedAction

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentIdpPlanStatusHistory = {
  id: number
  status_display: string
  /**
   * * `new` - New
   * * `draft` - Draft
   * * `rejected` - Rejected
   * * `waits_for_admin_approval` - In admin approval
   * * `in_parental_approval` - In parental approval
   * * `is_discussion` - In discussion
   * * `approved` - Approved
   * * `automatically_approved` - Automatically approved
   * * `updated` - Updated
   * * `archived` - Archived
   */
  target_status: IdpPlanStatusEnum
  version: number
  /** @format date-time */
  created: string
  parent_approve_author: string
  parent_approve_author_str: string
  parent_message_author: string
  parent_message_author_str: string
}

export type ParentIdpPlanYears = {
  id: number
  name: string
  has_new_idp_plan: boolean
}

export type ParentIdpSectionEnrollment = {
  id: number
  is_private: boolean
  number_of_lessons: number
  quarter: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentLeaveRequest = {
  /** @format uuid */
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_out: ParentLeaveCheck
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_in: ParentLeaveCheck
  leave_type: LeaveRequestTypeEnum
  leave_type_label: string
  request_type: RequestTypeEnum
  status: string
  status_label: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  order_date: string
  parent_saw_changes: boolean
  notifications_mark_as_read: number | null
}

export type ParentLeaveRequestStatus = {
  label: string
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  value: LeaveRequestStatusEnum
}

export type ParentLeaveRequestType = {
  label: string
  /**
   * * `0` - Weekend
   * * `1` - Holiday
   * * `2` - Other
   */
  value: LeaveRequestTypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentMessage = {
  id: number
  content: string
  /** @format date-time */
  created: string
  /**
   * * `alert` - Alert
   * * `notice` - Notice
   */
  type: MessageTypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentMissionControlResponse = {
  id: number
  degree_aim_1: string
  degree_aim_2: string
  degree_destination_1: string
  degree_destination_2: string
  estimated_completion_display: string
}

export type ParentProfile = {
  /** @format uuid */
  id: string
  first_name: string
  last_name: string
  name: string
  full_name: string
  /**
   * Email address
   * @format email
   */
  email: string
  phone_number: string
  account_overdue: AccountOverdueEnum
  favourites?: string[]
}

export type ParentProfileRequest = {
  favourites?: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentReportCard = {
  id: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  quarter: ReportCardQuarter
  /** @format uri */
  file: string
  is_read: boolean
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type ParentRequest = {
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /**
   * @format email
   * @maxLength 254
   */
  secondary_email?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  phone_number: string
  /**
   * * `miss` - Miss
   * * `ms` - Ms.
   * * `mr` - Mr.
   * * `mrs` - Mrs.
   * * `title` - Title
   * * `other` - Other
   */
  salutation: SalutationEnum
  salutation_other?: string
  occupation?: string
  /**
   * * `parent` - Parent
   * * `legal_guardian` - Legal Guardian
   * * `in_legal_guardian_absence` - In Legal Guardian Absence
   */
  contact_type?: ContactTypeEnum
  contact_leave_request?: boolean
  is_allowed_to_visit?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  contacts?: ParentStudentContactRequest[]
}

export type ParentSendReportCardRequest = {
  quarter: number
  students: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ParentStudent = {
  /** @format uuid */
  id: string
  first_name: string
  last_name: string
  full_name: string
  /** @format uri */
  avatar: string | null
  current_grade_level_name: string
  house_phone_number: string
  is_day_student: boolean
  account_overdue: AccountOverdueEnum
  has_pending_item: boolean
}

export type ParentStudentContact = {
  id: number
  /** @format uuid */
  parent: string
  parent_full_name: string
  student_full_name: string
  /** @format uuid */
  student: string
  /** Parent roles for student */
  roles: RolesEnum[]
  has_app_access?: boolean
}

export type ParentStudentContactRequest = {
  /** @format uuid */
  student: string
  /** Parent roles for student */
  roles: RolesEnum[]
  has_app_access?: boolean
}

export type ParentWarning = {
  id: number
  idp_plan?: string | null
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  content: string
  accepted_by_parent: boolean
  note?: string
}

export type ParentWarningRequest = {
  /** @minLength 1 */
  idp_plan?: string | null
  /** @minLength 1 */
  content: string
  accepted_by_parent: boolean
  note?: string
}

export type ParentsFile = {
  id: number
  name: string
  /** @format uri */
  file: string | null
  filename: string
  /** @format uri */
  link: string
}

export type ParticipantAvailableStringFilter = {
  query_param: string
  label: string
  choices: ParticipantStringOption[]
}

export type ParticipantStringOption = {
  label: string
  value: string
  is_leader: boolean
  is_member: boolean
  is_owner: boolean
}

export type ParticipantStudent = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /** @format uri */
  avatar?: string | null
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
}

export type ParticipantStudentRequest = {
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /** @format binary */
  avatar?: File | null
}

export type PatchedAbacusInvoiceProductRequest = {
  /**
   * @min -2147483648
   * @max 2147483647
   */
  abacus_id?: number
  /** @minLength 1 */
  name?: string
}

export type PatchedAbsenceRequest = {
  students?: string[]
  /** @format date-time */
  start_date?: string
  /** @format date-time */
  end_date?: string
  comment?: string
  /**
   * * `late` - Late
   * * `absent_excused` - Absent excused
   * * `absent_unexcused` - Absent unexcused
   * * `sick` - Sick
   */
  absence_type?: AbsenceTypeEnum
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  added_by?: string
  last_modified_by?: string
}

export type PatchedAcademicAttendanceRequest = {
  is_present?: boolean
  is_late?: boolean
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  unlock_edit?: boolean
  /** @format date-time */
  marked_at?: string | null
}

export type PatchedAccGradeRequest = {
  quarter?: number | null
  semester?: number | null
  /** @maxLength 2000 */
  comment?: string
  /** @format uuid */
  course?: string
  /** @format uuid */
  student?: string
  custom_grade?: CustomGradeEnum | BlankEnum
}

export type PatchedAccommodationRequest = {
  /** @format uuid */
  student?: string
  room?: number
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string | null
  is_temporary_movement?: boolean
  /**
   * * `isolation` - Isolation
   * * `quarantine` - Quarantine
   * * `sleepover` - Sleepover
   */
  temporary_movement_type?: TemporaryMovementTypeEnum
  is_room_only_for_student?: boolean
  added_by?: string
  last_modified_by?: string
}

export type PatchedAdminAbsenceRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype?: RecoveryResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminBoardRequest = {
  widgets?: AdminWidgetRequest[]
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  sort_value?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminBoardScheduleRequest = {
  /** @minLength 1 */
  name?: string
  /** @format date */
  publication_date?: string
  boards?: string[]
  recurring?: RecurringRequest | null
}

export type PatchedAdminBoardingAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  text?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminCelebrationWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminClockWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminCongratulationsWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminEventAgendaWidgetRequest = {
  agenda_items?: AgendaItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header?: string
  /** @format date */
  date_from?: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from?: string
  /** @format time */
  time_to?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminEventInfoWidgetRequest = {
  /** @minLength 1 */
  bg_color?: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 90
   */
  header?: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminEventInfoWithPhotoWidgetRequest = {
  /** @minLength 1 */
  bg_color?: string
  link?: AdminLinkWithQRCodeRequest | null
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * @minLength 1
   * @maxLength 100
   */
  header?: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  crop_my_rosenberg_idp_photo_separately?: boolean
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  /** @maxLength 64 */
  qr_code_text?: string
  /** @minLength 1 */
  photo?: string
  /** @minLength 1 */
  photo_in_my_rosenberg_idp?: string | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminInfoboardsSettingsRequest = {
  /** Generate automated alerts about teacher's covers */
  generate_teacher_cover_alerts?: boolean
  /** Generate automated alerts about school breaks (1 week before start date) */
  generate_school_break_alerts?: boolean
  /** Show 2 most recent articles */
  show_recent_articles?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminJournalWidgetRequest = {
  journal_items?: AdminJournalItemRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminLunchtimeRecoveryRequest = {
  /** @format uuid */
  assigned_to?: string | null
  description?: string
  /**
   * * `AbsenceRecovery` - AbsenceRecovery
   * * `LunchtimeRecovery` - LunchtimeRecovery
   */
  resourcetype?: RecoveryResourcetypeEnum
}

export type PatchedAdminMenuWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminMessageRequest = {
  /**
   * * `alert` - Alert
   * * `notice` - Notice
   */
  type?: MessageTypeEnum
  /** @minLength 1 */
  name?: string
  /** @minLength 1 */
  content?: string
  /** @format date */
  display_to?: string
  is_created_manually?: boolean
  author?: string
}

export type PatchedAdminMysteryLinkWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminPosterWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /** @minLength 1 */
  image?: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminRecoveryRequest =
  | PatchedAdminAbsenceRecoveryRequest
  | PatchedAdminLunchtimeRecoveryRequest

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminSchoolYearRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name?: string
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
  semesters?: AdminSemesterRequest[]
}

export type PatchedAdminSiteSettingsRequest = {
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminStudentAnnouncementRequest = {
  /** @format uuid */
  student?: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  emojis?: string[]
  /**
   * @minLength 1
   * @maxLength 100
   */
  text?: string
  /** @maxLength 7 */
  bg_color?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAdminStudentAnnouncementWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminStudentArtGalleryWidgetRequest = {
  arts?: AdminStudentArtRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminStudentContentRequest =
  | PatchedAdminStudentMysteryLinkRequest
  | PatchedAdminStudentAnnouncementRequest
  | PatchedAdminStudentPicRequest

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminStudentMysteryLinkRequest = {
  /** @format uuid */
  student?: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  link?: AdminLinkWithQRCodeRequest
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminStudentPicRequest = {
  /** @format uuid */
  student?: string
  /**
   * * `pending` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `deleted` - Deleted
   * * `archived` - Archived
   */
  status?: StudentContentStatusEnum
  /** @format date-time */
  published_at?: string | null
  /** @format date-time */
  display_to?: string | null
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image?: ImageRequest
  /** @maxLength 50 */
  title?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAdminStudentsPicWidgetRequest = {
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedAdminTeacherRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** @minLength 1 */
  first_name?: string
  /** @minLength 1 */
  last_name?: string
  /**
   * @minLength 1
   * @maxLength 16
   */
  acronym?: string
  /** @minLength 1 */
  phone_number?: string
  departments?: number[]
  preferred_rooms?: number[]
  two_factor_auth?: boolean
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  custom_schedule?: Record<string, any>
  is_active?: boolean
}

export type PatchedAdminTimetableLessonUpdateRequest = {
  /** @format uuid */
  teacher_cover?: string | null
}

export type PatchedAdminUserRequest = {
  /** @minLength 1 */
  password1?: string
  /** @minLength 1 */
  password2?: string
  /** @minLength 1 */
  first_name?: string
  /** @minLength 1 */
  last_name?: string
  /**
   * @format email
   * @minLength 1
   */
  email?: string
  /** @minLength 1 */
  phone_number?: string
  two_factor_auth?: boolean
  /** @format date-time */
  last_login?: string | null
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  /** @format date-time */
  date_joined?: string
  search?: string | null
  searchable_content?: string
  /** @maxLength 256 */
  name?: string
  gender?: GenderEnum | BlankEnum
  disable_access_to_idp?: boolean
  staff_type?: StaffTypeEnum | BlankEnum
  can_handle_generator?: boolean
  is_vaccinated?: boolean
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
  cannot_use_sms_login?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAdminVariantManualCongratulationsWidgetRequest = {
  items?: AdminVariantManualCongratulationsRequest[]
  /** @maxLength 255 */
  working_name?: string
  show_in_idp_app?: boolean
  show_in_parents_app?: boolean
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype?: WidgetResourcetypeEnum
}

export type PatchedAdminWidgetRequest =
  | PatchedAdminBoardingAnnouncementWidgetRequest
  | PatchedAdminCelebrationWidgetRequest
  | PatchedAdminEventAgendaWidgetRequest
  | PatchedAdminCongratulationsWidgetRequest
  | PatchedAdminPosterWidgetRequest
  | PatchedAdminStudentArtGalleryWidgetRequest
  | PatchedAdminEventInfoWidgetRequest
  | PatchedAdminEventInfoWithPhotoWidgetRequest
  | PatchedAdminJournalWidgetRequest
  | PatchedAdminMysteryLinkWidgetRequest
  | PatchedAdminMenuWidgetRequest
  | PatchedAdminStudentAnnouncementWidgetRequest
  | PatchedAdminStudentsPicWidgetRequest
  | PatchedAdminVariantManualCongratulationsWidgetRequest
  | PatchedAdminClockWidgetRequest

export type PatchedAllergyRequest = {
  /** @minLength 1 */
  type?: string
  subtypes?: string[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAppMysteryLinkRequest = {
  student?: string
  link?: LinkWithQRCodeRequest
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAppStudentAnnouncementRequest = {
  student?: string
  emojis?: string[]
  /**
   * @minLength 1
   * @maxLength 100
   */
  text?: string
  /** @maxLength 7 */
  bg_color?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAppStudentPicRequest = {
  student?: string
  /** @minLength 1 */
  image?: string
  /** @maxLength 50 */
  title?: string
  /**
   * * `StudentMysteryLink` - StudentMysteryLink
   * * `StudentAnnouncement` - StudentAnnouncement
   * * `StudentPic` - StudentPic
   */
  resourcetype?: StudentContentResourcetypeEnum
}

export type PatchedAppointmentCancellationRequest = {
  /** @minLength 1 */
  cancellation_reason?: string
}

export type PatchedAppointmentRequest = {
  /**
   * * `hospital` - Hospital
   * * `doctor` - Doctor
   */
  appointment_type?: AppointmentTypeEnum
  doctor?: number | null
  doctor_name?: string
  hospital?: number | null
  hospital_name?: string
  /** @format date-time */
  date_and_time?: string
  /** @minLength 1 */
  report?: string
  /** @format date-time */
  end_of_visit?: string
}

export type PatchedApproveStudentContentRequest = {
  /** @format date */
  display_to?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAssignmentGradeRequest = {
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student?: string
  assignment?: AssignmentInStudentAssignmentItemRequest
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedAssignmentRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /** @minLength 1 */
  description?: string
  type?: number
  /** @format date */
  due_date?: string
  /** @format uuid */
  lesson?: string | null
  /** @format uuid */
  course?: string
  student_items?: StudentAssignmentItemRequest[]
  is_homework?: boolean
  quarter?: number
}

export type PatchedBillingReportRequest = {
  /**
   * @minLength 1
   * @maxLength 120
   */
  name?: string
}

export type PatchedBoardingAttendanceReportRequest = {
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  /** @format uuid */
  tutor?: string | null
  house?: number | null
  for_senior_campus?: boolean
}

export type PatchedBoardingAttendanceRequest = {
  mark?: BoardingAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student?: string
  is_at_home?: boolean
  is_at_class?: boolean
  is_at_exam?: boolean
  /** @minLength 1 */
  boarding_status?: string
}

export type PatchedBoardingCommentRequest = {
  added_by?: string
  last_modified_by?: string
  /**
   * @min 0
   * @max 32767
   */
  semester?: SemesterEnum | NullEnum | null
  /** @format uuid */
  student?: string
  school_year?: number
  current_quarter?: number
  /** @minLength 1 */
  content?: string
}

export type PatchedCalendarEventRequest = {
  /** @format date */
  date?: string
  /**
   * * `community_weekend` - Community weekend
   * * `academic_tests` - Academic tests
   */
  event_type?: EventTypeEnum
}

export type PatchedCampusAbsenceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content?: string
  reject?: boolean
}

/** Adds nested create feature */
export type PatchedCampusAbsenceRequest = {
  created_by?: string
  last_modified_by?: string
  campus_absence_students?: CampusAbsenceStudentRequest[]
  quarter?: number
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  campus_absence_type?: CampusAbsenceTypeEnum
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype?: CampusAbsenceSubTypeEnum
  /** @minLength 1 */
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from?: string
  /** @format date-time */
  date_to?: string
  disable_students_length_validation?: boolean
  supervisors?: (string | null)[]
  house?: number | null
}

export type PatchedCatalogRequest = {
  /** @minLength 1 */
  name?: string
}

export type PatchedChangeAvatarRequest = {
  /** @format binary */
  avatar?: File | null
}

export type PatchedChronicConditionRequest = {
  /** @minLength 1 */
  type?: string
  subtypes?: string[]
}

export type PatchedConditionRequest = {
  /** @format uuid */
  health_check?: string
  /**
   * * `unchanged` - Unchanged
   * * `better` - Better
   * * `worse` - Worse
   */
  status?: ConditionStatusEnum
  new_symptoms?: NewSymptomsEnum[]
  new_symptoms_appeared?: boolean
}

export type PatchedCourseDescriptionRequest = {
  description?: string
}

export type PatchedCourseFamilyRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedCourseGroupRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedCourseInBasketUpdateDepartmentRequest = {
  mission_control_department?: MissionControlDepartmentEnum | BlankEnum
}

export type PatchedCourseIntelligenceRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedCourseLevelRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedCoursePlannerRequest = {
  number_of_lessons?: number | null
  previous_courses?: string[]
  related_courses?: string[]
  course_request?: number | null
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  is_latest_version?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /** @minLength 1 */
  difficulty?: string
  /** @minLength 1 */
  number?: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,1}(?:\.\d{0,2})?$
   */
  humanix_factor?: string
  /** @maxItems 4 */
  performed_in_quarters?: QuarterShortNameEnum[]
  description?: string
  has_academic_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  exam_duration?: number | null
  has_external_exam?: boolean
  external_exam_type?: ExternalExamTypeEnum | BlankEnum
  exam_course_name?: string
  /** RPS-6527 */
  is_payable?: boolean
  legacy_department?: number | null
  /** @minLength 1 */
  group?: string
  /** @minLength 1 */
  subject?: string
  departments?: number[]
  rooms?: number[]
  families?: string[]
  intelligences?: string[]
  types?: string[]
  preparation_for_types?: string[]
  levels?: string[]
}

export type PatchedCourseSubjectRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedCourseTypeRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedDayMenuRequest = {
  /** @format date */
  date?: string
  is_filled?: boolean
  is_skipped?: boolean
  /** @maxLength 120 */
  lunch_starter?: string
  /** @maxLength 120 */
  lunch_soup?: string
  /** @maxLength 120 */
  lunch_main_course?: string
  /** @maxLength 120 */
  lunch_dessert?: string
  /** @maxLength 120 */
  lunch_starter_vege?: string
  /** @maxLength 120 */
  lunch_soup_vege?: string
  /** @maxLength 120 */
  lunch_main_course_vege?: string
  /** @maxLength 120 */
  lunch_dessert_vege?: string
  /** @maxLength 120 */
  dinner_starter?: string
  /** @maxLength 120 */
  dinner_soup?: string
  /** @maxLength 120 */
  dinner_main_course?: string
  /** @maxLength 120 */
  dinner_dessert?: string
  /** @maxLength 120 */
  dinner_starter_vege?: string
  /** @maxLength 120 */
  dinner_soup_vege?: string
  /** @maxLength 120 */
  dinner_main_course_vege?: string
  /** @maxLength 120 */
  dinner_dessert_vege?: string
  /** @maxLength 120 */
  breakfast_special?: string
  /** @maxLength 120 */
  breakfast_special_vege?: string
}

export type PatchedDepartmentRequest = {
  /**
   * @minLength 1
   * @maxLength 32
   */
  name?: string
  /** @maxLength 32 */
  label?: string
  is_talent_and_enrichment?: boolean
}

export type PatchedDoctorDiagnosisRequest = {
  /** @minLength 1 */
  diagnosis?: string
  /** @minLength 1 */
  recommendations?: string
  /**
   * * `new` - New
   * * `cancelled` - Cancelled
   * * `after_the_visit` - After the visit
   */
  status?: AppointmentStatusEnum
}

export type PatchedDoctorRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
}

export type PatchedDraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day?: DayEnum
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /** @format double */
  duration?: number
  is_archived?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  external_id?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  fet_id?: number | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  /** @format uuid */
  course?: string
  /**
   * Artisan
   * @format uuid
   */
  teacher?: string
  room?: number | null
  timetable?: number
  section?: number | null
  from_optimization?: number | null
  /** @format uuid */
  separated_from?: string | null
  students?: string[]
}

export type PatchedDrinkGiveRequest = {
  was_given?: boolean
  /** @format uuid */
  health_check?: string
}

export type PatchedExamEnrolledStudentRequest = {
  is_present?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_absent_unexcused?: boolean
  is_sick?: boolean
  is_academic_honesty?: boolean
  is_disturbance?: boolean
  disturbance_merit_note?: string
  academic_honesty_merit_note?: string
}

export type PatchedExamRequest = {
  /** @format uuid */
  course?: string
  /** @format date */
  date?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  duration?: number | null
  /** @format time */
  start_time?: string | null
  /** @format time */
  end_time?: string | null
  location?: number | null
  test_invigilators?: string[]
  saa_invigilators?: string[]
  teachers?: string[]
  grade_levels?: number[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedExamSessionRequest = {
  /** @minLength 1 */
  name?: string
  /**
   * * `quarter` - Quarter exams
   * * `academic` - Academic
   * * `external` - External exams
   */
  exam_type?: ExamTypeEnum
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
  quarter?: number
  departments?: number[]
  grade_levels?: number[]
  cancellation_dates?: ExamSessionCancellationDateRequest[]
}

export type PatchedFavouriteStudentsViewSettingRequest = {
  user?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  data?: Record<string, any>
}

export type PatchedFileForStudentRequest = {
  /** @maxLength 100 */
  name?: string
  author?: string
  /** @format binary */
  file?: File
  /**
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  link?: string
  display_in?: DisplayInEnum[]
}

export type PatchedFirstContactRequest = {
  symptoms?: SymptomsEnum[]
  /** @minLength 1 */
  details?: string
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  temperature?: string | null
}

export type PatchedFoodGiveRequest = {
  was_given?: boolean
  /** @format uuid */
  health_check?: string
}

export type PatchedHealthCheckCommentRequest = {
  /** @format uuid */
  health_check?: string
  /** @minLength 1 */
  content?: string
}

export type PatchedHospitalRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
}

export type PatchedHouseRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  tutors?: string[]
  generate_reports?: boolean
  generate_reports_at_study_time?: boolean
  generate_reports_at_the_weekend?: boolean
  generate_night_attendance_reports?: boolean
  is_senior_campus?: boolean
  is_junior_campus?: boolean
}

export type PatchedHouseRoomRequest = {
  /**
   * * `S` - Small
   * * `L` - Large
   */
  size?: SizeEnum
  /**
   * @min 0
   * @max 32767
   */
  capacity?: CapacityEnum
  /**
   * @min 0
   * @max 32767
   */
  people_per_bathroom?: number
}

export type PatchedIdpSectionEnrollRequest = {
  section?: number | null
  /** @format uuid */
  course?: string | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_lessons?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedImageRequest = {
  /** @minLength 1 */
  ppoi?: string
  catalogs?: number[]
  /**
   * @minLength 1
   * @maxLength 7
   */
  dominant_color?: string
  /** @maxLength 128 */
  title?: string
  /** @maxLength 128 */
  alt?: string
  /** @format binary */
  file?: File
}

export type PatchedLeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination?: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * * `in` - Check-in
   * * `out` - Check-out
   */
  check_type?: LeaveCheckTypeEnum
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone?: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date?: string
  /** @format uuid */
  leave_request?: string
}

export type PatchedLeaveRequestRequest = {
  check_out?: RelatedLeaveCheckRequest
  check_in?: RelatedLeaveCheckRequest
  /** @format uuid */
  student?: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type?: RequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  student_message?: string
  has_clashes?: boolean
  clashes_resolved?: boolean
}

export type PatchedMarkHomeworkAsSentStudentRequest = {
  is_marked_as_sent_by_student?: boolean
}

export type PatchedMedicineGivenRequest = {
  /** @format uuid */
  health_check?: string
  /** @format uuid */
  scheduled_medicine?: string | null
  /** @minLength 1 */
  optional_medicine?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedMeritItemRequest = {
  /**
   * * `respect` - Respect
   * * `community` - Community
   * * `safety` - Safety
   * * `health` - Health
   */
  type?: MeritItemTypeEnum
  /** @minLength 1 */
  name?: string
  /**
   * * `attitude` - Attitude
   * * `kindness` - Kindness
   * * `service` - Service
   * * `language` - Language
   * * `electronics` - Electronics
   * * `dress` - Dress
   * * `late` - Late
   * * `night` - Night
   * * `room` - Room
   * * `absence` - Absence
   * * `leave_request` - Leave Request
   * * `ausgang` - Ausgang
   * * `deception` - Deception
   * * `transport` - Transport
   * * `private_home` - Private Home
   * * `deliveries` - Deliveries
   * * `safety` - Safety
   * * `facilities` - Facilities
   * * `aggression` - Aggression
   * * `recklessness` - Recklessness
   * * `covid` - Covid
   * * `smoking` - Smoking
   * * `alcohol` - Alcohol
   * * `drugs` - Drugs
   * * `possession` - Possession
   * * `reputation` - Reputation
   * * `refusal` - Refusal
   * * `abusive_behaviour` - Abusive Behaviour
   */
  subtype?: SubtypeEnum
  /**
   * * `merit` - Merit
   * * `verbal_commendation` - Verbal commendation
   * * `written_commendation` - Written commendation
   * * `certificate` - Certificate
   * * `verbal_warning` - Verbal warning
   * * `written_warning` - Written warning
   * * `ultimatum` - Ultimatum
   * * `suspension` - Suspension
   * * `exclusion` - Exclusion
   */
  sanction?: NextLastSanctionEnum
  /** @format double */
  merit_factor?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  immediate_downgrade?: StudentCategoryEnum | NullEnum | null
  /**
   * * `week` - Weekly
   * * `quarter` - Quarterly
   * * `semester` - Semesterly
   * * `year` - Yearly
   */
  escalation_expire_after?: EscalationExpireAfterEnum
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type?: MeritTypeEnum
  profiles_that_can_review?: string[]
  profiles_that_can_add?: string[]
  allow_to_select?: boolean
  all_profiles_can_add?: boolean
  is_automatic_approve?: boolean
  next_merit_item?: number | null
  next_last_sanction?: NextLastSanctionEnum | BlankEnum
  has_grade_based_escalation_factor?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  pri_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  msl_escalation_factor?: number | null
  /**
   * @min 0
   * @max 2147483647
   */
  hsl_escalation_factor?: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedMeritRequest = {
  /** @format uuid */
  student?: string
  /** @format uuid */
  course?: string | null
  /** @minLength 1 */
  note?: string
  merit_item?: number
  /**
   * * `merit` - Merit
   * * `demerit` - Demerit
   */
  merit_type?: MeritTypeEnum
  /** @format double */
  alcohol_level?: number | null
  /** @format double */
  value?: number
  house_room?: number | null
}

export type PatchedMeritRewardParticipantUpdateRequest = {
  /**
   * * `rejected` - rejected
   * * `accepted` - accepted
   */
  status?: MeritRewardParticipantUpdateStatusEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedMissionControlRequest = {
  degree_aim_1_id?: number | null
  degree_destination_1?: NationalityEnum | BlankEnum
  degree_aim_2_id?: number | null
  degree_destination_2?: NationalityEnum | BlankEnum
  interests?: number[]
  skills?: number[]
  competencies?: number[]
  /** @format date */
  estimated_completion?: string | null
  recommendations?: string
  /** @format uuid */
  student?: string
}

export type PatchedNightAttendanceCommentRequest = {
  author?: string
  /** @minLength 1 */
  content?: string
  mark_disturbance?: boolean
}

export type PatchedNightAttendanceReportRequest = {
  night_guard?: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  for_gender?: ForGenderEnum
  has_disruption?: boolean
}

export type PatchedNightAttendanceRequest = {
  mark?: NightAttendanceMarkEnum | BlankEnum
  /** @format uuid */
  student?: string
  /** @minLength 1 */
  disturbance_note?: string
  electronics_involved?: boolean
  is_at_home?: boolean
  is_at_campus_absence?: boolean
  /** @format time */
  bedtime?: string | null
  /** @minLength 1 */
  boarding_status?: string
}

export type PatchedNotificationSettingsRequest = {
  accept_campus_notifications?: boolean
  accept_education_notifications?: boolean
  accept_reminders_notifications?: boolean
  accept_dashboard_notifications?: boolean
}

export type PatchedParentProfileRequest = {
  favourites?: string[]
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type PatchedParentRequest = {
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /**
   * @format email
   * @maxLength 254
   */
  secondary_email?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  phone_number?: string
  /**
   * * `miss` - Miss
   * * `ms` - Ms.
   * * `mr` - Mr.
   * * `mrs` - Mrs.
   * * `title` - Title
   * * `other` - Other
   */
  salutation?: SalutationEnum
  salutation_other?: string
  occupation?: string
  /**
   * * `parent` - Parent
   * * `legal_guardian` - Legal Guardian
   * * `in_legal_guardian_absence` - In Legal Guardian Absence
   */
  contact_type?: ContactTypeEnum
  contact_leave_request?: boolean
  is_allowed_to_visit?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  contacts?: ParentStudentContactRequest[]
}

export type PatchedProgrammeAttendanceReportRequest = {
  programme?: number
  /** @maxLength 64 */
  location?: string
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  assigned_to?: string
}

export type PatchedProgrammeStudentAttendanceRequest = {
  report?: number
  /** @format date-time */
  marked_at?: string | null
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  is_marked?: boolean
  disturbance_merit_note?: string
}

/** Adds nested create feature */
export type PatchedProgressCommentDeadlineRequest = {
  /** @format date-time */
  comment_deadline?: string
}

/** Adds nested create feature */
export type PatchedProgressCommentDraftRequest = {
  draft?: Record<string, any>
  /** @format date-time */
  draft_created_at?: string | null
  /** @format date-time */
  draft_modified_at?: string | null
  draft_added_by?: string
  draft_last_modified_by?: string
}

/** Adds nested create feature */
export type PatchedProgressCommentEvaluateRequest = {
  added_by?: string
  last_modified_by?: string
  /**
   * @min 1
   * @max 5
   */
  strategy_efficiency?: number | null
  strategy_efficiency_comment?: string
}

/** Adds nested create feature */
export type PatchedProgressCommentRequest = {
  added_by?: string
  last_modified_by?: string
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
  /** @format date-time */
  comment_deadline?: string
  /** @format uuid */
  student?: string
  /** @format uuid */
  course?: string
  semester?: number
  /** @minLength 1 */
  semester_grade?: string
  quarter?: number
  /** @minLength 1 */
  quarter_grade?: string
  /** @minLength 1 */
  potential_grade?: string
  /**
   * * `pending` - Pending
   * * `much_above` - Much Above
   * * `above` - Above
   * * `below` - Below
   * * `much_below` - Much Below
   */
  target_grade_status?: TargetGradeStatusEnum
  /**
   * @min 1
   * @max 5
   */
  strategy_efficiency?: number | null
  strategy_efficiency_comment?: string
  achievement_and_effort?: AchievementAndEffortProgressCommentRequest
  behaviour_and_attitude?: BehaviourAndAttitudeProgressCommentRequest
  student_target?: StudentTargetProgressCommentRequest
  intervention?: InterventionProgressCommentRequest
}

export type PatchedRoomBookingUpdateRequest = {
  /** @format date */
  start_date?: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  last_modified_by?: string
}

export type PatchedRoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  is_private?: boolean
  msl_room?: boolean
  pri_room?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  group?: RoomGroupEnum | NullEnum | null
  schedule_automatically?: boolean
  available_for_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
  department?: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedSchoolBreakRequest = {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name?: string
  school_year?: number
  /** @format date */
  start_date?: string
  /** @format date */
  end_date?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedSchoolProgrammeRequest = {
  is_compulsory?: boolean
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  type?: SchoolProgrammeTypeEnum
  /**
   * @minLength 1
   * @maxLength 128
   */
  title?: string
  description?: string
  parental_approval_required?: boolean
  message_to_parents?: string
  enrol_all_students?: boolean
  categories?: StudentCategoryEnum[]
  houses?: number[]
  grade_levels?: number[]
  ages?: number[]
  added_by?: string
  last_modified_by?: string
  leaders?: string[]
  members?: string[]
  /** @format date-time */
  start_date?: string
  /** @format date-time */
  end_date?: string
  /** @format date-time */
  enrolment_deadline?: string | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost?: string | null
  /**
   * @min 0
   * @max 32767
   */
  students_limit?: number
  risk_assessment_file?: number
  other_files?: number[]
  enrolments?: ProgrammeEnrolmentRequest[]
  rejection_reason?: string
}

/** Adds nested create feature */
export type PatchedSectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  is_mentoring?: boolean | null
  must_be_consecutive?: boolean
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  quarter?: number
  /** @format uuid */
  course?: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher?: string
  teachers?: string[]
}

export type PatchedSickReportAbsenceDatesRequest = {
  created_by?: string
  last_modified_by?: string
  /** @minLength 1 */
  sick_report?: string
  /** @format date-time */
  absent_from?: string | null
  /** @format date-time */
  absent_to?: string | null
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type?: ClassTypeEnum
  omit_campus_checks?: boolean
}

export type PatchedSickReportCommentRequest = {
  author?: string
  /** @minLength 1 */
  content?: string
}

export type PatchedSickReportRequest = {
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  /** @format uuid */
  student?: string
  generate_absences_on_create?: boolean
  close_reason?: CloseReasonEnum | BlankEnum
  /** @format date-time */
  closed_at?: string | null
  created_by?: string
  last_modified_by?: string
}

export type PatchedStudentAccountOverdueRequest = {
  account_overdue?: AccountOverdueEnum
}

export type PatchedStudentAchievementRequest = {
  achievement?: number
  /** @format date */
  date?: string
}

export type PatchedStudentAimForCourseRequest = {
  /** @format uuid */
  student?: string
  /** @format uuid */
  course?: string
  aim?: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentAllergyRequest = {
  /** @format uuid */
  student?: string
  /** @minLength 1 */
  allergen_kind?: string
  allergen_subtypes?: string[]
  reactions?: StudentAllergyReactionRequest[]
  medicines?: string[]
  is_emergency_medicine?: boolean
  medicine_location?: MedicineLocationEnum | BlankEnum
  immediate_response?: ImmediateResponseEnum | BlankEnum
}

export type PatchedStudentBloodTypeRequest = {
  /**
   * * `0-` - 0-
   * * `0+` - 0+
   * * `a-` - A-
   * * `a+` - A+
   * * `b-` - B-
   * * `b+` - B+
   * * `ab-` - AB-
   * * `ab+` - AB+
   * * `unknown` - Unknown
   */
  blood_type?: BloodTypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentCampusAbsenceRequest = {
  created_by?: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype?: CampusAbsenceSubTypeEnum
  custom_name?: string
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from?: string
  /** @format date-time */
  date_to?: string
  campus_absence_students?: StudentCampusAbsenceStudentRequest[]
}

export type PatchedStudentCategoryItemRequest = {
  /** @format uuid */
  student?: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  assigned_category?: StudentCategoryEnum
  is_completed?: boolean
}

export type PatchedStudentChronicConditionRequest = {
  /** @format uuid */
  student?: string
  /** @minLength 1 */
  condition_type?: string
  condition_subtype?: string[]
  other_condition_type?: string
  impact?: ImpactEnum[]
  watch_out_for?: WatchOutForEnum[]
  emergency_medicines?: string[]
  emergency_medicine_applications?: EmergencyMedicineApplicationsEnum[]
  emergency_medicine_location?: EmergencyMedicineLocationEnum[]
  iep_contact?: IepContactEnum[]
  immediate_response?: ImmediateResponseEnum | BlankEnum
  comment?: string
  has_medicine_plan?: boolean
  has_emergency_medicine?: boolean
  has_iep?: boolean
}

export type PatchedStudentContentRequest =
  | PatchedAppMysteryLinkRequest
  | PatchedAppStudentAnnouncementRequest
  | PatchedAppStudentPicRequest

export type PatchedStudentCourseRequirementRequest = {
  /** @minLength 1 */
  min_grade?: string
  /** @minLength 1 */
  current_grade?: string
  /** @minLength 1 */
  potential_grade?: string
  /**
   * @min 0
   * @max 100
   */
  min_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  current_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  potential_percents?: number | null
  aim?: number | null
  year?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentDetailRequest = {
  /** @maxLength 256 */
  nickname?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  first_name?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  last_name?: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
  current_grade_level?: number
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
  nationality?: NationalityEnum | BlankEnum
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth?: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  gender?: GenderEnum
  is_at_home?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  parent_1?: ParentRequest | null
  parent_1_has_access?: boolean
  parent_2?: ParentRequest | null
  parent_2_has_access?: boolean
  legal_guardian?: ParentRequest | null
  legal_guardian_has_access?: boolean
  contact_in_legal_guardian_absence?: ParentRequest | null
  contact_in_legal_guardian_absence_has_access?: boolean
  /**
   * * `first_parent` - First Parent
   * * `second_parent` - Second Parent
   * * `both_parents` - Both Parents
   * * `relative` - Relative
   * * `other` - Other
   */
  legal_guardian_relation?: LegalGuardianRelationEnum
  external_id_in_invoice_system?: string
  account_overdue?: AccountOverdueEnum
}

export type PatchedStudentExternalExamRequest = {
  /**
   * * `ACT` - ACT
   * * `A_LEVEL` - A Level (GCE), iGCSE
   * * `AP` - Advanced Placement
   * * `CAMBRIDGE` - CAMBRIDGE
   * * `GCSE` - GCSE
   * * `GMAT` - GMAT
   * * `IAL` - International A Level
   * * `IB` - International Baccalaureate
   * * `IELTS` - IELTS
   * * `MAP` - MAP
   * * `SAT_ENG` - SAT ENG
   * * `SAT_MAT` - SAT MAT
   * * `WIDA` - WIDA
   */
  type?: ExternalExamTypeEnum
  /** @format date */
  date?: string
  /** @format double */
  min_score?: number | null
  /** @format double */
  score?: number | null
  /** @maxLength 16 */
  subtype?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentIdpPlanRequest = {
  baskets?: BasketRequest[]
  /** @format uuid */
  student?: string
  note?: string
  grade_level?: number
  /** @minLength 1 */
  aim?: string
  year?: number
  draft_payload?: Record<string, any>
  exams?: ExamScoreRequest[]
  warnings?: ParentWarningRequest[]
  /** @default true */
  commit?: boolean
  /** @format double */
  credit_count?: number | null
  courses_mission_control_departments?: CourseMissionControlDepartmentRequest[]
  parent_message?: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedStudentInternshipsUpdateRequest = {
  internships?: StudentInternshipUpdateRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedStudentLeaveRequestRequest = {
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_out?: StudentLeaveCheckRequest
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_in?: StudentLeaveCheckRequest
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  student_message?: string
}

export type PatchedStudentProfileRequest = {
  /** @format double */
  merit_points?: number
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  favourites?: string[]
}

export type PatchedStudentTAEExperienceRequest = {
  experience?: number
  /** @format date */
  date?: string
}

export type PatchedStudentTAEProjectRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
  /** @format date */
  expected_completion_date?: string
  /** @format date */
  completion_date?: string | null
}

export type PatchedStudentTranscriptRequest = {
  grade_level?: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  semester?: SemesterEnum
  /** @maxLength 256 */
  school?: string
  status?: StudentTranscriptStatusEnum | BlankEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedTaskRequest = {
  /** @minLength 1 */
  title?: string
  description?: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  /** @format date-time */
  deadline?: string | null
  created_by?: string
  /** @format uuid */
  assignee?: string | null
  /** @format uuid */
  team?: string | null
  subtasks?: SubtaskRequest[]
  done_comment?: string
  recurring?: RecurringRequest | null
  is_cloned?: boolean
  /** @minLength 1 */
  cloned_from?: string | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedTeamRequest = {
  /** @minLength 1 */
  name?: string
  house?: number | null
  members?: TeamMemberRequest[]
  include_in_boarding_rota?: boolean
}

export type PatchedTemperatureCheckRequest = {
  /** @format uuid */
  health_check?: string
  /** @format double */
  temperature?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type PatchedTimetableMentoringRequest = {
  ignored_students?: string[]
  timetable?: number
  grades_to_assign?: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  students_in_section?: number
  copy_from_previous_quarter?: boolean
  teachers_per_grade?: TeachersPerGradeRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedTimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

export type PatchedUserTaskTitleRequest = {
  /** @minLength 1 */
  title?: string
  added_by?: string
  last_modified_by?: string
}

export type PatchedWeeklyCommentRequest = {
  /**
   * @minLength 1
   * @maxLength 2000
   */
  content?: string
  /** @format uuid */
  course?: string
  /** @format uuid */
  student?: string
}

export type PostsInfo = {
  ever_created_post: boolean
}

export type PotentialOptimization = {
  id: number
  activity: string
  conflict_students: string[]
  conflict_free_students: string[]
  no_teacher?: boolean
  private_activities_collisions: string
  /**
   * @min 0
   * @max 2147483647
   */
  cost: number
  /**
   * @min 0
   * @max 2147483647
   */
  profit: number
  /**
   * @min 0
   * @max 2147483647
   */
  balance: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  day_display: string
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  hour_display: string
}

/**
 * * `academic_individual` - Academic Individual
 * * `sports` - Sports
 * * `co_curricular` - Co-Curricular
 * * `graduation_fee` - Graduation Fee
 */
export type ProductTypeEnum =
  | 'academic_individual'
  | 'sports'
  | 'co_curricular'
  | 'graduation_fee'

/**
 * * `academic_individual` - Academic Individual
 * * `sports` - Sports
 * * `co_curricular` - Co-Curricular
 * * `graduation_fee` - Graduation Fee
 */
export type ProductTypesEnum =
  | 'academic_individual'
  | 'sports'
  | 'co_curricular'
  | 'graduation_fee'

export type ProgrammeAttendanceReport = {
  id: number
  /** @format date-time */
  created: string
  programme: number
  /** @maxLength 64 */
  location?: string
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  assigned_to?: string
  status_display: string
  previous_obj_id: string
  next_obj_id: string
  is_performable_by_requested_user: string
}

export type ProgrammeAttendanceReportCreate = {
  programme: number
  assigned_to?: string
}

export type ProgrammeAttendanceReportCreateRequest = {
  programme: number
  assigned_to?: string
}

export type ProgrammeAttendanceReportRecovery = {
  id: number
  /** @format date-time */
  created: string
  programme: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
  status_display: string
}

export type ProgrammeAttendanceReportRecoveryRequest = {
  programme: number
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
}

export type ProgrammeAttendanceReportUpdate = {
  /** @maxLength 64 */
  location?: string
}

export type ProgrammeAttendanceReportUpdateRequest = {
  /** @maxLength 64 */
  location?: string
}

export type ProgrammeClashes = {
  id: number
  /** @maxLength 128 */
  title: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @default "programmes" */
  clash_type: string
}

export type ProgrammeEnrolment = {
  id: number
  status: EnrolmentStatusEnum
  rejection_comment: string
  from_custom_list?: boolean
  /** @format uuid */
  student: string
  student_data: SimpleEnrolledStudent
  student_house?: number | null
  student_grade_level: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  student_category?: StudentCategoryEnum | NullEnum | null
  student_category_label: string
}

export type ProgrammeEnrolmentRequest = {
  from_custom_list?: boolean
  /** @format uuid */
  student: string
  student_house?: number | null
  student_grade_level: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  student_category?: StudentCategoryEnum | NullEnum | null
}

export type ProgrammeStudentAttendance = {
  id: number
  /** @format date-time */
  created: string
  report: number
  student_str: string
  /** @format date-time */
  marked_at?: string | null
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  is_marked?: boolean
  disturbance_merit_note?: string
  is_performable_by_requested_user: string
}

export type ProgrammeStudentAttendanceUpdate = {
  id: number
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  disturbance_merit_note?: string
}

export type ProgrammeStudentAttendanceUpdateRequest = {
  is_present?: boolean
  is_late?: boolean
  is_absent_unexcused?: boolean
  /** is exempt */
  is_absent_excused?: boolean
  is_sick?: boolean
  is_disturbance?: boolean
  is_marked_automatically?: boolean
  disturbance_merit_note?: string
}

/** Adds nested create feature */
export type ProgressComment = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  added_by?: string
  last_modified_by?: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  /** @format date-time */
  comment_deadline: string
  /** @format uuid */
  student: string
  student_name: string
  /** @format uuid */
  course: string
  course_name: string
  course_enrollment_id: number
  /** @format uuid */
  lead_teacher_id: string | null
  lead_teacher_str: string
  semester: number
  semester_grade: string
  quarter: number
  quarter_grade: string
  potential_grade: string
  /**
   * * `pending` - Pending
   * * `much_above` - Much Above
   * * `above` - Above
   * * `below` - Below
   * * `much_below` - Much Below
   */
  target_grade_status: TargetGradeStatusEnum
  fixed_target_grade_status: string
  /**
   * @min 1
   * @max 5
   */
  strategy_efficiency?: number | null
  strategy_efficiency_comment?: string
  achievement_and_effort: AchievementAndEffortProgressComment
  behaviour_and_attitude: BehaviourAndAttitudeProgressComment
  student_target: StudentTargetProgressComment
  intervention: InterventionProgressComment
  draft: Record<string, any>
  /** @format date-time */
  draft_created_at: string | null
  /** @format date-time */
  draft_modified_at: string | null
}

/** Adds nested create feature */
export type ProgressCommentDeadline = {
  /** @format uuid */
  id: string
  /** @format date-time */
  comment_deadline: string
}

/** Adds nested create feature */
export type ProgressCommentDraft = {
  /** @format uuid */
  id: string
  draft: Record<string, any>
  /** @format date-time */
  draft_created_at?: string | null
  /** @format date-time */
  draft_modified_at?: string | null
  draft_added_by?: string
  draft_last_modified_by?: string
}

/** Adds nested create feature */
export type ProgressCommentEvaluate = {
  /** @format uuid */
  id: string
  added_by?: string
  last_modified_by?: string
  /**
   * @min 1
   * @max 5
   */
  strategy_efficiency?: number | null
  strategy_efficiency_comment?: string
}

/** Adds nested create feature */
export type ProgressCommentRequest = {
  added_by?: string
  last_modified_by?: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  /** @format date-time */
  comment_deadline: string
  /** @format uuid */
  student: string
  /** @format uuid */
  course: string
  semester: number
  /** @minLength 1 */
  semester_grade: string
  quarter: number
  /** @minLength 1 */
  quarter_grade: string
  /** @minLength 1 */
  potential_grade: string
  /**
   * * `pending` - Pending
   * * `much_above` - Much Above
   * * `above` - Above
   * * `below` - Below
   * * `much_below` - Much Below
   */
  target_grade_status: TargetGradeStatusEnum
  /**
   * @min 1
   * @max 5
   */
  strategy_efficiency?: number | null
  strategy_efficiency_comment?: string
  achievement_and_effort: AchievementAndEffortProgressCommentRequest
  behaviour_and_attitude: BehaviourAndAttitudeProgressCommentRequest
  student_target: StudentTargetProgressCommentRequest
  intervention: InterventionProgressCommentRequest
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type PropertyManagerProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type PropertyManagerProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicBoard = {
  id: string
  widgets: PublicWidget[]
  /** @format date-time */
  display_to?: string | null
  /**
   * * `square` - Square
   * * `framed` - Framed
   * * `fullscreen` - Fullscreen
   */
  layout?: LayoutEnum
  label_color: string | null
  /**
   * @min -2147483648
   * @max 2147483647
   */
  display_time?: number
}

export type PublicBoardingAnnouncementWidget = {
  id: string
  /** @maxLength 90 */
  text: string
  /** @default "#FD8F5E" */
  bg_color: string
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicCelebrationWidget = {
  id: string
  /** @default "#FF607F" */
  bg_color: string
  students: string[]
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicClockWidget = {
  id: string
  /** @default "#000000" */
  bg_color: string
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicCongratulationsWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  grades: PublicGrade[]
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicDayMenu = {
  id: number
  /** @format date */
  date: string
  is_skipped: boolean
  lunch_starter: string
  lunch_soup: string
  lunch_main_course: string
  lunch_dessert: string
  lunch_starter_vege: string
  lunch_soup_vege: string
  lunch_main_course_vege: string
  lunch_dessert_vege: string
  dinner_starter: string
  dinner_soup: string
  dinner_main_course: string
  dinner_dessert: string
  dinner_starter_vege: string
  dinner_soup_vege: string
  dinner_main_course_vege: string
  dinner_dessert_vege: string
  breakfast_special: string
  breakfast_special_vege: string
}

export type PublicEventAgendaWidget = {
  id: string
  /** @default "#5965EF" */
  bg_color: string
  header: string
  /** @format date */
  date_from: string
  /** @format date */
  date_to?: string | null
  /** @format time */
  time_from?: string | null
  /** @format time */
  time_to?: string | null
  agenda_items: AgendaItem[]
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicEventInfoWidget = {
  id: string
  bg_color: string
  /** @maxLength 90 */
  header: string
  /** @maxLength 400 */
  message?: string
  /** @maxLength 64 */
  classroom_shortcut?: string
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  link: PublicLinkWithQRCode
  /** @maxLength 64 */
  qr_code_text?: string
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicEventInfoWithPhotoWidget = {
  id: string
  bg_color: string
  /** @maxLength 100 */
  header: string
  include_message?: boolean
  /** @maxLength 400 */
  message?: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  photo: PublicImage
  crop_my_rosenberg_idp_photo_separately?: boolean
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  photo_in_my_rosenberg_idp: PublicImage
  /** @maxLength 64 */
  location?: string
  /** @maxLength 64 */
  course_name?: string
  show_qr_code?: boolean
  link: PublicLinkWithQRCode
  /** @maxLength 64 */
  qr_code_text?: string
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicGrade = {
  course: string
  grade: string
  student: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicImage = {
  id: string
  images: string
  alt: string
  title: string
  /** @format uri */
  file?: string
  height: number | null
  width: number | null
  ppoi_tuple: string
  /** @maxLength 7 */
  dominant_color?: string
  has_transparency?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicImageRequest = {
  /** @format binary */
  file?: File
  /**
   * @minLength 1
   * @maxLength 7
   */
  dominant_color?: string
  has_transparency?: boolean
}

export type PublicJournalItem = {
  id: string
  article_url: PublicLinkWithQRCode
  title: string
  lead: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  photo: PublicImage
}

export type PublicJournalWidget = {
  id: string
  /** @default "#EBCFCF" */
  bg_color: string
  journal_items: PublicJournalItem[]
  /** via annotate (feed) */
  published_at: string
  /** deprecated field */
  article_url: PublicLinkWithQRCode | null
  /** deprecated field */
  title: string | null
  /** deprecated field */
  lead: string | null
  /** deprecated field */
  photo: PublicImage | null
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
}

export type PublicLeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone: string
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
}

export type PublicLeaveRequest = {
  /** @format uuid */
  id: string
  check_out: PublicLeaveCheck
  check_in: PublicLeaveCheck
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
  student_first_name: string
}

export type PublicLeaveRequestRequest = {
  check_out: PublicLeaveCheckRequest
  check_in: PublicLeaveCheckRequest
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type?: LeaveRequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  status?: LeaveRequestStatusEnum
}

export type PublicLinkWithQRCode = {
  /**
   * @format uri
   * @maxLength 200
   */
  url: string
  /** @format uri */
  qr_code?: string
}

export type PublicMenuWidget = {
  id: string
  /** @default "#00AC69" */
  bg_color: string
  menu: PublicDayMenu
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicMessage = {
  id: number
  content: string
  /** @format date */
  display_to: string
  /**
   * * `alert` - Alert
   * * `notice` - Notice
   */
  type: MessageTypeEnum
}

export type PublicMysteryLinkWidget = {
  id: string
  /** @default "#9561E8" */
  bg_color: string
  links: PublicLinkWithQRCode[]
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicNextLesson = {
  /** @maxLength 36 */
  course: string
  room: string
}

export type PublicPosterWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicRecentJournalArticle = {
  title: string
  lead: string
}

export type PublicStudentAnnouncementWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  announcements: StudentAnnouncement[]
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicStudentArt = {
  id: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  /** @maxLength 255 */
  name: string
  /** @maxLength 255 */
  author: string
}

export type PublicStudentArtGalleryWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  arts: PublicStudentArt[]
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicStudentsPicWidget = {
  id: string
  /** @default "#FFFFFF" */
  bg_color: string
  pics: StudentPic[]
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicUpdateStatus = {
  /** @format date-time */
  boards?: string | null
  /** @format date-time */
  alerts?: string | null
  /** @format date-time */
  notices?: string | null
  /** @format date-time */
  frontend?: string | null
}

export type PublicVariantManualCongratulations = {
  id: number
  /**
   * * `wreath` - Wreath
   * * `cap` - Cap
   */
  variant: VariantEnum
  images: PublicImage[]
  student: string
  /** @maxLength 400 */
  message: string
  /** @maxLength 128 */
  course_name?: string
}

export type PublicVariantManualCongratulationsWidget = {
  id: string
  /** @default "#808080" */
  bg_color: string
  items: PublicVariantManualCongratulations[]
  /** via annotate (feed) */
  published_at: string
  /**
   * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
   * * `CelebrationWidget` - CelebrationWidget
   * * `EventAgendaWidget` - EventAgendaWidget
   * * `CongratulationsWidget` - CongratulationsWidget
   * * `PosterWidget` - PosterWidget
   * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
   * * `EventInfoWidget` - EventInfoWidget
   * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
   * * `JournalWidget` - JournalWidget
   * * `MysteryLinkWidget` - MysteryLinkWidget
   * * `MenuWidget` - MenuWidget
   * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
   * * `StudentsPicWidget` - StudentsPicWidget
   * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
   * * `ClockWidget` - ClockWidget
   */
  resourcetype: WidgetResourcetypeEnum
}

export type PublicWidget =
  | PublicBoardingAnnouncementWidget
  | PublicCelebrationWidget
  | PublicEventAgendaWidget
  | PublicCongratulationsWidget
  | PublicPosterWidget
  | PublicStudentArtGalleryWidget
  | PublicEventInfoWidget
  | PublicEventInfoWithPhotoWidget
  | PublicJournalWidget
  | PublicMysteryLinkWidget
  | PublicMenuWidget
  | PublicStudentAnnouncementWidget
  | PublicStudentsPicWidget
  | PublicVariantManualCongratulationsWidget
  | PublicClockWidget

/**
 * * `reserved` - Reserved
 * * `social_room` - Social room
 * * `duty_room` - Duty room
 * * `storage` - Storage
 * * `artisan_private_room` - Artisan private room
 */
export type PurposeEnum =
  | 'reserved'
  | 'social_room'
  | 'duty_room'
  | 'storage'
  | 'artisan_private_room'

/**
 * * `quarter` - Quarter
 * * `week` - Week
 * * `year` - Year
 */
export type QuantityUnitEnum = 'quarter' | 'week' | 'year'

export type Quarter = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester: number
  short_name: QuarterShortNameEnum
  school_year: number
}

export type QuarterInCourses = {
  courses: string[]
  /**
   * * `Q1` - Q1
   * * `Q2` - Q2
   * * `Q3` - Q3
   * * `Q4` - Q4
   */
  quarter: QuarterShortNameEnum
}

export type QuarterInCoursesRequest = {
  courses: string[]
  /**
   * * `Q1` - Q1
   * * `Q2` - Q2
   * * `Q3` - Q3
   * * `Q4` - Q4
   */
  quarter: QuarterShortNameEnum
}

/**
 * * `Q1` - Q1
 * * `Q2` - Q2
 * * `Q3` - Q3
 * * `Q4` - Q4
 */
export type QuarterShortNameEnum = 'Q1' | 'Q2' | 'Q3' | 'Q4'

export type RecoveryAvailableFilters = {
  school_programme__type: AvailableStringFilter
  student: AvailableStringFilter
  assigned_to: AvailableStringFilter
}

/**
 * * `AbsenceRecovery` - AbsenceRecovery
 * * `LunchtimeRecovery` - LunchtimeRecovery
 */
export type RecoveryResourcetypeEnum = 'AbsenceRecovery' | 'LunchtimeRecovery'

export type Recurring = {
  pk: string
  /**
   * * `daily` - Daily
   * * `weekly` - Weekly
   * * `monthly` - Monthly
   * * `every_weekday` - Every weekday
   * * `every_weekend` - Every weekend
   */
  type: RecurringTypeEnum
  /** @format date */
  end_date?: string | null
}

export type RecurringRequest = {
  /**
   * * `daily` - Daily
   * * `weekly` - Weekly
   * * `monthly` - Monthly
   * * `every_weekday` - Every weekday
   * * `every_weekend` - Every weekend
   */
  type: RecurringTypeEnum
  /** @format date */
  end_date?: string | null
  update_type?: UpdateTypeEnum
}

/**
 * * `daily` - Daily
 * * `weekly` - Weekly
 * * `monthly` - Monthly
 * * `every_weekday` - Every weekday
 * * `every_weekend` - Every weekend
 */
export type RecurringTypeEnum =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'every_weekday'
  | 'every_weekend'

export type RegisterDevice = {
  /** @maxLength 256 */
  device_id: string
  user?: string
  /** @maxLength 12 */
  language?: string
  is_active?: boolean
}

export type RegisterDeviceRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  device_id: string
  user?: string
  /**
   * @minLength 1
   * @maxLength 12
   */
  language?: string
  is_active?: boolean
}

export type RejectEnrolment = {
  rejection_comment: string
}

export type RejectEnrolmentRequest = {
  /** @minLength 1 */
  rejection_comment: string
}

export type RejectIdpPlan = {
  note: string
  rejected_by: string
  rejected_at: string
}

export type RejectIdpPlanRequest = {
  /** @minLength 1 */
  note: string
}

export type RelatedLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  check_type: LeaveCheckTypeEnum
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format date-time */
  original_date?: string | null
}

export type RelatedLeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format date-time */
  original_date?: string | null
}

/**
 * * `daily` - Daily
 * * `every_x_days` - Every "X" days
 * * `monthly` - Monthly
 * * `weekly` - Weekly
 */
export type RepeatFormEnum = 'daily' | 'every_x_days' | 'monthly' | 'weekly'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ReportCard = {
  id: number
  /** @format uuid */
  student: string
  /** @format uri */
  file: string
  quarter: number
  /** @format date-time */
  created: string
  /** @format date-time */
  sent_at: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type ReportCardQuarter = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: QuarterShortNameEnum
  school_year: SchoolYear
}

/**
 * * `manual` - Manual
 * * `from idp` - From IDP
 * * `by_student` - Requested by student
 */
export type RequestTypeEnum = 'manual' | 'from idp' | 'by_student'

export type RequestedCourse = {
  id: number
  status_display: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * * `new` - New
   * * `rejected` - Rejected
   * * `course_created` - Course created
   */
  status?: RequestedCourseStatusEnum
  /** @maxLength 256 */
  name: string
  note: string
  /** @format uuid */
  course: string | null
}

export type RequestedCourseRequest = {
  /**
   * * `new` - New
   * * `rejected` - Rejected
   * * `course_created` - Course created
   */
  status?: RequestedCourseStatusEnum
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  /** @minLength 1 */
  note: string
}

/**
 * * `new` - New
 * * `rejected` - Rejected
 * * `course_created` - Course created
 */
export type RequestedCourseStatusEnum = 'new' | 'rejected' | 'course_created'

/** Serializer for pin code's reset's (first step). */
export type ResetMobilePinCode = {
  device_id: string
}

/** Serializer for pin code's reset's confirmation (second step). */
export type ResetMobilePinCodeConfirm = {
  device_id: string
  hashed_token_and_device_id: string
}

/** Serializer for pin code's reset's confirmation (second step). */
export type ResetMobilePinCodeConfirmRequest = {
  /** @minLength 1 */
  device_id: string
  /** @minLength 1 */
  hashed_token_and_device_id: string
}

/** Serializer for pin code's reset's (first step). */
export type ResetMobilePinCodeRequest = {
  /** @minLength 1 */
  device_id: string
}

export type ResourcetypeEnum =
  | 'BoardingAnnouncementWidget'
  | 'CelebrationWidget'
  | 'EventAgendaWidget'
  | 'CongratulationsWidget'
  | 'PosterWidget'
  | 'StudentArtGalleryWidget'
  | 'EventInfoWidget'
  | 'EventInfoWithPhotoWidget'
  | 'JournalWidget'
  | 'MysteryLinkWidget'
  | 'MenuWidget'
  | 'StudentsAnnouncementWidget'
  | 'StudentsPicWidget'
  | 'VariantManualCongratulationsWidget'
  | 'ClockWidget'

export type ReviewLeaveRequest = {
  /**
   * * `approve` - Approve
   * * `reject` - Reject
   * * `requested` - Requested
   */
  action: LeaveRequestReviewActionEnum
  comment?: string
}

export type ReviewLeaveRequestRequest = {
  /**
   * * `approve` - Approve
   * * `reject` - Reject
   * * `requested` - Requested
   */
  action: LeaveRequestReviewActionEnum
  comment?: string
}

export type RewardType = {
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
  cost: number
  is_available: boolean
}

/**
 * * `1` - Member
 * * `2` - Leader
 */
export type RoleEnum = 1 | 2

/**
 * * `parent` - Parent
 * * `legal_guardian` - Legal Guardian
 * * `in_legal_guardian_absence` - In Legal Guardian Absence
 */
export type RolesEnum =
  | 'parent'
  | 'legal_guardian'
  | 'in_legal_guardian_absence'

export type Room = {
  id: number
  /** @format date-time */
  deleted: string | null
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  is_private?: boolean
  msl_room?: boolean
  pri_room?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  group?: RoomGroupEnum | NullEnum | null
  schedule_automatically?: boolean
  available_for_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
  department: number
}

export type RoomBookingBookForOptions = {
  label: string
  /** @format uuid */
  value: string
  is_student: boolean
}

export type RoomBookingCreate = {
  id: number
  room: number
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
}

export type RoomBookingCreateRequest = {
  room: number
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  added_by?: string
}

export type RoomBookingUpdate = {
  id: number
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
}

export type RoomBookingUpdateRequest = {
  /** @format date */
  start_date: string
  /** @format uuid */
  booked_for?: string | null
  /**
   * * `reserved` - Reserved
   * * `social_room` - Social room
   * * `duty_room` - Duty room
   * * `storage` - Storage
   * * `artisan_private_room` - Artisan private room
   */
  purpose?: PurposeEnum
  last_modified_by?: string
}

/**
 * * `1` - 1
 * * `2` - 2
 * * `3` - 3
 * * `4` - 4
 * * `5` - 5
 */
export type RoomGroupEnum = 1 | 2 | 3 | 4 | 5

export type RoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  is_private?: boolean
  msl_room?: boolean
  pri_room?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  group?: RoomGroupEnum | NullEnum | null
  schedule_automatically?: boolean
  available_for_exam?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  capacity_for_exam?: number | null
  department: number
}

export type RotaGenerationTask = {
  id: number
  status: RotaGenerationTaskStatusEnum
  /** @format date-time */
  finished_at: string | null
  rota_timetable_id: number | null
  timetable?: number | null
}

export type RotaGenerationTaskRequest = {
  timetable?: number | null
}

/**
 * * `new` - New
 * * `in_progress` - In progress
 * * `failed` - Generation has failed
 * * `success` - Success
 */
export type RotaGenerationTaskStatusEnum =
  | 'new'
  | 'in_progress'
  | 'failed'
  | 'success'

export type RotaRoleChoice = {
  label: string
  /**
   * * `boarding_director` - Boarding Director
   * * `campus_head` - Campus Head
   * * `deputy_campus_head_main` - Deputy Campus Head (Main)
   * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
   * * `head_of_house` - Head of House
   * * `house_tutor` - House Tutor
   */
  value: BoardingArtisanRoleEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type RotaTimetable = {
  id: number
  timetable: number
  status: RotaTimetableStatusEnum
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  rota_generation: number | null
}

/**
 * * `generation_in_progress` - Generation in progress
 * * `selected_for_import` - Selected for import
 * * `draft` - Draft
 */
export type RotaTimetableStatusEnum =
  | 'generation_in_progress'
  | 'selected_for_import'
  | 'draft'

/**
 * * `regular` - Regular
 * * `break` - Break
 * * `meeting` - Meeting
 * * `night_presence` - Night presence
 */
export type RotaTypeEnum = 'regular' | 'break' | 'meeting' | 'night_presence'

/**
 * * `miss` - Miss
 * * `ms` - Ms.
 * * `mr` - Mr.
 * * `mrs` - Mrs.
 * * `title` - Title
 * * `other` - Other
 */
export type SalutationEnum = 'miss' | 'ms' | 'mr' | 'mrs' | 'title' | 'other'

export type SanctionsOption = {
  merit: StringOption[]
  demerit: StringOption[]
}

export type SaveStudentsFilesOrder = {
  files_to_order: number[]
}

export type SaveStudentsFilesOrderRequest = {
  files_to_order: number[]
}

export type ScheduledMedicine = {
  /** @format uuid */
  id: string
  medicine_intake_plan: MedicineIntakePlan
  /** @format uuid */
  student_id: string
  student_name: string
  /** @format date-time */
  should_be_given_at: string
  was_given: boolean
  /** @format date-time */
  given_at: string | null
  /** @format uuid */
  given_by: string | null
  is_optional: boolean
  is_deleted: boolean
  /** @format date-time */
  deleted_at: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SchoolBreak = {
  id: number
  /** @maxLength 100 */
  name: string
  school_year: number
  school_year_name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  status: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SchoolBreakRequest = {
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string
  school_year: number
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type SchoolProgramme = {
  id: number
  status: SchoolProgrammeStatusEnum
  status_label: string
  /** @format date-time */
  created: string
  is_compulsory?: boolean
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  type: SchoolProgrammeTypeEnum
  type_label: string
  /** @maxLength 128 */
  title: string
  description?: string
  parental_approval_required?: boolean
  message_to_parents?: string
  enrol_all_students?: boolean
  categories?: StudentCategoryEnum[]
  houses?: number[]
  grade_levels?: number[]
  ages?: number[]
  added_by?: string
  /** @format uuid */
  added_by_id: string
  last_modified_by?: string
  leaders: string[]
  leaders_data: SimpleUser[]
  members: string[]
  members_data: SimpleUser[]
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  enrolment_deadline?: string | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost?: string | null
  cost_currency: string | null
  /**
   * @min 0
   * @max 32767
   */
  students_limit: number
  risk_assessment_file: number
  risk_assessment_file_details: SchoolProgrammeAttachment
  other_files?: number[]
  other_files_details: SchoolProgrammeAttachment[]
  enrolments: ProgrammeEnrolment[]
  approved_enrolments_count: string
  rejection_reason?: string
  is_editable: string
  is_status_editable: string
  can_be_deleted_by_user: string
}

export type SchoolProgrammeAttachment = {
  id: number
  /** @format uri */
  file: string
  file_name: string
}

export type SchoolProgrammeAttachmentRequest = {
  /** @format binary */
  file: File
}

export type SchoolProgrammeAvailableFilters = {
  student: StudentAvailableStringFilter
  participant: ParticipantAvailableStringFilter
  type: AvailableStringFilter
  is_compulsory: AvailableStringFilter
  status: AvailableStringFilter
}

export type SchoolProgrammeList = {
  id: number
  status: SchoolProgrammeStatusEnum
  status_label: string
  is_compulsory: boolean
  type: SchoolProgrammeTypeEnum
  type_label: string
  title: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  students_limit: number
  approved_enrolments_count: string
  requested_enrolments_count: string
  rejection_reason: string
  is_status_editable: string
  can_be_deleted_by_user: string
}

export type SchoolProgrammeRecovery = {
  id: number
  type: SchoolProgrammeTypeEnum
  type_label: string
  title: string
  description: string
  added_by_str: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
}

export type SchoolProgrammeReject = {
  rejection_reason: string
}

export type SchoolProgrammeRejectRequest = {
  /** @minLength 1 */
  rejection_reason: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type SchoolProgrammeRequest = {
  is_compulsory?: boolean
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  type: SchoolProgrammeTypeEnum
  /**
   * @minLength 1
   * @maxLength 128
   */
  title: string
  description?: string
  parental_approval_required?: boolean
  message_to_parents?: string
  enrol_all_students?: boolean
  categories?: StudentCategoryEnum[]
  houses?: number[]
  grade_levels?: number[]
  ages?: number[]
  added_by?: string
  last_modified_by?: string
  leaders: string[]
  members: string[]
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  enrolment_deadline?: string | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost?: string | null
  /**
   * @min 0
   * @max 32767
   */
  students_limit: number
  risk_assessment_file: number
  other_files?: number[]
  enrolments: ProgrammeEnrolmentRequest[]
  rejection_reason?: string
}

/**
 * * `requested` - Requested
 * * `approved` - Approved
 * * `rejected` - Rejected
 */
export type SchoolProgrammeStatusEnum = 'requested' | 'approved' | 'rejected'

/**
 * * `house_evening` - House Evening
 * * `term_break` - Term Break
 * * `school_trip` - School Trip
 * * `weekend_programme` - Weekend Programme
 * * `other` - Other
 * * `absence_recovery` - Absence Recovery
 * * `lunchtime_recovery` - Lunchtime Recovery
 */
export type SchoolProgrammeTypeEnum =
  | 'house_evening'
  | 'term_break'
  | 'school_trip'
  | 'weekend_programme'
  | 'other'
  | 'absence_recovery'
  | 'lunchtime_recovery'

export type SchoolYear = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: string
}

/** Adds nested create feature */
export type Section = {
  id: number
  course_name: string
  course_code: string
  all_enrollments: Enrollment[]
  enrollments?: SectionEnrollment[]
  group_data: SectionGroup
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  is_mentoring?: boolean | null
  must_be_consecutive?: boolean
  hours: number | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  quarter: number
  /** @format uuid */
  course: string
  group: number
  /**
   * Main teacher
   * @format uuid
   */
  teacher: string
  teachers?: string[]
  preferred_rooms: number[]
}

export type SectionEnrollment = {
  id: number
  /** @format uuid */
  student: string
  section?: number
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionEnrollmentRequest = {
  /** @format uuid */
  student: string
  section?: number
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionGroup = {
  id: number
  group_number: number
  group_sub_number: number | null
  /** @format uuid */
  course: string
  school_year: number
  q1_section: number | null
  q2_section: number | null
  q3_section: number | null
  q4_section: number | null
}

export type SectionList = {
  id: number
  course_name: string
  course_code: string
  /** @format uuid */
  course_id: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher_id: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1_id: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2_id: string | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  group_data: SectionGroup
}

/** Adds nested create feature */
export type SectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  is_mentoring?: boolean | null
  must_be_consecutive?: boolean
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  quarter: number
  /** @format uuid */
  course: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher: string
  teachers?: string[]
}

export type SectionToEnroll = {
  id: number
  /**
   * Main teacher
   * @format uuid
   */
  teacher?: string | null
  teacher_str: string
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  all_enrollments: Enrollment[]
  score: string
  group_data: SectionGroup
}

export type Semester = {
  id: number
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: SemesterShortNameEnum
  school_year: number
}

/**
 * * `1` - Semester 1
 * * `2` - Semester 2
 */
export type SemesterEnum = 1 | 2

export type SemesterShortNameEnum = 'S1' | 'S2'

export type SickReport = {
  id: string
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  type_display: string
  /** @format uuid */
  student: string
  first_contact: FirstContact
  student_id: string
  generate_absences_on_create?: boolean
  /** @format uuid */
  requested_by: string | null
  student_str: string
  status_display: string
  status: string
  close_reason?: CloseReasonEnum | BlankEnum
  close_reason_display: string
  /** @format date-time */
  closed_at?: string | null
  /** @format date-time */
  created: string
  has_comments: string
  requested_by_str: string
  closed_by_str: string
  opened_by_str: string
  created_by?: string
  last_modified_by?: string
  absence_dates: SickReportAbsenceDates[]
  current_location: string
  pending_closure_request_id: number | null
  pending_closure_request_reason: string
}

export type SickReportAbsenceDates = {
  /** @format uuid */
  id: string
  created_by?: string
  last_modified_by?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  sick_report: string
  /** @format date-time */
  absent_from?: string | null
  /** @format date-time */
  absent_to?: string | null
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type: ClassTypeEnum
  omit_campus_checks?: boolean
}

export type SickReportAbsenceDatesRequest = {
  created_by?: string
  last_modified_by?: string
  /** @minLength 1 */
  sick_report: string
  /** @format date-time */
  absent_from?: string | null
  /** @format date-time */
  absent_to?: string | null
  /**
   * * `regular` - Regular
   * * `sport` - Sport
   */
  class_type: ClassTypeEnum
  omit_campus_checks?: boolean
}

export type SickReportClosureRequest = {
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type SickReportClosureRequestRequest = {
  /**
   * * `mistake` - Sick report made by mistake
   * * `student_not_sick` - Student not sick
   * * `student_recovered` - Student recovered
   */
  close_reason: CloseReasonEnum
}

export type SickReportComment = {
  /** @format uuid */
  id: string
  author?: string
  author_str: string
  content: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
}

export type SickReportCommentRequest = {
  author?: string
  /** @minLength 1 */
  content: string
}

export type SickReportInHealthCheck = {
  id: string
  /** @format date-time */
  created: string
  symptoms: SymptomsEnum[]
  status: string
}

export type SickReportList = {
  id: string
  /** @format uuid */
  student: string
  current_location: string
  student_id: string
  /** @format uuid */
  requested_by: string | null
  student_str: string
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  type_display: string
  status: string
  status_display: string
  close_reason_display: string
  /** @format date-time */
  created: string
  absent_now: string
  /** Return shortened grade in number */
  student_grade: number
  has_comments: string
  pending_closure_request_id: number | null
  pending_closure_request_reason: string
  created_by?: string
  symptoms: string
}

export type SickReportRequest = {
  /**
   * * `regular` - Regular
   * * `med_only` - Med-only
   */
  type?: SickReportTypeEnum
  /** @format uuid */
  student: string
  generate_absences_on_create?: boolean
  close_reason?: CloseReasonEnum | BlankEnum
  /** @format date-time */
  closed_at?: string | null
  created_by?: string
  last_modified_by?: string
}

/**
 * * `regular` - Regular
 * * `med_only` - Med-only
 */
export type SickReportTypeEnum = 'regular' | 'med_only'

export type SimpleCampusAbsenceStudent = {
  /** @format uuid */
  id: string
  student_data: StudentSimple
}

export type SimpleCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
}

export type SimpleEnrolledStudent = {
  /** @format uuid */
  id: string
  full_name: string
}

/** Sections without enrollments */
export type SimpleSection = {
  id: number
  quarter: number
  /** @format uuid */
  course: string
  /**
   * Main teacher
   * @format uuid
   */
  teacher?: string | null
  teacher_str: string
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  group_data: SectionGroup
}

export type SimpleSickReport = {
  id: string
}

export type SimpleStudentCampusAbsence = {
  /** @format uuid */
  id: string
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  campus_absence_subtype_display: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
}

export type SimpleUser = {
  /** @format uuid */
  id: string
  full_name: string
}

export type SimpleUserWithAvatar = {
  /** @format uuid */
  id: string
  full_name: string
  /** @format uri */
  avatar: string | null
}

export type SiteSettings = {
  id: number
  /** Allow teachers to set target grades */
  target_grades_set_allowed?: boolean
  /**
   * Setting target grades starts at
   * @format date-time
   */
  target_grades_start?: string | null
  /**
   * Setting target grades ends at
   * @format date-time
   */
  target_grades_end?: string | null
  /** Edit values, or set from pending only */
  target_grades_set_all?: boolean
  /**
   * Disable generating tasks from datetime
   * @format date-time
   */
  disable_generating_tasks_from?: string | null
  /**
   * Disable generating tasks to datetime
   * @format date-time
   */
  disable_generating_tasks_to?: string | null
  /** Disable generating take student to destination task */
  disable_take_student_to_destination_task?: boolean
}

/**
 * * `S` - Small
 * * `L` - Large
 */
export type SizeEnum = 'S' | 'L'

export type Skill = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type SkillRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
}

/**
 * * `manual` - Manual
 * * `check_out` - Check out
 * * `sick_report` - Sick report
 * * `doctor_appointment` - Doctor appointment
 * * `hospital_appointment` - Hospital appointment
 * * `campus_absence` - Campus absence
 * * `exam` - Exam session
 * * `programme_enrolment` - School Programme Enrolment
 */
export type SourceEnum =
  | 'manual'
  | 'check_out'
  | 'sick_report'
  | 'doctor_appointment'
  | 'hospital_appointment'
  | 'campus_absence'
  | 'exam'
  | 'programme_enrolment'

export type SourceIDPPlan = {
  id: number
  idp_plan?: string
  serialized_data: Record<string, any>
}

export type StaffMember = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
}

/**
 * * `academic` - Academic
 * * `boarding` - Boarding
 * * `tae` - TAE
 * * `other` - Other
 */
export type StaffTypeEnum = 'academic' | 'boarding' | 'tae' | 'other'

/**
 * * `0` - Pre-School
 * * `1` - Primary School
 * * `2` - Middle School
 * * `3` - High School
 */
export type StageEnum = 0 | 1 | 2 | 3

/**
 * * `PENDING` - PENDING
 * * `RECEIVED` - RECEIVED
 * * `STARTED` - STARTED
 * * `PROGRESS` - PROGRESS
 * * `FINALIZE` - FINALIZE
 * * `SUCCESS` - SUCCESS
 * * `FAILURE` - FAILURE
 * * `REVOKED` - REVOKED
 * * `REJECTED` - REJECTED
 * * `RETRY` - RETRY
 * * `IGNORED` - IGNORED
 */
export type StateEnum =
  | 'PENDING'
  | 'RECEIVED'
  | 'STARTED'
  | 'PROGRESS'
  | 'FINALIZE'
  | 'SUCCESS'
  | 'FAILURE'
  | 'REVOKED'
  | 'REJECTED'
  | 'RETRY'
  | 'IGNORED'

export type StringOption = {
  label: string
  value: string
}

export type Student = {
  first_name: string
  middle_name: string
  last_name: string
  /** @format date */
  birthdate: string
  birthplace: string
  gender: string
  address: string
  address_city: string
  address_zipcode: string
  address_country: string
  billing_address: string
  billing_address_city: string
  billing_address_zipcode: string
  billing_address_country: string
  is_billing_address_same: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentAcademicAttendance = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  mark_label: string
}

export type StudentAccommodation = {
  id: number
  house: number
  room?: number | null
  house_str: string
  /** @format date */
  start_date: string
  room_str: string
  room_code: string
  room_name: string
  is_room_only_for_student?: boolean
}

export type StudentAccommodationRequest = {
  house: number
  room?: number | null
  /** @format date */
  start_date: string
  is_room_only_for_student?: boolean
}

export type StudentAccountOverdue = {
  account_overdue: AccountOverdueEnum
}

export type StudentAchievement = {
  id: number
  achievement: number
  achievement_str: string
  /** @format date */
  date: string
}

export type StudentAchievementRequest = {
  achievement: number
  /** @format date */
  date: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentAdditionalAusgang = {
  id: string
  status: MeritRewardStatusEnum
  /** @format uuid */
  student_id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  leave_details: string
  custom_name: string
  decision_comment: string
  campus_absence_students: StudentMeritRewardParticipant[]
  my_participation: StudentMeritRewardParticipant
  /** @format uuid */
  campus_absence: string | null
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentAdditionalAusgangRequest = {
  student?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  /** @minLength 1 */
  leave_details: string
  /** @minLength 1 */
  custom_name: string
  campus_absence_students: StudentMeritRewardParticipantRequest[]
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentAimForCourse = {
  id: number
  /** @format uuid */
  student: string
  /** @format uuid */
  course: string
  aim?: number | null
}

export type StudentAimForCourseRequest = {
  /** @format uuid */
  student: string
  /** @format uuid */
  course: string
  aim?: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentAllergy = {
  id: number
  /** @format uuid */
  student: string
  allergen_kind: string
  allergen_subtypes?: string[]
  reactions?: StudentAllergyReaction[]
  medicines?: string[]
  is_emergency_medicine?: boolean
  medicine_location?: MedicineLocationEnum | BlankEnum
  immediate_response?: ImmediateResponseEnum | BlankEnum
  medicine_location_display: string
  immediate_response_display: string
}

export type StudentAllergyReaction = {
  id: number
  reaction: string
  severe_reactions?: string[]
  mild_reactions?: string[]
}

export type StudentAllergyReactionRequest = {
  /** @minLength 1 */
  reaction: string
  severe_reactions?: string[]
  mild_reactions?: string[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentAllergyRequest = {
  /** @format uuid */
  student: string
  /** @minLength 1 */
  allergen_kind: string
  allergen_subtypes?: string[]
  reactions?: StudentAllergyReactionRequest[]
  medicines?: string[]
  is_emergency_medicine?: boolean
  medicine_location?: MedicineLocationEnum | BlankEnum
  immediate_response?: ImmediateResponseEnum | BlankEnum
}

export type StudentAnnouncement = {
  id: string
  emojis?: string[]
  /** @maxLength 100 */
  text: string
  /** @maxLength 7 */
  bg_color?: string
  student: string
}

export type StudentApplication = {
  external_id: string
  status: string
  student: Student
  applicant_relation: string
  applicant_first_name: string
  applicant_last_name: string
  /** @format email */
  applicant_email: string
  parent_1_first_name: string
  parent_1_last_name: string
  parent_1_salutation: string
  parent_1_salutation_other?: string
  parent_1_occupation: string
  /** @format email */
  parent_1_email: string
  /** @format email */
  parent_1_secondary_email?: string
  parent_1_phone: string
  parent_2_first_name?: string
  parent_2_last_name?: string
  parent_2_salutation?: string
  parent_2_salutation_other?: string
  parent_2_occupation?: string
  /** @format email */
  parent_2_email?: string
  /** @format email */
  parent_2_secondary_email?: string
  parent_2_phone?: string
  legal_guardian_first_name?: string
  legal_guardian_last_name?: string
  legal_guardian_salutation?: string
  legal_guardian_salutation_other?: string
  legal_guardian_occupation?: string
  /** @format email */
  legal_guardian_email?: string
  /** @format email */
  legal_guardian_secondary_email?: string
  legal_guardian_phone?: string
  /**
   * * `first_parent` - First Parent
   * * `second_parent` - Second Parent
   * * `both_parents` - Both Parents
   * * `relative` - Relative
   * * `other` - Other
   */
  legal_guardian_relation: LegalGuardianRelationEnum
  contact_first_name: string
  contact_last_name: string
  contact_occupation: string
  /** @format email */
  contact_email: string
  /** @format email */
  contact_secondary_email?: string
  contact_phone: string
  contact_child_relation: string
  contact_child_relation_other?: string
  is_contact_allowed_to_visit?: boolean
  date_of_entry_to_rosenberg_year?: number
  career_aim?: string
  mission_control_degree_1_aim?: string
  mission_control_degree_2_aim?: string
  mission_control_degree_1_destination?: string
  mission_control_degree_2_destination?: string
  last_schools?: Record<string, any>[]
  studied_languages?: Record<string, any>[]
  has_other_language_qualifications?: boolean
  other_language_qualifications?: string
  rosenberg_legacies?: Record<string, any>[]
  current_hobbies?: string
  current_interests?: string
  would_have_to_lunch_with?: string
  awards?: Record<string, any>[]
  has_any_least_favourite_subjects?: boolean
  least_favourite_subjects?: Record<string, any>[]
  favourite_subjects?: Record<string, any>[]
  last_book_read?: string
  does_student_play_any_musical_instruments?: boolean
  musical_instruments?: Record<string, any>[]
  has_any_allergies: boolean
  allergies?: Record<string, any>[]
  has_any_chronic_conditions: boolean
  chronic_conditions?: Record<string, any>[]
  has_any_dietary_restrictions: boolean
  dietary_restrictions?: string
  has_any_regular_medication: boolean
  medications?: StudentApplicationMedication[]
  has_any_mental_health_issues: boolean
  mental_health_issues?: Record<string, any>[]
  has_regular_psychological_care: boolean
  medical_reports?: string[]
  /** @format double */
  student_height?: number
  /** @format double */
  student_weight?: number
  student_fitness_for_sports: string
  student_blood_type?: string
  is_student_swimmer: boolean
  is_more_medical_information_available: boolean
  more_medical_information?: string
  has_student_contact_with_drugs: boolean
  has_been_student_expelled: boolean
  report_cards_from_last_three_years?: string[]
  personal_statement?: string[]
  /** @format uri */
  student_photo_in_passport_format: string
  student_passport_photo: string[]
  legal_guardian_passport_photo: string[]
  vaccination_record?: string[]
  other_document?: string[]
  decision_comment: string
}

export type StudentApplicationMedication = {
  medicine_name: string
  dosage: string
  reason_and_diagnosis: string
}

export type StudentApplicationMedicationRequest = {
  /** @minLength 1 */
  medicine_name: string
  /** @minLength 1 */
  dosage: string
  /** @minLength 1 */
  reason_and_diagnosis: string
}

export type StudentApplicationRequest = {
  /** @minLength 1 */
  external_id: string
  /** @minLength 1 */
  status: string
  student: StudentRequest
  /** @minLength 1 */
  applicant_relation: string
  /** @minLength 1 */
  applicant_first_name: string
  /** @minLength 1 */
  applicant_last_name: string
  /**
   * @format email
   * @minLength 1
   */
  applicant_email: string
  /** @minLength 1 */
  parent_1_first_name: string
  /** @minLength 1 */
  parent_1_last_name: string
  /** @minLength 1 */
  parent_1_salutation: string
  parent_1_salutation_other?: string
  /** @minLength 1 */
  parent_1_occupation: string
  /**
   * @format email
   * @minLength 1
   */
  parent_1_email: string
  /** @format email */
  parent_1_secondary_email?: string
  /** @minLength 1 */
  parent_1_phone: string
  parent_2_first_name?: string
  parent_2_last_name?: string
  parent_2_salutation?: string
  parent_2_salutation_other?: string
  parent_2_occupation?: string
  /** @format email */
  parent_2_email?: string
  /** @format email */
  parent_2_secondary_email?: string
  parent_2_phone?: string
  legal_guardian_first_name?: string
  legal_guardian_last_name?: string
  legal_guardian_salutation?: string
  legal_guardian_salutation_other?: string
  legal_guardian_occupation?: string
  /** @format email */
  legal_guardian_email?: string
  /** @format email */
  legal_guardian_secondary_email?: string
  legal_guardian_phone?: string
  /**
   * * `first_parent` - First Parent
   * * `second_parent` - Second Parent
   * * `both_parents` - Both Parents
   * * `relative` - Relative
   * * `other` - Other
   */
  legal_guardian_relation: LegalGuardianRelationEnum
  /** @minLength 1 */
  contact_first_name: string
  /** @minLength 1 */
  contact_last_name: string
  /** @minLength 1 */
  contact_occupation: string
  /**
   * @format email
   * @minLength 1
   */
  contact_email: string
  /** @format email */
  contact_secondary_email?: string
  /** @minLength 1 */
  contact_phone: string
  /** @minLength 1 */
  contact_child_relation: string
  contact_child_relation_other?: string
  is_contact_allowed_to_visit?: boolean
  date_of_entry_to_rosenberg_year?: number
  /** @minLength 1 */
  career_aim?: string
  mission_control_degree_1_aim?: string
  mission_control_degree_2_aim?: string
  mission_control_degree_1_destination?: string
  mission_control_degree_2_destination?: string
  last_schools?: Record<string, any>[]
  studied_languages?: Record<string, any>[]
  has_other_language_qualifications?: boolean
  other_language_qualifications?: string
  rosenberg_legacies?: Record<string, any>[]
  /** @minLength 1 */
  current_hobbies?: string
  /** @minLength 1 */
  current_interests?: string
  /** @minLength 1 */
  would_have_to_lunch_with?: string
  awards?: Record<string, any>[]
  has_any_least_favourite_subjects?: boolean
  least_favourite_subjects?: Record<string, any>[]
  favourite_subjects?: Record<string, any>[]
  /** @minLength 1 */
  last_book_read?: string
  does_student_play_any_musical_instruments?: boolean
  musical_instruments?: Record<string, any>[]
  has_any_allergies: boolean
  allergies?: Record<string, any>[]
  has_any_chronic_conditions: boolean
  chronic_conditions?: Record<string, any>[]
  has_any_dietary_restrictions: boolean
  dietary_restrictions?: string
  has_any_regular_medication: boolean
  medications?: StudentApplicationMedicationRequest[]
  has_any_mental_health_issues: boolean
  mental_health_issues?: Record<string, any>[]
  has_regular_psychological_care: boolean
  medical_reports?: string[]
  /** @format double */
  student_height?: number
  /** @format double */
  student_weight?: number
  /** @minLength 1 */
  student_fitness_for_sports: string
  student_blood_type?: string
  is_student_swimmer: boolean
  is_more_medical_information_available: boolean
  more_medical_information?: string
  has_student_contact_with_drugs: boolean
  has_been_student_expelled: boolean
  report_cards_from_last_three_years?: string[]
  personal_statement?: string[]
  /**
   * @format uri
   * @minLength 1
   */
  student_photo_in_passport_format: string
  student_passport_photo: string[]
  legal_guardian_passport_photo: string[]
  vaccination_record?: string[]
  other_document?: string[]
  /** @minLength 1 */
  decision_comment: string
}

export type StudentAssignmentItem = {
  /** @format uuid */
  id: string
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  student_full_name: string
  /** @format date-time */
  grade_modified_at?: string | null
}

export type StudentAssignmentItemRequest = {
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
  /** @maxLength 32 */
  grade_in_ib?: string
  is_submitted?: boolean
  is_incomplete?: boolean
  is_late?: boolean
  is_exempt?: boolean
  /** @format uuid */
  student: string
  /** @format date-time */
  grade_modified_at?: string | null
}

export type StudentAvailableStringFilter = {
  query_param: string
  label: string
  choices: StudentStringOption[]
}

export type StudentBasic = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
}

export type StudentBirthdayValidateRequest = {
  /** @format date */
  date_of_birth: string
}

export type StudentBloodType = {
  /**
   * * `0-` - 0-
   * * `0+` - 0+
   * * `a-` - A-
   * * `a+` - A+
   * * `b-` - B-
   * * `b+` - B+
   * * `ab-` - AB-
   * * `ab+` - AB+
   * * `unknown` - Unknown
   */
  blood_type: BloodTypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentBookTheCafe = {
  id: string
  status: MeritRewardStatusEnum
  /** @format uuid */
  student_id: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  decision_comment: string
  /** @maxLength 128 */
  movie: string
  campus_absence_students: StudentMeritRewardParticipant[]
  my_participation: StudentMeritRewardParticipant
  /** @format uuid */
  campus_absence: string | null
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentBookTheCafeRequest = {
  student?: string
  /** @format date */
  date: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  movie: string
  campus_absence_students: StudentMeritRewardParticipantRequest[]
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsence = {
  /** @format uuid */
  id: string
  created_by?: string
  campus_absence_type: CampusAbsenceTypeEnum
  campus_absence_type_label: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  custom_name?: string
  campus_absence_subtype_label: string
  status: CampusAbsenceStatusEnum
  status_label: string
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  can_cancel: boolean
  campus_absence_students?: StudentCampusAbsenceStudent[]
  can_edit: boolean
  student_saw_changes: boolean
  object_type: ObjectTypeEnum
  notifications_mark_as_read: number | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsenceRequest = {
  created_by?: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  custom_name?: string
  leave_details?: string
  /** @maxLength 256 */
  contact_person_name?: string
  /** @maxLength 128 */
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  campus_absence_students?: StudentCampusAbsenceStudentRequest[]
}

export type StudentCampusAbsenceStatus = {
  label: string
  /**
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  value: CampusAbsenceStatusEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsenceStudent = {
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_data: StudentData
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCampusAbsenceStudentRequest = {
  /** @format uuid */
  student: string
}

export type StudentCampusAbsenceSubtype = {
  label: string
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  value: CampusAbsenceSubTypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentCampusAbsenceType = {
  label: string
  /**
   * * `ausgang` - Ausgang
   * * `external_exam` - External exam
   * * `official` - Official
   * * `school_programme` - School Programme
   * * `extra_ausgang` - Extra Ausgang
   */
  value: CampusAbsenceTypeEnum
  subtypes: StudentCampusAbsenceSubtype[]
}

export type StudentCasualAnyday = {
  id: string
  status: MeritRewardStatusEnum
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  decision_comment: string
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentCasualAnydayRequest = {
  student?: string
  /** @format date */
  date: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/**
 * * `4` - S
 * * `3` - E
 * * `2` - A
 * * `1` - C
 * * `0` - not assigned
 */
export type StudentCategoryEnum = 4 | 3 | 2 | 1 | 0

export type StudentCategoryItem = {
  /** @format date-time */
  downgrade_due_date: string | null
  is_changed_by_downgrade: boolean
  /** @format uuid */
  id: string
  /** @format uuid */
  student: string
  student_str: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  assigned_category?: StudentCategoryEnum
  homework_status: string
  is_completed?: boolean
  week_ago_category: StudentCategoryEnum
  two_weeks_ago_category: StudentCategoryEnum
  /** @format double */
  weekly_merit_count: number
  pending_merit_count: number
}

export type StudentCategoryItemList = {
  house: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  results: StudentCategoryItem[]
}

export type StudentCategoryItemRequest = {
  /** @format uuid */
  student: string
  /**
   * @min -2147483648
   * @max 2147483647
   */
  assigned_category?: StudentCategoryEnum
  is_completed?: boolean
}

export type StudentCategoryReportList = {
  /** @format uuid */
  id: string
  /** @format date-time */
  created: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  house_str: string
  status_display: string
  /**
   * * `new` - New
   * * `incomplete` - Incomplete
   * * `finished` - Finished
   */
  status?: BoardingAttendanceReportStatusEnum
}

export type StudentCategoryTransition = {
  assigned_category: string
  background_color: string
  text: string
  text_color: string
  assignment_color: string
  upgrade_text: string
}

export type StudentCategoryUpgrade = {
  id: string
  status: MeritRewardStatusEnum
  /** @format date-time */
  created: string
  decision_comment: string
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentCategoryUpgradeRequest = {
  student?: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentChronicCondition = {
  id: number
  /** @format uuid */
  student: string
  condition_type: string
  condition_subtype?: string[]
  other_condition_type?: string
  impact?: ImpactEnum[]
  watch_out_for?: WatchOutForEnum[]
  emergency_medicines?: string[]
  emergency_medicine_applications?: EmergencyMedicineApplicationsEnum[]
  emergency_medicine_location?: EmergencyMedicineLocationEnum[]
  iep_contact?: IepContactEnum[]
  immediate_response?: ImmediateResponseEnum | BlankEnum
  comment?: string
  has_medicine_plan?: boolean
  has_emergency_medicine?: boolean
  has_iep?: boolean
  impact_display: string
  watch_out_for_display: string
  emergency_medicine_applications_display: string
  emergency_medicine_location_display: string
  iep_contact_display: string
  immediate_response_display: string
}

export type StudentChronicConditionRequest = {
  /** @format uuid */
  student: string
  /** @minLength 1 */
  condition_type: string
  condition_subtype?: string[]
  other_condition_type?: string
  impact?: ImpactEnum[]
  watch_out_for?: WatchOutForEnum[]
  emergency_medicines?: string[]
  emergency_medicine_applications?: EmergencyMedicineApplicationsEnum[]
  emergency_medicine_location?: EmergencyMedicineLocationEnum[]
  iep_contact?: IepContactEnum[]
  immediate_response?: ImmediateResponseEnum | BlankEnum
  comment?: string
  has_medicine_plan?: boolean
  has_emergency_medicine?: boolean
  has_iep?: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCityBrunchZurich = {
  id: string
  status: MeritRewardStatusEnum
  /** @format uuid */
  student_id: string
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  decision_comment: string
  campus_absence_students: StudentMeritRewardParticipant[]
  my_participation: StudentMeritRewardParticipant
  /** @format uuid */
  campus_absence: string | null
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentCityBrunchZurichRequest = {
  student?: string
  /** @format date */
  date: string
  campus_absence_students: StudentMeritRewardParticipantRequest[]
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentClashes = {
  lessons: LessonClashes[]
  exams: ExamClashes[]
  programmes: ProgrammeClashes[]
  leave_requests: LeaveRequestClashes[]
}

export type StudentClashesNumber = {
  clashes_number: number
}

export type StudentClashesNumberRequest = {
  clashes_number: number
}

export type StudentContent =
  | AppMysteryLink
  | AppStudentAnnouncement
  | AppStudentPic

export type StudentContentRequest =
  | AppMysteryLinkRequest
  | AppStudentAnnouncementRequest
  | AppStudentPicRequest

/**
 * * `StudentMysteryLink` - StudentMysteryLink
 * * `StudentAnnouncement` - StudentAnnouncement
 * * `StudentPic` - StudentPic
 */
export type StudentContentResourcetypeEnum =
  | 'StudentMysteryLink'
  | 'StudentAnnouncement'
  | 'StudentPic'

/**
 * * `pending` - Pending
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `deleted` - Deleted
 * * `archived` - Archived
 */
export type StudentContentStatusEnum =
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'deleted'
  | 'archived'

export type StudentCourseRequirement = {
  id: number
  course: string
  min_grade: string
  current_grade: string
  potential_grade: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * @min 0
   * @max 100
   */
  min_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  current_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  potential_percents?: number | null
  aim?: number | null
  year: number
}

export type StudentCourseRequirementRequest = {
  /** @minLength 1 */
  min_grade: string
  /** @minLength 1 */
  current_grade: string
  /** @minLength 1 */
  potential_grade: string
  /**
   * @min 0
   * @max 100
   */
  min_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  current_percents?: number | null
  /**
   * @min 0
   * @max 100
   */
  potential_percents?: number | null
  aim?: number | null
  year: number
}

export type StudentCourseYearResponse = {
  id: number
  name: string
  is_active: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentCourses = {
  id: string
  name: string
  teacher: UserInCourse
  co_teacher_1?: UserInCourse
  co_teacher_2?: UserInCourse
  grades: StudentGrade[]
  current_grade: StudentGrade
  notifications_mark_as_read: number | null
  homework_count: number
}

export type StudentData = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
  /** @format uri */
  avatar?: string | null
}

export type StudentDataRequest = {
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /** @format binary */
  avatar?: File | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentDayMenu = {
  id: number
  /** @format date */
  date: string
  is_skipped: boolean
  lunch_starter: string
  lunch_soup: string
  lunch_main_course: string
  lunch_dessert: string
  lunch_starter_vege: string
  lunch_soup_vege: string
  lunch_main_course_vege: string
  lunch_dessert_vege: string
  dinner_starter: string
  dinner_soup: string
  dinner_main_course: string
  dinner_dessert: string
  dinner_starter_vege: string
  dinner_soup_vege: string
  dinner_main_course_vege: string
  dinner_dessert_vege: string
  breakfast_special: string
  breakfast_special_vege: string
  is_room_service: boolean
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentDetail = {
  /** @format uuid */
  id: string
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string
  /** @maxLength 256 */
  nickname?: string
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  full_name: string
  /** @maxLength 256 */
  name: string
  current_grade_level: number
  current_grade_level_str: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  nationality?: NationalityEnum | BlankEnum
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  gender: GenderEnum
  is_at_home?: boolean
  is_vaccinated: boolean
  has_allergy: boolean
  has_chronic_condition: boolean
  accommodation: StudentAccommodation
  category: string
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  has_pending_draft_medications: boolean
  contacts: ParentStudentContact[]
  parent_1?: Parent | null
  parent_1_has_access?: boolean
  parent_2?: Parent | null
  parent_2_has_access?: boolean
  legal_guardian?: Parent | null
  legal_guardian_has_access?: boolean
  contact_in_legal_guardian_absence?: Parent | null
  contact_in_legal_guardian_absence_has_access?: boolean
  /**
   * * `first_parent` - First Parent
   * * `second_parent` - Second Parent
   * * `both_parents` - Both Parents
   * * `relative` - Relative
   * * `other` - Other
   */
  legal_guardian_relation?: LegalGuardianRelationEnum
  external_id_in_invoice_system?: string
  meal_preference: StudentMealPreference
  blood_type: BloodTypeEnum | BlankEnum
  account_overdue?: AccountOverdueEnum
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentDetailRequest = {
  /** @maxLength 256 */
  nickname?: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  first_name: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  last_name: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  current_grade_level: number
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  nationality?: NationalityEnum | BlankEnum
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth: string
  /**
   * * `male` - Male
   * * `female` - Female
   */
  gender: GenderEnum
  is_at_home?: boolean
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  parent_1?: ParentRequest | null
  parent_1_has_access?: boolean
  parent_2?: ParentRequest | null
  parent_2_has_access?: boolean
  legal_guardian?: ParentRequest | null
  legal_guardian_has_access?: boolean
  contact_in_legal_guardian_absence?: ParentRequest | null
  contact_in_legal_guardian_absence_has_access?: boolean
  /**
   * * `first_parent` - First Parent
   * * `second_parent` - Second Parent
   * * `both_parents` - Both Parents
   * * `relative` - Relative
   * * `other` - Other
   */
  legal_guardian_relation?: LegalGuardianRelationEnum
  external_id_in_invoice_system?: string
  account_overdue?: AccountOverdueEnum
}

export type StudentExternalExam = {
  id: number
  /**
   * * `ACT` - ACT
   * * `A_LEVEL` - A Level (GCE), iGCSE
   * * `AP` - Advanced Placement
   * * `CAMBRIDGE` - CAMBRIDGE
   * * `GCSE` - GCSE
   * * `GMAT` - GMAT
   * * `IAL` - International A Level
   * * `IB` - International Baccalaureate
   * * `IELTS` - IELTS
   * * `MAP` - MAP
   * * `SAT_ENG` - SAT ENG
   * * `SAT_MAT` - SAT MAT
   * * `WIDA` - WIDA
   */
  type: ExternalExamTypeEnum
  /** @format date */
  date: string
  /** @format double */
  min_score?: number | null
  /** @format double */
  score?: number | null
  /** @maxLength 16 */
  subtype?: string
  name: string
  certificate: Attachment
}

export type StudentExternalExamRequest = {
  /**
   * * `ACT` - ACT
   * * `A_LEVEL` - A Level (GCE), iGCSE
   * * `AP` - Advanced Placement
   * * `CAMBRIDGE` - CAMBRIDGE
   * * `GCSE` - GCSE
   * * `GMAT` - GMAT
   * * `IAL` - International A Level
   * * `IB` - International Baccalaureate
   * * `IELTS` - IELTS
   * * `MAP` - MAP
   * * `SAT_ENG` - SAT ENG
   * * `SAT_MAT` - SAT MAT
   * * `WIDA` - WIDA
   */
  type: ExternalExamTypeEnum
  /** @format date */
  date: string
  /** @format double */
  min_score?: number | null
  /** @format double */
  score?: number | null
  /** @maxLength 16 */
  subtype?: string
}

export type StudentGrade = {
  period_short_name: string
  grade: string
  weighted_percents: number | null
  target_grade: string | null
  target_percents: number | null
  /**
   * * `pending` - Pending
   * * `much_above` - Much Above
   * * `above` - Above
   * * `below` - Below
   * * `much_below` - Much Below
   */
  target_grade_status?: TargetGradeStatusEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentHomework = {
  /** @format uuid */
  id: string
  grade_in_percent: number | null
  grade_in_ib: string
  is_submitted: boolean
  is_incomplete: boolean
  is_late: boolean
  is_exempt: boolean
  assignment_name: string
  assignment_description: string
  /** @format date */
  assignment_due_date: string
  assignment_type_name: string
  /** @format uuid */
  course_id: string
  course_name: string
  student_saw_changes: boolean
  status: string
  notifications_mark_as_read?: number | null
  is_homework: boolean
  quarter_name: string
  /** @format date-time */
  created: string
}

export type StudentHomeworkCurrentlyLate = {
  currently_late: number
}

export type StudentHomeworkNewlyReviewed = {
  newly_reviewed: number
}

export type StudentHomeworkToDo = {
  to_do: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentIdpPlan = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  baskets?: Basket[]
  /** @format uuid */
  student: string
  status: string
  status_display: string
  note?: string
  grade_level: number
  aim: string
  year: number
  draft_payload?: Record<string, any>
  exams?: ExamScore[]
  warnings?: ParentWarning[]
  /**
   * @min 0
   * @max 2147483647
   */
  version: number
  student_str: string
  reject_details: Record<string, any>
  /** @format double */
  credit_count?: number | null
  /** @format date */
  parent_approval_deadline: string | null
  parent_message?: string
  parent_message_author_str: string
  /** @format date-time */
  parent_message_created_at: string | null
  parent_approve_comment: string
  parent_approve_author: string
  /** @format date-time */
  parent_approved_at: string | null
}

export type StudentIdpPlanList = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format uuid */
  student: string
  status: string
  status_display: string
  note?: string
  grade_level: number
  aim: string
  year: number
  /**
   * @min 0
   * @max 2147483647
   */
  version: number
  /** Last approved version */
  is_active?: boolean
  student_str: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentIdpPlanRequest = {
  baskets?: BasketRequest[]
  /** @format uuid */
  student: string
  note?: string
  grade_level: number
  /** @minLength 1 */
  aim: string
  year: number
  draft_payload?: Record<string, any>
  exams?: ExamScoreRequest[]
  warnings?: ParentWarningRequest[]
  /** @default true */
  commit?: boolean
  /** @format double */
  credit_count?: number | null
  courses_mission_control_departments?: CourseMissionControlDepartmentRequest[]
  parent_message?: string
}

export type StudentInAttendance = {
  /** @format uuid */
  id: string
  full_name: string
  is_at_home?: boolean
}

export type StudentInAttendanceRequest = {
  is_at_home?: boolean
}

export type StudentInternship = {
  id: number
  internship_str: string
  internship: number
  document?: number | null
  document_data: InternshipAttachment | null
  /** @maxLength 256 */
  organisation: string
  /** @format date */
  date: string
  /** @format date */
  date_to: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipUpdate = {
  id?: number
  internship: number
  document?: number | null
  /** @maxLength 256 */
  organisation: string
  /** @format date */
  date: string
  /** @format date */
  date_to: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipUpdateRequest = {
  id?: number
  internship: number
  document?: number | null
  /**
   * @minLength 1
   * @maxLength 256
   */
  organisation: string
  /** @format date */
  date: string
  /** @format date */
  date_to: string
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipsUpdate = {
  internships: StudentInternshipUpdate[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type StudentInternshipsUpdateRequest = {
  internships: StudentInternshipUpdateRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentLeaveCheckRequest = {
  /**
   * @minLength 1
   * @maxLength 1024
   */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /**
   * @minLength 1
   * @maxLength 64
   */
  contact_person: string
  /**
   * @minLength 1
   * @maxLength 128
   */
  contact_person_phone: string
  is_driver_needed?: boolean
  /** @format date-time */
  date: string
  /** @format date-time */
  original_date?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentLeaveRequest = {
  /** @format uuid */
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_out: StudentLeaveCheck
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_in: StudentLeaveCheck
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  leave_type_label: string
  request_type: RequestTypeEnum
  status: string
  status_label: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  order_date: string
  can_edit: boolean
  can_cancel: boolean
  can_delete: boolean
  object_type: ObjectTypeEnum
  student_message?: string
  student_saw_changes: boolean
  notifications_mark_as_read: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentLeaveRequestRequest = {
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_out: StudentLeaveCheckRequest
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_in: StudentLeaveCheckRequest
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  student_message?: string
}

export type StudentLeaveRequestStatus = {
  label: string
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   */
  value: LeaveRequestStatusEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentLeaveRequestSwagger = {
  /** @format uuid */
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_out?: StudentLeaveCheck
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_in?: StudentLeaveCheck
  /**
   * @min -2147483648
   * @max 2147483647
   */
  leave_type: LeaveRequestTypeEnum
  leave_type_label: string
  request_type: RequestTypeEnum
  status: string
  status_label: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  order_date: string
  can_edit: boolean
  can_cancel: boolean
  can_delete: boolean
  object_type: ObjectTypeEnum
  student_message?: string
  student_saw_changes: boolean
  notifications_mark_as_read: number | null
}

export type StudentLeaveRequestType = {
  label: string
  /**
   * * `0` - Weekend
   * * `1` - Holiday
   * * `2` - Other
   */
  value: LeaveRequestTypeEnum
}

export type StudentLeavesSummarySwagger = {
  /** @format uuid */
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_in?: StudentLeaveCheck
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  check_out?: StudentLeaveCheck
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @format date-time */
  order_date: string
  can_edit: boolean
  leave_type: LeaveRequestTypeEnum
  /**
   * * `manual` - Manual
   * * `from idp` - From IDP
   * * `by_student` - Requested by student
   */
  request_type: RequestTypeEnum
  /**
   * * `tentative` - Pending parental
   * * `confirmed_contact_person` - Pending academic
   * * `confirmed_academic` - Pending boarding
   * * `changed` - Changed
   * * `confirmed` - Approved
   * * `declined` - Rejected
   * * `declined_contact_person` - Rejected by contact person
   * * `declined_academic` - Rejected by academic
   * * `canceled` - Canceled by student
   * * `expired` - Expired
   * * `requested` - Pending
   * * `approved` - Approved
   * * `rejected` - Rejected
   * * `canceled_by_student` - Canceled by student
   * * `done` - Done
   * * `cancelled` - Cancelled
   */
  status: StudentLeavesSummarySwaggerStatusEnum
  /** @format uuid */
  created_by: string
  campus_absence_students?: StudentCampusAbsenceStudent[]
  /**
   * * `afternoon_ausgang` - Afternoon Ausgang
   * * `evening_ausgang` - Evening Ausgang
   * * `evening_campus_ausgang` - Evening - Campus Ausgang
   * * `birthday_ausgang` - Birthday Ausgang
   * * `family_visit` - Family visit
   * * `on_board` - On board Ausgang
   * * `prolonged_ausgang` - Prolonged Ausgang
   * * `campus` - Campus
   * * `offsite` - Offsite
   * * `offsite_overnight` - Offsite, Overnight
   * * `official_appointment` - Official appointment
   * * `specialist_doctor_appointment` - Specialist Doctor Appointment
   * * `programme` - Programme
   * * `house_evening` - House evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `book_the_cafe` - Book the Cafe
   * * `city_brunch_zurich` - City Brunch Zurich
   * * `additional_ausgang` - Additional Ausgang
   */
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  campus_absence_subtype_label: string
  leave_details?: string
  contact_person_name?: string
  contact_person_phone?: string
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  object_type: ObjectTypeEnum
  leave_type_label: string
  status_label: string
  student_saw_changes: boolean
}

export type StudentLeavesSummarySwaggerList = {
  results: StudentLeavesSummarySwagger[]
}

/**
 * * `tentative` - Pending parental
 * * `confirmed_contact_person` - Pending academic
 * * `confirmed_academic` - Pending boarding
 * * `changed` - Changed
 * * `confirmed` - Approved
 * * `declined` - Rejected
 * * `declined_contact_person` - Rejected by contact person
 * * `declined_academic` - Rejected by academic
 * * `canceled` - Canceled by student
 * * `expired` - Expired
 * * `requested` - Pending
 * * `approved` - Approved
 * * `rejected` - Rejected
 * * `canceled_by_student` - Canceled by student
 * * `done` - Done
 * * `cancelled` - Cancelled
 */
export type StudentLeavesSummarySwaggerStatusEnum =
  | 'tentative'
  | 'confirmed_contact_person'
  | 'confirmed_academic'
  | 'changed'
  | 'confirmed'
  | 'declined'
  | 'declined_contact_person'
  | 'declined_academic'
  | 'canceled'
  | 'expired'
  | 'requested'
  | 'approved'
  | 'rejected'
  | 'canceled_by_student'
  | 'done'
  | 'cancelled'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentList = {
  /** @format uuid */
  id: string
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string
  /** @maxLength 256 */
  nickname?: string
  /** @maxLength 128 */
  first_name: string
  /** @maxLength 128 */
  last_name: string
  full_name: string
  /** @maxLength 256 */
  name?: string
  current_grade_level: number
  current_grade_level_str: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  nationality?: NationalityEnum | BlankEnum
  native_language?: NativeLanguageEnum | BlankEnum
  education_details?: string
  /** @format date */
  date_of_birth?: string | null
  two_factor_auth?: boolean
  gender?: GenderEnum | BlankEnum
  is_at_home?: boolean
  is_vaccinated: boolean
  has_allergy: boolean
  has_chronic_condition: boolean
  homework_status: string
  accommodation: StudentAccommodation
  category: string
  week_ago_category: string
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean
  disable_access_to_idp?: boolean
  saa_student?: boolean
  is_statemented?: boolean
  /**
   * @min -2147483648
   * @max 2147483647
   */
  exam_extra_time?: ExamExtraTimeEnum | NullEnum | null
  access_arrangements?: AccessArrangementsEnum[]
  is_draft?: boolean
  external_id_in_invoice_system?: string
  searchable_content?: string
  /** @format date-time */
  report_card_sent_at: string | null
  account_overdue?: AccountOverdueEnum
}

export type StudentMealPreference = {
  /**
   * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
   * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
   * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
   * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
   */
  diet_type?: DietTypeEnum
  other_requirements?: string
}

export type StudentMealPreferenceHistory = {
  id: number
  /** @format date-time */
  created: string
  /**
   * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
   * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
   * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
   * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
   */
  diet_type?: DietTypeEnum
  diet_type_display: string
  other_requirements?: string
}

export type StudentMealPreferenceRequest = {
  /** @format uuid */
  student: string
  /**
   * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
   * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
   * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
   * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
   */
  diet_type?: DietTypeEnum
  other_requirements?: string
}

export type StudentMeritCount = {
  merits_count: number
  demerits_count: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentMeritList = {
  id: string
  /** @format double */
  value: number
  merit_type: MeritTypeEnum
  /** @format date-time */
  created: string
  escalated_merit_item_str: string
  escalated_sanction_str: string
  student_saw_changes: boolean
  status: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentMeritRetrieve = {
  id: string
  /** @format double */
  value: number
  merit_type: MeritTypeEnum
  /** @format date-time */
  created: string
  escalated_merit_item_str: string
  escalated_sanction_str: string
  student_saw_changes: boolean
  status: string
  course_str: string
  author_str: string
  next_sanction_str: string
  escalation_expire_after_str: string
  notifications_mark_as_read?: number | null
  /** @format date */
  suspension_date_from: string | null
  /** @format date */
  suspension_date_to: string | null
  sanction_message: string
}

export type StudentMeritReward =
  | StudentCasualAnyday
  | StudentRoomService
  | StudentBookTheCafe
  | StudentCityBrunchZurich
  | StudentAdditionalAusgang
  | StudentCategoryUpgrade
  | StudentStopTheClock

export type StudentMeritRewardParticipant = {
  id: number
  /** @format uuid */
  student: string
  student_data: ParticipantStudent
  status: StudentMeritRewardParticipantStatusEnum
  student_saw_changes: boolean
}

export type StudentMeritRewardParticipantRequest = {
  /** @format uuid */
  student: string
}

/**
 * * `pending` - Pending
 * * `accepted` - Accepted
 * * `rejected` - Rejected
 */
export type StudentMeritRewardParticipantStatusEnum =
  | 'pending'
  | 'accepted'
  | 'rejected'

export type StudentMeritRewardRequest =
  | StudentCasualAnydayRequest
  | StudentRoomServiceRequest
  | StudentBookTheCafeRequest
  | StudentCityBrunchZurichRequest
  | StudentAdditionalAusgangRequest
  | StudentCategoryUpgradeRequest
  | StudentStopTheClockRequest

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentMessage = {
  id: number
  content: string
  /** @format date-time */
  created: string
  /**
   * * `alert` - Alert
   * * `notice` - Notice
   */
  type: MessageTypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentPic = {
  id: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  image: PublicImage
  /** @maxLength 50 */
  title?: string
  student_str: string
}

export type StudentProfile = {
  /** @format uuid */
  id: string
  first_name: string
  last_name: string
  name: string
  full_name: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
  /**
   * Email address
   * @format email
   */
  email: string
  nationality: NationalityEnum
  nickname: string
  /** @format date */
  date_of_birth: string | null
  current_grade_level_str: string
  /** @format uri */
  avatar: string | null
  /** @format double */
  merit_points: number
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  favourites?: string[]
}

export type StudentProfileRequest = {
  /** @format double */
  merit_points: number
  allow_push_notifications?: boolean
  allow_email_notifications?: boolean
  favourites?: string[]
}

export type StudentProgrammeEnrollmentStatusSwagger = {
  label: string
  /**
   * * `pending` - Pending
   * * `requested` - Requested
   * * `waiting_parental_approve` - Waiting parental approve
   * * `rejected` - Rejected
   * * `approved` - Approved
   * * `leave_absent` - Leave - Absent
   */
  value: EnrolmentStatusEnum
}

export type StudentRequest = {
  /** @minLength 1 */
  first_name: string
  middle_name: string
  /** @minLength 1 */
  last_name: string
  /** @format date */
  birthdate: string
  /** @minLength 1 */
  birthplace: string
  /** @minLength 1 */
  gender: string
  /** @minLength 1 */
  address: string
  /** @minLength 1 */
  address_city: string
  /** @minLength 1 */
  address_zipcode: string
  /** @minLength 1 */
  address_country: string
  billing_address: string
  billing_address_city: string
  billing_address_zipcode: string
  billing_address_country: string
  is_billing_address_same: boolean
}

export type StudentRoomService = {
  id: string
  status: MeritRewardStatusEnum
  /** @format date-time */
  created: string
  /** @format date */
  date: string
  decision_comment: string
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentRoomServiceRequest = {
  student?: string
  /** @format date */
  date: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentSchoolProgramme = {
  id: number
  can_enrol: string
  /** @format date-time */
  enrolment_deadline: string | null
  enrolment_status: EnrolmentStatusEnum
  enrolment_status_label: string
  enrolment_rejection_comment: string
  enrolment_rejected_by_full_name: string
  leaders: SimpleUserWithAvatar[]
  title: string
  description: string
  is_compulsory: boolean
  status: SchoolProgrammeStatusEnum
  status_label: string
  type: SchoolProgrammeTypeEnum
  type_label: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  students_limit: number
  notifications_mark_as_read: number | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type StudentSchoolProgrammeList = {
  id: number
  can_enrol: string
  /** @format date-time */
  enrolment_deadline: string | null
  enrolment_status: EnrolmentStatusEnum
  enrolment_status_label: string
  student_saw_changes: boolean
  title: string
  is_compulsory: boolean
  status: SchoolProgrammeStatusEnum
  status_label: string
  type: SchoolProgrammeTypeEnum
  type_label: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
}

export type StudentSchoolProgrammeStatusSwagger = {
  label: string
  /**
   * * `requested` - Requested
   * * `approved` - Approved
   * * `rejected` - Rejected
   */
  value: SchoolProgrammeStatusEnum
}

export type StudentSchoolProgrammeTypeSwagger = {
  label: string
  /**
   * * `house_evening` - House Evening
   * * `term_break` - Term Break
   * * `school_trip` - School Trip
   * * `weekend_programme` - Weekend Programme
   * * `other` - Other
   * * `absence_recovery` - Absence Recovery
   * * `lunchtime_recovery` - Lunchtime Recovery
   */
  value: SchoolProgrammeTypeEnum
}

export type StudentSimple = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /** @maxLength 256 */
  name?: string
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
}

export type StudentSimpleRequest = {
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  /** @maxLength 256 */
  name?: string
}

export type StudentStopTheClock = {
  id: string
  status: MeritRewardStatusEnum
  /** @format date-time */
  created: string
  decision_comment: string
  /** @format uuid */
  campus_absence: string
  notifications_mark_as_read: number | null
  student_saw_changes: boolean
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentStopTheClockRequest = {
  student?: string
  /** @format uuid */
  campus_absence: string
  /**
   * * `CasualAnyday` - CasualAnyday
   * * `RoomService` - RoomService
   * * `BookTheCafe` - BookTheCafe
   * * `CityBrunchZurich` - CityBrunchZurich
   * * `AdditionalAusgang` - AdditionalAusgang
   * * `CategoryUpgrade` - CategoryUpgrade
   * * `StopTheClock` - StopTheClock
   */
  resourcetype: MeritRewardResourcetypeEnum
}

export type StudentStringOption = {
  label: string
  value: string
  searchable_content: string
}

export type StudentSurveyResponse = {
  answers: Record<string, any>
}

export type StudentSurveyResponseRequest = {
  answers: Record<string, any>
}

export type StudentTAEExperience = {
  id: number
  experience_str: string
  experience: number
  /** @format date */
  date: string
}

export type StudentTAEExperienceRequest = {
  experience: number
  /** @format date */
  date: string
}

export type StudentTAEProject = {
  id: number
  /** @maxLength 256 */
  name: string
  /** @format date */
  expected_completion_date: string
  /** @format date */
  completion_date?: string | null
}

export type StudentTAEProjectRequest = {
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  /** @format date */
  expected_completion_date: string
  /** @format date */
  completion_date?: string | null
}

export type StudentTargetProgressComment = {
  /** @format uuid */
  id: string
  comments?: string[]
}

export type StudentTargetProgressCommentRequest = {
  comments?: string[]
}

export type StudentTranscript = {
  id: number
  grade_level: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  semester: SemesterEnum
  /** @maxLength 256 */
  school?: string
  certificate: Attachment
  year: string
  status?: StudentTranscriptStatusEnum | BlankEnum
}

export type StudentTranscriptRequest = {
  grade_level: number
  /**
   * @min -2147483648
   * @max 2147483647
   */
  semester: SemesterEnum
  /** @maxLength 256 */
  school?: string
  status?: StudentTranscriptStatusEnum | BlankEnum
}

/**
 * * `RECEIVED` - Received
 * * `MISSING` - Missing
 */
export type StudentTranscriptStatusEnum = 'RECEIVED' | 'MISSING'

/** mainly student's app usage */
export type StudentWithAvatar = {
  /** @format uuid */
  id: string
  full_name: string
  /** @format uri */
  avatar: string | null
  /**
   * mainly student's app usage
   * -> we're considering only first name given
   */
  reduced_full_name: string
}

export type StudentsClashesForDateParamsRequest = {
  student_ids: string[]
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  /** @minLength 1 */
  exclude_lesson_id?: string | null
  /** @minLength 1 */
  exclude_exam_id?: string | null
  /** @minLength 1 */
  exclude_programme_id?: string | null
  /** @minLength 1 */
  exclude_leave_request_id?: string | null
}

export type StudentsFile = {
  id: number
  name: string
  /** @format uri */
  file: string | null
  filename: string
  /** @format uri */
  link: string
}

export type Subtask = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  title: string
  is_done: boolean
}

export type SubtaskRequest = {
  /** @minLength 1 */
  title: string
}

/**
 * * `attitude` - Attitude
 * * `kindness` - Kindness
 * * `service` - Service
 * * `language` - Language
 * * `electronics` - Electronics
 * * `dress` - Dress
 * * `late` - Late
 * * `night` - Night
 * * `room` - Room
 * * `absence` - Absence
 * * `leave_request` - Leave Request
 * * `ausgang` - Ausgang
 * * `deception` - Deception
 * * `transport` - Transport
 * * `private_home` - Private Home
 * * `deliveries` - Deliveries
 * * `safety` - Safety
 * * `facilities` - Facilities
 * * `aggression` - Aggression
 * * `recklessness` - Recklessness
 * * `covid` - Covid
 * * `smoking` - Smoking
 * * `alcohol` - Alcohol
 * * `drugs` - Drugs
 * * `possession` - Possession
 * * `reputation` - Reputation
 * * `refusal` - Refusal
 * * `abusive_behaviour` - Abusive Behaviour
 */
export type SubtypeEnum =
  | 'attitude'
  | 'kindness'
  | 'service'
  | 'language'
  | 'electronics'
  | 'dress'
  | 'late'
  | 'night'
  | 'room'
  | 'absence'
  | 'leave_request'
  | 'ausgang'
  | 'deception'
  | 'transport'
  | 'private_home'
  | 'deliveries'
  | 'safety'
  | 'facilities'
  | 'aggression'
  | 'recklessness'
  | 'covid'
  | 'smoking'
  | 'alcohol'
  | 'drugs'
  | 'possession'
  | 'reputation'
  | 'refusal'
  | 'abusive_behaviour'

export type Suggestions = {
  school_year: number
  /** @format uuid */
  student: string
  courses_in_baskets?: Record<string, any>
}

export type SuggestionsRequest = {
  school_year: number
  /** @format uuid */
  student: string
  courses_in_baskets?: Record<string, any>
}

/**
 * * `cold` - Cold
 * * `flu` - Flu
 * * `stomach` - Stomach
 * * `allergy` - Allergy
 * * `teeth` - Teeth
 * * `headache` - Headache
 * * `fatigue` - Fatigue
 * * `nausea` - Nausea
 * * `menstruation_pain` - Menstruation pain
 * * `fever` - Fever
 * * `covid_isolation` - Covid - isolation
 * * `covid_quarantine` - Covid - quarantine
 * * `other` - Other
 */
export type SymptomsEnum =
  | 'cold'
  | 'flu'
  | 'stomach'
  | 'allergy'
  | 'teeth'
  | 'headache'
  | 'fatigue'
  | 'nausea'
  | 'menstruation_pain'
  | 'fever'
  | 'covid_isolation'
  | 'covid_quarantine'
  | 'other'

export type TAEExperience = {
  id: number
  /** @maxLength 256 */
  name: string
}

export type TargetGrade = {
  id: number
  target_grade: string
}

export type TargetGradeRequest = {
  /** @minLength 1 */
  target_grade: string
}

/**
 * * `pending` - Pending
 * * `much_above` - Much Above
 * * `above` - Above
 * * `below` - Below
 * * `much_below` - Much Below
 */
export type TargetGradeStatusEnum =
  | 'pending'
  | 'much_above'
  | 'above'
  | 'below'
  | 'much_below'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Task = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  title: string
  description?: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  /** @format date-time */
  start: string | null
  /** @format date-time */
  deadline?: string | null
  is_done: boolean
  created_by?: string
  created_by_str: string
  /** @format uuid */
  assignee?: string | null
  assignee_str: string
  /** @format uuid */
  team?: string | null
  team_str: string
  is_recurring: boolean
  subtasks?: Subtask[]
  status: string
  is_marked_done_manually: boolean
  done_comment?: string
  allow_to_close_subtask: boolean
  complete_automatically: boolean
  is_editable: string
  recurring?: Recurring | null
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TaskRequest = {
  /** @minLength 1 */
  title: string
  description?: string
  /**
   * @format uri
   * @maxLength 200
   */
  link?: string
  /** @format date-time */
  deadline?: string | null
  created_by?: string
  /** @format uuid */
  assignee?: string | null
  /** @format uuid */
  team?: string | null
  subtasks?: SubtaskRequest[]
  done_comment?: string
  recurring?: RecurringRequest | null
  is_cloned?: boolean
  /** @minLength 1 */
  cloned_from?: string | null
}

export type TaskToggleIsDone = {
  done_comment?: string
}

export type TaskToggleIsDoneRequest = {
  done_comment?: string
}

export type TaskToggleSubtaskIsDone = {
  subtask: string
}

export type TaskToggleSubtaskIsDoneRequest = {
  /** @minLength 1 */
  subtask: string
}

export type TeacherStudentItem = {
  /** @format uuid */
  id: string
  /**
   * @min 0
   * @max 100
   */
  grade_in_percent?: number | null
}

export type TeacherStudentWithAssignment = {
  /** @format uuid */
  id: string
  full_name: string
  assignments: TeacherStudentItem[]
}

export type TeacherTimetableCourse = {
  /** @format uuid */
  id: string
  /** @maxLength 128 */
  name: string
  types: string[]
  levels: string[]
  /** @format double */
  credits: number | null
  departments: string[]
}

export type TeacherTimetableLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  /**
   * @min 0
   * @max 15
   */
  hour?: HourEnum | NullEnum | null
  /** @format double */
  duration?: number
  day: DayEnum
  end: string
  display_hour: string
  course: TeacherTimetableCourse
  quarter: number
  room?: number | null
  students: SimpleUser[]
  teacher: SimpleUser
  is_archived?: boolean
  teacher_cover?: SimpleUser | null
  co_teacher_1: SimpleUser | null
  co_teacher_2: SimpleUser | null
}

export type TeacherTimetableListLesson = {
  /** @format uuid */
  id: string
  /** @format date */
  date: string
  start: string
  end: string
  display_hour: string
  course: SimpleCourse
  room: TeacherTimetableRoom
  quarter: number
  /** @format uuid */
  teacher: string
  /** @format uuid */
  teacher_cover?: string | null
  /**
   * Co-artisan 1
   * @format uuid
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @format uuid
   */
  co_teacher_2?: string | null
}

export type TeacherTimetableRoom = {
  id: number
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
}

export type TeachersPerGrade = {
  id: number
  teachers: string[]
  grade_level: number
  mentoring?: number
}

export type TeachersPerGradeRequest = {
  teachers: string[]
  grade_level: number
  mentoring?: number
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type Team = {
  /** @format uuid */
  id: string
  name: string
  house?: number | null
  house_name: string
  members: TeamMember[]
  is_predefined: boolean
  include_in_boarding_rota?: boolean
}

export type TeamMember = {
  /** @format uuid */
  id: string
  /** @format uuid */
  user: string
  /**
   * @min 0
   * @max 32767
   */
  role: RoleEnum
  role_display: string
  user_first_name: string
  user_last_name: string
  rota_roles?: BoardingArtisanRoleEnum[]
}

export type TeamMemberRequest = {
  /** @format uuid */
  user: string
  /**
   * @min 0
   * @max 32767
   */
  role: RoleEnum
  rota_roles?: BoardingArtisanRoleEnum[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TeamRequest = {
  /** @minLength 1 */
  name: string
  house?: number | null
  members: TeamMemberRequest[]
  include_in_boarding_rota?: boolean
}

export type TemperatureCheck = {
  id: number
  /** @format uuid */
  health_check: string
  /** @format double */
  temperature: number
}

export type TemperatureCheckRequest = {
  /** @format uuid */
  health_check: string
  /** @format double */
  temperature: number
}

/**
 * * `isolation` - Isolation
 * * `quarantine` - Quarantine
 * * `sleepover` - Sleepover
 */
export type TemporaryMovementTypeEnum = 'isolation' | 'quarantine' | 'sleepover'

/**
 * * `quarter` - Quarter
 * * `school_year` - School Year
 */
export type TimeframeTypeEnum = 'quarter' | 'school_year'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Timetable = {
  id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  quarter: number
  total_activities: number
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  status_display: string
  generation_task: number
  quarter_str: string
  quarter_short_name: QuarterShortNameEnum
  idp_plans: number[]
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
  selected_rota_timetable: string
}

export type TimetableCampusAbsence = {
  /** @format uuid */
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "campus_absence"
   */
  _type: string
  campus_absence_type: CampusAbsenceTypeEnum
  campus_absence_type_label: string
  campus_absence_subtype: CampusAbsenceSubTypeEnum
  campus_absence_subtype_label: string
  students: StudentWithAvatar[]
  leave_details: string
  can_cancel: boolean
  can_edit: boolean
  created_by?: string
  merit_reward: string
  /**
   * * `CampusAbsence` - CampusAbsence
   * * `Appointment` - Appointment
   * * `Exam` - Exam
   * * `LeaveRequest` - LeaveRequest
   * * `Lesson` - Lesson
   * * `SchoolBreak` - SchoolBreak
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: UserTimetableResourcetypeEnum
}

export type TimetableDoctorAppointment = {
  id: number
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "doctor_appointment"
   */
  _type: string
  student_id: string
  doctor_name: string
  /**
   * * `CampusAbsence` - CampusAbsence
   * * `Appointment` - Appointment
   * * `Exam` - Exam
   * * `LeaveRequest` - LeaveRequest
   * * `Lesson` - Lesson
   * * `SchoolBreak` - SchoolBreak
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: UserTimetableResourcetypeEnum
}

export type TimetableExam = {
  id: string
  start: string
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "exam"
   */
  _type: string
  /** @format date */
  date: string | null
  /** @format uuid */
  course: string
  course_str: string
  users: string
  location_str: string
  test_invigilators: SimpleUserWithAvatar[]
  saa_invigilators: SimpleUserWithAvatar[]
  teachers: SimpleUserWithAvatar[]
  exam_session_id: string
  is_allowed: string
  /** via annotate */
  school_year_id: number
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

export type TimetableLeaveCheck = {
  /** @format uuid */
  id: string
  /** @maxLength 1024 */
  destination: string
  /** @maxLength 256 */
  train?: string
  /** @maxLength 256 */
  plane?: string
  /** @maxLength 256 */
  pick_up_location?: string
  /** @format time */
  pick_up_time?: string | null
  comment?: string
  /** @maxLength 64 */
  contact_person: string
  /** @maxLength 128 */
  contact_person_phone: string
  is_completed?: boolean
  is_driver_needed?: boolean
}

export type TimetableLeaveRequest = {
  /** @format uuid */
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "leave_request"
   */
  _type: string
  /** @format uuid */
  student_id: string
  leave_type: LeaveRequestTypeEnum
  leave_type_label: string
  check_in: TimetableLeaveCheck
  check_out: TimetableLeaveCheck
  can_edit: boolean
  can_cancel: boolean
  /**
   * * `CampusAbsence` - CampusAbsence
   * * `Appointment` - Appointment
   * * `Exam` - Exam
   * * `LeaveRequest` - LeaveRequest
   * * `Lesson` - Lesson
   * * `SchoolBreak` - SchoolBreak
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: UserTimetableResourcetypeEnum
}

export type TimetableLesson = {
  /** @format uuid */
  id: string
  start: string
  end: string
  start_date: string
  end_date: string
  /**
   *  type
   * @default "lesson"
   */
  _type: string
  /** @format date */
  date: string
  /** @format uuid */
  course: string
  course_str: string
  users: SimpleUserWithAvatar[]
  room_str: string
  co_room_str: string
  teacher: SimpleUserWithAvatar
  teacher_cover: SimpleUserWithAvatar | null
  co_teacher_1: SimpleUserWithAvatar | null
  co_teacher_2: SimpleUserWithAvatar | null
  is_allowed: boolean
  homework_count: number
  /** via annotate */
  school_year_id: number
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

export type TimetableLog = {
  id: number
  /** @format date-time */
  created: string
  message: string
  changed_fields: string
  timetable: number
  /**
   * * `create` - Create
   * * `delete` - Delete
   * * `update` - Update
   */
  log_type: TimetableLogLogTypeEnum
}

/**
 * * `create` - Create
 * * `delete` - Delete
 * * `update` - Update
 */
export type TimetableLogLogTypeEnum = 'create' | 'delete' | 'update'

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TimetableMentoring = {
  id: number
  ignored_students?: string[]
  timetable: number
  grades_to_assign: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  students_in_section?: number
  is_applied: boolean
  copy_from_previous_quarter?: boolean
  teachers_per_grade: TeachersPerGrade[]
}

/** Child is set instead of add in m2m save, handle blank save_kwargs */
export type TimetableMentoringRequest = {
  ignored_students?: string[]
  timetable: number
  grades_to_assign: number[]
  /**
   * @min 0
   * @max 2147483647
   */
  students_in_section?: number
  copy_from_previous_quarter?: boolean
  teachers_per_grade: TeachersPerGradeRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type TimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

export type TimetableRotaActivity = {
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "rota"
   */
  _type: string
  role: string
  /** @default "" */
  house_name: string
  /** @format uuid */
  user: string
  rota_type: RotaTypeEnum
  /** @format uuid */
  team: string | null
  team_str: string
  date: string
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

export type TimetableSchoolBreak = {
  id: number
  /** @format date */
  start: string
  /** @format date */
  end: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  /**
   *  type
   * @default "school_break"
   */
  _type: string
  name: string
  related_notification_id: string
  /**
   * * `CampusAbsence` - CampusAbsence
   * * `Appointment` - Appointment
   * * `Exam` - Exam
   * * `LeaveRequest` - LeaveRequest
   * * `Lesson` - Lesson
   * * `SchoolBreak` - SchoolBreak
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: UserTimetableResourcetypeEnum
}

export type TimetableSchoolProgramme = {
  id: string
  /** @format date-time */
  start: string
  /** @format date-time */
  end: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   *  type
   * @default "school_programme"
   */
  _type: string
  title: string
  is_allowed: string
  leaders_data: SimpleUser[]
  type: SchoolProgrammeTypeEnum
  is_recovery_type: boolean
  recovery_location: string | null
  recovery_id: string | null
  /**
   * * `Exam` - Exam
   * * `Lesson` - Lesson
   * * `SchoolProgramme` - SchoolProgramme
   * * `Rota` - Rota
   */
  resourcetype: AdminTimetableResourcetypeEnum
}

export type TimetableStats = {
  id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  total_activities: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  generation_task: GenerationTask
  late_activities: string
  students_with_most_hours: string
  teachers_with_most_hours: string
  generation_time: string
}

/**
 * * `live` - Live
 * * `active_in_past` - Active in past
 * * `generation_in_progress` - Generation in progress
 * * `draft` - Draft
 * * `infeasible` - Infeasible data. Please increase time, or verify sections
 * * `failed` - Generation process failed
 */
export type TimetableStatusEnum =
  | 'live'
  | 'active_in_past'
  | 'generation_in_progress'
  | 'draft'
  | 'infeasible'
  | 'failed'

export type TreatmentHistoryItem = {
  id: string
  symptoms: string
  status: string
  /** @format date-time */
  created: string
  /** @format date-time */
  closed_at?: string | null
  absent_now: string
}

export type Tutor = {
  /** @format uuid */
  id: string
  full_name: string
  profiles: string[]
}

export type TwoFactorLogin =
  | TwoFactorLoginResponse
  | TwoFactorLoginCodeResponse
  | TwoFactorLoginUserResponse

export type TwoFactorLoginCodeResponse = {
  code: number
  user: LoginUserResponse
}

export type TwoFactorLoginResponse = {
  token: string
  expiry: string
  user: LoginUserResponse
}

export type TwoFactorLoginUserResponse = {
  user: LoginUserResponse
}

export type UpdateActivitiesRooms = {
  /**
   * @format uri
   * @pattern (?:csv)$
   */
  file: string
}

export type UpdateActivitiesRoomsRequest = {
  /**
   * @format binary
   * @pattern (?:csv)$
   */
  file: File
}

/**
 * * `this` - This
 * * `this_and_next` - This and following
 * * `next` - Following
 */
export type UpdateTypeEnum = 'this' | 'this_and_next' | 'next'

export type User = {
  /** @format uuid */
  id: string
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  first_name?: string
  /** @maxLength 128 */
  last_name?: string
  full_name: string
  /** @maxLength 256 */
  name?: string
  permissions: string
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  profiles: string[]
  staff_type?: StaffTypeEnum | BlankEnum
  /** @format uri */
  avatar: string | null
  has_perms_to_own_objects_only: string
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserAcademicAdminProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserAcademicAdminProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserAcademicHODProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserAcademicHODProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserAdminProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserAdminProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserBoardingDirectorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserBoardingDirectorProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserHealthCoordinatorProfile = {
  id: string
  /** @format uuid */
  user?: string | null
  excluded_from_worse_condition_email?: boolean
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserHealthCoordinatorProfileRequest = {
  /** @format uuid */
  user?: string | null
  excluded_from_worse_condition_email?: boolean
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserIdpAdminProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserIdpAdminProfileRequest = {
  /** @format uuid */
  user?: string | null
}

export type UserInCourse = {
  /** @format uuid */
  id: string
  name: string
  /** @format uri */
  avatar?: string | null
}

export type UserPhoneNumberRequest = {
  /** @minLength 1 */
  phone_number: string
  /** @format date */
  date_of_birth: string
}

export type UserTaskTitle = {
  id: string
  title: string
  added_by?: string
  last_modified_by?: string
}

export type UserTaskTitleRequest = {
  /** @minLength 1 */
  title: string
  added_by?: string
  last_modified_by?: string
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserTeacherProfile = {
  id: string
  /** @maxLength 16 */
  acronym: string
  departments?: number[]
  preferred_room?: number | null
  preferred_rooms_str: string[]
  departments_str: string[]
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  preferred_rooms?: number[]
  custom_schedule?: Record<string, any>
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type UserTeacherProfileRequest = {
  /**
   * @minLength 1
   * @maxLength 16
   */
  acronym: string
  departments?: number[]
  preferred_room?: number | null
  /** Teacher hidden in timetable generator */
  is_hidden_in_generator?: boolean
  preferred_rooms?: number[]
  custom_schedule?: Record<string, any>
  /** @format uuid */
  user?: string | null
}

export type UserTimetable =
  | TimetableCampusAbsence
  | TimetableDoctorAppointment
  | TimetableExam
  | TimetableLeaveRequest
  | TimetableLesson
  | TimetableSchoolBreak
  | TimetableSchoolProgramme
  | TimetableRotaActivity

/**
 * * `CampusAbsence` - CampusAbsence
 * * `Appointment` - Appointment
 * * `Exam` - Exam
 * * `LeaveRequest` - LeaveRequest
 * * `Lesson` - Lesson
 * * `SchoolBreak` - SchoolBreak
 * * `SchoolProgramme` - SchoolProgramme
 * * `Rota` - Rota
 */
export type UserTimetableResourcetypeEnum =
  | 'CampusAbsence'
  | 'Appointment'
  | 'Exam'
  | 'LeaveRequest'
  | 'Lesson'
  | 'SchoolBreak'
  | 'SchoolProgramme'
  | 'Rota'

export type UsersClashesForDateParamsRequest = {
  user_ids: string[]
  /** @format date-time */
  date_from: string
  /** @format date-time */
  date_to: string
  /** @minLength 1 */
  exclude_lesson_id?: string | null
  /** @minLength 1 */
  exclude_exam_id?: string | null
  /** @minLength 1 */
  exclude_programme_id?: string | null
}

export type ValidateCodeRequest = {
  code: number
}

export type ValidateLoginLinkRequest = {
  /** @minLength 1 */
  token: string
}

/**
 * * `wreath` - Wreath
 * * `cap` - Cap
 */
export type VariantEnum = 'wreath' | 'cap'

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type ViewerProfile = {
  id: string
  /** @format uuid */
  user?: string | null
}

/**
 * Moves `UniqueValidator`'s from the validation stage to the save stage.
 * It solves the problem with nested validation for unique fields on update.
 *
 * If you want more details, you can read related issues and articles:
 * https://github.com/beda-software/drf-writable-nested/issues/1
 * http://www.django-rest-framework.org/api-guide/validators/#updating-nested-serializers
 *
 * Example of usage:
 * ```
 *     class Child(models.Model):
 *     field = models.CharField(unique=True)
 *
 *
 * class Parent(models.Model):
 *     child = models.ForeignKey('Child')
 *
 *
 * class ChildSerializer(UniqueFieldsMixin, serializers.ModelSerializer):
 *     class Meta:
 *         model = Child
 *
 *
 * class ParentSerializer(NestedUpdateMixin, serializers.ModelSerializer):
 *     child = ChildSerializer()
 *
 *     class Meta:
 *         model = Parent
 * ```
 *
 * Note: `UniqueFieldsMixin` must be applied only on the serializer
 * which has unique fields.
 *
 * Note: When you are using both mixins
 * (`UniqueFieldsMixin` and `NestedCreateMixin` or `NestedUpdateMixin`)
 * you should put `UniqueFieldsMixin` ahead.
 */
export type ViewerProfileRequest = {
  /** @format uuid */
  user?: string | null
}

/**
 * * `shortness of breath` - Shortness of Breath
 * * `wheezing` - Wheezing
 * * `anxiety` - Anxiety
 * * `confusion` - Confusion
 * * `sudden or severe tiredness` - Sudden or Severe Tiredness
 * * `exhaustion` - Exhaustion
 * * `pale skin` - Pale Skin
 * * `skin rash` - Skin Rash
 * * `skin hive` - Skin Hive
 * * `nausea` - Nausea
 * * `faintness` - Faintness
 * * `inability to concentrate` - Inability to Concentrate
 * * `heart palpitations` - Heart Palpitations
 * * `seizure` - Seizure
 * * `unusual behaviour` - Unusual Behaviour
 * * `sensations` - Sensations
 * * `compulsive bahviour` - Compulsive Behaviour
 * * `obsessive behaviour` - Obsessive Behaviour
 * * `headaches` - Headaches
 * * `infection` - Infection
 * * `body ache or pain` - Body Ache or Pain
 * * `sadness` - Sadness
 * * `depression` - Depression
 */
export type WatchOutForEnum =
  | 'shortness of breath'
  | 'wheezing'
  | 'anxiety'
  | 'confusion'
  | 'sudden or severe tiredness'
  | 'exhaustion'
  | 'pale skin'
  | 'skin rash'
  | 'skin hive'
  | 'nausea'
  | 'faintness'
  | 'inability to concentrate'
  | 'heart palpitations'
  | 'seizure'
  | 'unusual behaviour'
  | 'sensations'
  | 'compulsive bahviour'
  | 'obsessive behaviour'
  | 'headaches'
  | 'infection'
  | 'body ache or pain'
  | 'sadness'
  | 'depression'

export type WeeklyComment = {
  id: number
  author: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 2000 */
  content: string
  /** @format uuid */
  course: string
  /** @format uuid */
  student: string
}

export type WeeklyCommentRequest = {
  /**
   * @minLength 1
   * @maxLength 2000
   */
  content: string
  /** @format uuid */
  course: string
  /** @format uuid */
  student: string
}

/**
 * * `BoardingAnnouncementWidget` - BoardingAnnouncementWidget
 * * `CelebrationWidget` - CelebrationWidget
 * * `EventAgendaWidget` - EventAgendaWidget
 * * `CongratulationsWidget` - CongratulationsWidget
 * * `PosterWidget` - PosterWidget
 * * `StudentArtGalleryWidget` - StudentArtGalleryWidget
 * * `EventInfoWidget` - EventInfoWidget
 * * `EventInfoWithPhotoWidget` - EventInfoWithPhotoWidget
 * * `JournalWidget` - JournalWidget
 * * `MysteryLinkWidget` - MysteryLinkWidget
 * * `MenuWidget` - MenuWidget
 * * `StudentsAnnouncementWidget` - StudentsAnnouncementWidget
 * * `StudentsPicWidget` - StudentsPicWidget
 * * `VariantManualCongratulationsWidget` - VariantManualCongratulationsWidget
 * * `ClockWidget` - ClockWidget
 */
export type WidgetResourcetypeEnum =
  | 'BoardingAnnouncementWidget'
  | 'CelebrationWidget'
  | 'EventAgendaWidget'
  | 'CongratulationsWidget'
  | 'PosterWidget'
  | 'StudentArtGalleryWidget'
  | 'EventInfoWidget'
  | 'EventInfoWithPhotoWidget'
  | 'JournalWidget'
  | 'MysteryLinkWidget'
  | 'MenuWidget'
  | 'StudentsAnnouncementWidget'
  | 'StudentsPicWidget'
  | 'VariantManualCongratulationsWidget'
  | 'ClockWidget'

import type { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then(response => response.data)
  }
}

type Flatten<T> = T extends any[] ? T[number] : T
type ChangeTypeOfKeys<T extends object, Keys extends keyof T, NewType> = {
  [key in keyof T]: key extends Keys ? NewType : T[key]
}
type MapObjectKeysToTrue<ObjectType extends Record<PropertyKey, any>> = {
  [key in keyof ObjectType]?: ObjectType[key] extends Record<PropertyKey, any>
    ? true | MapObjectKeysToTrue<Flatten<ObjectType[key]>>
    : true
}
type FetchKeysType = { [key in PropertyKey]: true | FetchKeysType }
type FetchKeys<ResponseModel> = ResponseModel extends {
  count?: number
  results?: Array<infer DataModel>
}
  ? DataModel extends Record<PropertyKey, any>
    ? MapObjectKeysToTrue<DataModel>
    : DataModel
  : ResponseModel extends Record<PropertyKey, any>
  ? MapObjectKeysToTrue<Flatten<ResponseModel>>
  : ResponseModel

type NestedSafePick<ResponseModel, FetchKeysType> = ResponseModel extends Array<
  infer Model
>
  ? NestedSafePick<Model, FetchKeysType>[]
  : {
      [key in keyof FetchKeysType]: FetchKeysType[key] extends Record<
        PropertyKey,
        any
      >
        ? key extends keyof ResponseModel
          ? ResponseModel[key] extends Array<infer DataModel>
            ? NestedSafePick<DataModel, FetchKeysType[key]>[]
            : NestedSafePick<ResponseModel[key], FetchKeysType[key]>
          : never
        : key extends keyof ResponseModel
        ? ResponseModel[key] extends Array<infer DataModel>
          ? DataModel[]
          : ResponseModel[key]
        : never
    }

type PickKeys<ResponseModel, FetchKeysType> = {} extends FetchKeysType
  ? ResponseModel
  : ResponseModel extends {
      count?: number
      results?: Array<infer DataModel>
    }
  ? ChangeTypeOfKeys<
      ResponseModel,
      'results',
      NestedSafePick<DataModel, FetchKeysType>[]
    >
  : ResponseModel extends Array<infer DataModel>
  ? NestedSafePick<DataModel, FetchKeysType>[]
  : NestedSafePick<ResponseModel, FetchKeysType>

function postprocessQuery(query?: any) {
  if (!query) return query

  const parsedQuery = query
  if (query.fetchKeys) {
    function getStringifiedQueryKeys(keys: FetchKeysType) {
      let query = '{'
      Object.entries(keys).forEach(([key, value], index) => {
        if (typeof value === 'object') {
          const keyToAdd = `${key}${getStringifiedQueryKeys(value)}`
          query += index > 0 ? `,${keyToAdd}` : keyToAdd
        } else {
          query += index > 0 ? `,${key}` : key
        }
      })
      return query + '}'
    }

    parsedQuery.query = getStringifiedQueryKeys(query.fetchKeys)
  }

  delete parsedQuery.fetchKeys
  return parsedQuery
}

/**
 * @title API* @version 1.0.0 (v1)*/
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  achievements = {
    /**
     * No description
     *
     * @tags achievements
     * @name AchievementsList
     * @request GET:/achievements/
     */
    achievementsList: <T extends FetchKeys<PaginatedAchievementList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAchievementList, T>, any>({
        path: `/achievements/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags achievements
     * @name AchievementsRetrieve
     * @request GET:/achievements/{id}/
     */ achievementsRetrieve: <T extends FetchKeys<Achievement>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Achievement, T>, any>({
        path: `/achievements/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags achievements
     * @name AchievementsStudentsList
     * @request GET:/achievements/students/{studentId}/
     */ achievementsStudentsList: <
      T extends FetchKeys<PaginatedStudentAchievementList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentAchievementList, T>, any>({
        path: `/achievements/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags achievements
     * @name AchievementsStudentsUpdate
     * @request PUT:/achievements/students/{studentId}/
     */ achievementsStudentsUpdate: <T extends FetchKeys<StudentAchievement>>(
      studentId: string,
      data: StudentAchievementRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAchievement, T>, any>({
        path: `/achievements/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags achievements
     * @name AchievementsStudentsPartialUpdate
     * @request PATCH:/achievements/students/{studentId}/
     */ achievementsStudentsPartialUpdate: <
      T extends FetchKeys<StudentAchievement>
    >(
      studentId: string,
      data: PatchedStudentAchievementRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAchievement, T>, any>({
        path: `/achievements/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  activities = {
    /**
     * No description
     *
     * @tags activities
     * @name ActivitiesEnrollmentsRetrieve
     * @request GET:/activities/enrollments/
     */
    activitiesEnrollmentsRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/activities/enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  adminTimetable = {
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags admin-timetable
     * @name AdminTimetableList
     * @request GET:/admin-timetable/
     */
    adminTimetableList: <T extends FetchKeys<AdminTimetable[]>>(
      query?: {
        /** @format date */
        date?: string
        students?: string[]
        teachers?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetable[], T>, any>({
        path: `/admin-timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags admin-timetable
     * @name AdminTimetablePdfRetrieve
     * @request GET:/admin-timetable/pdf/
     */ adminTimetablePdfRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin-timetable/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  admin = {
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonList
     * @request GET:/admin/activities/lesson/
     */
    adminActivitiesLessonList: <
      T extends FetchKeys<PaginatedAdminTimetableLessonList>
    >(
      query?: {
        /** @format uuid */
        activity__course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        during_exams?: string[]
        hide_past_lessons?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string
        students?: string[]
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teacher_cover?: string
        teacher_cover__isnull?: boolean
        /** @format uuid */
        teacher_covered?: string
        teachers?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminTimetableLessonList, T>, any>({
        path: `/admin/activities/lesson/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonRetrieve
     * @request GET:/admin/activities/lesson/{id}/
     */ adminActivitiesLessonRetrieve: <
      T extends FetchKeys<AdminTimetableLesson>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLesson, T>, any>({
        path: `/admin/activities/lesson/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonUpdate
     * @request PUT:/admin/activities/lesson/{id}/
     */ adminActivitiesLessonUpdate: <
      T extends FetchKeys<AdminTimetableLessonUpdate>
    >(
      id: string,
      data: AdminTimetableLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLessonUpdate, T>, any>({
        path: `/admin/activities/lesson/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonPartialUpdate
     * @request PATCH:/admin/activities/lesson/{id}/
     */ adminActivitiesLessonPartialUpdate: <
      T extends FetchKeys<AdminTimetableLessonUpdate>
    >(
      id: string,
      data: PatchedAdminTimetableLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLessonUpdate, T>, any>({
        path: `/admin/activities/lesson/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonAssignRoomUpdate
     * @request PUT:/admin/activities/lesson/{id}/assign-room/
     */ adminActivitiesLessonAssignRoomUpdate: <
      T extends FetchKeys<AdminTimetableLessonRoomAssignment>
    >(
      id: string,
      data: AdminTimetableLessonRoomAssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLessonRoomAssignment, T>, any>({
        path: `/admin/activities/lesson/${id}/assign-room/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminActivitiesLessonSplitLessonCreate
     * @request POST:/admin/activities/lesson/{id}/split-lesson/
     */ adminActivitiesLessonSplitLessonCreate: <
      T extends FetchKeys<AdminTimetableLesson>
    >(
      id: string,
      data: AdminTimetableLessonRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTimetableLesson, T>, any>({
        path: `/admin/activities/lesson/${id}/split-lesson/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesList
     * @request GET:/admin/attendances/absences/
     */ adminAttendancesAbsencesList: <
      T extends FetchKeys<PaginatedAbsenceList>
    >(
      query?: {
        /**
         * * `late` - Late
         * * `absent_excused` - Absent excused
         * * `absent_unexcused` - Absent unexcused
         * * `sick` - Sick
         */
        absence_type?: 'absent_excused' | 'absent_unexcused' | 'late' | 'sick'
        /** @format date */
        end_date_after?: string
        /** @format date */
        end_date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format date */
        start_date_after?: string
        /** @format date */
        start_date_before?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAbsenceList, T>, any>({
        path: `/admin/attendances/absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesCreate
     * @request POST:/admin/attendances/absences/
     */ adminAttendancesAbsencesCreate: <T extends FetchKeys<Absence>>(
      data: AbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesRetrieve
     * @request GET:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesRetrieve: <T extends FetchKeys<Absence>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesUpdate
     * @request PUT:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesUpdate: <T extends FetchKeys<Absence>>(
      id: string,
      data: AbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesPartialUpdate
     * @request PATCH:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesPartialUpdate: <T extends FetchKeys<Absence>>(
      id: string,
      data: PatchedAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Absence, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminAttendancesAbsencesDestroy
     * @request DELETE:/admin/attendances/absences/{id}/
     */ adminAttendancesAbsencesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/attendances/absences/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsList
     * @request GET:/admin/attendances/alerts/
     */ adminAttendancesAlertsList: <
      T extends FetchKeys<PaginatedAcademicAttendanceAlertList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        is_notification_sent?: boolean
        is_read?: boolean
        /** @format date */
        lesson_date?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `unresolved` - Unresolved
         * * `resolved` - Resolved
         */
        status?: 'resolved' | 'unresolved'
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAcademicAttendanceAlertList, T>, any>({
        path: `/admin/attendances/alerts/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsRetrieve
     * @request GET:/admin/attendances/alerts/{id}/
     */ adminAttendancesAlertsRetrieve: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsMarkAsReadCreate
     * @request POST:/admin/attendances/alerts/{id}/mark-as-read/
     */ adminAttendancesAlertsMarkAsReadCreate: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      id: string,
      data: AcademicAttendanceAlertRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/${id}/mark-as-read/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsSendNotificationCreate
     * @request POST:/admin/attendances/alerts/{id}/send-notification/
     */ adminAttendancesAlertsSendNotificationCreate: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      id: string,
      data: AcademicAttendanceAlertRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/${id}/send-notification/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags admin
     * @name AdminAttendancesAlertsAvailableFiltersRetrieve
     * @request GET:/admin/attendances/alerts/available-filters/
     */ adminAttendancesAlertsAvailableFiltersRetrieve: <
      T extends FetchKeys<AcademicAttendanceAlert>
    >(
      query?: {
        /** @format uuid */
        course?: string
        is_notification_sent?: boolean
        is_read?: boolean
        /** @format date */
        lesson_date?: string
        /**
         * * `unresolved` - Unresolved
         * * `resolved` - Resolved
         */
        status?: 'resolved' | 'unresolved'
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendanceAlert, T>, any>({
        path: `/admin/attendances/alerts/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarList
     * @request GET:/admin/core/calendar/
     */ adminCoreCalendarList: <
      T extends FetchKeys<PaginatedCalendarEventList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCalendarEventList, T>, any>({
        path: `/admin/core/calendar/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarCreate
     * @request POST:/admin/core/calendar/
     */ adminCoreCalendarCreate: <T extends FetchKeys<CalendarEvent>>(
      data: CalendarEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarRetrieve
     * @request GET:/admin/core/calendar/{id}/
     */ adminCoreCalendarRetrieve: <T extends FetchKeys<CalendarEvent>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarUpdate
     * @request PUT:/admin/core/calendar/{id}/
     */ adminCoreCalendarUpdate: <T extends FetchKeys<CalendarEvent>>(
      id: number,
      data: CalendarEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarPartialUpdate
     * @request PATCH:/admin/core/calendar/{id}/
     */ adminCoreCalendarPartialUpdate: <T extends FetchKeys<CalendarEvent>>(
      id: number,
      data: PatchedCalendarEventRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarDestroy
     * @request DELETE:/admin/core/calendar/{id}/
     */ adminCoreCalendarDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/core/calendar/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreCalendarTypesRetrieve
     * @request GET:/admin/core/calendar/types/
     */ adminCoreCalendarTypesRetrieve: <T extends FetchKeys<CalendarEvent>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CalendarEvent, T>, any>({
        path: `/admin/core/calendar/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreSettingsRetrieve
     * @request GET:/admin/core/settings/
     */ adminCoreSettingsRetrieve: <T extends FetchKeys<AdminSiteSettings>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSiteSettings, T>, any>({
        path: `/admin/core/settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreSettingsUpdate
     * @request PUT:/admin/core/settings/
     */ adminCoreSettingsUpdate: <T extends FetchKeys<AdminSiteSettings>>(
      data: AdminSiteSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSiteSettings, T>, any>({
        path: `/admin/core/settings/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminCoreSettingsPartialUpdate
     * @request PATCH:/admin/core/settings/
     */ adminCoreSettingsPartialUpdate: <
      T extends FetchKeys<AdminSiteSettings>
    >(
      data: PatchedAdminSiteSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSiteSettings, T>, any>({
        path: `/admin/core/settings/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminDashboardContentCountersRetrieve
     * @request GET:/admin/dashboard/content-counters/
     */ adminDashboardContentCountersRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/dashboard/content-counters/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminDashboardUserRetrieve
     * @request GET:/admin/dashboard/user/
     */ adminDashboardUserRetrieve: <T extends FetchKeys<AdminDashboardUser>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminDashboardUser, T>, any>({
        path: `/admin/dashboard/user/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingCeleryTaskStatusList
     * @request GET:/admin/importing/celery-task-status/
     */ adminImportingCeleryTaskStatusList: <
      T extends FetchKeys<PaginatedAdminCeleryTaskStatusListList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `PENDING` - PENDING
         * * `RECEIVED` - RECEIVED
         * * `STARTED` - STARTED
         * * `PROGRESS` - PROGRESS
         * * `FINALIZE` - FINALIZE
         * * `SUCCESS` - SUCCESS
         * * `FAILURE` - FAILURE
         * * `REVOKED` - REVOKED
         * * `REJECTED` - REJECTED
         * * `RETRY` - RETRY
         * * `IGNORED` - IGNORED
         */
        state?:
          | 'FAILURE'
          | 'FINALIZE'
          | 'IGNORED'
          | 'PENDING'
          | 'PROGRESS'
          | 'RECEIVED'
          | 'REJECTED'
          | 'RETRY'
          | 'REVOKED'
          | 'STARTED'
          | 'SUCCESS'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminCeleryTaskStatusListList, T>, any>({
        path: `/admin/importing/celery-task-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingCeleryTaskStatusRetrieve
     * @request GET:/admin/importing/celery-task-status/{id}/
     */ adminImportingCeleryTaskStatusRetrieve: <
      T extends FetchKeys<AdminCeleryTaskStatus>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminCeleryTaskStatus, T>, any>({
        path: `/admin/importing/celery-task-status/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingCeleryTaskStatusRevokeTaskCreate
     * @request POST:/admin/importing/celery-task-status/{id}/revoke-task/
     */ adminImportingCeleryTaskStatusRevokeTaskCreate: <
      T extends FetchKeys<AdminCeleryTaskStatus>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminCeleryTaskStatus, T>, any>({
        path: `/admin/importing/celery-task-status/${id}/revoke-task/`,
        method: 'POST',
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminImportingImportTimetableCreate
     * @request POST:/admin/importing/import-timetable/
     */ adminImportingImportTimetableCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/importing/import-timetable/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsList
     * @request GET:/admin/periods/years/
     */ adminPeriodsYearsList: <
      T extends FetchKeys<PaginatedAdminSchoolYearList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminSchoolYearList, T>, any>({
        path: `/admin/periods/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsCreate
     * @request POST:/admin/periods/years/
     */ adminPeriodsYearsCreate: <T extends FetchKeys<AdminSchoolYear>>(
      data: AdminSchoolYearRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsRetrieve
     * @request GET:/admin/periods/years/{id}/
     */ adminPeriodsYearsRetrieve: <T extends FetchKeys<AdminSchoolYear>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsUpdate
     * @request PUT:/admin/periods/years/{id}/
     */ adminPeriodsYearsUpdate: <T extends FetchKeys<AdminSchoolYear>>(
      id: number,
      data: AdminSchoolYearRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsPartialUpdate
     * @request PATCH:/admin/periods/years/{id}/
     */ adminPeriodsYearsPartialUpdate: <T extends FetchKeys<AdminSchoolYear>>(
      id: number,
      data: PatchedAdminSchoolYearRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminSchoolYear, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminPeriodsYearsDestroy
     * @request DELETE:/admin/periods/years/{id}/
     */ adminPeriodsYearsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/periods/years/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminStudentsClashesCreate
     * @request POST:/admin/students/clashes/
     */ adminStudentsClashesCreate: <T extends FetchKeys<ManyStudentsClashes>>(
      data: StudentsClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyStudentsClashes, T>, any>({
        path: `/admin/students/clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminStudentsClashesHaveStudentsClashesCreate
     * @request POST:/admin/students/clashes/have-students-clashes/
     */ adminStudentsClashesHaveStudentsClashesCreate: <
      T extends FetchKeys<HaveStudentsClashes>
    >(
      data: StudentsClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HaveStudentsClashes, T>, any>({
        path: `/admin/students/clashes/have-students-clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersList
     * @request GET:/admin/teachers/
     */ adminTeachersList: <T extends FetchKeys<PaginatedAdminTeacherList>>(
      query?: {
        /** Multiple values may be separated by commas. */
        available_in_gap?: string[]
        departments?: number[]
        has_activities_in_current_quarter?: boolean
        is_hidden_in_generator?: boolean
        order_by_department?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        /**
         * * `academic` - Academic
         * * `boarding` - Boarding
         * * `tae` - TAE
         * * `other` - Other
         */
        user__staff_type?: 'academic' | 'boarding' | 'other' | 'tae'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminTeacherList, T>, any>({
        path: `/admin/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersCreate
     * @request POST:/admin/teachers/
     */ adminTeachersCreate: <T extends FetchKeys<AdminTeacher>>(
      data: AdminTeacherRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersRetrieve
     * @request GET:/admin/teachers/{user}/
     */ adminTeachersRetrieve: <T extends FetchKeys<AdminTeacher>>(
      user: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersUpdate
     * @request PUT:/admin/teachers/{user}/
     */ adminTeachersUpdate: <T extends FetchKeys<AdminTeacher>>(
      user: string,
      data: AdminTeacherRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersPartialUpdate
     * @request PATCH:/admin/teachers/{user}/
     */ adminTeachersPartialUpdate: <T extends FetchKeys<AdminTeacher>>(
      user: string,
      data: PatchedAdminTeacherRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTeacher, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersDestroy
     * @request DELETE:/admin/teachers/{user}/
     */ adminTeachersDestroy: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/teachers/${user}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags admin
     * @name AdminTeachersCountedLessonsRetrieve
     * @request GET:/admin/teachers/counted-lessons/
     */ adminTeachersCountedLessonsRetrieve: <
      T extends FetchKeys<CountedLessonsParams>
    >(
      query?: {
        /** Multiple values may be separated by commas. */
        available_in_gap?: string[]
        departments?: number[]
        has_activities_in_current_quarter?: boolean
        is_hidden_in_generator?: boolean
        order_by_department?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        /**
         * * `academic` - Academic
         * * `boarding` - Boarding
         * * `tae` - TAE
         * * `other` - Other
         */
        user__staff_type?: 'academic' | 'boarding' | 'other' | 'tae'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CountedLessonsParams, T>, any>({
        path: `/admin/teachers/counted-lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersList
     * @request GET:/admin/users/
     */ adminUsersList: <T extends FetchKeys<PaginatedAdminUserListList>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminUserListList, T>, any>({
        path: `/admin/users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersCreate
     * @request POST:/admin/users/
     */ adminUsersCreate: <T extends FetchKeys<AdminUser>>(
      data: AdminUserChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersRetrieve
     * @request GET:/admin/users/{id}/
     */ adminUsersRetrieve: <T extends FetchKeys<AdminUser>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersUpdate
     * @request PUT:/admin/users/{id}/
     */ adminUsersUpdate: <T extends FetchKeys<AdminUser>>(
      id: string,
      data: AdminUserChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersPartialUpdate
     * @request PATCH:/admin/users/{id}/
     */ adminUsersPartialUpdate: <T extends FetchKeys<AdminUser>>(
      id: string,
      data: PatchedAdminUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersDestroy
     * @request DELETE:/admin/users/{id}/
     */ adminUsersDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/admin/users/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersSetPasswordCreate
     * @request POST:/admin/users/{id}/set-password/
     */ adminUsersSetPasswordCreate: <
      T extends FetchKeys<AdminUserPasswordChange>
    >(
      id: string,
      data: AdminUserPasswordChangeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUserPasswordChange, T>, any>({
        path: `/admin/users/${id}/set-password/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersToggleActiveCreate
     * @request POST:/admin/users/{id}/toggle-active/
     */ adminUsersToggleActiveCreate: <T extends FetchKeys<AdminUser>>(
      id: string,
      data: AdminUserRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminUser, T>, any>({
        path: `/admin/users/${id}/toggle-active/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminUsersClashesCreate
     * @request POST:/admin/users/clashes/
     */ adminUsersClashesCreate: <T extends FetchKeys<ManyStaffUsersClashes>>(
      data: UsersClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyStaffUsersClashes, T>, any>({
        path: `/admin/users/clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags admin
     * @name AdminUsersClashesHaveStaffUsersClashesCreate
     * @request POST:/admin/users/clashes/have-staff-users-clashes/
     */ adminUsersClashesHaveStaffUsersClashesCreate: <
      T extends FetchKeys<HaveStaffUsersClashes>
    >(
      data: UsersClashesForDateParamsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HaveStaffUsersClashes, T>, any>({
        path: `/admin/users/clashes/have-staff-users-clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersGendersList
     * @request GET:/admin/users/genders/
     */ adminUsersGendersList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/admin/users/genders/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersProfilesList
     * @request GET:/admin/users/profiles/
     */ adminUsersProfilesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/admin/users/profiles/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersTeachersAndTutorsRetrieve
     * @request GET:/admin/users/teachers-and-tutors/
     */ adminUsersTeachersAndTutorsRetrieve: <T extends FetchKeys<SimpleUser>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleUser, T>, any>({
        path: `/admin/users/teachers-and-tutors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Store requests in Log models with action description, objects description & user
     *
     * @tags admin
     * @name AdminUsersTypesList
     * @request GET:/admin/users/types/
     */ adminUsersTypesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        groups?: number[]
        is_active?: boolean
        is_staff?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        profile?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/admin/users/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  aims = {
    /**
     * No description
     *
     * @tags aims
     * @name AimsList
     * @request GET:/aims/
     */
    aimsList: <T extends FetchKeys<PaginatedAimList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAimList, T>, any>({
        path: `/aims/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsAimForCourseRetrieve
     * @request GET:/aims/aim-for-course/
     */ aimsAimForCourseRetrieve: <T extends FetchKeys<StudentAimForCourse>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAimForCourse, T>, any>({
        path: `/aims/aim-for-course/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsAimForCourseUpdate
     * @request PUT:/aims/aim-for-course/
     */ aimsAimForCourseUpdate: <T extends FetchKeys<StudentAimForCourse>>(
      data: StudentAimForCourseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAimForCourse, T>, any>({
        path: `/aims/aim-for-course/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsAimForCoursePartialUpdate
     * @request PATCH:/aims/aim-for-course/
     */ aimsAimForCoursePartialUpdate: <
      T extends FetchKeys<StudentAimForCourse>
    >(
      data: PatchedStudentAimForCourseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAimForCourse, T>, any>({
        path: `/aims/aim-for-course/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsCalcGradeCreate
     * @request POST:/aims/calc-grade/
     */ aimsCalcGradeCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/aims/calc-grade/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsForStudentList
     * @request GET:/aims/for-student/
     */ aimsForStudentList: <T extends FetchKeys<PaginatedAimList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAimList, T>, any>({
        path: `/aims/for-student/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags aims
     * @name AimsForStudentRetrieve
     * @request GET:/aims/for-student/{id}/
     */ aimsForStudentRetrieve: <T extends FetchKeys<Aim>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Aim, T>, any>({
        path: `/aims/for-student/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  allergies = {
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesList
     * @request GET:/allergies/
     */
    allergiesList: <T extends FetchKeys<PaginatedAllergyList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAllergyList, T>, any>({
        path: `/allergies/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesCreate
     * @request POST:/allergies/
     */ allergiesCreate: <T extends FetchKeys<Allergy>>(
      data: AllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Allergy, T>, any>({
        path: `/allergies/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesRetrieve
     * @request GET:/allergies/{id}/
     */ allergiesRetrieve: <T extends FetchKeys<Allergy>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Allergy, T>, any>({
        path: `/allergies/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesUpdate
     * @request PUT:/allergies/{id}/
     */ allergiesUpdate: <T extends FetchKeys<Allergy>>(
      id: number,
      data: AllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Allergy, T>, any>({
        path: `/allergies/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesPartialUpdate
     * @request PATCH:/allergies/{id}/
     */ allergiesPartialUpdate: <T extends FetchKeys<Allergy>>(
      id: number,
      data: PatchedAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Allergy, T>, any>({
        path: `/allergies/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesList
     * @request GET:/allergies/student-allergies/
     */ allergiesStudentAllergiesList: <T extends FetchKeys<StudentAllergy[]>>(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy[], T>, any>({
        path: `/allergies/student-allergies/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesCreate
     * @request POST:/allergies/student-allergies/
     */ allergiesStudentAllergiesCreate: <T extends FetchKeys<StudentAllergy>>(
      data: StudentAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesRetrieve
     * @request GET:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesRetrieve: <
      T extends FetchKeys<StudentAllergy>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesUpdate
     * @request PUT:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesUpdate: <T extends FetchKeys<StudentAllergy>>(
      id: number,
      data: StudentAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesPartialUpdate
     * @request PATCH:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesPartialUpdate: <
      T extends FetchKeys<StudentAllergy>
    >(
      id: number,
      data: PatchedStudentAllergyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAllergy, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesDestroy
     * @request DELETE:/allergies/student-allergies/{id}/
     */ allergiesStudentAllergiesDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/allergies/student-allergies/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags allergies
     * @name AllergiesStudentAllergiesChoicesRetrieve
     * @request GET:/allergies/student-allergies/choices/
     */ allergiesStudentAllergiesChoicesRetrieve: <
      T extends FetchKeys<AllergyChoicesResponse>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AllergyChoicesResponse, T>, any>({
        path: `/allergies/student-allergies/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  assignments = {
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsList
     * @request GET:/assignments/
     */
    assignmentsList: <T extends FetchKeys<PaginatedAssignmentList>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        quarter__semester?: number
        /** @format uuid */
        student_items__student?: string
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAssignmentList, T>, any>({
        path: `/assignments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsCreate
     * @request POST:/assignments/
     */ assignmentsCreate: <T extends FetchKeys<Assignment>>(
      data: AssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsRetrieve
     * @request GET:/assignments/{id}/
     */ assignmentsRetrieve: <T extends FetchKeys<Assignment>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsUpdate
     * @request PUT:/assignments/{id}/
     */ assignmentsUpdate: <T extends FetchKeys<Assignment>>(
      id: string,
      data: AssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsPartialUpdate
     * @request PATCH:/assignments/{id}/
     */ assignmentsPartialUpdate: <T extends FetchKeys<Assignment>>(
      id: string,
      data: PatchedAssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Assignment, T>, any>({
        path: `/assignments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsDestroy
     * @request DELETE:/assignments/{id}/
     */ assignmentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/assignments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsList
     * @request GET:/assignments/items/
     */ assignmentsItemsList: <
      T extends FetchKeys<PaginatedAssignmentGradeList>
    >(
      query?: {
        /** @format uuid */
        assignment__course?: string
        assignment__quarter?: number
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAssignmentGradeList, T>, any>({
        path: `/assignments/items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsCreate
     * @request POST:/assignments/items/
     */ assignmentsItemsCreate: <T extends FetchKeys<AssignmentGrade>>(
      data: AssignmentGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsRetrieve
     * @request GET:/assignments/items/{id}/
     */ assignmentsItemsRetrieve: <T extends FetchKeys<AssignmentGrade>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsUpdate
     * @request PUT:/assignments/items/{id}/
     */ assignmentsItemsUpdate: <T extends FetchKeys<AssignmentGrade>>(
      id: string,
      data: AssignmentGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsPartialUpdate
     * @request PATCH:/assignments/items/{id}/
     */ assignmentsItemsPartialUpdate: <T extends FetchKeys<AssignmentGrade>>(
      id: string,
      data: PatchedAssignmentGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentGrade, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsItemsDestroy
     * @request DELETE:/assignments/items/{id}/
     */ assignmentsItemsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/assignments/items/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsReportsRetrieve
     * @request GET:/assignments/reports/
     */ assignmentsReportsRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/assignments/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags assignments
     * @name AssignmentsTypesRetrieve
     * @request GET:/assignments/types/
     */ assignmentsTypesRetrieve: <T extends FetchKeys<AssignmentType>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        quarter?: number
        quarter__semester?: number
        /** @format uuid */
        student_items__student?: string
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AssignmentType, T>, any>({
        path: `/assignments/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  attachments = {
    /**
     * No description
     *
     * @tags attachments
     * @name AttachmentsList
     * @request GET:/attachments/{attachmentType}/
     */
    attachmentsList: <T extends FetchKeys<PaginatedAttachmentList>>(
      attachmentType: string,
      query?: {
        /** Multiple values may be separated by commas. */
        id__in?: number[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAttachmentList, T>, any>({
        path: `/attachments/${attachmentType}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attachments
     * @name AttachmentsCreate
     * @request POST:/attachments/{attachmentType}/
     */ attachmentsCreate: <T extends FetchKeys<Attachment>>(
      attachmentType: string,
      data: AttachmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Attachment, T>, any>({
        path: `/attachments/${attachmentType}/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      })
  }
  attendances = {
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesList
     * @request GET:/attendances/
     */
    attendancesList: <T extends FetchKeys<PaginatedAcademicAttendanceList>>(
      query?: {
        /** @format uuid */
        lesson?: string
        /** @format uuid */
        lesson__activity__course?: string
        /** @format date */
        lesson__date_after?: string
        /** @format date */
        lesson__date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAcademicAttendanceList, T>, any>({
        path: `/attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesUpdate
     * @request PUT:/attendances/{id}/
     */ attendancesUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: AcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesPartialUpdate
     * @request PATCH:/attendances/{id}/
     */ attendancesPartialUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: PatchedAcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Return attendances in cumulative format. Requires student in query params. :return response [ { "course": course_name, "attendances_count": 5, "is_present": 2, "is_late": 2, "is_absent_excused": 0, "is_absent_unexcused": 0, "is_sick": 1, "missed_lessons_count": 1, "missed_lessons_percentage": 20.0 } ]
     *
     * @tags attendances
     * @name AttendancesCumulativeRetrieve
     * @request GET:/attendances/cumulative/
     */ attendancesCumulativeRetrieve: <
      T extends FetchKeys<AcademicAttendance>
    >(
      query?: {
        /** @format uuid */
        lesson?: string
        /** @format uuid */
        lesson__activity__course?: string
        /** @format date */
        lesson__date_after?: string
        /** @format date */
        lesson__date_before?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/attendances/cumulative/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags attendances
     * @name AttendancesReportsRetrieve
     * @request GET:/attendances/reports/
     */ attendancesReportsRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/attendances/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  billings = {
    /**
     * No description
     *
     * @tags billings
     * @name BillingsAbacusProductsList
     * @request GET:/billings/abacus-products/
     */
    billingsAbacusProductsList: <
      T extends FetchKeys<PaginatedAbacusInvoiceProductList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAbacusInvoiceProductList, T>, any>({
        path: `/billings/abacus-products/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsAbacusProductsCreate
     * @request POST:/billings/abacus-products/
     */ billingsAbacusProductsCreate: <
      T extends FetchKeys<AbacusInvoiceProduct>
    >(
      data: AbacusInvoiceProductRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AbacusInvoiceProduct, T>, any>({
        path: `/billings/abacus-products/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsAbacusProductsRetrieve
     * @request GET:/billings/abacus-products/{id}/
     */ billingsAbacusProductsRetrieve: <
      T extends FetchKeys<AbacusInvoiceProduct>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AbacusInvoiceProduct, T>, any>({
        path: `/billings/abacus-products/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsAbacusProductsUpdate
     * @request PUT:/billings/abacus-products/{id}/
     */ billingsAbacusProductsUpdate: <
      T extends FetchKeys<AbacusInvoiceProduct>
    >(
      id: number,
      data: AbacusInvoiceProductRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AbacusInvoiceProduct, T>, any>({
        path: `/billings/abacus-products/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsAbacusProductsPartialUpdate
     * @request PATCH:/billings/abacus-products/{id}/
     */ billingsAbacusProductsPartialUpdate: <
      T extends FetchKeys<AbacusInvoiceProduct>
    >(
      id: number,
      data: PatchedAbacusInvoiceProductRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AbacusInvoiceProduct, T>, any>({
        path: `/billings/abacus-products/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsAbacusProductsDestroy
     * @request DELETE:/billings/abacus-products/{id}/
     */ billingsAbacusProductsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/billings/abacus-products/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsList
     * @request GET:/billings/reports/
     */ billingsReportsList: <T extends FetchKeys<PaginatedBillingReportList>>(
      query?: {
        /** @format uuid */
        author?: string
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        school_year?: number
        /** A search term. */
        search?: string
        /**
         * * `quarter` - Quarter
         * * `school_year` - School Year
         */
        timeframe_type?: 'quarter' | 'school_year'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedBillingReportList, T>, any>({
        path: `/billings/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsCreate
     * @request POST:/billings/reports/
     */ billingsReportsCreate: <T extends FetchKeys<BillingReportCreate>>(
      data: BillingReportCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReportCreate, T>, any>({
        path: `/billings/reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsRetrieve
     * @request GET:/billings/reports/{id}/
     */ billingsReportsRetrieve: <T extends FetchKeys<BillingReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsUpdate
     * @request PUT:/billings/reports/{id}/
     */ billingsReportsUpdate: <T extends FetchKeys<BillingReport>>(
      id: string,
      data: BillingReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsPartialUpdate
     * @request PATCH:/billings/reports/{id}/
     */ billingsReportsPartialUpdate: <T extends FetchKeys<BillingReport>>(
      id: string,
      data: PatchedBillingReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsDestroy
     * @request DELETE:/billings/reports/{id}/
     */ billingsReportsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/billings/reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsDownloadRetrieve
     * @request GET:/billings/reports/{id}/download/
     */ billingsReportsDownloadRetrieve: <T extends FetchKeys<BillingReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/download/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsItemsRetrieve
     * @request GET:/billings/reports/{id}/items/
     */ billingsReportsItemsRetrieve: <T extends FetchKeys<BillingReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/${id}/items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsAuthorsRetrieve
     * @request GET:/billings/reports/authors/
     */ billingsReportsAuthorsRetrieve: <T extends FetchKeys<BillingReport>>(
      query?: {
        /** @format uuid */
        author?: string
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        school_year?: number
        /** A search term. */
        search?: string
        /**
         * * `quarter` - Quarter
         * * `school_year` - School Year
         */
        timeframe_type?: 'quarter' | 'school_year'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/authors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Handler method for HTTP 'OPTIONS' request.
     *
     * @tags billings
     * @name BillingsReportsOptionsRetrieve
     * @request GET:/billings/reports/options/
     */ billingsReportsOptionsRetrieve: <T extends FetchKeys<BillingReport>>(
      query?: {
        /** @format uuid */
        author?: string
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        school_year?: number
        /** A search term. */
        search?: string
        /**
         * * `quarter` - Quarter
         * * `school_year` - School Year
         */
        timeframe_type?: 'quarter' | 'school_year'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReport, T>, any>({
        path: `/billings/reports/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags billings
     * @name BillingsReportsPreviewCreate
     * @request POST:/billings/reports/preview/
     */ billingsReportsPreviewCreate: <
      T extends FetchKeys<BillingReportCreate>
    >(
      data: BillingReportCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BillingReportCreate, T>, any>({
        path: `/billings/reports/preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  boarding = {
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsList
     * @request GET:/boarding/accommodations/
     */
    boardingAccommodationsList: <
      T extends FetchKeys<PaginatedAccommodationList>
    >(
      query?: {
        current_or_future?: boolean
        house?: number
        is_temporary_movement?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAccommodationList, T>, any>({
        path: `/boarding/accommodations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsCreate
     * @request POST:/boarding/accommodations/
     */ boardingAccommodationsCreate: <T extends FetchKeys<Accommodation>>(
      data: AccommodationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsRetrieve
     * @request GET:/boarding/accommodations/{id}/
     */ boardingAccommodationsRetrieve: <T extends FetchKeys<Accommodation>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsUpdate
     * @request PUT:/boarding/accommodations/{id}/
     */ boardingAccommodationsUpdate: <T extends FetchKeys<Accommodation>>(
      id: number,
      data: AccommodationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsPartialUpdate
     * @request PATCH:/boarding/accommodations/{id}/
     */ boardingAccommodationsPartialUpdate: <
      T extends FetchKeys<Accommodation>
    >(
      id: number,
      data: PatchedAccommodationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Accommodation, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAccommodationsDestroy
     * @request DELETE:/boarding/accommodations/{id}/
     */ boardingAccommodationsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/accommodations/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesList
     * @request GET:/boarding/attendances/
     */ boardingAttendancesList: <T extends FetchKeys<BoardingAttendance[]>>(
      query?: {
        /**
         * * `present` - Present
         * * `late` - Late
         * * `sick` - Sick
         * * `absent_excused` - Absent exc.
         * * `absent_unexcused` - Absent unexc.
         */
        mark?:
          | 'absent_excused'
          | 'absent_unexcused'
          | 'late'
          | 'present'
          | 'sick'
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance[], T>, any>({
        path: `/boarding/attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesCreate
     * @request POST:/boarding/attendances/
     */ boardingAttendancesCreate: <T extends FetchKeys<BoardingAttendance>>(
      data: BoardingAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesRetrieve
     * @request GET:/boarding/attendances/{id}/
     */ boardingAttendancesRetrieve: <T extends FetchKeys<BoardingAttendance>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesUpdate
     * @request PUT:/boarding/attendances/{id}/
     */ boardingAttendancesUpdate: <T extends FetchKeys<BoardingAttendance>>(
      id: string,
      data: BoardingAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesPartialUpdate
     * @request PATCH:/boarding/attendances/{id}/
     */ boardingAttendancesPartialUpdate: <
      T extends FetchKeys<BoardingAttendance>
    >(
      id: string,
      data: PatchedBoardingAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendance, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesDestroy
     * @request DELETE:/boarding/attendances/{id}/
     */ boardingAttendancesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/attendances/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesMarksList
     * @request GET:/boarding/attendances/marks/
     */ boardingAttendancesMarksList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        /**
         * * `present` - Present
         * * `late` - Late
         * * `sick` - Sick
         * * `absent_excused` - Absent exc.
         * * `absent_unexcused` - Absent unexc.
         */
        mark?:
          | 'absent_excused'
          | 'absent_unexcused'
          | 'late'
          | 'present'
          | 'sick'
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/attendances/marks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsList
     * @request GET:/boarding/attendances/reports/
     */ boardingAttendancesReportsList: <
      T extends FetchKeys<PaginatedBoardingAttendanceReportListList>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'
        /** @format uuid */
        tutor?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedBoardingAttendanceReportListList, T>, any>(
        {
          path: `/boarding/attendances/reports/`,
          method: 'GET',
          query: postprocessQuery(query),
          format: 'json',
          ...params
        }
      ),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsCreate
     * @request POST:/boarding/attendances/reports/
     */ boardingAttendancesReportsCreate: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      data: BoardingAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsRetrieve
     * @request GET:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsRetrieve: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsUpdate
     * @request PUT:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsUpdate: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      id: number,
      data: BoardingAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsPartialUpdate
     * @request PATCH:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsPartialUpdate: <
      T extends FetchKeys<BoardingAttendanceReport>
    >(
      id: number,
      data: PatchedBoardingAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingAttendanceReport, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsDestroy
     * @request DELETE:/boarding/attendances/reports/{id}/
     */ boardingAttendancesReportsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/attendances/reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingAttendancesReportsStatusesList
     * @request GET:/boarding/attendances/reports/statuses/
     */ boardingAttendancesReportsStatusesList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'
        /** @format uuid */
        tutor?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/attendances/reports/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsList
     * @request GET:/boarding/boarding-comments/
     */ boardingBoardingCommentsList: <
      T extends FetchKeys<PaginatedBoardingCommentList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        school_year?: number
        /** @format uuid */
        student?: string
        weekly?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedBoardingCommentList, T>, any>({
        path: `/boarding/boarding-comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsCreate
     * @request POST:/boarding/boarding-comments/
     */ boardingBoardingCommentsCreate: <T extends FetchKeys<BoardingComment>>(
      data: BoardingCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsRetrieve
     * @request GET:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsRetrieve: <
      T extends FetchKeys<BoardingComment>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsUpdate
     * @request PUT:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsUpdate: <T extends FetchKeys<BoardingComment>>(
      id: string,
      data: BoardingCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsPartialUpdate
     * @request PATCH:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsPartialUpdate: <
      T extends FetchKeys<BoardingComment>
    >(
      id: string,
      data: PatchedBoardingCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BoardingComment, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingBoardingCommentsDestroy
     * @request DELETE:/boarding/boarding-comments/{id}/
     */ boardingBoardingCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/boarding-comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesList
     * @request GET:/boarding/houses/
     */ boardingHousesList: <T extends FetchKeys<PaginatedHouseListList>>(
      query?: {
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        generate_night_attendance_reports?: boolean
        is_senior_campus?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHouseListList, T>, any>({
        path: `/boarding/houses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesCreate
     * @request POST:/boarding/houses/
     */ boardingHousesCreate: <T extends FetchKeys<House>>(
      data: HouseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesRetrieve
     * @request GET:/boarding/houses/{id}/
     */ boardingHousesRetrieve: <T extends FetchKeys<House>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesUpdate
     * @request PUT:/boarding/houses/{id}/
     */ boardingHousesUpdate: <T extends FetchKeys<House>>(
      id: number,
      data: HouseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesPartialUpdate
     * @request PATCH:/boarding/houses/{id}/
     */ boardingHousesPartialUpdate: <T extends FetchKeys<House>>(
      id: number,
      data: PatchedHouseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<House, T>, any>({
        path: `/boarding/houses/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsList
     * @request GET:/boarding/houses/rooms/
     */ boardingHousesRoomsList: <
      T extends FetchKeys<PaginatedHouseRoomListList>
    >(
      query?: {
        booked_for?: string
        code?: string
        house?: number
        id?: number
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        status?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHouseRoomListList, T>, any>({
        path: `/boarding/houses/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsRetrieve
     * @request GET:/boarding/houses/rooms/{id}/
     */ boardingHousesRoomsRetrieve: <T extends FetchKeys<HouseRoom>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoom, T>, any>({
        path: `/boarding/houses/rooms/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsUpdate
     * @request PUT:/boarding/houses/rooms/{id}/
     */ boardingHousesRoomsUpdate: <T extends FetchKeys<HouseRoom>>(
      id: number,
      data: HouseRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoom, T>, any>({
        path: `/boarding/houses/rooms/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsPartialUpdate
     * @request PATCH:/boarding/houses/rooms/{id}/
     */ boardingHousesRoomsPartialUpdate: <T extends FetchKeys<HouseRoom>>(
      id: number,
      data: PatchedHouseRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoom, T>, any>({
        path: `/boarding/houses/rooms/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description cancel (remove) all bookings/accommodations made for specific room, having both -> cancel only bookings
     *
     * @tags boarding
     * @name BoardingHousesRoomsCancelBookingCreate
     * @request POST:/boarding/houses/rooms/{id}/cancel-booking/
     */ boardingHousesRoomsCancelBookingCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/houses/rooms/${id}/cancel-booking/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsAvailableFiltersRetrieve
     * @request GET:/boarding/houses/rooms/available-filters/
     */ boardingHousesRoomsAvailableFiltersRetrieve: <
      T extends FetchKeys<HouseRoomAvailableFilters>
    >(
      query?: {
        booked_for?: string
        code?: string
        house?: number
        id?: number
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        status?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HouseRoomAvailableFilters, T>, any>({
        path: `/boarding/houses/rooms/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags boarding
     * @name BoardingHousesRoomsCodesList
     * @request GET:/boarding/houses/rooms/codes/
     */ boardingHousesRoomsCodesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        booked_for?: string
        code?: string
        house?: number
        id?: number
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        status?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/boarding/houses/rooms/codes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingHousesTutorsList
     * @request GET:/boarding/houses/tutors/
     */ boardingHousesTutorsList: <T extends FetchKeys<PaginatedTutorList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTutorList, T>, any>({
        path: `/boarding/houses/tutors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesList
     * @request GET:/boarding/night-attendances/
     */ boardingNightAttendancesList: <T extends FetchKeys<NightAttendance[]>>(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /**
         * * `male` - Male
         * * `female` - Female
         */
        student__gender?: 'female' | 'male'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance[], T>, any>({
        path: `/boarding/night-attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCreate
     * @request POST:/boarding/night-attendances/
     */ boardingNightAttendancesCreate: <T extends FetchKeys<NightAttendance>>(
      data: NightAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsList
     * @request GET:/boarding/night-attendances/{attendanceId}/comments/
     */ boardingNightAttendancesCommentsList: <
      T extends FetchKeys<NightAttendanceComment[]>
    >(
      attendanceId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment[], T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsCreate
     * @request POST:/boarding/night-attendances/{attendanceId}/comments/
     */ boardingNightAttendancesCommentsCreate: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      data: NightAttendanceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsRetrieve
     * @request GET:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsRetrieve: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsUpdate
     * @request PUT:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsUpdate: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      data: NightAttendanceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsPartialUpdate
     * @request PATCH:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsPartialUpdate: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      data: PatchedNightAttendanceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsDestroy
     * @request DELETE:/boarding/night-attendances/{attendanceId}/comments/{id}/
     */ boardingNightAttendancesCommentsDestroy: <T extends FetchKeys<void>>(
      attendanceId: string,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesCommentsVersionsRetrieve
     * @request GET:/boarding/night-attendances/{attendanceId}/comments/{id}/versions/
     */ boardingNightAttendancesCommentsVersionsRetrieve: <
      T extends FetchKeys<NightAttendanceComment>
    >(
      attendanceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceComment, T>, any>({
        path: `/boarding/night-attendances/${attendanceId}/comments/${id}/versions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesRetrieve
     * @request GET:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesRetrieve: <
      T extends FetchKeys<NightAttendance>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesUpdate
     * @request PUT:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesUpdate: <T extends FetchKeys<NightAttendance>>(
      id: string,
      data: NightAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesPartialUpdate
     * @request PATCH:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesPartialUpdate: <
      T extends FetchKeys<NightAttendance>
    >(
      id: string,
      data: PatchedNightAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesDestroy
     * @request DELETE:/boarding/night-attendances/{id}/
     */ boardingNightAttendancesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesMarksList
     * @request GET:/boarding/night-attendances/marks/
     */ boardingNightAttendancesMarksList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /**
         * * `male` - Male
         * * `female` - Female
         */
        student__gender?: 'female' | 'male'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/night-attendances/marks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportRetrieve
     * @request GET:/boarding/night-attendances/report/
     */ boardingNightAttendancesReportRetrieve: <
      T extends FetchKeys<NightAttendance>
    >(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        report?: number
        /**
         * * `male` - Male
         * * `female` - Female
         */
        student__gender?: 'female' | 'male'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendance, T>, any>({
        path: `/boarding/night-attendances/report/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsList
     * @request GET:/boarding/night-attendances/reports/
     */ boardingNightAttendancesReportsList: <
      T extends FetchKeys<PaginatedNightAttendanceReportListList>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /**
         * * `male` - Male
         * * `female` - Female
         */
        for_gender?: 'female' | 'male'
        has_disruption?: boolean
        /** @format uuid */
        night_guard?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedNightAttendanceReportListList, T>, any>({
        path: `/boarding/night-attendances/reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsCreate
     * @request POST:/boarding/night-attendances/reports/
     */ boardingNightAttendancesReportsCreate: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      data: NightAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsRetrieve
     * @request GET:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsRetrieve: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsUpdate
     * @request PUT:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsUpdate: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      id: number,
      data: NightAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsPartialUpdate
     * @request PATCH:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsPartialUpdate: <
      T extends FetchKeys<NightAttendanceReport>
    >(
      id: number,
      data: PatchedNightAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NightAttendanceReport, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsDestroy
     * @request DELETE:/boarding/night-attendances/reports/{id}/
     */ boardingNightAttendancesReportsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/night-attendances/reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsCreatorsList
     * @request GET:/boarding/night-attendances/reports/creators/
     */ boardingNightAttendancesReportsCreatorsList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /**
         * * `male` - Male
         * * `female` - Female
         */
        for_gender?: 'female' | 'male'
        has_disruption?: boolean
        /** @format uuid */
        night_guard?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/night-attendances/reports/creators/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingNightAttendancesReportsStatusesList
     * @request GET:/boarding/night-attendances/reports/statuses/
     */ boardingNightAttendancesReportsStatusesList: <
      T extends FetchKeys<StringOption[]>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /**
         * * `male` - Male
         * * `female` - Female
         */
        for_gender?: 'female' | 'male'
        has_disruption?: boolean
        /** @format uuid */
        night_guard?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/boarding/night-attendances/reports/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaList
     * @request GET:/boarding/rota/
     */ boardingRotaList: <T extends FetchKeys<RotaTimetable[]>>(
      query?: {
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaTimetable[], T>, any>({
        path: `/boarding/rota/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesList
     * @request GET:/boarding/rota-activities/
     */ boardingRotaActivitiesList: <
      T extends FetchKeys<PaginatedDraftRotaActivityList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        rota_timetable?: number
        timetable__enabled_preview_for_teachers?: boolean
        /** @format uuid */
        user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDraftRotaActivityList, T>, any>({
        path: `/boarding/rota-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaActivitiesRetrieve
     * @request GET:/boarding/rota-activities/{id}/
     */ boardingRotaActivitiesRetrieve: <
      T extends FetchKeys<DraftRotaActivity>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftRotaActivity, T>, any>({
        path: `/boarding/rota-activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaRetrieve
     * @request GET:/boarding/rota/{id}/
     */ boardingRotaRetrieve: <T extends FetchKeys<RotaTimetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaTimetable, T>, any>({
        path: `/boarding/rota/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaMarkAsDraftCreate
     * @request POST:/boarding/rota/{id}/mark-as-draft/
     */ boardingRotaMarkAsDraftCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota/${id}/mark-as-draft/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags boarding
     * @name BoardingRotaMarkAsSelectedForImportCreate
     * @request POST:/boarding/rota/{id}/mark-as-selected-for-import/
     */ boardingRotaMarkAsSelectedForImportCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/boarding/rota/${id}/mark-as-selected-for-import/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaGenerationCreate
     * @request POST:/boarding/rota/generation/
     */ boardingRotaGenerationCreate: <T extends FetchKeys<RotaGenerationTask>>(
      data: RotaGenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationTask, T>, any>({
        path: `/boarding/rota/generation/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaGenerationRetrieve
     * @request GET:/boarding/rota/generation/{id}/
     */ boardingRotaGenerationRetrieve: <
      T extends FetchKeys<RotaGenerationTask>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationTask, T>, any>({
        path: `/boarding/rota/generation/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags boarding
     * @name BoardingRotaGenerationActiveList
     * @request GET:/boarding/rota/generation/active/
     */ boardingRotaGenerationActiveList: <
      T extends FetchKeys<RotaGenerationTask[]>
    >(
      query?: {
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaGenerationTask[], T>, any>({
        path: `/boarding/rota/generation/active/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  campusAbsences = {
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsList
     * @request GET:/campus-absences/{campusAbsenceId}/comments/
     */
    campusAbsencesCommentsList: <T extends FetchKeys<CampusAbsenceComment[]>>(
      campusAbsenceId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment[], T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsCreate
     * @request POST:/campus-absences/{campusAbsenceId}/comments/
     */ campusAbsencesCommentsCreate: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      data: CampusAbsenceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsRetrieve
     * @request GET:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsRetrieve: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsUpdate
     * @request PUT:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsUpdate: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      data: CampusAbsenceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsPartialUpdate
     * @request PATCH:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsPartialUpdate: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      data: PatchedCampusAbsenceCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsDestroy
     * @request DELETE:/campus-absences/{campusAbsenceId}/comments/{id}/
     */ campusAbsencesCommentsDestroy: <T extends FetchKeys<void>>(
      campusAbsenceId: string,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCommentsVersionsRetrieve
     * @request GET:/campus-absences/{campusAbsenceId}/comments/{id}/versions/
     */ campusAbsencesCommentsVersionsRetrieve: <
      T extends FetchKeys<CampusAbsenceComment>
    >(
      campusAbsenceId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceComment, T>, any>({
        path: `/campus-absences/${campusAbsenceId}/comments/${id}/versions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesAusgangsPerWeekRetrieve
     * @request GET:/campus-absences/ausgangs-per-week/
     */ campusAbsencesAusgangsPerWeekRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/campus-absences/ausgangs-per-week/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesList
     * @request GET:/campus-absences/campus-absences/
     */ campusAbsencesCampusAbsencesList: <
      T extends FetchKeys<PaginatedCampusAbsenceList>
    >(
      query?: {
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?:
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        check_in?: boolean
        check_out?: boolean
        /** @format date-time */
        date_from_after?: string
        /** @format date-time */
        date_to_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCampusAbsenceList, T>, any>({
        path: `/campus-absences/campus-absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesCreate
     * @request POST:/campus-absences/campus-absences/
     */ campusAbsencesCampusAbsencesCreate: <
      T extends FetchKeys<CampusAbsence>
    >(
      data: CampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesRetrieve
     * @request GET:/campus-absences/campus-absences/{id}/
     */ campusAbsencesCampusAbsencesRetrieve: <
      T extends FetchKeys<CampusAbsence>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesUpdate
     * @request PUT:/campus-absences/campus-absences/{id}/
     */ campusAbsencesCampusAbsencesUpdate: <
      T extends FetchKeys<CampusAbsence>
    >(
      id: string,
      data: CampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesPartialUpdate
     * @request PATCH:/campus-absences/campus-absences/{id}/
     */ campusAbsencesCampusAbsencesPartialUpdate: <
      T extends FetchKeys<CampusAbsence>
    >(
      id: string,
      data: PatchedCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesChangeCheckInOutUpdate
     * @request PUT:/campus-absences/campus-absences/{id}/change-check-in-out/
     */ campusAbsencesCampusAbsencesChangeCheckInOutUpdate: <
      T extends FetchKeys<CampusAbsenceCheckInOut>
    >(
      id: string,
      data: CampusAbsenceCheckInOutRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceCheckInOut, T>, any>({
        path: `/campus-absences/campus-absences/${id}/change-check-in-out/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesChangeStatusUpdate
     * @request PUT:/campus-absences/campus-absences/{id}/change-status/
     */ campusAbsencesCampusAbsencesChangeStatusUpdate: <
      T extends FetchKeys<CampusAbsenceChangeStatus>
    >(
      id: string,
      data: CampusAbsenceChangeStatusRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceChangeStatus, T>, any>({
        path: `/campus-absences/campus-absences/${id}/change-status/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesHistoryRetrieve
     * @request GET:/campus-absences/campus-absences/{id}/history/
     */ campusAbsencesCampusAbsencesHistoryRetrieve: <
      T extends FetchKeys<CampusAbsenceLog>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceLog, T>, any>({
        path: `/campus-absences/campus-absences/${id}/history/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesChoicesRetrieve
     * @request GET:/campus-absences/campus-absences/choices/
     */ campusAbsencesCampusAbsencesChoicesRetrieve: <
      T extends FetchKeys<CampusAbsence>
    >(
      query?: {
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?:
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        check_in?: boolean
        check_out?: boolean
        /** @format date-time */
        date_from_after?: string
        /** @format date-time */
        date_to_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        students?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsence, T>, any>({
        path: `/campus-absences/campus-absences/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags campus-absences
     * @name CampusAbsencesCampusAbsencesCollisionsCreate
     * @request POST:/campus-absences/campus-absences/collisions/
     */ campusAbsencesCampusAbsencesCollisionsCreate: <
      T extends FetchKeys<CampusAbsenceWarning>
    >(
      data: CampusAbsenceWarningRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CampusAbsenceWarning, T>, any>({
        path: `/campus-absences/campus-absences/collisions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  chronicConditions = {
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsList
     * @request GET:/chronic-conditions/
     */
    chronicConditionsList: <T extends FetchKeys<PaginatedChronicConditionList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedChronicConditionList, T>, any>({
        path: `/chronic-conditions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsCreate
     * @request POST:/chronic-conditions/
     */ chronicConditionsCreate: <T extends FetchKeys<ChronicCondition>>(
      data: ChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsRetrieve
     * @request GET:/chronic-conditions/{id}/
     */ chronicConditionsRetrieve: <T extends FetchKeys<ChronicCondition>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsUpdate
     * @request PUT:/chronic-conditions/{id}/
     */ chronicConditionsUpdate: <T extends FetchKeys<ChronicCondition>>(
      id: number,
      data: ChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsPartialUpdate
     * @request PATCH:/chronic-conditions/{id}/
     */ chronicConditionsPartialUpdate: <T extends FetchKeys<ChronicCondition>>(
      id: number,
      data: PatchedChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicCondition, T>, any>({
        path: `/chronic-conditions/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsList
     * @request GET:/chronic-conditions/student-chronic-conditions/
     */ chronicConditionsStudentChronicConditionsList: <
      T extends FetchKeys<StudentChronicCondition[]>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition[], T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsCreate
     * @request POST:/chronic-conditions/student-chronic-conditions/
     */ chronicConditionsStudentChronicConditionsCreate: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      data: StudentChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsRetrieve
     * @request GET:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsRetrieve: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsUpdate
     * @request PUT:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsUpdate: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      id: number,
      data: StudentChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsPartialUpdate
     * @request PATCH:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsPartialUpdate: <
      T extends FetchKeys<StudentChronicCondition>
    >(
      id: number,
      data: PatchedStudentChronicConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentChronicCondition, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsDestroy
     * @request DELETE:/chronic-conditions/student-chronic-conditions/{id}/
     */ chronicConditionsStudentChronicConditionsDestroy: <
      T extends FetchKeys<void>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags chronic-conditions
     * @name ChronicConditionsStudentChronicConditionsChoicesRetrieve
     * @request GET:/chronic-conditions/student-chronic-conditions/choices/
     */ chronicConditionsStudentChronicConditionsChoicesRetrieve: <
      T extends FetchKeys<ChronicConditionChoicesResponse>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChronicConditionChoicesResponse, T>, any>({
        path: `/chronic-conditions/student-chronic-conditions/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  core = {
    /**
     * No description
     *
     * @tags core
     * @name CoreAppStatusRetrieve
     * @request GET:/core/app-status/
     */
    coreAppStatusRetrieve: <T extends FetchKeys<AppStatusVersion>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AppStatusVersion, T>, any>({
        path: `/core/app-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags core
     * @name CoreCountriesRetrieve
     * @request GET:/core/countries/
     */ coreCountriesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/core/countries/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags core
     * @name CoreCurrencyChoicesRetrieve
     * @request GET:/core/currency-choices/
     */ coreCurrencyChoicesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/core/currency-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags core
     * @name CoreSettingsRetrieve
     * @request GET:/core/settings/
     */ coreSettingsRetrieve: <T extends FetchKeys<SiteSettings>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SiteSettings, T>, any>({
        path: `/core/settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  courses = {
    /**
     * No description
     *
     * @tags courses
     * @name CoursesList
     * @request GET:/courses/
     */
    coursesList: <T extends FetchKeys<PaginatedCourseListList>>(
      query?: {
        is_latest_version?: boolean
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        types__name?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseListList, T>, any>({
        path: `/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesRetrieve
     * @request GET:/courses/{id}/
     */ coursesRetrieve: <T extends FetchKeys<CourseDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseDetail, T>, any>({
        path: `/courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesUpdateDescriptionPartialUpdate
     * @request PATCH:/courses/{id}/update-description/
     */ coursesUpdateDescriptionPartialUpdate: <
      T extends FetchKeys<CourseDescription>
    >(
      id: string,
      data: PatchedCourseDescriptionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseDescription, T>, any>({
        path: `/courses/${id}/update-description/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesEnrollmentsList
     * @request GET:/courses/enrollments/
     */ coursesEnrollmentsList: <T extends FetchKeys<CourseEnrollment[]>>(
      query?: {
        /** @format uuid */
        course?: string
        id?: number[]
        quarter?: number
        quarter__semester?: number
        quarter__semester__year?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseEnrollment[], T>, any>({
        path: `/courses/enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerList
     * @request GET:/courses/planner/
     */ coursesPlannerList: <
      T extends FetchKeys<PaginatedCoursePlannerListList>
    >(
      query?: {
        activities__quarter?: number
        code?: string
        is_latest_version?: boolean
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        types__name?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCoursePlannerListList, T>, any>({
        path: `/courses/planner/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerCreate
     * @request POST:/courses/planner/
     */ coursesPlannerCreate: <T extends FetchKeys<CoursePlanner>>(
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/courses/planner/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerRetrieve
     * @request GET:/courses/planner/{id}/
     */ coursesPlannerRetrieve: <T extends FetchKeys<CoursePlannerResponse>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlannerResponse, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerUpdate
     * @request PUT:/courses/planner/{id}/
     */ coursesPlannerUpdate: <T extends FetchKeys<CoursePlannerResponse>>(
      id: string,
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlannerResponse, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerPartialUpdate
     * @request PATCH:/courses/planner/{id}/
     */ coursesPlannerPartialUpdate: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      data: PatchedCoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerDestroy
     * @request DELETE:/courses/planner/{id}/
     */ coursesPlannerDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses/planner/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerToggleLatestVersionCreate
     * @request POST:/courses/planner/{id}/toggle-latest-version/
     */ coursesPlannerToggleLatestVersionCreate: <
      T extends FetchKeys<CoursePlanner>
    >(
      id: string,
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/courses/planner/${id}/toggle-latest-version/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesPlannerSetQuarterCreate
     * @request POST:/courses/planner/set-quarter/
     */ coursesPlannerSetQuarterCreate: <T extends FetchKeys<QuarterInCourses>>(
      data: QuarterInCoursesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<QuarterInCourses, T>, any>({
        path: `/courses/planner/set-quarter/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsList
     * @request GET:/courses/weekly-comments/
     */ coursesWeeklyCommentsList: <
      T extends FetchKeys<PaginatedWeeklyCommentList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedWeeklyCommentList, T>, any>({
        path: `/courses/weekly-comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsCreate
     * @request POST:/courses/weekly-comments/
     */ coursesWeeklyCommentsCreate: <T extends FetchKeys<WeeklyComment>>(
      data: WeeklyCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsRetrieve
     * @request GET:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsRetrieve: <T extends FetchKeys<WeeklyComment>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsUpdate
     * @request PUT:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsUpdate: <T extends FetchKeys<WeeklyComment>>(
      id: number,
      data: WeeklyCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsPartialUpdate
     * @request PATCH:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsPartialUpdate: <
      T extends FetchKeys<WeeklyComment>
    >(
      id: number,
      data: PatchedWeeklyCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsDestroy
     * @request DELETE:/courses/weekly-comments/{id}/
     */ coursesWeeklyCommentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses/weekly-comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsCsvRetrieve
     * @request GET:/courses/weekly-comments/csv/
     */ coursesWeeklyCommentsCsvRetrieve: <T extends FetchKeys<WeeklyComment>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/csv/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsDownloadRetrieve
     * @request GET:/courses/weekly-comments/download/
     */ coursesWeeklyCommentsDownloadRetrieve: <
      T extends FetchKeys<WeeklyComment>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/download/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses
     * @name CoursesWeeklyCommentsPdfRetrieve
     * @request GET:/courses/weekly-comments/pdf/
     */ coursesWeeklyCommentsPdfRetrieve: <T extends FetchKeys<WeeklyComment>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        from_date?: string
        /** @format uuid */
        student?: string
        /** @format date */
        to_date?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<WeeklyComment, T>, any>({
        path: `/courses/weekly-comments/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  coursesMeta = {
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaFamiliesList
     * @request GET:/courses-meta/families/
     */
    coursesMetaFamiliesList: <T extends FetchKeys<PaginatedCourseFamilyList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseFamilyList, T>, any>({
        path: `/courses-meta/families/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaFamiliesCreate
     * @request POST:/courses-meta/families/
     */ coursesMetaFamiliesCreate: <T extends FetchKeys<CourseFamily>>(
      data: CourseFamilyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseFamily, T>, any>({
        path: `/courses-meta/families/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaFamiliesRetrieve
     * @request GET:/courses-meta/families/{id}/
     */ coursesMetaFamiliesRetrieve: <T extends FetchKeys<CourseFamily>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseFamily, T>, any>({
        path: `/courses-meta/families/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaFamiliesUpdate
     * @request PUT:/courses-meta/families/{id}/
     */ coursesMetaFamiliesUpdate: <T extends FetchKeys<CourseFamily>>(
      id: string,
      data: CourseFamilyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseFamily, T>, any>({
        path: `/courses-meta/families/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaFamiliesPartialUpdate
     * @request PATCH:/courses-meta/families/{id}/
     */ coursesMetaFamiliesPartialUpdate: <T extends FetchKeys<CourseFamily>>(
      id: string,
      data: PatchedCourseFamilyRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseFamily, T>, any>({
        path: `/courses-meta/families/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaFamiliesDestroy
     * @request DELETE:/courses-meta/families/{id}/
     */ coursesMetaFamiliesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses-meta/families/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaGroupsList
     * @request GET:/courses-meta/groups/
     */ coursesMetaGroupsList: <T extends FetchKeys<PaginatedCourseGroupList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseGroupList, T>, any>({
        path: `/courses-meta/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaGroupsCreate
     * @request POST:/courses-meta/groups/
     */ coursesMetaGroupsCreate: <T extends FetchKeys<CourseGroup>>(
      data: CourseGroupRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseGroup, T>, any>({
        path: `/courses-meta/groups/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaGroupsRetrieve
     * @request GET:/courses-meta/groups/{id}/
     */ coursesMetaGroupsRetrieve: <T extends FetchKeys<CourseGroup>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseGroup, T>, any>({
        path: `/courses-meta/groups/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaGroupsUpdate
     * @request PUT:/courses-meta/groups/{id}/
     */ coursesMetaGroupsUpdate: <T extends FetchKeys<CourseGroup>>(
      id: string,
      data: CourseGroupRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseGroup, T>, any>({
        path: `/courses-meta/groups/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaGroupsPartialUpdate
     * @request PATCH:/courses-meta/groups/{id}/
     */ coursesMetaGroupsPartialUpdate: <T extends FetchKeys<CourseGroup>>(
      id: string,
      data: PatchedCourseGroupRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseGroup, T>, any>({
        path: `/courses-meta/groups/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaGroupsDestroy
     * @request DELETE:/courses-meta/groups/{id}/
     */ coursesMetaGroupsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses-meta/groups/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaIntelligencesList
     * @request GET:/courses-meta/intelligences/
     */ coursesMetaIntelligencesList: <
      T extends FetchKeys<PaginatedCourseIntelligenceList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseIntelligenceList, T>, any>({
        path: `/courses-meta/intelligences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaIntelligencesCreate
     * @request POST:/courses-meta/intelligences/
     */ coursesMetaIntelligencesCreate: <
      T extends FetchKeys<CourseIntelligence>
    >(
      data: CourseIntelligenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseIntelligence, T>, any>({
        path: `/courses-meta/intelligences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaIntelligencesRetrieve
     * @request GET:/courses-meta/intelligences/{id}/
     */ coursesMetaIntelligencesRetrieve: <
      T extends FetchKeys<CourseIntelligence>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseIntelligence, T>, any>({
        path: `/courses-meta/intelligences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaIntelligencesUpdate
     * @request PUT:/courses-meta/intelligences/{id}/
     */ coursesMetaIntelligencesUpdate: <
      T extends FetchKeys<CourseIntelligence>
    >(
      id: string,
      data: CourseIntelligenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseIntelligence, T>, any>({
        path: `/courses-meta/intelligences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaIntelligencesPartialUpdate
     * @request PATCH:/courses-meta/intelligences/{id}/
     */ coursesMetaIntelligencesPartialUpdate: <
      T extends FetchKeys<CourseIntelligence>
    >(
      id: string,
      data: PatchedCourseIntelligenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseIntelligence, T>, any>({
        path: `/courses-meta/intelligences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaIntelligencesDestroy
     * @request DELETE:/courses-meta/intelligences/{id}/
     */ coursesMetaIntelligencesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses-meta/intelligences/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaLevelsList
     * @request GET:/courses-meta/levels/
     */ coursesMetaLevelsList: <T extends FetchKeys<PaginatedCourseLevelList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseLevelList, T>, any>({
        path: `/courses-meta/levels/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaLevelsCreate
     * @request POST:/courses-meta/levels/
     */ coursesMetaLevelsCreate: <T extends FetchKeys<CourseLevel>>(
      data: CourseLevelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseLevel, T>, any>({
        path: `/courses-meta/levels/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaLevelsRetrieve
     * @request GET:/courses-meta/levels/{id}/
     */ coursesMetaLevelsRetrieve: <T extends FetchKeys<CourseLevel>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseLevel, T>, any>({
        path: `/courses-meta/levels/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaLevelsUpdate
     * @request PUT:/courses-meta/levels/{id}/
     */ coursesMetaLevelsUpdate: <T extends FetchKeys<CourseLevel>>(
      id: string,
      data: CourseLevelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseLevel, T>, any>({
        path: `/courses-meta/levels/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaLevelsPartialUpdate
     * @request PATCH:/courses-meta/levels/{id}/
     */ coursesMetaLevelsPartialUpdate: <T extends FetchKeys<CourseLevel>>(
      id: string,
      data: PatchedCourseLevelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseLevel, T>, any>({
        path: `/courses-meta/levels/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaLevelsDestroy
     * @request DELETE:/courses-meta/levels/{id}/
     */ coursesMetaLevelsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses-meta/levels/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaSubjectsList
     * @request GET:/courses-meta/subjects/
     */ coursesMetaSubjectsList: <
      T extends FetchKeys<PaginatedCourseSubjectList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseSubjectList, T>, any>({
        path: `/courses-meta/subjects/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaSubjectsCreate
     * @request POST:/courses-meta/subjects/
     */ coursesMetaSubjectsCreate: <T extends FetchKeys<CourseSubject>>(
      data: CourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/courses-meta/subjects/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaSubjectsRetrieve
     * @request GET:/courses-meta/subjects/{id}/
     */ coursesMetaSubjectsRetrieve: <T extends FetchKeys<CourseSubject>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/courses-meta/subjects/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaSubjectsUpdate
     * @request PUT:/courses-meta/subjects/{id}/
     */ coursesMetaSubjectsUpdate: <T extends FetchKeys<CourseSubject>>(
      id: string,
      data: CourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/courses-meta/subjects/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaSubjectsPartialUpdate
     * @request PATCH:/courses-meta/subjects/{id}/
     */ coursesMetaSubjectsPartialUpdate: <T extends FetchKeys<CourseSubject>>(
      id: string,
      data: PatchedCourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/courses-meta/subjects/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaSubjectsDestroy
     * @request DELETE:/courses-meta/subjects/{id}/
     */ coursesMetaSubjectsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses-meta/subjects/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaTypesList
     * @request GET:/courses-meta/types/
     */ coursesMetaTypesList: <T extends FetchKeys<PaginatedCourseTypeList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseTypeList, T>, any>({
        path: `/courses-meta/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaTypesCreate
     * @request POST:/courses-meta/types/
     */ coursesMetaTypesCreate: <T extends FetchKeys<CourseType>>(
      data: CourseTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseType, T>, any>({
        path: `/courses-meta/types/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaTypesRetrieve
     * @request GET:/courses-meta/types/{id}/
     */ coursesMetaTypesRetrieve: <T extends FetchKeys<CourseType>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseType, T>, any>({
        path: `/courses-meta/types/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaTypesUpdate
     * @request PUT:/courses-meta/types/{id}/
     */ coursesMetaTypesUpdate: <T extends FetchKeys<CourseType>>(
      id: string,
      data: CourseTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseType, T>, any>({
        path: `/courses-meta/types/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaTypesPartialUpdate
     * @request PATCH:/courses-meta/types/{id}/
     */ coursesMetaTypesPartialUpdate: <T extends FetchKeys<CourseType>>(
      id: string,
      data: PatchedCourseTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseType, T>, any>({
        path: `/courses-meta/types/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags courses-meta
     * @name CoursesMetaTypesDestroy
     * @request DELETE:/courses-meta/types/{id}/
     */ coursesMetaTypesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/courses-meta/types/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  curriculum = {
    /**
     * No description
     *
     * @tags curriculum
     * @name CurriculumGradeLevelsList
     * @request GET:/curriculum/grade-levels/
     */
    curriculumGradeLevelsList: <T extends FetchKeys<PaginatedGradeLevelList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedGradeLevelList, T>, any>({
        path: `/curriculum/grade-levels/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags curriculum
     * @name CurriculumGradeLevelsRetrieve
     * @request GET:/curriculum/grade-levels/{id}/
     */ curriculumGradeLevelsRetrieve: <T extends FetchKeys<GradeLevel>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GradeLevel, T>, any>({
        path: `/curriculum/grade-levels/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  departments = {
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsList
     * @request GET:/departments/
     */
    departmentsList: <T extends FetchKeys<PaginatedDepartmentList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDepartmentList, T>, any>({
        path: `/departments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsCreate
     * @request POST:/departments/
     */ departmentsCreate: <T extends FetchKeys<Department>>(
      data: DepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsRetrieve
     * @request GET:/departments/{id}/
     */ departmentsRetrieve: <T extends FetchKeys<Department>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsUpdate
     * @request PUT:/departments/{id}/
     */ departmentsUpdate: <T extends FetchKeys<Department>>(
      id: number,
      data: DepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsPartialUpdate
     * @request PATCH:/departments/{id}/
     */ departmentsPartialUpdate: <T extends FetchKeys<Department>>(
      id: number,
      data: PatchedDepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Department, T>, any>({
        path: `/departments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags departments
     * @name DepartmentsDestroy
     * @request DELETE:/departments/{id}/
     */ departmentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/departments/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  discipline = {
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsList
     * @request GET:/discipline/student-category-reports/
     */
    disciplineStudentCategoryReportsList: <
      T extends FetchKeys<PaginatedStudentCategoryReportListList>
    >(
      query?: {
        /** @format date */
        end_date__lte?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format date */
        start_date__gte?: string
        /**
         * * `new` - New
         * * `incomplete` - Incomplete
         * * `finished` - Finished
         */
        status?: 'finished' | 'incomplete' | 'new'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCategoryReportListList, T>, any>({
        path: `/discipline/student-category-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsRetrieve
     * @request GET:/discipline/student-category-reports/{reportId}/
     */ disciplineStudentCategoryReportsRetrieve: <
      T extends FetchKeys<StudentCategoryItemList>
    >(
      reportId: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItemList, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsRetrieve2
     * @request GET:/discipline/student-category-reports/{reportId}/{categoryItemId}/
     */ disciplineStudentCategoryReportsRetrieve2: <
      T extends FetchKeys<StudentCategoryItemList>
    >(
      categoryItemId: string,
      reportId: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItemList, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/${categoryItemId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsUpdate
     * @request PUT:/discipline/student-category-reports/{reportId}/{categoryItemId}/
     */ disciplineStudentCategoryReportsUpdate: <
      T extends FetchKeys<StudentCategoryItem>
    >(
      categoryItemId: string,
      reportId: string,
      data: StudentCategoryItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItem, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/${categoryItemId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags discipline
     * @name DisciplineStudentCategoryReportsPartialUpdate
     * @request PATCH:/discipline/student-category-reports/{reportId}/{categoryItemId}/
     */ disciplineStudentCategoryReportsPartialUpdate: <
      T extends FetchKeys<StudentCategoryItem>
    >(
      categoryItemId: string,
      reportId: string,
      data: PatchedStudentCategoryItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryItem, T>, any>({
        path: `/discipline/student-category-reports/${reportId}/${categoryItemId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  doctors = {
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsList
     * @request GET:/doctors/
     */
    doctorsList: <T extends FetchKeys<PaginatedDoctorList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDoctorList, T>, any>({
        path: `/doctors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsCreate
     * @request POST:/doctors/
     */ doctorsCreate: <T extends FetchKeys<Doctor>>(
      data: DoctorRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsRetrieve
     * @request GET:/doctors/{id}/
     */ doctorsRetrieve: <T extends FetchKeys<Doctor>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsUpdate
     * @request PUT:/doctors/{id}/
     */ doctorsUpdate: <T extends FetchKeys<Doctor>>(
      id: number,
      data: DoctorRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsPartialUpdate
     * @request PATCH:/doctors/{id}/
     */ doctorsPartialUpdate: <T extends FetchKeys<Doctor>>(
      id: number,
      data: PatchedDoctorRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Doctor, T>, any>({
        path: `/doctors/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags doctors
     * @name DoctorsDestroy
     * @request DELETE:/doctors/{id}/
     */ doctorsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/doctors/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  exams = {
    /**
     * No description
     *
     * @tags exams
     * @name ExamsList
     * @request GET:/exams/
     */
    examsList: <T extends FetchKeys<PaginatedStudentExternalExamList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentExternalExamList, T>, any>({
        path: `/exams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsCreate
     * @request POST:/exams/
     */ examsCreate: <T extends FetchKeys<StudentExternalExam>>(
      data: StudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsRetrieve
     * @request GET:/exams/{id}/
     */ examsRetrieve: <T extends FetchKeys<StudentExternalExam>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsUpdate
     * @request PUT:/exams/{id}/
     */ examsUpdate: <T extends FetchKeys<StudentExternalExam>>(
      id: number,
      data: StudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsPartialUpdate
     * @request PATCH:/exams/{id}/
     */ examsPartialUpdate: <T extends FetchKeys<StudentExternalExam>>(
      id: number,
      data: PatchedStudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsDestroy
     * @request DELETE:/exams/{id}/
     */ examsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsUploadCertificateCreate
     * @request POST:/exams/{id}/upload-certificate/
     */ examsUploadCertificateCreate: <
      T extends FetchKeys<StudentExternalExam>
    >(
      id: number,
      data: StudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/${id}/upload-certificate/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamClashesList
     * @request GET:/exams/exam-planner/exam-clashes/
     */ examsExamPlannerExamClashesList: <T extends FetchKeys<ExamClash[]>>(
      query?: {
        exams?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamClash[], T>, any>({
        path: `/exams/exam-planner/exam-clashes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamClashesExamClashPreviewCreate
     * @request POST:/exams/exam-planner/exam-clashes/exam-clash-preview/
     */ examsExamPlannerExamClashesExamClashPreviewCreate: <
      T extends FetchKeys<ExamClashPreview>
    >(
      data: ExamClashPreviewRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamClashPreview, T>, any>({
        path: `/exams/exam-planner/exam-clashes/exam-clash-preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamEnrolledStudentList
     * @request GET:/exams/exam-planner/exam-enrolled-student/
     */ examsExamPlannerExamEnrolledStudentList: <
      T extends FetchKeys<PaginatedExamEnrolledStudentList>
    >(
      query?: {
        exam?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedExamEnrolledStudentList, T>, any>({
        path: `/exams/exam-planner/exam-enrolled-student/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamEnrolledStudentRetrieve
     * @request GET:/exams/exam-planner/exam-enrolled-student/{id}/
     */ examsExamPlannerExamEnrolledStudentRetrieve: <
      T extends FetchKeys<ExamEnrolledStudent>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamEnrolledStudent, T>, any>({
        path: `/exams/exam-planner/exam-enrolled-student/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamEnrolledStudentUpdate
     * @request PUT:/exams/exam-planner/exam-enrolled-student/{id}/
     */ examsExamPlannerExamEnrolledStudentUpdate: <
      T extends FetchKeys<ExamEnrolledStudent>
    >(
      id: number,
      data: ExamEnrolledStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamEnrolledStudent, T>, any>({
        path: `/exams/exam-planner/exam-enrolled-student/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamEnrolledStudentPartialUpdate
     * @request PATCH:/exams/exam-planner/exam-enrolled-student/{id}/
     */ examsExamPlannerExamEnrolledStudentPartialUpdate: <
      T extends FetchKeys<ExamEnrolledStudent>
    >(
      id: number,
      data: PatchedExamEnrolledStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamEnrolledStudent, T>, any>({
        path: `/exams/exam-planner/exam-enrolled-student/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionList
     * @request GET:/exams/exam-planner/exam-session/
     */ examsExamPlannerExamSessionList: <
      T extends FetchKeys<ExamSessionList[]>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSessionList[], T>, any>({
        path: `/exams/exam-planner/exam-session/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionCreate
     * @request POST:/exams/exam-planner/exam-session/
     */ examsExamPlannerExamSessionCreate: <T extends FetchKeys<ExamSession>>(
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsList
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/
     */ examsExamPlannerExamSessionExamsList: <
      T extends FetchKeys<PaginatedExamList>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedExamList, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsCreate
     * @request POST:/exams/exam-planner/exam-session/{examSessionId}/exams/
     */ examsExamPlannerExamSessionExamsCreate: <T extends FetchKeys<ExamForm>>(
      examSessionId: string,
      data: ExamFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamForm, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsRetrieve: <T extends FetchKeys<Exam>>(
      examSessionId: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsUpdate
     * @request PUT:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsUpdate: <T extends FetchKeys<ExamForm>>(
      examSessionId: string,
      id: string,
      data: ExamFormRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamForm, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsPartialUpdate
     * @request PATCH:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsPartialUpdate: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      id: string,
      data: PatchedExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsDestroy
     * @request DELETE:/exams/exam-planner/exam-session/{examSessionId}/exams/{id}/
     */ examsExamPlannerExamSessionExamsDestroy: <T extends FetchKeys<void>>(
      examSessionId: string,
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsAllIdsRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/all-ids/
     */ examsExamPlannerExamSessionExamsAllIdsRetrieve: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/all-ids/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsAvailableFiltersRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/available-filters/
     */ examsExamPlannerExamSessionExamsAvailableFiltersRetrieve: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsDatesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{examSessionId}/exams/dates/
     */ examsExamPlannerExamSessionExamsDatesRetrieve: <
      T extends FetchKeys<Exam>
    >(
      examSessionId: string,
      query?: {
        course?: string[]
        /** @format date */
        date?: string
        departments?: string[]
        end_time__isnull?: boolean
        grade_levels?: number[]
        has_clashes?: boolean
        is_published?: boolean
        is_scheduled?: boolean
        saa_invigilators?: string[]
        start_time__isnull?: boolean
        students?: number[]
        teachers?: string[]
        test_invigilators?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Exam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/dates/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsRemoveMultipleCreate
     * @request POST:/exams/exam-planner/exam-session/{examSessionId}/exams/remove-multiple/
     */ examsExamPlannerExamSessionExamsRemoveMultipleCreate: <
      T extends FetchKeys<MultipleExam>
    >(
      examSessionId: string,
      data: MultipleExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MultipleExam, T>, any>({
        path: `/exams/exam-planner/exam-session/${examSessionId}/exams/remove-multiple/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionRetrieve: <T extends FetchKeys<ExamSession>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionUpdate
     * @request PUT:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionUpdate: <T extends FetchKeys<ExamSession>>(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionPartialUpdate
     * @request PATCH:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionPartialUpdate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: PatchedExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionDestroy
     * @request DELETE:/exams/exam-planner/exam-session/{id}/
     */ examsExamPlannerExamSessionDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionAvailableExamCoursesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/available-exam-courses/
     */ examsExamPlannerExamSessionAvailableExamCoursesRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/available-exam-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionChangesPreviewCreate
     * @request POST:/exams/exam-planner/exam-session/{id}/changes-preview/
     */ examsExamPlannerExamSessionChangesPreviewCreate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/changes-preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionCoursesInExamSessionRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/courses-in-exam-session/
     */ examsExamPlannerExamSessionCoursesInExamSessionRetrieve: <
      T extends FetchKeys<CourseInExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/courses-in-exam-session/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionExamsReadyToPublishRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/exams-ready-to-publish/
     */ examsExamPlannerExamSessionExamsReadyToPublishRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/exams-ready-to-publish/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionPublishExamsCreate
     * @request POST:/exams/exam-planner/exam-session/{id}/publish-exams/
     */ examsExamPlannerExamSessionPublishExamsCreate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/publish-exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionPublishReadyExamsCreate
     * @request POST:/exams/exam-planner/exam-session/{id}/publish-ready-exams/
     */ examsExamPlannerExamSessionPublishReadyExamsCreate: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      data: ExamSessionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/publish-ready-exams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionStudentsForExamRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/students-for-exam/
     */ examsExamPlannerExamSessionStudentsForExamRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/students-for-exam/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionStudentsFromCoursesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/students-from-courses/
     */ examsExamPlannerExamSessionStudentsFromCoursesRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/students-from-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionTeachersFromCoursesRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/teachers-from-courses/
     */ examsExamPlannerExamSessionTeachersFromCoursesRetrieve: <
      T extends FetchKeys<void>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/teachers-from-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionXlsxRetrieve
     * @request GET:/exams/exam-planner/exam-session/{id}/xlsx/
     */ examsExamPlannerExamSessionXlsxRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/${id}/xlsx/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionAvailableUsersRetrieve
     * @request GET:/exams/exam-planner/exam-session/available-users/
     */ examsExamPlannerExamSessionAvailableUsersRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/available-users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionHasExamsInDateRangeRetrieve
     * @request GET:/exams/exam-planner/exam-session/has-exams-in-date-range/
     */ examsExamPlannerExamSessionHasExamsInDateRangeRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/has-exams-in-date-range/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionSimpleListRetrieve
     * @request GET:/exams/exam-planner/exam-session/simple-list/
     */ examsExamPlannerExamSessionSimpleListRetrieve: <
      T extends FetchKeys<ExamSessionSimpleList>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSessionSimpleList, T>, any>({
        path: `/exams/exam-planner/exam-session/simple-list/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionStudentsRetrieve
     * @request GET:/exams/exam-planner/exam-session/students/
     */ examsExamPlannerExamSessionStudentsRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExamPlannerExamSessionSuggestedRoomsRetrieve
     * @request GET:/exams/exam-planner/exam-session/suggested-rooms/
     */ examsExamPlannerExamSessionSuggestedRoomsRetrieve: <
      T extends FetchKeys<ExamSession>
    >(
      query?: {
        /**
         * * `quarter` - Quarter exams
         * * `academic` - Academic
         * * `external` - External exams
         */
        exam_type?: 'academic' | 'external' | 'quarter'
        quarter?: number
        school_year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ExamSession, T>, any>({
        path: `/exams/exam-planner/exam-session/suggested-rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsExternalTypesRetrieve
     * @request GET:/exams/external-types/
     */ examsExternalTypesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/external-types/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsStudentsList
     * @request GET:/exams/students/{studentId}/
     */ examsStudentsList: <
      T extends FetchKeys<PaginatedStudentExternalExamList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentExternalExamList, T>, any>({
        path: `/exams/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsStudentsUpdate
     * @request PUT:/exams/students/{studentId}/
     */ examsStudentsUpdate: <T extends FetchKeys<StudentExternalExam>>(
      studentId: string,
      data: StudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsStudentsPartialUpdate
     * @request PATCH:/exams/students/{studentId}/
     */ examsStudentsPartialUpdate: <T extends FetchKeys<StudentExternalExam>>(
      studentId: string,
      data: PatchedStudentExternalExamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentExternalExam, T>, any>({
        path: `/exams/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsList
     * @request GET:/exams/transcripts/
     */ examsTranscriptsList: <
      T extends FetchKeys<PaginatedStudentTranscriptList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentTranscriptList, T>, any>({
        path: `/exams/transcripts/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsCreate
     * @request POST:/exams/transcripts/
     */ examsTranscriptsCreate: <T extends FetchKeys<StudentTranscript>>(
      data: StudentTranscriptRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsRetrieve
     * @request GET:/exams/transcripts/{id}/
     */ examsTranscriptsRetrieve: <T extends FetchKeys<StudentTranscript>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsUpdate
     * @request PUT:/exams/transcripts/{id}/
     */ examsTranscriptsUpdate: <T extends FetchKeys<StudentTranscript>>(
      id: number,
      data: StudentTranscriptRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsPartialUpdate
     * @request PATCH:/exams/transcripts/{id}/
     */ examsTranscriptsPartialUpdate: <T extends FetchKeys<StudentTranscript>>(
      id: number,
      data: PatchedStudentTranscriptRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsDestroy
     * @request DELETE:/exams/transcripts/{id}/
     */ examsTranscriptsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/exams/transcripts/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsUploadCertificateCreate
     * @request POST:/exams/transcripts/{id}/upload-certificate/
     */ examsTranscriptsUploadCertificateCreate: <
      T extends FetchKeys<StudentTranscript>
    >(
      id: number,
      data: StudentTranscriptRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/${id}/upload-certificate/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsStudentsList
     * @request GET:/exams/transcripts/students/{studentId}/
     */ examsTranscriptsStudentsList: <
      T extends FetchKeys<PaginatedStudentTranscriptList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentTranscriptList, T>, any>({
        path: `/exams/transcripts/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsStudentsUpdate
     * @request PUT:/exams/transcripts/students/{studentId}/
     */ examsTranscriptsStudentsUpdate: <
      T extends FetchKeys<StudentTranscript>
    >(
      studentId: string,
      data: StudentTranscriptRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags exams
     * @name ExamsTranscriptsStudentsPartialUpdate
     * @request PATCH:/exams/transcripts/students/{studentId}/
     */ examsTranscriptsStudentsPartialUpdate: <
      T extends FetchKeys<StudentTranscript>
    >(
      studentId: string,
      data: PatchedStudentTranscriptRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTranscript, T>, any>({
        path: `/exams/transcripts/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  favouriteWidgets = {
    /**
     * No description
     *
     * @tags favourite-widgets
     * @name FavouriteWidgetsList
     * @request GET:/favourite-widgets/
     */
    favouriteWidgetsList: <T extends FetchKeys<PaginatedFavouriteWidgetList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedFavouriteWidgetList, T>, any>({
        path: `/favourite-widgets/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  filesForStudents = {
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsList
     * @request GET:/files-for-students/
     */
    filesForStudentsList: <T extends FetchKeys<PaginatedFileForStudentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedFileForStudentList, T>, any>({
        path: `/files-for-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsCreate
     * @request POST:/files-for-students/
     */ filesForStudentsCreate: <T extends FetchKeys<FileForStudent>>(
      data: FileForStudentSwaggerRequest[],
      query?: {
        replace_duplicated?: boolean
        save_duplicated?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsRetrieve
     * @request GET:/files-for-students/{id}/
     */ filesForStudentsRetrieve: <T extends FetchKeys<FileForStudent>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsUpdate
     * @request PUT:/files-for-students/{id}/
     */ filesForStudentsUpdate: <T extends FetchKeys<FileForStudent>>(
      id: number,
      data: FileForStudentSwaggerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsPartialUpdate
     * @request PATCH:/files-for-students/{id}/
     */ filesForStudentsPartialUpdate: <T extends FetchKeys<FileForStudent>>(
      id: number,
      data: PatchedFileForStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudent, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsDestroy
     * @request DELETE:/files-for-students/{id}/
     */ filesForStudentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/files-for-students/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsChoicesRetrieve
     * @request GET:/files-for-students/choices/
     */ filesForStudentsChoicesRetrieve: <
      T extends FetchKeys<FileForStudentChoices>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FileForStudentChoices, T>, any>({
        path: `/files-for-students/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags files-for-students
     * @name FilesForStudentsSaveOrderCreate
     * @request POST:/files-for-students/save-order/
     */ filesForStudentsSaveOrderCreate: <
      T extends FetchKeys<SaveStudentsFilesOrder>
    >(
      data: SaveStudentsFilesOrderRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SaveStudentsFilesOrder, T>, any>({
        path: `/files-for-students/save-order/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  grades = {
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccList
     * @request GET:/grades/acc/
     */
    gradesAccList: <T extends FetchKeys<PaginatedAccGradeList>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAccGradeList, T>, any>({
        path: `/grades/acc/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccCreate
     * @request POST:/grades/acc/
     */ gradesAccCreate: <T extends FetchKeys<AccGrade>>(
      data: AccGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccRetrieve
     * @request GET:/grades/acc/{id}/
     */ gradesAccRetrieve: <T extends FetchKeys<AccGrade>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccUpdate
     * @request PUT:/grades/acc/{id}/
     */ gradesAccUpdate: <T extends FetchKeys<AccGrade>>(
      id: number,
      data: AccGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccPartialUpdate
     * @request PATCH:/grades/acc/{id}/
     */ gradesAccPartialUpdate: <T extends FetchKeys<AccGrade>>(
      id: number,
      data: PatchedAccGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccDestroy
     * @request DELETE:/grades/acc/{id}/
     */ gradesAccDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/grades/acc/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccSetTargetCreate
     * @request POST:/grades/acc/{id}/set-target/
     */ gradesAccSetTargetCreate: <T extends FetchKeys<TargetGrade>>(
      id: number,
      data: TargetGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TargetGrade, T>, any>({
        path: `/grades/acc/${id}/set-target/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccAllGradesRetrieve
     * @request GET:/grades/acc/all-grades/
     */ gradesAccAllGradesRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/all-grades/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccCsvRetrieve
     * @request GET:/grades/acc/csv/
     */ gradesAccCsvRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/csv/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesAccCsvForCurrentQuarterRetrieve
     * @request GET:/grades/acc/csv-for-current-quarter/
     */ gradesAccCsvForCurrentQuarterRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/csv-for-current-quarter/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Return grades in IDP format (columns with Q1, S1 etc.) :return response [ { student: 1, courses: [ { "course": course_id, "grades": { "Q1": {"id": 1, "grade": "A+", "comment": "X"}, "Q2": {"id": 1, "grade": "A+", "comment": "X"}, "S1": {"id": 1, "grade": "A+", "comment": "X"}, } } ] } ]
     *
     * @tags grades
     * @name GradesAccIdpTableRetrieve
     * @request GET:/grades/acc/idp-table/
     */ gradesAccIdpTableRetrieve: <T extends FetchKeys<AccGrade>>(
      query?: {
        below_target?: boolean
        /** @format uuid */
        course?: string
        /** @format date-time */
        created?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        semester?: number
        /** @format uuid */
        student?: string
        /**
         * * `pending` - Pending
         * * `much_above` - Much Above
         * * `above` - Above
         * * `below` - Below
         * * `much_below` - Much Below
         */
        target_grade_status?:
          | 'above'
          | 'below'
          | 'much_above'
          | 'much_below'
          | 'pending'
        /** @format uuid */
        teacher_id?: string
        year?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AccGrade, T>, any>({
        path: `/grades/acc/idp-table/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesCustomGradesRetrieve
     * @request GET:/grades/custom-grades/
     */ gradesCustomGradesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/grades/custom-grades/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesHighSchoolGpaRetrieve
     * @request GET:/grades/high-school-gpa/{studentId}/
     */ gradesHighSchoolGpaRetrieve: <T extends FetchKeys<HighSchoolGPA>>(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HighSchoolGPA, T>, any>({
        path: `/grades/high-school-gpa/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesPeriodGpasRetrieve
     * @request GET:/grades/period-gpas/{studentId}/
     */ gradesPeriodGpasRetrieve: <
      T extends FetchKeys<ManyPeriodGPASerializer>
    >(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyPeriodGPASerializer, T>, any>({
        path: `/grades/period-gpas/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags grades
     * @name GradesTargetGradeStatusRetrieve
     * @request GET:/grades/target-grade-status/
     */ gradesTargetGradeStatusRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/grades/target-grade-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  health = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksList
     * @request GET:/health/checks/
     */
    healthChecksList: <T extends FetchKeys<PaginatedHealthCheckListList>>(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHealthCheckListList, T>, any>({
        path: `/health/checks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksRetrieve
     * @request GET:/health/checks/{id}/
     */ healthChecksRetrieve: <T extends FetchKeys<HealthCheck>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheck, T>, any>({
        path: `/health/checks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksMedicinesRetrieve
     * @request GET:/health/checks/{id}/medicines/
     */ healthChecksMedicinesRetrieve: <
      T extends FetchKeys<MedicinesInHealthCheck>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicinesInHealthCheck, T>, any>({
        path: `/health/checks/${id}/medicines/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksOptionalMedicinesRetrieve
     * @request GET:/health/checks/{id}/optional-medicines/
     */ healthChecksOptionalMedicinesRetrieve: <
      T extends FetchKeys<OptionalMedicinesInHealthCheck>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<OptionalMedicinesInHealthCheck, T>, any>({
        path: `/health/checks/${id}/optional-medicines/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksAvailableFiltersRetrieve
     * @request GET:/health/checks/available-filters/
     */ healthChecksAvailableFiltersRetrieve: <
      T extends FetchKeys<HealthCheckAvailableFilters>
    >(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckAvailableFilters, T>, any>({
        path: `/health/checks/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsList
     * @request GET:/health/checks/comments/
     */ healthChecksCommentsList: <T extends FetchKeys<HealthCheckComment[]>>(
      query?: {
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment[], T>, any>({
        path: `/health/checks/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsCreate
     * @request POST:/health/checks/comments/
     */ healthChecksCommentsCreate: <T extends FetchKeys<HealthCheckComment>>(
      data: HealthCheckCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsRetrieve
     * @request GET:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsRetrieve: <T extends FetchKeys<HealthCheckComment>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsUpdate
     * @request PUT:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsUpdate: <T extends FetchKeys<HealthCheckComment>>(
      healthCheckPk: string,
      data: HealthCheckCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsPartialUpdate
     * @request PATCH:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsPartialUpdate: <
      T extends FetchKeys<HealthCheckComment>
    >(
      healthCheckPk: string,
      data: PatchedHealthCheckCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckComment, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksCommentsDestroy
     * @request DELETE:/health/checks/comments/{healthCheckPk}/
     */ healthChecksCommentsDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/comments/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsList
     * @request GET:/health/checks/conditions/
     */ healthChecksConditionsList: <T extends FetchKeys<Condition[]>>(
      query?: {
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition[], T>, any>({
        path: `/health/checks/conditions/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsCreate
     * @request POST:/health/checks/conditions/
     */ healthChecksConditionsCreate: <T extends FetchKeys<Condition>>(
      data: ConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsRetrieve
     * @request GET:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsRetrieve: <T extends FetchKeys<Condition>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsUpdate
     * @request PUT:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsUpdate: <T extends FetchKeys<Condition>>(
      healthCheckPk: string,
      data: ConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsPartialUpdate
     * @request PATCH:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsPartialUpdate: <T extends FetchKeys<Condition>>(
      healthCheckPk: string,
      data: PatchedConditionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Condition, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksConditionsDestroy
     * @request DELETE:/health/checks/conditions/{healthCheckPk}/
     */ healthChecksConditionsDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/conditions/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveList
     * @request GET:/health/checks/drink-give/
     */ healthChecksDrinkGiveList: <T extends FetchKeys<DrinkGive[]>>(
      query?: {
        /** @format date-time */
        created?: string
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive[], T>, any>({
        path: `/health/checks/drink-give/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveCreate
     * @request POST:/health/checks/drink-give/
     */ healthChecksDrinkGiveCreate: <T extends FetchKeys<DrinkGive>>(
      data: DrinkGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveRetrieve
     * @request GET:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGiveRetrieve: <T extends FetchKeys<DrinkGive>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveUpdate
     * @request PUT:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGiveUpdate: <T extends FetchKeys<DrinkGive>>(
      healthCheckPk: string,
      data: DrinkGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGivePartialUpdate
     * @request PATCH:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGivePartialUpdate: <T extends FetchKeys<DrinkGive>>(
      healthCheckPk: string,
      data: PatchedDrinkGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DrinkGive, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksDrinkGiveDestroy
     * @request DELETE:/health/checks/drink-give/{healthCheckPk}/
     */ healthChecksDrinkGiveDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/drink-give/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveList
     * @request GET:/health/checks/food-give/
     */ healthChecksFoodGiveList: <T extends FetchKeys<FoodGive[]>>(
      query?: {
        /** @format date-time */
        created?: string
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive[], T>, any>({
        path: `/health/checks/food-give/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveCreate
     * @request POST:/health/checks/food-give/
     */ healthChecksFoodGiveCreate: <T extends FetchKeys<FoodGive>>(
      data: FoodGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveRetrieve
     * @request GET:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGiveRetrieve: <T extends FetchKeys<FoodGive>>(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveUpdate
     * @request PUT:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGiveUpdate: <T extends FetchKeys<FoodGive>>(
      healthCheckPk: string,
      data: FoodGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGivePartialUpdate
     * @request PATCH:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGivePartialUpdate: <T extends FetchKeys<FoodGive>>(
      healthCheckPk: string,
      data: PatchedFoodGiveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FoodGive, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksFoodGiveDestroy
     * @request DELETE:/health/checks/food-give/{healthCheckPk}/
     */ healthChecksFoodGiveDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/food-give/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksLogsList
     * @request GET:/health/checks/logs/
     */ healthChecksLogsList: <T extends FetchKeys<HealthCheckLog[]>>(
      query?: {
        /** @format date */
        date?: string
        health_check__sick_report?: string
        /** @format uuid */
        health_check__student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckLog[], T>, any>({
        path: `/health/checks/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksLogsRetrieve
     * @request GET:/health/checks/logs/{id}/
     */ healthChecksLogsRetrieve: <T extends FetchKeys<HealthCheckLog>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheckLog, T>, any>({
        path: `/health/checks/logs/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenList
     * @request GET:/health/checks/medicine-given/
     */ healthChecksMedicineGivenList: <T extends FetchKeys<MedicineGiven[]>>(
      query?: {
        /** @format uuid */
        health_check?: string
        is_regular_medicine?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven[], T>, any>({
        path: `/health/checks/medicine-given/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenCreate
     * @request POST:/health/checks/medicine-given/
     */ healthChecksMedicineGivenCreate: <T extends FetchKeys<MedicineGiven>>(
      data: MedicineGivenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenRetrieve
     * @request GET:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenRetrieve: <T extends FetchKeys<MedicineGiven>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenUpdate
     * @request PUT:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenUpdate: <T extends FetchKeys<MedicineGiven>>(
      id: string,
      data: MedicineGivenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenPartialUpdate
     * @request PATCH:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenPartialUpdate: <
      T extends FetchKeys<MedicineGiven>
    >(
      id: string,
      data: PatchedMedicineGivenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineGiven, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksMedicineGivenDestroy
     * @request DELETE:/health/checks/medicine-given/{id}/
     */ healthChecksMedicineGivenDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/medicine-given/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksMinAndMaxDateRetrieve
     * @request GET:/health/checks/min-and-max-date/
     */ healthChecksMinAndMaxDateRetrieve: <T extends FetchKeys<HealthCheck>>(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheck, T>, any>({
        path: `/health/checks/min-and-max-date/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags health
     * @name HealthChecksSlotsRetrieve
     * @request GET:/health/checks/slots/
     */ healthChecksSlotsRetrieve: <T extends FetchKeys<HealthCheck>>(
      query?: {
        /**
         * * `room` - room check
         * * `campus` - campus check
         */
        check_type?: 'campus' | 'room'
        /** @format date */
        date?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format time */
        start_time?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<HealthCheck, T>, any>({
        path: `/health/checks/slots/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureList
     * @request GET:/health/checks/temperature/
     */ healthChecksTemperatureList: <T extends FetchKeys<TemperatureCheck[]>>(
      query?: {
        /** @format uuid */
        health_check?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck[], T>, any>({
        path: `/health/checks/temperature/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureCreate
     * @request POST:/health/checks/temperature/
     */ healthChecksTemperatureCreate: <T extends FetchKeys<TemperatureCheck>>(
      data: TemperatureCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureRetrieve
     * @request GET:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperatureRetrieve: <
      T extends FetchKeys<TemperatureCheck>
    >(
      healthCheckPk: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureUpdate
     * @request PUT:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperatureUpdate: <T extends FetchKeys<TemperatureCheck>>(
      healthCheckPk: string,
      data: TemperatureCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperaturePartialUpdate
     * @request PATCH:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperaturePartialUpdate: <
      T extends FetchKeys<TemperatureCheck>
    >(
      healthCheckPk: string,
      data: PatchedTemperatureCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TemperatureCheck, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthChecksTemperatureDestroy
     * @request DELETE:/health/checks/temperature/{healthCheckPk}/
     */ healthChecksTemperatureDestroy: <T extends FetchKeys<void>>(
      healthCheckPk: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/checks/temperature/${healthCheckPk}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthHospitalsList
     * @request GET:/health/hospitals/
     */ healthHospitalsList: <T extends FetchKeys<PaginatedHospitalList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedHospitalList, T>, any>({
        path: `/health/hospitals/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthHospitalsCreate
     * @request POST:/health/hospitals/
     */ healthHospitalsCreate: <T extends FetchKeys<Hospital>>(
      data: HospitalRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Hospital, T>, any>({
        path: `/health/hospitals/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthHospitalsRetrieve
     * @request GET:/health/hospitals/{id}/
     */ healthHospitalsRetrieve: <T extends FetchKeys<Hospital>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Hospital, T>, any>({
        path: `/health/hospitals/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthHospitalsUpdate
     * @request PUT:/health/hospitals/{id}/
     */ healthHospitalsUpdate: <T extends FetchKeys<Hospital>>(
      id: number,
      data: HospitalRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Hospital, T>, any>({
        path: `/health/hospitals/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthHospitalsPartialUpdate
     * @request PATCH:/health/hospitals/{id}/
     */ healthHospitalsPartialUpdate: <T extends FetchKeys<Hospital>>(
      id: number,
      data: PatchedHospitalRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Hospital, T>, any>({
        path: `/health/hospitals/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthHospitalsDestroy
     * @request DELETE:/health/hospitals/{id}/
     */ healthHospitalsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/health/hospitals/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicineList
     * @request GET:/health/medicine/
     */ healthMedicineList: <T extends FetchKeys<PaginatedMedicineList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMedicineList, T>, any>({
        path: `/health/medicine/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicineCreate
     * @request POST:/health/medicine/
     */ healthMedicineCreate: <T extends FetchKeys<Medicine>>(
      data: MedicineRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Medicine, T>, any>({
        path: `/health/medicine/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanList
     * @request GET:/health/medicine-plan/medicine-in-plan/
     */ healthMedicinePlanMedicineInPlanList: <
      T extends FetchKeys<PaginatedMedicineInPlanList>
    >(
      query?: {
        /** @format date */
        end_date_before?: string
        is_archived?: boolean
        is_temporary?: boolean
        my_house_students?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format date */
        scheduled_medicine_for?: string
        /** A search term. */
        search?: string
        /** @format date */
        start_date_after?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMedicineInPlanList, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanCreate
     * @request POST:/health/medicine-plan/medicine-in-plan/
     */ healthMedicinePlanMedicineInPlanCreate: <
      T extends FetchKeys<MedicineInPlan>
    >(
      data: MedicineInPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicineInPlan, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanChangeEndDateUpdate
     * @request PUT:/health/medicine-plan/medicine-in-plan/{id}/change-end-date/
     */ healthMedicinePlanMedicineInPlanChangeEndDateUpdate: <
      T extends FetchKeys<ChangeEndDateMedicineInPlan>
    >(
      id: string,
      data: ChangeEndDateMedicineInPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeEndDateMedicineInPlan, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/${id}/change-end-date/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicineInPlanGetWarningsChangeEndDateCreate
     * @request POST:/health/medicine-plan/medicine-in-plan/{id}/get-warnings-change-end-date/
     */ healthMedicinePlanMedicineInPlanGetWarningsChangeEndDateCreate: <
      T extends FetchKeys<ChangeEndDateMedicineInPlan>
    >(
      id: string,
      data: ChangeEndDateMedicineInPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeEndDateMedicineInPlan, T>, any>({
        path: `/health/medicine-plan/medicine-in-plan/${id}/get-warnings-change-end-date/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicinePlanList
     * @request GET:/health/medicine-plan/medicine-plan/
     */ healthMedicinePlanMedicinePlanList: <
      T extends FetchKeys<PaginatedMedicinePlanList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        sick_report?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMedicinePlanList, T>, any>({
        path: `/health/medicine-plan/medicine-plan/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicinePlanMedicinePlanCreate
     * @request POST:/health/medicine-plan/medicine-plan/
     */ healthMedicinePlanMedicinePlanCreate: <
      T extends FetchKeys<MedicinePlan>
    >(
      data: MedicinePlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MedicinePlan, T>, any>({
        path: `/health/medicine-plan/medicine-plan/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthMedicineRetrieve
     * @request GET:/health/medicine/{id}/
     */ healthMedicineRetrieve: <T extends FetchKeys<Medicine>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Medicine, T>, any>({
        path: `/health/medicine/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags health
     * @name HealthScheduledMedicineList
     * @request GET:/health/scheduled-medicine/
     */ healthScheduledMedicineList: <T extends FetchKeys<ScheduledMedicine[]>>(
      query?: {
        /** @format date */
        date?: string
        is_deleted?: boolean
        medicine_in_plan?: string
        /** @format uuid */
        medicine_in_plan__student?: string
        /** @format date-time */
        should_be_given_at_after?: string
        /** @format date-time */
        should_be_given_at_before?: string
        was_given?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ScheduledMedicine[], T>, any>({
        path: `/health/scheduled-medicine/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  images = {
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags images
     * @name ImagesList
     * @request GET:/images/
     */
    imagesList: <T extends FetchKeys<PaginatedImageList>>(
      query?: {
        catalog?: number
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedImageList, T>, any>({
        path: `/images/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags images
     * @name ImagesCreate
     * @request POST:/images/
     */ imagesCreate: <T extends FetchKeys<Image>>(
      data: ImageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Image, T>, any>({
        path: `/images/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags images
     * @name ImagesRetrieve
     * @request GET:/images/{id}/
     */ imagesRetrieve: <T extends FetchKeys<Image>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Image, T>, any>({
        path: `/images/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags images
     * @name ImagesUpdate
     * @request PUT:/images/{id}/
     */ imagesUpdate: <T extends FetchKeys<Image>>(
      id: string,
      data: ImageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Image, T>, any>({
        path: `/images/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags images
     * @name ImagesPartialUpdate
     * @request PATCH:/images/{id}/
     */ imagesPartialUpdate: <T extends FetchKeys<Image>>(
      id: string,
      data: PatchedImageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Image, T>, any>({
        path: `/images/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with ModelViewSet, or trigger invalidate_cache method manually
     *
     * @tags images
     * @name ImagesDestroy
     * @request DELETE:/images/{id}/
     */ imagesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/images/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags images
     * @name ImagesCatalogsList
     * @request GET:/images/catalogs/
     */ imagesCatalogsList: <T extends FetchKeys<PaginatedCatalogList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCatalogList, T>, any>({
        path: `/images/catalogs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags images
     * @name ImagesCatalogsCreate
     * @request POST:/images/catalogs/
     */ imagesCatalogsCreate: <T extends FetchKeys<Catalog>>(
      data: CatalogRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Catalog, T>, any>({
        path: `/images/catalogs/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags images
     * @name ImagesCatalogsRetrieve
     * @request GET:/images/catalogs/{id}/
     */ imagesCatalogsRetrieve: <T extends FetchKeys<Catalog>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Catalog, T>, any>({
        path: `/images/catalogs/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags images
     * @name ImagesCatalogsUpdate
     * @request PUT:/images/catalogs/{id}/
     */ imagesCatalogsUpdate: <T extends FetchKeys<Catalog>>(
      id: number,
      data: CatalogRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Catalog, T>, any>({
        path: `/images/catalogs/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags images
     * @name ImagesCatalogsPartialUpdate
     * @request PATCH:/images/catalogs/{id}/
     */ imagesCatalogsPartialUpdate: <T extends FetchKeys<Catalog>>(
      id: number,
      data: PatchedCatalogRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Catalog, T>, any>({
        path: `/images/catalogs/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags images
     * @name ImagesCatalogsDestroy
     * @request DELETE:/images/catalogs/{id}/
     */ imagesCatalogsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/images/catalogs/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  infoboards = {
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsList
     * @request GET:/infoboards/boards/
     */
    infoboardsBoardsList: <T extends FetchKeys<PaginatedAdminBoardList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `unpublished` - Unpublished
         * * `published` - Published
         */
        status?: 'published' | 'unpublished'
        widget_type?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminBoardList, T>, any>({
        path: `/infoboards/boards/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsCreate
     * @request POST:/infoboards/boards/
     */ infoboardsBoardsCreate: <T extends FetchKeys<AdminBoard>>(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsRetrieve
     * @request GET:/infoboards/boards/{id}/
     */ infoboardsBoardsRetrieve: <T extends FetchKeys<AdminBoard>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsUpdate
     * @request PUT:/infoboards/boards/{id}/
     */ infoboardsBoardsUpdate: <T extends FetchKeys<AdminBoard>>(
      id: string,
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsPartialUpdate
     * @request PATCH:/infoboards/boards/{id}/
     */ infoboardsBoardsPartialUpdate: <T extends FetchKeys<AdminBoard>>(
      id: string,
      data: PatchedAdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsDestroy
     * @request DELETE:/infoboards/boards/{id}/
     */ infoboardsBoardsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/boards/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsTogglePublishCreate
     * @request POST:/infoboards/boards/{id}/toggle-publish/
     */ infoboardsBoardsTogglePublishCreate: <T extends FetchKeys<AdminBoard>>(
      id: string,
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/${id}/toggle-publish/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsChangeOrderCreate
     * @request POST:/infoboards/boards/change-order/
     */ infoboardsBoardsChangeOrderCreate: <T extends FetchKeys<AdminBoard>>(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/change-order/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsCountRetrieve
     * @request GET:/infoboards/boards/count/
     */ infoboardsBoardsCountRetrieve: <T extends FetchKeys<AdminBoard>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `unpublished` - Unpublished
         * * `published` - Published
         */
        status?: 'published' | 'unpublished'
        widget_type?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsPublishMultipleCreate
     * @request POST:/infoboards/boards/publish-multiple/
     */ infoboardsBoardsPublishMultipleCreate: <
      T extends FetchKeys<AdminBoard>
    >(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/publish-multiple/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags infoboards
     * @name InfoboardsBoardsUnpublishMultipleCreate
     * @request POST:/infoboards/boards/unpublish-multiple/
     */ infoboardsBoardsUnpublishMultipleCreate: <
      T extends FetchKeys<AdminBoard>
    >(
      data: AdminBoardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoard, T>, any>({
        path: `/infoboards/boards/unpublish-multiple/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsMessagesList
     * @request GET:/infoboards/messages/
     */ infoboardsMessagesList: <
      T extends FetchKeys<PaginatedAdminMessageList>
    >(
      query?: {
        /** @format date */
        created?: string
        is_created_manually?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `alert` - Alert
         * * `notice` - Notice
         */
        type?: 'alert' | 'notice'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminMessageList, T>, any>({
        path: `/infoboards/messages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsMessagesCreate
     * @request POST:/infoboards/messages/
     */ infoboardsMessagesCreate: <T extends FetchKeys<AdminMessage>>(
      data: AdminMessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMessage, T>, any>({
        path: `/infoboards/messages/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsMessagesRetrieve
     * @request GET:/infoboards/messages/{id}/
     */ infoboardsMessagesRetrieve: <T extends FetchKeys<AdminMessage>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMessage, T>, any>({
        path: `/infoboards/messages/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsMessagesUpdate
     * @request PUT:/infoboards/messages/{id}/
     */ infoboardsMessagesUpdate: <T extends FetchKeys<AdminMessage>>(
      id: number,
      data: AdminMessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMessage, T>, any>({
        path: `/infoboards/messages/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsMessagesPartialUpdate
     * @request PATCH:/infoboards/messages/{id}/
     */ infoboardsMessagesPartialUpdate: <T extends FetchKeys<AdminMessage>>(
      id: number,
      data: PatchedAdminMessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMessage, T>, any>({
        path: `/infoboards/messages/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsMessagesDestroy
     * @request DELETE:/infoboards/messages/{id}/
     */ infoboardsMessagesDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/messages/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsPublicBoardsList
     * @request GET:/infoboards/public/boards/
     */ infoboardsPublicBoardsList: <T extends FetchKeys<PublicBoard[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicBoard[], T>, any>({
        path: `/infoboards/public/boards/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsPublicBoardsRetrieve
     * @request GET:/infoboards/public/boards/{id}/
     */ infoboardsPublicBoardsRetrieve: <T extends FetchKeys<PublicBoard>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicBoard, T>, any>({
        path: `/infoboards/public/boards/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsPublicJournalArticlesList
     * @request GET:/infoboards/public/journal-articles/
     */ infoboardsPublicJournalArticlesList: <
      T extends FetchKeys<PublicRecentJournalArticle[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicRecentJournalArticle[], T>, any>({
        path: `/infoboards/public/journal-articles/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsPublicMessagesList
     * @request GET:/infoboards/public/messages/
     */ infoboardsPublicMessagesList: <T extends FetchKeys<PublicMessage[]>>(
      query?: {
        /**
         * * `alert` - Alert
         * * `notice` - Notice
         */
        type?: 'alert' | 'notice'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicMessage[], T>, any>({
        path: `/infoboards/public/messages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsPublicMessagesRetrieve
     * @request GET:/infoboards/public/messages/{id}/
     */ infoboardsPublicMessagesRetrieve: <T extends FetchKeys<PublicMessage>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicMessage, T>, any>({
        path: `/infoboards/public/messages/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsPublicNextLessonsList
     * @request GET:/infoboards/public/next-lessons/
     */ infoboardsPublicNextLessonsList: <
      T extends FetchKeys<PaginatedPublicNextLessonList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicNextLessonList, T>, any>({
        path: `/infoboards/public/next-lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsPublicUpdateStatusRetrieve
     * @request GET:/infoboards/public/update-status/
     */ infoboardsPublicUpdateStatusRetrieve: <
      T extends FetchKeys<PublicUpdateStatus>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicUpdateStatus, T>, any>({
        path: `/infoboards/public/update-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsSchedulesList
     * @request GET:/infoboards/schedules/
     */ infoboardsSchedulesList: <
      T extends FetchKeys<PaginatedAdminBoardScheduleList>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        recurring?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminBoardScheduleList, T>, any>({
        path: `/infoboards/schedules/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsSchedulesCreate
     * @request POST:/infoboards/schedules/
     */ infoboardsSchedulesCreate: <T extends FetchKeys<AdminBoardSchedule>>(
      data: AdminBoardScheduleRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardSchedule, T>, any>({
        path: `/infoboards/schedules/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsSchedulesRetrieve
     * @request GET:/infoboards/schedules/{id}/
     */ infoboardsSchedulesRetrieve: <T extends FetchKeys<AdminBoardSchedule>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardSchedule, T>, any>({
        path: `/infoboards/schedules/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsSchedulesUpdate
     * @request PUT:/infoboards/schedules/{id}/
     */ infoboardsSchedulesUpdate: <T extends FetchKeys<AdminBoardSchedule>>(
      id: number,
      data: AdminBoardScheduleRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardSchedule, T>, any>({
        path: `/infoboards/schedules/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsSchedulesPartialUpdate
     * @request PATCH:/infoboards/schedules/{id}/
     */ infoboardsSchedulesPartialUpdate: <
      T extends FetchKeys<AdminBoardSchedule>
    >(
      id: number,
      data: PatchedAdminBoardScheduleRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminBoardSchedule, T>, any>({
        path: `/infoboards/schedules/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsSchedulesDestroy
     * @request DELETE:/infoboards/schedules/{id}/
     */ infoboardsSchedulesDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/schedules/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsSettingsRetrieve
     * @request GET:/infoboards/settings/
     */ infoboardsSettingsRetrieve: <
      T extends FetchKeys<AdminInfoboardsSettings>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminInfoboardsSettings, T>, any>({
        path: `/infoboards/settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsSettingsUpdate
     * @request PUT:/infoboards/settings/
     */ infoboardsSettingsUpdate: <
      T extends FetchKeys<AdminInfoboardsSettings>
    >(
      data: AdminInfoboardsSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminInfoboardsSettings, T>, any>({
        path: `/infoboards/settings/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsSettingsPartialUpdate
     * @request PATCH:/infoboards/settings/
     */ infoboardsSettingsPartialUpdate: <
      T extends FetchKeys<AdminInfoboardsSettings>
    >(
      data: PatchedAdminInfoboardsSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminInfoboardsSettings, T>, any>({
        path: `/infoboards/settings/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentList
     * @request GET:/infoboards/student-content/
     */ infoboardsStudentContentList: <
      T extends FetchKeys<PaginatedAdminStudentContentList>
    >(
      query?: {
        /**
         * Ordering
         *
         * * `created` - Created
         * * `-created` - Created (descending)
         * * `modified` - Modified
         * * `-modified` - Modified (descending)
         * * `published_at` - Published at
         * * `-published_at` - Published at (descending)
         * * `display_to` - Display to
         * * `-display_to` - Display to (descending)
         * * `status` - Status
         * * `-status` - Status (descending)
         */
        ordering?: (
          | '-created'
          | '-display_to'
          | '-modified'
          | '-published_at'
          | '-status'
          | 'created'
          | 'display_to'
          | 'modified'
          | 'published_at'
          | 'status'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `pending` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `deleted` - Deleted
         * * `archived` - Archived
         */
        status?: 'approved' | 'archived' | 'deleted' | 'pending' | 'rejected'
        /**
         * * `StudentMysteryLink` - None
         * * `StudentAnnouncement` - None
         * * `StudentPic` - None
         */
        student_content_type?:
          | 'StudentAnnouncement'
          | 'StudentMysteryLink'
          | 'StudentPic'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminStudentContentList, T>, any>({
        path: `/infoboards/student-content/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentRetrieve
     * @request GET:/infoboards/student-content/{id}/
     */ infoboardsStudentContentRetrieve: <
      T extends FetchKeys<AdminStudentContent>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentContent, T>, any>({
        path: `/infoboards/student-content/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentDestroy
     * @request DELETE:/infoboards/student-content/{id}/
     */ infoboardsStudentContentDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/student-content/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentApprovePartialUpdate
     * @request PATCH:/infoboards/student-content/{id}/approve/
     */ infoboardsStudentContentApprovePartialUpdate: <
      T extends FetchKeys<ApproveStudentContent>
    >(
      id: string,
      data: PatchedApproveStudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ApproveStudentContent, T>, any>({
        path: `/infoboards/student-content/${id}/approve/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentRejectPartialUpdate
     * @request PATCH:/infoboards/student-content/{id}/reject/
     */ infoboardsStudentContentRejectPartialUpdate: <
      T extends FetchKeys<AdminStudentContent>
    >(
      id: string,
      data: PatchedAdminStudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentContent, T>, any>({
        path: `/infoboards/student-content/${id}/reject/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags infoboards
     * @name InfoboardsStudentContentPendingItemsRetrieve
     * @request GET:/infoboards/student-content/pending-items/
     */ infoboardsStudentContentPendingItemsRetrieve: <
      T extends FetchKeys<AdminStudentContent>
    >(
      query?: {
        /**
         * Ordering
         *
         * * `created` - Created
         * * `-created` - Created (descending)
         * * `modified` - Modified
         * * `-modified` - Modified (descending)
         * * `published_at` - Published at
         * * `-published_at` - Published at (descending)
         * * `display_to` - Display to
         * * `-display_to` - Display to (descending)
         * * `status` - Status
         * * `-status` - Status (descending)
         */
        ordering?: (
          | '-created'
          | '-display_to'
          | '-modified'
          | '-published_at'
          | '-status'
          | 'created'
          | 'display_to'
          | 'modified'
          | 'published_at'
          | 'status'
        )[]
        /** A search term. */
        search?: string
        /**
         * * `pending` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `deleted` - Deleted
         * * `archived` - Archived
         */
        status?: 'approved' | 'archived' | 'deleted' | 'pending' | 'rejected'
        /**
         * * `StudentMysteryLink` - None
         * * `StudentAnnouncement` - None
         * * `StudentPic` - None
         */
        student_content_type?:
          | 'StudentAnnouncement'
          | 'StudentMysteryLink'
          | 'StudentPic'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentContent, T>, any>({
        path: `/infoboards/student-content/pending-items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsList
     * @request GET:/infoboards/widgets/
     */ infoboardsWidgetsList: <T extends FetchKeys<PaginatedAdminWidgetList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        polymorphic_ctype?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminWidgetList, T>, any>({
        path: `/infoboards/widgets/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsCreate
     * @request POST:/infoboards/widgets/
     */ infoboardsWidgetsCreate: <T extends FetchKeys<AdminWidget>>(
      data: AdminWidgetRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsRetrieve
     * @request GET:/infoboards/widgets/{id}/
     */ infoboardsWidgetsRetrieve: <T extends FetchKeys<AdminWidget>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsUpdate
     * @request PUT:/infoboards/widgets/{id}/
     */ infoboardsWidgetsUpdate: <T extends FetchKeys<AdminWidget>>(
      id: string,
      data: AdminWidgetRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsPartialUpdate
     * @request PATCH:/infoboards/widgets/{id}/
     */ infoboardsWidgetsPartialUpdate: <T extends FetchKeys<AdminWidget>>(
      id: string,
      data: PatchedAdminWidgetRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsDestroy
     * @request DELETE:/infoboards/widgets/{id}/
     */ infoboardsWidgetsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/infoboards/widgets/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsAvailableRetrieve
     * @request GET:/infoboards/widgets/available/
     */ infoboardsWidgetsAvailableRetrieve: <T extends FetchKeys<AdminWidget>>(
      query?: {
        polymorphic_ctype?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminWidget, T>, any>({
        path: `/infoboards/widgets/available/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags infoboards
     * @name InfoboardsWidgetsTypesList
     * @request GET:/infoboards/widgets/types/
     */ infoboardsWidgetsTypesList: <T extends FetchKeys<AdminTypes[]>>(
      query?: {
        polymorphic_ctype?: number
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminTypes[], T>, any>({
        path: `/infoboards/widgets/types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  internships = {
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsList
     * @request GET:/internships/
     */
    internshipsList: <T extends FetchKeys<PaginatedInternshipList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedInternshipList, T>, any>({
        path: `/internships/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsRetrieve
     * @request GET:/internships/{id}/
     */ internshipsRetrieve: <T extends FetchKeys<Internship>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Internship, T>, any>({
        path: `/internships/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsStudentsList
     * @request GET:/internships/students/{studentId}/
     */ internshipsStudentsList: <
      T extends FetchKeys<PaginatedStudentInternshipList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentInternshipList, T>, any>({
        path: `/internships/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsStudentsUpdate
     * @request PUT:/internships/students/{studentId}/
     */ internshipsStudentsUpdate: <
      T extends FetchKeys<StudentInternshipsUpdate>
    >(
      studentId: string,
      data: StudentInternshipsUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentInternshipsUpdate, T>, any>({
        path: `/internships/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags internships
     * @name InternshipsStudentsPartialUpdate
     * @request PATCH:/internships/students/{studentId}/
     */ internshipsStudentsPartialUpdate: <
      T extends FetchKeys<StudentInternshipsUpdate>
    >(
      studentId: string,
      data: PatchedStudentInternshipsUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentInternshipsUpdate, T>, any>({
        path: `/internships/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  kitchen = {
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMealAttendanceRetrieve
     * @request GET:/kitchen/meal-attendance/
     */
    kitchenMealAttendanceRetrieve: <
      T extends FetchKeys<MealAttendanceResponse>
    >(
      query?: {
        /** @format date */
        date?: string
        diet_type?: 'l1' | 'l2' | 'l3' | 'l4'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MealAttendanceResponse, T>, any>({
        path: `/kitchen/meal-attendance/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMealPreferencesStudentsCreate
     * @request POST:/kitchen/meal-preferences/students/
     */ kitchenMealPreferencesStudentsCreate: <
      T extends FetchKeys<StudentMealPreference>
    >(
      data: StudentMealPreferenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMealPreference, T>, any>({
        path: `/kitchen/meal-preferences/students/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMealPreferencesStudentsHistoryList
     * @request GET:/kitchen/meal-preferences/students/{studentId}/history
     */ kitchenMealPreferencesStudentsHistoryList: <
      T extends FetchKeys<StudentMealPreferenceHistory[]>
    >(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMealPreferenceHistory[], T>, any>({
        path: `/kitchen/meal-preferences/students/${studentId}/history`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuList
     * @request GET:/kitchen/menu/
     */ kitchenMenuList: <T extends FetchKeys<PaginatedDayMenuList>>(
      query?: {
        /** @format date */
        date?: string
        is_filled?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        week?: number
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDayMenuList, T>, any>({
        path: `/kitchen/menu/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuCreate
     * @request POST:/kitchen/menu/
     */ kitchenMenuCreate: <T extends FetchKeys<DayMenu>>(
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuRetrieve
     * @request GET:/kitchen/menu/{id}/
     */ kitchenMenuRetrieve: <T extends FetchKeys<DayMenu>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuUpdate
     * @request PUT:/kitchen/menu/{id}/
     */ kitchenMenuUpdate: <T extends FetchKeys<DayMenu>>(
      id: number,
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuPartialUpdate
     * @request PATCH:/kitchen/menu/{id}/
     */ kitchenMenuPartialUpdate: <T extends FetchKeys<DayMenu>>(
      id: number,
      data: PatchedDayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuIsPublishedRetrieve
     * @request GET:/kitchen/menu/is-published/{week}/{year}/
     */ kitchenMenuIsPublishedRetrieve: <T extends FetchKeys<DayMenu>>(
      week: string,
      year: string,
      query?: {
        /** @format date */
        date?: string
        is_filled?: boolean
        week?: number
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/is-published/${week}/${year}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuPublishCreate
     * @request POST:/kitchen/menu/publish/{week}/{year}/
     */ kitchenMenuPublishCreate: <T extends FetchKeys<DayMenu>>(
      week: string,
      year: string,
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/publish/${week}/${year}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags kitchen
     * @name KitchenMenuUnpublishCreate
     * @request POST:/kitchen/menu/unpublish/{week}/{year}/
     */ kitchenMenuUnpublishCreate: <T extends FetchKeys<DayMenu>>(
      week: string,
      year: string,
      data: DayMenuRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DayMenu, T>, any>({
        path: `/kitchen/menu/unpublish/${week}/${year}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  leaves = {
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksCreate
     * @request POST:/leaves/checks/
     */
    leavesChecksCreate: <T extends FetchKeys<LeaveCheck>>(
      data: LeaveCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksRetrieve
     * @request GET:/leaves/checks/{id}/
     */ leavesChecksRetrieve: <T extends FetchKeys<LeaveCheck>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksUpdate
     * @request PUT:/leaves/checks/{id}/
     */ leavesChecksUpdate: <T extends FetchKeys<LeaveCheck>>(
      id: string,
      data: LeaveCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksPartialUpdate
     * @request PATCH:/leaves/checks/{id}/
     */ leavesChecksPartialUpdate: <T extends FetchKeys<LeaveCheck>>(
      id: string,
      data: PatchedLeaveCheckRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheck, T>, any>({
        path: `/leaves/checks/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesChecksCompleteCreate
     * @request POST:/leaves/checks/{id}/complete/
     */ leavesChecksCompleteCreate: <T extends FetchKeys<LeaveCheckComplete>>(
      id: string,
      data: LeaveCheckCompleteRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveCheckComplete, T>, any>({
        path: `/leaves/checks/${id}/complete/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsList
     * @request GET:/leaves/requests/
     */ leavesRequestsList: <T extends FetchKeys<PaginatedLeaveRequestList>>(
      query?: {
        current_year_only?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        house?: string
        is_completed?: string
        is_driver_needed?: boolean
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        show_todays?: boolean
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?:
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedLeaveRequestList, T>, any>({
        path: `/leaves/requests/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsCreate
     * @request POST:/leaves/requests/
     */ leavesRequestsCreate: <T extends FetchKeys<LeaveRequest>>(
      data: LeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsRetrieve
     * @request GET:/leaves/requests/{id}/
     */ leavesRequestsRetrieve: <T extends FetchKeys<LeaveRequest>>(
      id: string,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsUpdate
     * @request PUT:/leaves/requests/{id}/
     */ leavesRequestsUpdate: <T extends FetchKeys<LeaveRequest>>(
      id: string,
      data: LeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/`,
        method: 'PUT',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsPartialUpdate
     * @request PATCH:/leaves/requests/{id}/
     */ leavesRequestsPartialUpdate: <T extends FetchKeys<LeaveRequest>>(
      id: string,
      data: PatchedLeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/`,
        method: 'PATCH',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description This endpoint is deprecated. Please use /admin/students/clashes/
     *
     * @tags leaves
     * @name LeavesRequestsClashesRetrieve
     * @request GET:/leaves/requests/{id}/clashes/
     * @deprecated
     */ leavesRequestsClashesRetrieve: <T extends FetchKeys<StudentClashes>>(
      id: string,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentClashes, T>, any>({
        path: `/leaves/requests/${id}/clashes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsReviewByAcademicCreate
     * @request POST:/leaves/requests/{id}/review-by-academic/
     */ leavesRequestsReviewByAcademicCreate: <
      T extends FetchKeys<ReviewLeaveRequest>
    >(
      id: string,
      data: ReviewLeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ReviewLeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/review-by-academic/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsReviewByBoardingCreate
     * @request POST:/leaves/requests/{id}/review-by-boarding/
     */ leavesRequestsReviewByBoardingCreate: <
      T extends FetchKeys<ReviewLeaveRequest>
    >(
      id: string,
      data: ReviewLeaveRequestRequest,
      query?: {
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ReviewLeaveRequest, T>, any>({
        path: `/leaves/requests/${id}/review-by-boarding/`,
        method: 'POST',
        query: postprocessQuery(query),
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags leaves
     * @name LeavesRequestsStatusesList
     * @request GET:/leaves/requests/statuses/
     */ leavesRequestsStatusesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        current_year_only?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        house?: string
        is_completed?: string
        is_driver_needed?: boolean
        ordering?:
          | '-check_in_date'
          | '-check_out_date'
          | 'check_in_date'
          | 'check_out_date'
        show_todays?: boolean
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?:
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/leaves/requests/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  lessonCancellation = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags lesson-cancellation
     * @name LessonCancellationCancelLessonList
     * @request GET:/lesson-cancellation/cancel-lesson/
     */
    lessonCancellationCancelLessonList: <
      T extends FetchKeys<PaginatedCancelLessonList>
    >(
      query?: {
        /**
         * * `TEACHER_HAS_EXAM` - Artisan has exam
         * * `COVER_TEACHER_HAS_EXAM` - Cover artisan has exam
         * * `TEACHER_HAS_SCHOOL_PROGRAMME` - Artisan has school programme
         * * `COVER_TEACHER_HAS_SCHOOL_PROGRAMME` - Cover artisan has school programme
         * * `ALL_STUDENTS_CANCELLED` - All students have cancelled lesson
         * * `SCHOOL_BREAK_PERIOD` - Lesson is cancelled due to school's break
         */
        cancellation_reason?:
          | 'ALL_STUDENTS_CANCELLED'
          | 'COVER_TEACHER_HAS_EXAM'
          | 'COVER_TEACHER_HAS_SCHOOL_PROGRAMME'
          | 'SCHOOL_BREAK_PERIOD'
          | 'TEACHER_HAS_EXAM'
          | 'TEACHER_HAS_SCHOOL_PROGRAMME'
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        /** A search term. */
        search?: string
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCancelLessonList, T>, any>({
        path: `/lesson-cancellation/cancel-lesson/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags lesson-cancellation
     * @name LessonCancellationCancelLessonRetrieve
     * @request GET:/lesson-cancellation/cancel-lesson/{id}/
     */ lessonCancellationCancelLessonRetrieve: <
      T extends FetchKeys<CancelLesson>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CancelLesson, T>, any>({
        path: `/lesson-cancellation/cancel-lesson/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags lesson-cancellation
     * @name LessonCancellationCancelLessonAvailableFiltersRetrieve
     * @request GET:/lesson-cancellation/cancel-lesson/available-filters/
     */ lessonCancellationCancelLessonAvailableFiltersRetrieve: <
      T extends FetchKeys<CancelLesson>
    >(
      query?: {
        /**
         * * `TEACHER_HAS_EXAM` - Artisan has exam
         * * `COVER_TEACHER_HAS_EXAM` - Cover artisan has exam
         * * `TEACHER_HAS_SCHOOL_PROGRAMME` - Artisan has school programme
         * * `COVER_TEACHER_HAS_SCHOOL_PROGRAMME` - Cover artisan has school programme
         * * `ALL_STUDENTS_CANCELLED` - All students have cancelled lesson
         * * `SCHOOL_BREAK_PERIOD` - Lesson is cancelled due to school's break
         */
        cancellation_reason?:
          | 'ALL_STUDENTS_CANCELLED'
          | 'COVER_TEACHER_HAS_EXAM'
          | 'COVER_TEACHER_HAS_SCHOOL_PROGRAMME'
          | 'SCHOOL_BREAK_PERIOD'
          | 'TEACHER_HAS_EXAM'
          | 'TEACHER_HAS_SCHOOL_PROGRAMME'
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        quarter?: number
        /** A search term. */
        search?: string
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CancelLesson, T>, any>({
        path: `/lesson-cancellation/cancel-lesson/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  logs = {
    /**
     * No description
     *
     * @tags logs
     * @name LogsList
     * @request GET:/logs/
     */
    logsList: <T extends FetchKeys<PaginatedLogEntryList>>(
      query?: {
        /**
         * * `0` - create
         * * `1` - update
         * * `2` - delete
         */
        action?: '0' | '1' | '2' | null
        /** @format uuid */
        actor?: string
        content_type?: number
        object?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** @format date-time */
        timestamp_after?: string
        /** @format date-time */
        timestamp_before?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedLogEntryList, T>, any>({
        path: `/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags logs
     * @name LogsRetrieve
     * @request GET:/logs/{id}/
     */ logsRetrieve: <T extends FetchKeys<LogEntry>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LogEntry, T>, any>({
        path: `/logs/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  merits = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsList
     * @request GET:/merits/
     */
    meritsList: <T extends FetchKeys<PaginatedMeritList>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMeritList, T>, any>({
        path: `/merits/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsCreate
     * @request POST:/merits/
     */ meritsCreate: <T extends FetchKeys<Merit>>(
      data: MeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsRetrieve
     * @request GET:/merits/{id}/
     */ meritsRetrieve: <T extends FetchKeys<Merit>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsUpdate
     * @request PUT:/merits/{id}/
     */ meritsUpdate: <T extends FetchKeys<Merit>>(
      id: number,
      data: MeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsPartialUpdate
     * @request PATCH:/merits/{id}/
     */ meritsPartialUpdate: <T extends FetchKeys<Merit>>(
      id: number,
      data: PatchedMeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsDestroy
     * @request DELETE:/merits/{id}/
     */ meritsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/merits/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsConfirmUpdate
     * @request PUT:/merits/{id}/confirm/
     */ meritsConfirmUpdate: <T extends FetchKeys<MeritConfirm>>(
      id: number,
      data: MeritConfirmRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritConfirm, T>, any>({
        path: `/merits/${id}/confirm/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsDeescalateCreate
     * @request POST:/merits/{id}/deescalate/
     */ meritsDeescalateCreate: <T extends FetchKeys<Merit>>(
      id: number,
      data: MeritRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/deescalate/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsPreviewSanctionRetrieve
     * @request GET:/merits/{id}/preview-sanction/
     */ meritsPreviewSanctionRetrieve: <T extends FetchKeys<Merit>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Merit, T>, any>({
        path: `/merits/${id}/preview-sanction/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsRejectUpdate
     * @request PUT:/merits/{id}/reject/
     */ meritsRejectUpdate: <T extends FetchKeys<MeritReject>>(
      id: number,
      data: MeritRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritReject, T>, any>({
        path: `/merits/${id}/reject/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsBehavioursList
     * @request GET:/merits/behaviours/
     */ meritsBehavioursList: <T extends FetchKeys<MeritItemBehaviour[]>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItemBehaviour[], T>, any>({
        path: `/merits/behaviours/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsFilterOptionsRetrieve
     * @request GET:/merits/filter-options/
     */ meritsFilterOptionsRetrieve: <T extends FetchKeys<MeritFilterOptions>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritFilterOptions, T>, any>({
        path: `/merits/filter-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsList
     * @request GET:/merits/items/
     */ meritsItemsList: <T extends FetchKeys<PaginatedMeritItemList>>(
      query?: {
        allow_to_select?: boolean
        /**
         * * `week` - Weekly
         * * `quarter` - Quarterly
         * * `semester` - Semesterly
         * * `year` - Yearly
         */
        escalation_expire_after?: 'quarter' | 'semester' | 'week' | 'year'
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMeritItemList, T>, any>({
        path: `/merits/items/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsRetrieve
     * @request GET:/merits/items/{id}/
     */ meritsItemsRetrieve: <T extends FetchKeys<MeritItem>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItem, T>, any>({
        path: `/merits/items/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsUpdate
     * @request PUT:/merits/items/{id}/
     */ meritsItemsUpdate: <T extends FetchKeys<MeritItem>>(
      id: number,
      data: MeritItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItem, T>, any>({
        path: `/merits/items/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsPartialUpdate
     * @request PATCH:/merits/items/{id}/
     */ meritsItemsPartialUpdate: <T extends FetchKeys<MeritItem>>(
      id: number,
      data: PatchedMeritItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItem, T>, any>({
        path: `/merits/items/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags merits
     * @name MeritsItemsAvailableFiltersRetrieve
     * @request GET:/merits/items/available-filters/
     */ meritsItemsAvailableFiltersRetrieve: <
      T extends FetchKeys<MeritItemAvailableFilters>
    >(
      query?: {
        allow_to_select?: boolean
        /**
         * * `week` - Weekly
         * * `quarter` - Quarterly
         * * `semester` - Semesterly
         * * `year` - Yearly
         */
        escalation_expire_after?: 'quarter' | 'semester' | 'week' | 'year'
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItemAvailableFilters, T>, any>({
        path: `/merits/items/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Handler method for HTTP 'OPTIONS' request.
     *
     * @tags merits
     * @name MeritsItemsOptionsRetrieve
     * @request GET:/merits/items/options/
     */ meritsItemsOptionsRetrieve: <T extends FetchKeys<MeritItemOptions>>(
      query?: {
        allow_to_select?: boolean
        /**
         * * `week` - Weekly
         * * `quarter` - Quarterly
         * * `semester` - Semesterly
         * * `year` - Yearly
         */
        escalation_expire_after?: 'quarter' | 'semester' | 'week' | 'year'
        name?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritItemOptions, T>, any>({
        path: `/merits/items/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits
     * @name MeritsStatisticsRetrieve
     * @request GET:/merits/statistics/
     */ meritsStatisticsRetrieve: <T extends FetchKeys<MeritStatistics>>(
      query?: {
        academic_only?: boolean
        /** @format date */
        added_on?: string
        /** @format uuid */
        author?: string
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** @format date-time */
        datetime_after?: string
        /** @format date-time */
        datetime_before?: string
        /** @format date-time */
        escalate_until_after?: string
        /** @format date-time */
        escalate_until_before?: string
        /**
         * * `merit` - Merit
         * * `verbal_commendation` - Verbal commendation
         * * `written_commendation` - Written commendation
         * * `certificate` - Certificate
         * * `verbal_warning` - Verbal warning
         * * `written_warning` - Written warning
         * * `ultimatum` - Ultimatum
         * * `suspension` - Suspension
         * * `exclusion` - Exclusion
         */
        escalated_sanction?:
          | 'certificate'
          | 'exclusion'
          | 'merit'
          | 'suspension'
          | 'ultimatum'
          | 'verbal_commendation'
          | 'verbal_warning'
          | 'written_commendation'
          | 'written_warning'
        house?: string
        merit_iso_year_and_week?: string
        merit_item?: number
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        merit_type?: 'demerit' | 'merit'
        /** Which field to use when ordering the results. */
        ordering?: string
        show_not_reviewed?: boolean
        /**
         * * `pending` - Pending
         * * `rejected` - Rejected
         * * `confirmed` - Approved
         * * `deescalated` - De-escalated
         */
        status?: 'confirmed' | 'deescalated' | 'pending' | 'rejected'
        /** @format uuid */
        student?: string
        /** @format float */
        value?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritStatistics, T>, any>({
        path: `/merits/statistics/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  meritsExchange = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeList
     * @request GET:/merits-exchange/
     */
    meritsExchangeList: <T extends FetchKeys<PaginatedAdminMeritRewardList>>(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        resourcetype?: string
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminMeritRewardList, T>, any>({
        path: `/merits-exchange/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeRetrieve
     * @request GET:/merits-exchange/{id}/
     */ meritsExchangeRetrieve: <T extends FetchKeys<AdminMeritReward>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMeritReward, T>, any>({
        path: `/merits-exchange/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeApproveCreate
     * @request POST:/merits-exchange/{id}/approve/
     */ meritsExchangeApproveCreate: <T extends FetchKeys<MeritRewardApprove>>(
      id: string,
      data: MeritRewardApproveRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardApprove, T>, any>({
        path: `/merits-exchange/${id}/approve/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeAvailableStudentsRetrieve
     * @request GET:/merits-exchange/{id}/available-students/
     */ meritsExchangeAvailableStudentsRetrieve: <
      T extends FetchKeys<AdminMeritReward>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminMeritReward, T>, any>({
        path: `/merits-exchange/${id}/available-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeCancelCreate
     * @request POST:/merits-exchange/{id}/cancel/
     */ meritsExchangeCancelCreate: <T extends FetchKeys<MeritRewardReject>>(
      id: string,
      data: MeritRewardRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardReject, T>, any>({
        path: `/merits-exchange/${id}/cancel/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeRejectCreate
     * @request POST:/merits-exchange/{id}/reject/
     */ meritsExchangeRejectCreate: <T extends FetchKeys<MeritRewardReject>>(
      id: string,
      data: MeritRewardRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardReject, T>, any>({
        path: `/merits-exchange/${id}/reject/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags merits-exchange
     * @name MeritsExchangeAvailableFiltersRetrieve
     * @request GET:/merits-exchange/available-filters/
     */ meritsExchangeAvailableFiltersRetrieve: <
      T extends FetchKeys<FilterRepresentationResponseSchema>
    >(
      query?: {
        house?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        resourcetype?: string
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FilterRepresentationResponseSchema, T>, any>({
        path: `/merits-exchange/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  missionControl = {
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlList
     * @request GET:/mission-control/
     */
    missionControlList: <T extends FetchKeys<PaginatedMissionControlList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedMissionControlList, T>, any>({
        path: `/mission-control/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlCreate
     * @request POST:/mission-control/
     */ missionControlCreate: <T extends FetchKeys<MissionControl>>(
      data: MissionControlRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlLogsRetrieve
     * @request GET:/mission-control/{studentId}/logs/
     */ missionControlLogsRetrieve: <T extends FetchKeys<MissionControl>>(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${studentId}/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRetrieve
     * @request GET:/mission-control/{student}/
     */ missionControlRetrieve: <T extends FetchKeys<MissionControl>>(
      student: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlUpdate
     * @request PUT:/mission-control/{student}/
     */ missionControlUpdate: <T extends FetchKeys<MissionControl>>(
      student: string,
      data: MissionControlRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlPartialUpdate
     * @request PATCH:/mission-control/{student}/
     */ missionControlPartialUpdate: <T extends FetchKeys<MissionControl>>(
      student: string,
      data: PatchedMissionControlRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MissionControl, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlDestroy
     * @request DELETE:/mission-control/{student}/
     */ missionControlDestroy: <T extends FetchKeys<void>>(
      student: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/${student}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlCompetenciesList
     * @request GET:/mission-control/competencies/
     */ missionControlCompetenciesList: <
      T extends FetchKeys<PaginatedCompetencyList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCompetencyList, T>, any>({
        path: `/mission-control/competencies/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlCompetenciesRetrieve
     * @request GET:/mission-control/competencies/{id}/
     */ missionControlCompetenciesRetrieve: <T extends FetchKeys<Competency>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Competency, T>, any>({
        path: `/mission-control/competencies/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlDegreeAimsList
     * @request GET:/mission-control/degree-aims/
     */ missionControlDegreeAimsList: <
      T extends FetchKeys<PaginatedDegreeAimList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDegreeAimList, T>, any>({
        path: `/mission-control/degree-aims/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlDegreeAimsRetrieve
     * @request GET:/mission-control/degree-aims/{id}/
     */ missionControlDegreeAimsRetrieve: <T extends FetchKeys<DegreeAim>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DegreeAim, T>, any>({
        path: `/mission-control/degree-aims/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlInterestsList
     * @request GET:/mission-control/interests/
     */ missionControlInterestsList: <
      T extends FetchKeys<PaginatedInterestList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedInterestList, T>, any>({
        path: `/mission-control/interests/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlInterestsRetrieve
     * @request GET:/mission-control/interests/{id}/
     */ missionControlInterestsRetrieve: <T extends FetchKeys<Interest>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Interest, T>, any>({
        path: `/mission-control/interests/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlLogChoicesRetrieve
     * @request GET:/mission-control/log-choices/
     */ missionControlLogChoicesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/log-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsCreditsStudentsRetrieve
     * @request GET:/mission-control/requirements/credits/students/{studentId}/
     */ missionControlRequirementsCreditsStudentsRetrieve: <
      T extends FetchKeys<void>
    >(
      studentId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/requirements/credits/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsCreditsStudentsImportHistoryCreate
     * @request POST:/mission-control/requirements/credits/students/{studentId}/import-history/
     */ missionControlRequirementsCreditsStudentsImportHistoryCreate: <
      T extends FetchKeys<void>
    >(
      studentId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/mission-control/requirements/credits/students/${studentId}/import-history/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsStudentsList
     * @request GET:/mission-control/requirements/students/{studentId}/
     */ missionControlRequirementsStudentsList: <
      T extends FetchKeys<PaginatedStudentCourseRequirementList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCourseRequirementList, T>, any>({
        path: `/mission-control/requirements/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsStudentsUpdate
     * @request PUT:/mission-control/requirements/students/{studentId}/
     */ missionControlRequirementsStudentsUpdate: <
      T extends FetchKeys<StudentCourseRequirement>
    >(
      studentId: string,
      data: StudentCourseRequirementRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourseRequirement, T>, any>({
        path: `/mission-control/requirements/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlRequirementsStudentsPartialUpdate
     * @request PATCH:/mission-control/requirements/students/{studentId}/
     */ missionControlRequirementsStudentsPartialUpdate: <
      T extends FetchKeys<StudentCourseRequirement>
    >(
      studentId: string,
      data: PatchedStudentCourseRequirementRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourseRequirement, T>, any>({
        path: `/mission-control/requirements/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlSkillsList
     * @request GET:/mission-control/skills/
     */ missionControlSkillsList: <T extends FetchKeys<PaginatedSkillList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSkillList, T>, any>({
        path: `/mission-control/skills/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlSkillsRetrieve
     * @request GET:/mission-control/skills/{id}/
     */ missionControlSkillsRetrieve: <T extends FetchKeys<Skill>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Skill, T>, any>({
        path: `/mission-control/skills/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesList
     * @request GET:/mission-control/tae/experiences/
     */ missionControlTaeExperiencesList: <
      T extends FetchKeys<PaginatedTAEExperienceList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTAEExperienceList, T>, any>({
        path: `/mission-control/tae/experiences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesRetrieve
     * @request GET:/mission-control/tae/experiences/{id}/
     */ missionControlTaeExperiencesRetrieve: <
      T extends FetchKeys<TAEExperience>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TAEExperience, T>, any>({
        path: `/mission-control/tae/experiences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesStudentsList
     * @request GET:/mission-control/tae/experiences/students/{studentId}/
     */ missionControlTaeExperiencesStudentsList: <
      T extends FetchKeys<PaginatedStudentTAEExperienceList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentTAEExperienceList, T>, any>({
        path: `/mission-control/tae/experiences/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesStudentsUpdate
     * @request PUT:/mission-control/tae/experiences/students/{studentId}/
     */ missionControlTaeExperiencesStudentsUpdate: <
      T extends FetchKeys<StudentTAEExperience>
    >(
      studentId: string,
      data: StudentTAEExperienceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEExperience, T>, any>({
        path: `/mission-control/tae/experiences/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeExperiencesStudentsPartialUpdate
     * @request PATCH:/mission-control/tae/experiences/students/{studentId}/
     */ missionControlTaeExperiencesStudentsPartialUpdate: <
      T extends FetchKeys<StudentTAEExperience>
    >(
      studentId: string,
      data: PatchedStudentTAEExperienceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEExperience, T>, any>({
        path: `/mission-control/tae/experiences/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeProjectsStudentsList
     * @request GET:/mission-control/tae/projects/students/{studentId}/
     */ missionControlTaeProjectsStudentsList: <
      T extends FetchKeys<PaginatedStudentTAEProjectList>
    >(
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentTAEProjectList, T>, any>({
        path: `/mission-control/tae/projects/students/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeProjectsStudentsUpdate
     * @request PUT:/mission-control/tae/projects/students/{studentId}/
     */ missionControlTaeProjectsStudentsUpdate: <
      T extends FetchKeys<StudentTAEProject>
    >(
      studentId: string,
      data: StudentTAEProjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEProject, T>, any>({
        path: `/mission-control/tae/projects/students/${studentId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags mission-control
     * @name MissionControlTaeProjectsStudentsPartialUpdate
     * @request PATCH:/mission-control/tae/projects/students/{studentId}/
     */ missionControlTaeProjectsStudentsPartialUpdate: <
      T extends FetchKeys<StudentTAEProject>
    >(
      studentId: string,
      data: PatchedStudentTAEProjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentTAEProject, T>, any>({
        path: `/mission-control/tae/projects/students/${studentId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  notifications = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags notifications
     * @name NotificationsList
     * @request GET:/notifications/
     */
    notificationsList: <T extends FetchKeys<PaginatedNotificationList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        was_read?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedNotificationList, T>, any>({
        path: `/notifications/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags notifications
     * @name NotificationsRetrieve
     * @request GET:/notifications/{id}/
     */ notificationsRetrieve: <T extends FetchKeys<Notification>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Notification, T>, any>({
        path: `/notifications/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags notifications
     * @name NotificationsMarkAsReadCreate
     * @request POST:/notifications/{id}/mark-as-read/
     */ notificationsMarkAsReadCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/notifications/${id}/mark-as-read/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags notifications
     * @name NotificationsMarkAllAsReadCreate
     * @request POST:/notifications/mark-all-as-read/
     */ notificationsMarkAllAsReadCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/notifications/mark-all-as-read/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags notifications
     * @name NotificationsMarkAsReadByClassNameCreate
     * @request POST:/notifications/mark-as-read-by-class-name/
     */ notificationsMarkAsReadByClassNameCreate: <
      T extends FetchKeys<NotificationClassName>
    >(
      data: NotificationClassNameRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NotificationClassName, T>, any>({
        path: `/notifications/mark-as-read-by-class-name/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags notifications
     * @name NotificationsPushRegisterDeviceCreate
     * @request POST:/notifications/push/register-device/
     */ notificationsPushRegisterDeviceCreate: <
      T extends FetchKeys<RegisterDevice>
    >(
      data: RegisterDeviceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RegisterDevice, T>, any>({
        path: `/notifications/push/register-device/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags notifications
     * @name NotificationsUnreadRetrieve
     * @request GET:/notifications/unread/
     */ notificationsUnreadRetrieve: <T extends FetchKeys<number>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        was_read?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<number, T>, any>({
        path: `/notifications/unread/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  oauth = {
    /**
     * @description View will login or signin (create) the user from social oauth2.0 provider. **Input** (default serializer_class_in): { "provider": "facebook", "code": "AQBPBBTjbdnehj51" } + optional "redirect_uri": "/relative/or/absolute/redirect/uri" **Output**: user data in serializer_class format
     *
     * @tags oauth
     * @name OauthLoginSocialTokenCreate
     * @request POST:/oauth/login/social/token/{provider}/
     * @secure
     */
    oauthLoginSocialTokenCreate: <T extends FetchKeys<Knox>>(
      provider: string,
      data: IdTokenOAuth2InputRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Knox, T>, any>({
        path: `/oauth/login/social/token/${provider}/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  parent = {
    /**
     * No description
     *
     * @tags parent
     * @name ParentContactMessagesCreate
     * @request POST:/parent/contact/messages/
     */
    parentContactMessagesCreate: <T extends FetchKeys<ParentContactMessage>>(
      data: ParentContactMessageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentContactMessage, T>, any>({
        path: `/parent/contact/messages/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentContactMessagesSubjectsList
     * @request GET:/parent/contact/messages/subjects/
     */ parentContactMessagesSubjectsList: <
      T extends FetchKeys<ParentContactMessageSubjects[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentContactMessageSubjects[], T>, any>({
        path: `/parent/contact/messages/subjects/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentCoursesList
     * @request GET:/parent/courses/
     */ parentCoursesList: <T extends FetchKeys<PaginatedParentCoursesList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentCoursesList, T>, any>({
        path: `/parent/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentCoursesRetrieve
     * @request GET:/parent/courses/{courseId}/
     */ parentCoursesRetrieve: <T extends FetchKeys<ParentCourses>>(
      courseId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentCourses, T>, any>({
        path: `/parent/courses/${courseId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentCoursesYearsList
     * @request GET:/parent/courses/years/
     */ parentCoursesYearsList: <
      T extends FetchKeys<StudentCourseYearResponse[]>
    >(
      query?: {
        /** A search term. */
        search?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourseYearResponse[], T>, any>({
        path: `/parent/courses/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentFavouritesList
     * @request GET:/parent/favourites/
     */ parentFavouritesList: <T extends FetchKeys<FavouriteWidget[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteWidget[], T>, any>({
        path: `/parent/favourites/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentFilesForParentsList
     * @request GET:/parent/files-for-parents/
     */ parentFilesForParentsList: <
      T extends FetchKeys<PaginatedParentsFileList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentsFileList, T>, any>({
        path: `/parent/files-for-parents/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentInfoboardsFeedList
     * @request GET:/parent/infoboards/feed/
     */ parentInfoboardsFeedList: <T extends FetchKeys<PaginatedFeedList>>(
      query?: {
        include_widgets?: (
          | 'BoardingAnnouncementWidget'
          | 'CelebrationWidget'
          | 'CongratulationsWidget'
          | 'EventAgendaWidget'
          | 'EventInfoWidget'
          | 'EventInfoWithPhotoWidget'
          | 'JournalWidget'
          | 'PosterWidget'
          | 'StudentArtGalleryWidget'
          | 'VariantManualCongratulationsWidget'
          | 'all'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedFeedList, T>, any>({
        path: `/parent/infoboards/feed/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentInfoboardsMessagesList
     * @request GET:/parent/infoboards/messages/
     */ parentInfoboardsMessagesList: <
      T extends FetchKeys<PaginatedParentMessageList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentMessageList, T>, any>({
        path: `/parent/infoboards/messages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentInfoboardsMessagesRetrieve
     * @request GET:/parent/infoboards/messages/{id}/
     */ parentInfoboardsMessagesRetrieve: <T extends FetchKeys<ParentMessage>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentMessage, T>, any>({
        path: `/parent/infoboards/messages/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentLeavesList
     * @request GET:/parent/leaves/
     */ parentLeavesList: <
      T extends FetchKeys<PaginatedParentLeaveRequestList>
    >(
      query?: {
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /**
         * * `0` - Weekend
         * * `1` - Holiday
         * * `2` - Other
         */
        leave_type?: (0 | 1 | 2)[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?: (
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        )[]
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentLeaveRequestList, T>, any>({
        path: `/parent/leaves/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentLeavesRetrieve
     * @request GET:/parent/leaves/{id}/
     */ parentLeavesRetrieve: <T extends FetchKeys<ParentLeaveRequest>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentLeaveRequest, T>, any>({
        path: `/parent/leaves/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentLeavesApproveCreate
     * @request POST:/parent/leaves/{id}/approve/
     */ parentLeavesApproveCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parent/leaves/${id}/approve/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentLeavesRejectCreate
     * @request POST:/parent/leaves/{id}/reject/
     */ parentLeavesRejectCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parent/leaves/${id}/reject/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentLeavesStatusesList
     * @request GET:/parent/leaves/statuses/
     */ parentLeavesStatusesList: <
      T extends FetchKeys<ParentLeaveRequestStatus[]>
    >(
      query?: {
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /**
         * * `0` - Weekend
         * * `1` - Holiday
         * * `2` - Other
         */
        leave_type?: (0 | 1 | 2)[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?: (
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        )[]
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentLeaveRequestStatus[], T>, any>({
        path: `/parent/leaves/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentLeavesTypeChoicesList
     * @request GET:/parent/leaves/type-choices/
     */ parentLeavesTypeChoicesList: <
      T extends FetchKeys<ParentLeaveRequestType[]>
    >(
      query?: {
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /**
         * * `0` - Weekend
         * * `1` - Holiday
         * * `2` - Other
         */
        leave_type?: (0 | 1 | 2)[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?: (
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        )[]
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentLeaveRequestType[], T>, any>({
        path: `/parent/leaves/type-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentMissionControlRetrieve
     * @request GET:/parent/mission-control/
     */ parentMissionControlRetrieve: <
      T extends FetchKeys<ParentMissionControlResponse>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentMissionControlResponse, T>, any>({
        path: `/parent/mission-control/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentParentsMeRetrieve
     * @request GET:/parent/parents/me/
     */ parentParentsMeRetrieve: <T extends FetchKeys<ParentProfile>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentProfile, T>, any>({
        path: `/parent/parents/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentParentsMeUpdate
     * @request PUT:/parent/parents/me/
     */ parentParentsMeUpdate: <T extends FetchKeys<ParentProfile>>(
      data: ParentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentProfile, T>, any>({
        path: `/parent/parents/me/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentParentsMePartialUpdate
     * @request PATCH:/parent/parents/me/
     */ parentParentsMePartialUpdate: <T extends FetchKeys<ParentProfile>>(
      data: PatchedParentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentProfile, T>, any>({
        path: `/parent/parents/me/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentParentsStudentBirthdayValidateCreate
     * @request POST:/parent/parents/student-birthday-validate/
     */ parentParentsStudentBirthdayValidateCreate: <
      T extends FetchKeys<boolean>
    >(
      data: StudentBirthdayValidateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<boolean, T>, any>({
        path: `/parent/parents/student-birthday-validate/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentReportsReportCardsList
     * @request GET:/parent/reports/report-cards/
     */ parentReportsReportCardsList: <
      T extends FetchKeys<PaginatedParentReportCardList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentReportCardList, T>, any>({
        path: `/parent/reports/report-cards/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentReportsReportCardsRetrieve
     * @request GET:/parent/reports/report-cards/{id}/
     */ parentReportsReportCardsRetrieve: <
      T extends FetchKeys<ParentReportCard>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentReportCard, T>, any>({
        path: `/parent/reports/report-cards/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentReportsReportCardsMarkAsReadCreate
     * @request POST:/parent/reports/report-cards/{id}/mark-as-read/
     */ parentReportsReportCardsMarkAsReadCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parent/reports/report-cards/${id}/mark-as-read/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentStudentsList
     * @request GET:/parent/students/
     */ parentStudentsList: <T extends FetchKeys<ParentStudent[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentStudent[], T>, any>({
        path: `/parent/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpRetrieve
     * @request GET:/parent/students-idp/{id}/
     */ parentStudentsIdpRetrieve: <T extends FetchKeys<ParentIdpPlan>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentIdpPlan, T>, any>({
        path: `/parent/students-idp/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpApproveCreate
     * @request POST:/parent/students-idp/{id}/approve/
     */ parentStudentsIdpApproveCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parent/students-idp/${id}/approve/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpChangesList
     * @request GET:/parent/students-idp/{id}/changes/
     */ parentStudentsIdpChangesList: <
      T extends FetchKeys<ParentIdpPlanChanges[]>
    >(
      id: string,
      query?: {
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentIdpPlanChanges[], T>, any>({
        path: `/parent/students-idp/${id}/changes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpDiscussCreate
     * @request POST:/parent/students-idp/{id}/discuss/
     */ parentStudentsIdpDiscussCreate: <T extends FetchKeys<void>>(
      id: string,
      data: ParentDiscussIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parent/students-idp/${id}/discuss/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpActiveRetrieve
     * @request GET:/parent/students-idp/active/
     */ parentStudentsIdpActiveRetrieve: <T extends FetchKeys<ParentIdpPlan>>(
      query?: {
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentIdpPlan, T>, any>({
        path: `/parent/students-idp/active/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpNewRetrieve
     * @request GET:/parent/students-idp/new/
     */ parentStudentsIdpNewRetrieve: <T extends FetchKeys<ParentIdpPlan>>(
      query?: {
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentIdpPlan, T>, any>({
        path: `/parent/students-idp/new/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpStatusHistoryList
     * @request GET:/parent/students-idp/status-history/
     */ parentStudentsIdpStatusHistoryList: <
      T extends FetchKeys<PaginatedParentIdpPlanStatusHistoryList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentIdpPlanStatusHistoryList, T>, any>({
        path: `/parent/students-idp/status-history/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags parent
     * @name ParentStudentsIdpYearsList
     * @request GET:/parent/students-idp/years/
     */ parentStudentsIdpYearsList: <T extends FetchKeys<ParentIdpPlanYears[]>>(
      query?: {
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentIdpPlanYears[], T>, any>({
        path: `/parent/students-idp/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags parent
     * @name ParentStudentsRetrieve
     * @request GET:/parent/students/{id}/
     */ parentStudentsRetrieve: <T extends FetchKeys<ParentStudent>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ParentStudent, T>, any>({
        path: `/parent/students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  parents = {
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsList
     * @request GET:/parents/
     */
    parentsList: <T extends FetchKeys<PaginatedParentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedParentList, T>, any>({
        path: `/parents/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsCreate
     * @request POST:/parents/
     */ parentsCreate: <T extends FetchKeys<Parent>>(
      data: ParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsRetrieve
     * @request GET:/parents/{id}/
     */ parentsRetrieve: <T extends FetchKeys<Parent>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsUpdate
     * @request PUT:/parents/{id}/
     */ parentsUpdate: <T extends FetchKeys<Parent>>(
      id: string,
      data: ParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsPartialUpdate
     * @request PATCH:/parents/{id}/
     */ parentsPartialUpdate: <T extends FetchKeys<Parent>>(
      id: string,
      data: PatchedParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Parent, T>, any>({
        path: `/parents/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags parents
     * @name ParentsDestroy
     * @request DELETE:/parents/{id}/
     */ parentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/parents/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  periods = {
    /**
     * No description
     *
     * @tags periods
     * @name PeriodsQuartersList
     * @request GET:/periods/quarters/
     */
    periodsQuartersList: <T extends FetchKeys<PaginatedQuarterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedQuarterList, T>, any>({
        path: `/periods/quarters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags periods
     * @name PeriodsSemestersList
     * @request GET:/periods/semesters/
     */ periodsSemestersList: <T extends FetchKeys<PaginatedSemesterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSemesterList, T>, any>({
        path: `/periods/semesters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags periods
     * @name PeriodsYearsList
     * @request GET:/periods/years/
     */ periodsYearsList: <T extends FetchKeys<PaginatedSchoolYearList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolYearList, T>, any>({
        path: `/periods/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  planner = {
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesList
     * @request GET:/planner/activities/
     */
    plannerActivitiesList: <
      T extends FetchKeys<PaginatedDraftActivityListList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDraftActivityListList, T>, any>({
        path: `/planner/activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesCreate
     * @request POST:/planner/activities/
     */ plannerActivitiesCreate: <T extends FetchKeys<DraftActivityCreate>>(
      data: DraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesRetrieve
     * @request GET:/planner/activities/{id}/
     */ plannerActivitiesRetrieve: <T extends FetchKeys<DraftActivityCreate>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesUpdate
     * @request PUT:/planner/activities/{id}/
     */ plannerActivitiesUpdate: <T extends FetchKeys<DraftActivityCreate>>(
      id: string,
      data: DraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesPartialUpdate
     * @request PATCH:/planner/activities/{id}/
     */ plannerActivitiesPartialUpdate: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      data: PatchedDraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesDestroy
     * @request DELETE:/planner/activities/{id}/
     */ plannerActivitiesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/activities/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesRoomsPreferencesRetrieve
     * @request GET:/planner/activities/{id}/rooms-preferences/
     */ plannerActivitiesRoomsPreferencesRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/${id}/rooms-preferences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesCountRetrieve
     * @request GET:/planner/activities/count/
     */ plannerActivitiesCountRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesDaysRetrieve
     * @request GET:/planner/activities/days/
     */ plannerActivitiesDaysRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/days/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Pass timetable and hour in query param
     *
     * @tags planner
     * @name PlannerActivitiesExportActivitiesAfterHourRetrieve
     * @request GET:/planner/activities/export-activities-after-hour/
     */ plannerActivitiesExportActivitiesAfterHourRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/activities/export-activities-after-hour/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesExportActivitiesPerSlotRetrieve
     * @request GET:/planner/activities/export-activities-per-slot/
     */ plannerActivitiesExportActivitiesPerSlotRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/activities/export-activities-per-slot/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesExportStudentActivitiesRetrieve
     * @request GET:/planner/activities/export-student-activities/
     */ plannerActivitiesExportStudentActivitiesRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/activities/export-student-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesExportTeacherActivitiesRetrieve
     * @request GET:/planner/activities/export-teacher-activities/
     */ plannerActivitiesExportTeacherActivitiesRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/activities/export-teacher-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesHoursRetrieve
     * @request GET:/planner/activities/hours/
     */ plannerActivitiesHoursRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        room?: number
        students?: string[]
        /** @format uuid */
        teacher?: string
        timetable?: number
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/planner/activities/hours/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerActivitiesUpdateActivitiesRoomsCreate
     * @request POST:/planner/activities/update-activities-rooms/
     */ plannerActivitiesUpdateActivitiesRoomsCreate: <
      T extends FetchKeys<UpdateActivitiesRooms>
    >(
      data: UpdateActivitiesRoomsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UpdateActivitiesRooms, T>, any>({
        path: `/planner/activities/update-activities-rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags planner
     * @name PlannerConflictsRetrieve
     * @request GET:/planner/conflicts/{id}/{day}/
     */ plannerConflictsRetrieve: <T extends FetchKeys<void>>(
      day: string,
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/conflicts/${id}/${day}/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerGeneratorCallbackCreate
     * @request POST:/planner/generator/callback
     */ plannerGeneratorCallbackCreate: <T extends FetchKeys<Callback>>(
      data: CallbackRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Callback, T>, any>({
        path: `/planner/generator/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerGeneratorStatusesRetrieve
     * @request GET:/planner/generator/statuses
     */ plannerGeneratorStatusesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/generator/statuses`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksList
     * @request GET:/planner/generator/tasks/
     */ plannerGeneratorTasksList: <
      T extends FetchKeys<PaginatedGenerationTaskList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedGenerationTaskList, T>, any>({
        path: `/planner/generator/tasks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksCreate
     * @request POST:/planner/generator/tasks/
     */ plannerGeneratorTasksCreate: <T extends FetchKeys<GenerationTask>>(
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksRetrieve
     * @request GET:/planner/generator/tasks/{id}/
     */ plannerGeneratorTasksRetrieve: <T extends FetchKeys<GenerationTask>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksStatsRetrieve
     * @request GET:/planner/generator/tasks/{id}/stats/
     */ plannerGeneratorTasksStatsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksStopCreate
     * @request POST:/planner/generator/tasks/{id}/stop/
     */ plannerGeneratorTasksStopCreate: <T extends FetchKeys<GenerationTask>>(
      id: number,
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/${id}/stop/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags planner
     * @name PlannerGeneratorTasksCostsRetrieve
     * @request GET:/planner/generator/tasks/costs/
     */ plannerGeneratorTasksCostsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      query?: {
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/planner/generator/tasks/costs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerLogsList
     * @request GET:/planner/logs/
     */ plannerLogsList: <T extends FetchKeys<PaginatedTimetableLogList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableLogList, T>, any>({
        path: `/planner/logs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsList
     * @request GET:/planner/mentorings/
     */ plannerMentoringsList: <
      T extends FetchKeys<PaginatedTimetableMentoringList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableMentoringList, T>, any>({
        path: `/planner/mentorings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsCreate
     * @request POST:/planner/mentorings/
     */ plannerMentoringsCreate: <T extends FetchKeys<TimetableMentoring>>(
      data: TimetableMentoringRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsRetrieve
     * @request GET:/planner/mentorings/{id}/
     */ plannerMentoringsRetrieve: <T extends FetchKeys<TimetableMentoring>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsUpdate
     * @request PUT:/planner/mentorings/{id}/
     */ plannerMentoringsUpdate: <T extends FetchKeys<TimetableMentoring>>(
      id: number,
      data: TimetableMentoringRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsPartialUpdate
     * @request PATCH:/planner/mentorings/{id}/
     */ plannerMentoringsPartialUpdate: <
      T extends FetchKeys<TimetableMentoring>
    >(
      id: number,
      data: PatchedTimetableMentoringRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsDestroy
     * @request DELETE:/planner/mentorings/{id}/
     */ plannerMentoringsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/mentorings/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerMentoringsInitialDataRetrieve
     * @request GET:/planner/mentorings/initial-data/
     */ plannerMentoringsInitialDataRetrieve: <
      T extends FetchKeys<TimetableMentoring>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableMentoring, T>, any>({
        path: `/planner/mentorings/initial-data/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerPotentialOptimizationsList
     * @request GET:/planner/potential-optimizations/
     */ plannerPotentialOptimizationsList: <
      T extends FetchKeys<PaginatedPotentialOptimizationList>
    >(
      query?: {
        /**
         * * `0` - 8:00-8:40
         * * `1` - 8:45-9:25
         * * `2` - 9:30-10:10
         * * `3` - 10:30-11:10
         * * `4` - 11:15-11:55
         * * `5` - 13:00-13:40
         * * `6` - 13:45-14:25
         * * `7` - 14:30-15:10
         * * `8` - 15:15-15:55
         * * `9` - 16:00-16:40
         * * `10` - 16:45-17:25
         * * `11` - 17:30-18:10
         * * `12` - 18:15-18:55
         * * `13` - 19:00-19:40
         * * `14` - 19:40-20:30
         * * `15` - 20:30-21:00
         */
        activity__hour?:
          | '0'
          | '1'
          | '10'
          | '11'
          | '12'
          | '13'
          | '14'
          | '15'
          | '2'
          | '3'
          | '4'
          | '5'
          | '6'
          | '7'
          | '8'
          | '9'
          | null
        activity__is_private?: boolean
        /**
         * * `0` - monday
         * * `1` - tuesday
         * * `2` - wednesday
         * * `3` - thursday
         * * `4` - friday
         */
        day?: 0 | 1 | 2 | 3 | 4
        /**
         * * `0` - 8:00-8:40
         * * `1` - 8:45-9:25
         * * `2` - 9:30-10:10
         * * `3` - 10:30-11:10
         * * `4` - 11:15-11:55
         * * `5` - 13:00-13:40
         * * `6` - 13:45-14:25
         * * `7` - 14:30-15:10
         * * `8` - 15:15-15:55
         * * `9` - 16:00-16:40
         * * `10` - 16:45-17:25
         * * `11` - 17:30-18:10
         * * `12` - 18:15-18:55
         * * `13` - 19:00-19:40
         * * `14` - 19:40-20:30
         * * `15` - 20:30-21:00
         */
        hour?:
          | '0'
          | '1'
          | '10'
          | '11'
          | '12'
          | '13'
          | '14'
          | '15'
          | '2'
          | '3'
          | '4'
          | '5'
          | '6'
          | '7'
          | '8'
          | '9'
          | null
        no_teacher?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPotentialOptimizationList, T>, any>({
        path: `/planner/potential-optimizations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerPotentialOptimizationsApplyPotentialOptimizationsUpdate
     * @request PUT:/planner/potential-optimizations/{id}/apply-potential-optimizations/
     */ plannerPotentialOptimizationsApplyPotentialOptimizationsUpdate: <
      T extends FetchKeys<void>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/potential-optimizations/${id}/apply-potential-optimizations/`,
        method: 'PUT',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerPotentialOptimizationsApplyPotentialOptimizationsPartialUpdate
     * @request PATCH:/planner/potential-optimizations/{id}/apply-potential-optimizations/
     */ plannerPotentialOptimizationsApplyPotentialOptimizationsPartialUpdate: <
      T extends FetchKeys<void>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/potential-optimizations/${id}/apply-potential-optimizations/`,
        method: 'PATCH',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerPotentialOptimizationsActivitiesMovesList
     * @request GET:/planner/potential-optimizations/activities/{id}/moves/
     */ plannerPotentialOptimizationsActivitiesMovesList: <
      T extends FetchKeys<PaginatedActivityMoveList>
    >(
      id: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedActivityMoveList, T>, any>({
        path: `/planner/potential-optimizations/activities/${id}/moves/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags planner
     * @name PlannerRoomsAssignerCreate
     * @request POST:/planner/rooms-assigner/{id}/
     */ plannerRoomsAssignerCreate: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/rooms-assigner/${id}/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsList
     * @request GET:/planner/sections/
     */ plannerSectionsList: <T extends FetchKeys<PaginatedSectionListList>>(
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        /** A search term. */
        search?: string
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSectionListList, T>, any>({
        path: `/planner/sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsCreate
     * @request POST:/planner/sections/
     */ plannerSectionsCreate: <T extends FetchKeys<Section>>(
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsRetrieve
     * @request GET:/planner/sections/{id}/
     */ plannerSectionsRetrieve: <T extends FetchKeys<Section>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsUpdate
     * @request PUT:/planner/sections/{id}/
     */ plannerSectionsUpdate: <T extends FetchKeys<Section>>(
      id: number,
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsPartialUpdate
     * @request PATCH:/planner/sections/{id}/
     */ plannerSectionsPartialUpdate: <T extends FetchKeys<Section>>(
      id: number,
      data: PatchedSectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsDestroy
     * @request DELETE:/planner/sections/{id}/
     */ plannerSectionsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsAllowedStudentsList
     * @request GET:/planner/sections/{id}/allowed-students/
     */ plannerSectionsAllowedStudentsList: <
      T extends FetchKeys<StringOption[]>
    >(
      id: number,
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        /** A search term. */
        search?: string
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/planner/sections/${id}/allowed-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsUsagesRetrieve
     * @request GET:/planner/sections/{id}/usages/
     */ plannerSectionsUsagesRetrieve: <T extends FetchKeys<Section>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/${id}/usages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsChangesInSectionsCreate
     * @request POST:/planner/sections/changes-in-sections/
     */ plannerSectionsChangesInSectionsCreate: <
      T extends FetchKeys<Record<string, any>>
    >(
      data: ChangesInSectionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Record<string, any>, T>, any>({
        path: `/planner/sections/changes-in-sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsExportStudentsEnrollmentsRetrieve
     * @request GET:/planner/sections/export-students-enrollments/
     */ plannerSectionsExportStudentsEnrollmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/export-students-enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsExportStudentsInSectionsRetrieve
     * @request GET:/planner/sections/export-students-in-sections/
     */ plannerSectionsExportStudentsInSectionsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/export-students-in-sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsExportTeachersEnrolmentsRetrieve
     * @request GET:/planner/sections/export-teachers-enrolments/
     */ plannerSectionsExportTeachersEnrolmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/sections/export-teachers-enrolments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      }),
    /**
     * @description Sections with students and scorings
     *
     * @tags planner
     * @name PlannerSectionsSectionToEnrollList
     * @request GET:/planner/sections/section-to-enroll/
     */ plannerSectionsSectionToEnrollList: <
      T extends FetchKeys<SectionToEnroll[]>
    >(
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        quarter?: number
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SectionToEnroll[], T>, any>({
        path: `/planner/sections/section-to-enroll/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Sections API without enrollments
     *
     * @tags planner
     * @name PlannerSectionsSimpleList
     * @request GET:/planner/sections/simple/
     */ plannerSectionsSimpleList: <
      T extends FetchKeys<PaginatedSimpleSectionList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** multi value filter: id1,id2,id3 */
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSimpleSectionList, T>, any>({
        path: `/planner/sections/simple/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Sections API without enrollments
     *
     * @tags planner
     * @name PlannerSectionsSimpleRetrieve
     * @request GET:/planner/sections/simple/{id}/
     */ plannerSectionsSimpleRetrieve: <T extends FetchKeys<SimpleSection>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleSection, T>, any>({
        path: `/planner/sections/simple/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerSectionsTotalDurationRetrieve
     * @request GET:/planner/sections/total-duration/
     */ plannerSectionsTotalDurationRetrieve: <T extends FetchKeys<Section>>(
      query?: {
        /** @format uuid */
        course?: string
        excluded_section?: string
        id?: number[]
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: number
        /** A search term. */
        search?: string
        /** @format uuid */
        students?: string
        /** @format uuid */
        teacher?: string
        /** @format uuid */
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/planner/sections/total-duration/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpList
     * @request GET:/planner/students-idp/
     */ plannerStudentsIdpList: <
      T extends FetchKeys<PaginatedStudentIdpPlanListList>
    >(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentIdpPlanListList, T>, any>({
        path: `/planner/students-idp/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCreate
     * @request POST:/planner/students-idp/
     */ plannerStudentsIdpCreate: <T extends FetchKeys<StudentIdpPlan>>(
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRetrieve
     * @request GET:/planner/students-idp/{id}/
     */ plannerStudentsIdpRetrieve: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpUpdate
     * @request PUT:/planner/students-idp/{id}/
     */ plannerStudentsIdpUpdate: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpPartialUpdate
     * @request PATCH:/planner/students-idp/{id}/
     */ plannerStudentsIdpPartialUpdate: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      data: PatchedStudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpDestroy
     * @request DELETE:/planner/students-idp/{id}/
     */ plannerStudentsIdpDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/students-idp/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpApproveCreate
     * @request POST:/planner/students-idp/{id}/approve/
     */ plannerStudentsIdpApproveCreate: <
      T extends FetchKeys<AdminApproveIdpPlan>
    >(
      id: string,
      data: AdminApproveIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminApproveIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/approve/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpAutoAssignSectionsCreate
     * @request POST:/planner/students-idp/{id}/auto-assign-sections/
     */ plannerStudentsIdpAutoAssignSectionsCreate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/auto-assign-sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCancelApprovalRequestUpdate
     * @request PUT:/planner/students-idp/{id}/cancel-approval-request/
     */ plannerStudentsIdpCancelApprovalRequestUpdate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/cancel-approval-request/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpParentApproveCreate
     * @request POST:/planner/students-idp/{id}/parent-approve/
     */ plannerStudentsIdpParentApproveCreate: <T extends FetchKeys<void>>(
      id: string,
      data: AdminParentApproveIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/students-idp/${id}/parent-approve/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpPdfRetrieve
     * @request GET:/planner/students-idp/{id}/pdf/
     */ plannerStudentsIdpPdfRetrieve: <T extends FetchKeys<StudentIdpPlan>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRejectCreate
     * @request POST:/planner/students-idp/{id}/reject/
     */ plannerStudentsIdpRejectCreate: <T extends FetchKeys<RejectIdpPlan>>(
      id: string,
      data: RejectIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RejectIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/reject/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpUpdateDraftUpdate
     * @request PUT:/planner/students-idp/{id}/update-draft/
     */ plannerStudentsIdpUpdateDraftUpdate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      id: string,
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/${id}/update-draft/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpValidateApproveCreate
     * @request POST:/planner/students-idp/{id}/validate-approve/
     */ plannerStudentsIdpValidateApproveCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/planner/students-idp/${id}/validate-approve/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCalculateCreditsCreate
     * @request POST:/planner/students-idp/calculate-credits/
     */ plannerStudentsIdpCalculateCreditsCreate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/calculate-credits/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCourseInBasketsRetrieve
     * @request GET:/planner/students-idp/course-in-baskets/{id}/
     */ plannerStudentsIdpCourseInBasketsRetrieve: <
      T extends FetchKeys<CourseInBasketUpdateDepartment>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInBasketUpdateDepartment, T>, any>({
        path: `/planner/students-idp/course-in-baskets/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCourseInBasketsUpdate
     * @request PUT:/planner/students-idp/course-in-baskets/{id}/
     */ plannerStudentsIdpCourseInBasketsUpdate: <
      T extends FetchKeys<CourseInBasketUpdateDepartment>
    >(
      id: number,
      data: CourseInBasketUpdateDepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInBasketUpdateDepartment, T>, any>({
        path: `/planner/students-idp/course-in-baskets/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCourseInBasketsPartialUpdate
     * @request PATCH:/planner/students-idp/course-in-baskets/{id}/
     */ plannerStudentsIdpCourseInBasketsPartialUpdate: <
      T extends FetchKeys<CourseInBasketUpdateDepartment>
    >(
      id: number,
      data: PatchedCourseInBasketUpdateDepartmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInBasketUpdateDepartment, T>, any>({
        path: `/planner/students-idp/course-in-baskets/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpCoursesRetrieve
     * @request GET:/planner/students-idp/courses/
     */ plannerStudentsIdpCoursesRetrieve: <T extends FetchKeys<CourseInIdp>>(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseInIdp, T>, any>({
        path: `/planner/students-idp/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpEnrollToSectionRetrieve
     * @request GET:/planner/students-idp/enroll-to-section/{id}/
     */ plannerStudentsIdpEnrollToSectionRetrieve: <
      T extends FetchKeys<IdpSectionEnroll>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdpSectionEnroll, T>, any>({
        path: `/planner/students-idp/enroll-to-section/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpEnrollToSectionUpdate
     * @request PUT:/planner/students-idp/enroll-to-section/{id}/
     */ plannerStudentsIdpEnrollToSectionUpdate: <
      T extends FetchKeys<IdpSectionEnroll>
    >(
      id: number,
      data: IdpSectionEnrollRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdpSectionEnroll, T>, any>({
        path: `/planner/students-idp/enroll-to-section/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpEnrollToSectionPartialUpdate
     * @request PATCH:/planner/students-idp/enroll-to-section/{id}/
     */ plannerStudentsIdpEnrollToSectionPartialUpdate: <
      T extends FetchKeys<IdpSectionEnroll>
    >(
      id: number,
      data: PatchedIdpSectionEnrollRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdpSectionEnroll, T>, any>({
        path: `/planner/students-idp/enroll-to-section/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpExamsRetrieve
     * @request GET:/planner/students-idp/exams/
     */ plannerStudentsIdpExamsRetrieve: <T extends FetchKeys<StudentIdpPlan>>(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/exams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpLastApprovedRetrieve
     * @request GET:/planner/students-idp/last-approved/
     */ plannerStudentsIdpLastApprovedRetrieve: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/last-approved/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRequestedCoursesList
     * @request GET:/planner/students-idp/requested-courses/
     */ plannerStudentsIdpRequestedCoursesList: <
      T extends FetchKeys<PaginatedRequestedCourseList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedRequestedCourseList, T>, any>({
        path: `/planner/students-idp/requested-courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpRequestedCoursesRetrieve
     * @request GET:/planner/students-idp/requested-courses/{id}/
     */ plannerStudentsIdpRequestedCoursesRetrieve: <
      T extends FetchKeys<RequestedCourse>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RequestedCourse, T>, any>({
        path: `/planner/students-idp/requested-courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpSaveDraftCreate
     * @request POST:/planner/students-idp/save-draft/
     */ plannerStudentsIdpSaveDraftCreate: <
      T extends FetchKeys<StudentIdpPlan>
    >(
      data: StudentIdpPlanRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentIdpPlan, T>, any>({
        path: `/planner/students-idp/save-draft/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpStatusesList
     * @request GET:/planner/students-idp/statuses/
     */ plannerStudentsIdpStatusesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        aim?: number
        grade_level?: number
        is_active?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        /**
         * * `new` - New
         * * `draft` - Draft
         * * `rejected` - Rejected
         * * `waits_for_admin_approval` - In admin approval
         * * `in_parental_approval` - In parental approval
         * * `is_discussion` - In discussion
         * * `approved` - Approved
         * * `automatically_approved` - Automatically approved
         * * `updated` - Updated
         * * `archived` - Archived
         */
        status?:
          | 'approved'
          | 'archived'
          | 'automatically_approved'
          | 'draft'
          | 'in_parental_approval'
          | 'is_discussion'
          | 'new'
          | 'rejected'
          | 'updated'
          | 'waits_for_admin_approval'
        /** @format uuid */
        student?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/planner/students-idp/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags planner
     * @name PlannerStudentsIdpSuggestionsCreate
     * @request POST:/planner/students-idp/suggestions/
     */ plannerStudentsIdpSuggestionsCreate: <T extends FetchKeys<Suggestions>>(
      data: SuggestionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Suggestions, T>, any>({
        path: `/planner/students-idp/suggestions/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  privateMedia = {
    /**
     * @description Overwrite get method to provide rest's exception
     *
     * @tags private-media
     * @name PrivateMediaRetrieve
     * @request GET:/private-media/{path}
     */
    privateMediaRetrieve: <T extends FetchKeys<void>>(
      path: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/private-media/${path}`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  progressComments = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsList
     * @request GET:/progress-comments/
     */
    progressCommentsList: <T extends FetchKeys<PaginatedProgressCommentList>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedProgressCommentList, T>, any>({
        path: `/progress-comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsCreate
     * @request POST:/progress-comments/
     */ progressCommentsCreate: <T extends FetchKeys<ProgressComment>>(
      data: ProgressCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsRetrieve
     * @request GET:/progress-comments/{id}/
     */ progressCommentsRetrieve: <T extends FetchKeys<ProgressComment>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsUpdate
     * @request PUT:/progress-comments/{id}/
     */ progressCommentsUpdate: <T extends FetchKeys<ProgressComment>>(
      id: string,
      data: ProgressCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsPartialUpdate
     * @request PATCH:/progress-comments/{id}/
     */ progressCommentsPartialUpdate: <T extends FetchKeys<ProgressComment>>(
      id: string,
      data: PatchedProgressCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsDestroy
     * @request DELETE:/progress-comments/{id}/
     */ progressCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/progress-comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsChangeCommentDeadlinePartialUpdate
     * @request PATCH:/progress-comments/{id}/change-comment-deadline/
     */ progressCommentsChangeCommentDeadlinePartialUpdate: <
      T extends FetchKeys<ProgressCommentDeadline>
    >(
      id: string,
      data: PatchedProgressCommentDeadlineRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressCommentDeadline, T>, any>({
        path: `/progress-comments/${id}/change-comment-deadline/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsEvaluatePartialUpdate
     * @request PATCH:/progress-comments/{id}/evaluate/
     */ progressCommentsEvaluatePartialUpdate: <
      T extends FetchKeys<ProgressCommentEvaluate>
    >(
      id: string,
      data: PatchedProgressCommentEvaluateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressCommentEvaluate, T>, any>({
        path: `/progress-comments/${id}/evaluate/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsSaveDraftPartialUpdate
     * @request PATCH:/progress-comments/{id}/save-draft/
     */ progressCommentsSaveDraftPartialUpdate: <
      T extends FetchKeys<ProgressCommentDraft>
    >(
      id: string,
      data: PatchedProgressCommentDraftRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressCommentDraft, T>, any>({
        path: `/progress-comments/${id}/save-draft/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsAvailableFiltersRetrieve
     * @request GET:/progress-comments/available-filters/
     */ progressCommentsAvailableFiltersRetrieve: <
      T extends FetchKeys<ProgressComment>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsChoicesRetrieve
     * @request GET:/progress-comments/choices/
     */ progressCommentsChoicesRetrieve: <T extends FetchKeys<ProgressComment>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsCsvRetrieve
     * @request GET:/progress-comments/csv/
     */ progressCommentsCsvRetrieve: <T extends FetchKeys<ProgressComment>>(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/csv/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsDatesForNewProgressCommentRetrieve
     * @request GET:/progress-comments/dates-for-new-progress-comment/
     */ progressCommentsDatesForNewProgressCommentRetrieve: <
      T extends FetchKeys<ProgressComment>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/dates-for-new-progress-comment/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsGeneralStatisticsRetrieve
     * @request GET:/progress-comments/general-statistics/
     */ progressCommentsGeneralStatisticsRetrieve: <
      T extends FetchKeys<ProgressComment>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/general-statistics/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags progress-comments
     * @name ProgressCommentsHasInstanceForCurrentWeekRetrieve
     * @request GET:/progress-comments/has-instance-for-current-week/
     */ progressCommentsHasInstanceForCurrentWeekRetrieve: <
      T extends FetchKeys<ProgressComment>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        end_date?: string
        fixed_target_grade_status?: string
        potential_grade?: string
        quarter?: number
        quarter_grade?: string
        /**
         * * `comment_not_filled_in` - None
         * * `more_than_30_percentage` - None
         * * `more_than_3_b_plus` - None
         * * `only_filled_comments` - None
         * * `teacher_has_more_than_30_percentage_under_performing_students` - None
         * * `students_with_higher_grade_than_potential` - None
         */
        quick_filters?:
          | 'comment_not_filled_in'
          | 'more_than_30_percentage'
          | 'more_than_3_b_plus'
          | 'only_filled_comments'
          | 'students_with_higher_grade_than_potential'
          | 'teacher_has_more_than_30_percentage_under_performing_students'
        semester?: number
        /** @format uuid */
        student?: string
        student_grade_level?: number[]
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgressComment, T>, any>({
        path: `/progress-comments/has-instance-for-current-week/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  public = {
    /**
     * No description
     *
     * @tags public
     * @name PublicLeavesRetrieve
     * @request GET:/public/leaves/{id}/
     * @secure
     */
    publicLeavesRetrieve: <T extends FetchKeys<PublicLeaveRequest>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicLeaveRequest, T>, any>({
        path: `/public/leaves/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags public
     * @name PublicLeavesApproveCreate
     * @request POST:/public/leaves/{id}/approve/
     * @secure
     */ publicLeavesApproveCreate: <T extends FetchKeys<PublicLeaveRequest>>(
      id: string,
      data: PublicLeaveRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicLeaveRequest, T>, any>({
        path: `/public/leaves/${id}/approve/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags public
     * @name PublicLeavesRejectCreate
     * @request POST:/public/leaves/{id}/reject/
     * @secure
     */ publicLeavesRejectCreate: <T extends FetchKeys<PublicLeaveRequest>>(
      id: string,
      data: PublicLeaveRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicLeaveRequest, T>, any>({
        path: `/public/leaves/${id}/reject/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  recovery = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryList
     * @request GET:/recovery/
     */
    recoveryList: <T extends FetchKeys<PaginatedAdminRecoveryList>>(
      query?: {
        /** @format uuid */
        assigned_to?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        school_programme__type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        /** A search term. */
        search?: string
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminRecoveryList, T>, any>({
        path: `/recovery/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryRetrieve
     * @request GET:/recovery/{id}/
     */ recoveryRetrieve: <T extends FetchKeys<AdminRecovery>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminRecovery, T>, any>({
        path: `/recovery/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryUpdate
     * @request PUT:/recovery/{id}/
     */ recoveryUpdate: <T extends FetchKeys<AdminRecovery>>(
      id: string,
      data: AdminRecoveryRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminRecovery, T>, any>({
        path: `/recovery/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryPartialUpdate
     * @request PATCH:/recovery/{id}/
     */ recoveryPartialUpdate: <T extends FetchKeys<AdminRecovery>>(
      id: string,
      data: PatchedAdminRecoveryRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminRecovery, T>, any>({
        path: `/recovery/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags recovery
     * @name RecoveryAvailableFiltersRetrieve
     * @request GET:/recovery/available-filters/
     */ recoveryAvailableFiltersRetrieve: <
      T extends FetchKeys<RecoveryAvailableFilters>
    >(
      query?: {
        /** @format uuid */
        assigned_to?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        school_programme__type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        /** A search term. */
        search?: string
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RecoveryAvailableFilters, T>, any>({
        path: `/recovery/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description return cached staff users map
     *
     * @tags recovery
     * @name RecoveryAvailableStaffUsersList
     * @request GET:/recovery/available-staff-users/
     */ recoveryAvailableStaffUsersList: <T extends FetchKeys<StringOption[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/recovery/available-staff-users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  reports = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags reports
     * @name ReportsReportCardsList
     * @request GET:/reports/report-cards/
     */
    reportsReportCardsList: <T extends FetchKeys<PaginatedReportCardList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedReportCardList, T>, any>({
        path: `/reports/report-cards/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags reports
     * @name ReportsReportCardsRetrieve
     * @request GET:/reports/report-cards/{id}/
     */ reportsReportCardsRetrieve: <T extends FetchKeys<ReportCard>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ReportCard, T>, any>({
        path: `/reports/report-cards/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags reports
     * @name ReportsReportCardsSendToParentsCreate
     * @request POST:/reports/report-cards/send-to-parents/
     */ reportsReportCardsSendToParentsCreate: <T extends FetchKeys<void>>(
      data: ParentSendReportCardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/reports/report-cards/send-to-parents/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
  }
  roomBookings = {
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsCreate
     * @request POST:/room-bookings/
     */
    roomBookingsCreate: <T extends FetchKeys<RoomBookingCreate>>(
      data: RoomBookingCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingCreate, T>, any>({
        path: `/room-bookings/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsUpdate
     * @request PUT:/room-bookings/{id}/
     */ roomBookingsUpdate: <T extends FetchKeys<RoomBookingUpdate>>(
      id: number,
      data: RoomBookingUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingUpdate, T>, any>({
        path: `/room-bookings/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsPartialUpdate
     * @request PATCH:/room-bookings/{id}/
     */ roomBookingsPartialUpdate: <T extends FetchKeys<RoomBookingUpdate>>(
      id: number,
      data: PatchedRoomBookingUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingUpdate, T>, any>({
        path: `/room-bookings/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsDestroy
     * @request DELETE:/room-bookings/{id}/
     */ roomBookingsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/room-bookings/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags room-bookings
     * @name RoomBookingsBookForOptionsList
     * @request GET:/room-bookings/book-for-options/
     */ roomBookingsBookForOptionsList: <
      T extends FetchKeys<RoomBookingBookForOptions[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RoomBookingBookForOptions[], T>, any>({
        path: `/room-bookings/book-for-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  rooms = {
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsList
     * @request GET:/rooms/
     */
    roomsList: <T extends FetchKeys<PaginatedRoomList>>(
      query?: {
        available_for_exam?: boolean
        department?: number
        is_private?: boolean
        msl_room?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        pri_room?: boolean
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedRoomList, T>, any>({
        path: `/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsCreate
     * @request POST:/rooms/
     */ roomsCreate: <T extends FetchKeys<Room>>(
      data: RoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsRetrieve
     * @request GET:/rooms/{id}/
     */ roomsRetrieve: <T extends FetchKeys<Room>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsUpdate
     * @request PUT:/rooms/{id}/
     */ roomsUpdate: <T extends FetchKeys<Room>>(
      id: number,
      data: RoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsPartialUpdate
     * @request PATCH:/rooms/{id}/
     */ roomsPartialUpdate: <T extends FetchKeys<Room>>(
      id: number,
      data: PatchedRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsDestroy
     * @request DELETE:/rooms/{id}/
     */ roomsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/rooms/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags rooms
     * @name RoomsAvailableRetrieve
     * @request GET:/rooms/available/
     */ roomsAvailableRetrieve: <T extends FetchKeys<Room>>(
      query: {
        available_for_exam?: boolean
        /** @format uuid */
        course: string
        day?: number
        department?: number
        /** @format double */
        duration?: number
        hour?: number
        is_private?: boolean
        msl_room?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        pri_room?: boolean
        schedule_automatically?: boolean
        /** A search term. */
        search?: string
        section: number
        students: string[]
        /** @format uuid */
        teacher: string
        timetable?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/available/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description list rooms that include is_occupied regards chosen lesson event
     *
     * @tags rooms
     * @name RoomsWithOccupationRetrieve
     * @request GET:/rooms/with-occupation/
     */ roomsWithOccupationRetrieve: <T extends FetchKeys<Room>>(
      query?: {
        available_for_exam?: boolean
        department?: number
        is_private?: boolean
        msl_room?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        pri_room?: boolean
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Room, T>, any>({
        path: `/rooms/with-occupation/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  schema = {
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags schema
     * @name SchemaRetrieve
     * @request GET:/schema/
     * @secure
     */
    schemaRetrieve: <T extends FetchKeys<Record<string, any>>>(
      query?: {
        format?: 'json' | 'yaml'
        lang?: 'en'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Record<string, any>, T>, any>({
        path: `/schema/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
  }
  schoolBreaks = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksList
     * @request GET:/school-breaks/
     */
    schoolBreaksList: <T extends FetchKeys<PaginatedSchoolBreakList>>(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        school_year?: number[]
        /** A search term. */
        search?: string
        /**
         * * `past` - Past
         * * `active` - Active
         * * `future` - Future
         */
        status?: 'active' | 'future' | 'past'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolBreakList, T>, any>({
        path: `/school-breaks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksCreate
     * @request POST:/school-breaks/
     */ schoolBreaksCreate: <T extends FetchKeys<SchoolBreak>>(
      data: SchoolBreakRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksRetrieve
     * @request GET:/school-breaks/{id}/
     */ schoolBreaksRetrieve: <T extends FetchKeys<SchoolBreak>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksUpdate
     * @request PUT:/school-breaks/{id}/
     */ schoolBreaksUpdate: <T extends FetchKeys<SchoolBreak>>(
      id: number,
      data: SchoolBreakRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksPartialUpdate
     * @request PATCH:/school-breaks/{id}/
     */ schoolBreaksPartialUpdate: <T extends FetchKeys<SchoolBreak>>(
      id: number,
      data: PatchedSchoolBreakRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolBreak, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags school-breaks
     * @name SchoolBreaksDestroy
     * @request DELETE:/school-breaks/{id}/
     */ schoolBreaksDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/school-breaks/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  schoolProgrammes = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesList
     * @request GET:/school-programmes/
     */
    schoolProgrammesList: <
      T extends FetchKeys<PaginatedSchoolProgrammeListList>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        is_compulsory?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        participant?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        status?: 'approved' | 'rejected' | 'requested'
        student?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolProgrammeListList, T>, any>({
        path: `/school-programmes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesCreate
     * @request POST:/school-programmes/
     */ schoolProgrammesCreate: <T extends FetchKeys<SchoolProgramme>>(
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesRetrieve
     * @request GET:/school-programmes/{id}/
     */ schoolProgrammesRetrieve: <T extends FetchKeys<SchoolProgramme>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesUpdate
     * @request PUT:/school-programmes/{id}/
     */ schoolProgrammesUpdate: <T extends FetchKeys<SchoolProgramme>>(
      id: number,
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesPartialUpdate
     * @request PATCH:/school-programmes/{id}/
     */ schoolProgrammesPartialUpdate: <T extends FetchKeys<SchoolProgramme>>(
      id: number,
      data: PatchedSchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesDestroy
     * @request DELETE:/school-programmes/{id}/
     */ schoolProgrammesDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/school-programmes/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesApproveProgrammeCreate
     * @request POST:/school-programmes/{id}/approve-programme/
     */ schoolProgrammesApproveProgrammeCreate: <
      T extends FetchKeys<SchoolProgramme>
    >(
      id: number,
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/approve-programme/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesRejectProgrammeCreate
     * @request POST:/school-programmes/{id}/reject-programme/
     */ schoolProgrammesRejectProgrammeCreate: <
      T extends FetchKeys<SchoolProgrammeReject>
    >(
      id: number,
      data: SchoolProgrammeRejectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgrammeReject, T>, any>({
        path: `/school-programmes/${id}/reject-programme/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesRequestProgrammeCreate
     * @request POST:/school-programmes/{id}/request-programme/
     */ schoolProgrammesRequestProgrammeCreate: <
      T extends FetchKeys<SchoolProgramme>
    >(
      id: number,
      data: SchoolProgrammeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/${id}/request-programme/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsList
     * @request GET:/school-programmes/{programmeId}/attendance-reports/
     */ schoolProgrammesAttendanceReportsList: <
      T extends FetchKeys<PaginatedProgrammeAttendanceReportList>
    >(
      programmeId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedProgrammeAttendanceReportList, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsCreate
     * @request POST:/school-programmes/{programmeId}/attendance-reports/
     */ schoolProgrammesAttendanceReportsCreate: <
      T extends FetchKeys<ProgrammeAttendanceReportCreate>
    >(
      programmeId: string,
      data: ProgrammeAttendanceReportCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReportCreate, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsRetrieve
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{id}/
     */ schoolProgrammesAttendanceReportsRetrieve: <
      T extends FetchKeys<ProgrammeAttendanceReport>
    >(
      id: number,
      programmeId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReport, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsUpdate
     * @request PUT:/school-programmes/{programmeId}/attendance-reports/{id}/
     */ schoolProgrammesAttendanceReportsUpdate: <
      T extends FetchKeys<ProgrammeAttendanceReportUpdate>
    >(
      id: number,
      programmeId: string,
      data: ProgrammeAttendanceReportUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReportUpdate, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsPartialUpdate
     * @request PATCH:/school-programmes/{programmeId}/attendance-reports/{id}/
     */ schoolProgrammesAttendanceReportsPartialUpdate: <
      T extends FetchKeys<ProgrammeAttendanceReport>
    >(
      id: number,
      programmeId: string,
      data: PatchedProgrammeAttendanceReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeAttendanceReport, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsList
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/
     */ schoolProgrammesAttendanceReportsStudentsList: <
      T extends FetchKeys<PaginatedProgrammeStudentAttendanceList>
    >(
      programmeId: string,
      reportId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedProgrammeStudentAttendanceList, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsRetrieve
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/
     */ schoolProgrammesAttendanceReportsStudentsRetrieve: <
      T extends FetchKeys<ProgrammeStudentAttendance>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendance, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsUpdate
     * @request PUT:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/
     */ schoolProgrammesAttendanceReportsStudentsUpdate: <
      T extends FetchKeys<ProgrammeStudentAttendanceUpdate>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      data: ProgrammeStudentAttendanceUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendanceUpdate, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsPartialUpdate
     * @request PATCH:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/
     */ schoolProgrammesAttendanceReportsStudentsPartialUpdate: <
      T extends FetchKeys<ProgrammeStudentAttendance>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      data: PatchedProgrammeStudentAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendance, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesAttendanceReportsStudentsEnrolmentHouseNameRetrieve
     * @request GET:/school-programmes/{programmeId}/attendance-reports/{reportId}/students/{id}/enrolment-house-name/
     */ schoolProgrammesAttendanceReportsStudentsEnrolmentHouseNameRetrieve: <
      T extends FetchKeys<ProgrammeStudentAttendance>
    >(
      id: number,
      programmeId: string,
      reportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ProgrammeStudentAttendance, T>, any>({
        path: `/school-programmes/${programmeId}/attendance-reports/${reportId}/students/${id}/enrolment-house-name/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableFiltersRetrieve
     * @request GET:/school-programmes/available-filters/
     */ schoolProgrammesAvailableFiltersRetrieve: <
      T extends FetchKeys<SchoolProgrammeAvailableFilters>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        is_compulsory?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        participant?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        status?: 'approved' | 'rejected' | 'requested'
        student?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgrammeAvailableFilters, T>, any>({
        path: `/school-programmes/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableStaffUsersRetrieve
     * @request GET:/school-programmes/available-staff-users/
     */ schoolProgrammesAvailableStaffUsersRetrieve: <
      T extends FetchKeys<SchoolProgramme>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        is_compulsory?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        participant?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        status?: 'approved' | 'rejected' | 'requested'
        student?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?:
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SchoolProgramme, T>, any>({
        path: `/school-programmes/available-staff-users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableStudentsList
     * @request GET:/school-programmes/available-students/
     */ schoolProgrammesAvailableStudentsList: <
      T extends FetchKeys<PaginatedAvailableStudentList>
    >(
      query?: {
        ages?: string[]
        /**
         * * `4` - S
         * * `3` - E
         * * `2` - A
         * * `1` - C
         * * `0` - not assigned
         */
        categories?: (0 | 1 | 2 | 3 | 4)[]
        grade_levels?: string[]
        houses?: number[]
        /** @format uuid */
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAvailableStudentList, T>, any>({
        path: `/school-programmes/available-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags school-programmes
     * @name SchoolProgrammesAvailableStudentsAvailableFiltersRetrieve
     * @request GET:/school-programmes/available-students/available-filters/
     */ schoolProgrammesAvailableStudentsAvailableFiltersRetrieve: <
      T extends FetchKeys<AvailableStudentForSchoolProgrammeAvailableFilters>
    >(
      query?: {
        ages?: string[]
        /**
         * * `4` - S
         * * `3` - E
         * * `2` - A
         * * `1` - C
         * * `0` - not assigned
         */
        categories?: (0 | 1 | 2 | 3 | 4)[]
        grade_levels?: string[]
        houses?: number[]
        /** @format uuid */
        id?: string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<AvailableStudentForSchoolProgrammeAvailableFilters, T>,
        any
      >({
        path: `/school-programmes/available-students/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetAsApprovedCreate
     * @request POST:/school-programmes/enrolments/{id}/set-as-approved/
     */ schoolProgrammesEnrolmentsSetAsApprovedCreate: <
      T extends FetchKeys<ApproveEnrolment>
    >(
      id: number,
      data: ApproveEnrolmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ApproveEnrolment, T>, any>({
        path: `/school-programmes/enrolments/${id}/set-as-approved/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetAsRejectedCreate
     * @request POST:/school-programmes/enrolments/{id}/set-as-rejected/
     */ schoolProgrammesEnrolmentsSetAsRejectedCreate: <
      T extends FetchKeys<RejectEnrolment>
    >(
      id: number,
      data: RejectEnrolmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RejectEnrolment, T>, any>({
        path: `/school-programmes/enrolments/${id}/set-as-rejected/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetAsRequestedCreate
     * @request POST:/school-programmes/enrolments/{id}/set-as-requested/
     */ schoolProgrammesEnrolmentsSetAsRequestedCreate: <
      T extends FetchKeys<void>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/school-programmes/enrolments/${id}/set-as-requested/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetManyAsApprovedCreate
     * @request POST:/school-programmes/enrolments/set-many-as-approved/
     */ schoolProgrammesEnrolmentsSetManyAsApprovedCreate: <
      T extends FetchKeys<ManyEnrolmentsToApproved>
    >(
      data: ManyEnrolmentsToApprovedRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyEnrolmentsToApproved, T>, any>({
        path: `/school-programmes/enrolments/set-many-as-approved/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetManyAsRejectedCreate
     * @request POST:/school-programmes/enrolments/set-many-as-rejected/
     */ schoolProgrammesEnrolmentsSetManyAsRejectedCreate: <
      T extends FetchKeys<ManyEnrolmentsToRejected>
    >(
      data: ManyEnrolmentsToRejectedRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyEnrolmentsToRejected, T>, any>({
        path: `/school-programmes/enrolments/set-many-as-rejected/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags school-programmes
     * @name SchoolProgrammesEnrolmentsSetManyAsRequestedCreate
     * @request POST:/school-programmes/enrolments/set-many-as-requested/
     */ schoolProgrammesEnrolmentsSetManyAsRequestedCreate: <
      T extends FetchKeys<ManyEnrolmentsToRequested>
    >(
      data: ManyEnrolmentsToRequestedRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ManyEnrolmentsToRequested, T>, any>({
        path: `/school-programmes/enrolments/set-many-as-requested/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  sickReports = {
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsList
     * @request GET:/sick-reports/
     */
    sickReportsList: <T extends FetchKeys<PaginatedSickReportListList>>(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSickReportListList, T>, any>({
        path: `/sick-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCreate
     * @request POST:/sick-reports/
     */ sickReportsCreate: <T extends FetchKeys<SickReport>>(
      data: SickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsRetrieve
     * @request GET:/sick-reports/{id}/
     */ sickReportsRetrieve: <T extends FetchKeys<SickReport>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsUpdate
     * @request PUT:/sick-reports/{id}/
     */ sickReportsUpdate: <T extends FetchKeys<SickReport>>(
      id: string,
      data: SickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsPartialUpdate
     * @request PATCH:/sick-reports/{id}/
     */ sickReportsPartialUpdate: <T extends FetchKeys<SickReport>>(
      id: string,
      data: PatchedSickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsDestroy
     * @request DELETE:/sick-reports/{id}/
     */ sickReportsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsApproveClosureCreate
     * @request POST:/sick-reports/{id}/approve-closure/
     */ sickReportsApproveClosureCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/approve-closure/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCloseCreate
     * @request POST:/sick-reports/{id}/close/
     */ sickReportsCloseCreate: <T extends FetchKeys<CloseSickReport>>(
      id: string,
      data: CloseSickReportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CloseSickReport, T>, any>({
        path: `/sick-reports/${id}/close/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsHasActiveMedicineInPlanRetrieve
     * @request GET:/sick-reports/{id}/has-active-medicine-in-plan/
     */ sickReportsHasActiveMedicineInPlanRetrieve: <
      T extends FetchKeys<SickReport>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReport, T>, any>({
        path: `/sick-reports/${id}/has-active-medicine-in-plan/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsOpenCreate
     * @request POST:/sick-reports/{id}/open/
     */ sickReportsOpenCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/open/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsRejectClosureCreate
     * @request POST:/sick-reports/{id}/reject-closure/
     */ sickReportsRejectClosureCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${id}/reject-closure/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsRequestClosureCreate
     * @request POST:/sick-reports/{id}/request-closure/
     */ sickReportsRequestClosureCreate: <
      T extends FetchKeys<SickReportClosureRequest>
    >(
      id: string,
      data: SickReportClosureRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportClosureRequest, T>, any>({
        path: `/sick-reports/${id}/request-closure/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsList
     * @request GET:/sick-reports/{sickReportId}/comments/
     */ sickReportsCommentsList: <T extends FetchKeys<SickReportComment[]>>(
      sickReportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment[], T>, any>({
        path: `/sick-reports/${sickReportId}/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsCreate
     * @request POST:/sick-reports/{sickReportId}/comments/
     */ sickReportsCommentsCreate: <T extends FetchKeys<SickReportComment>>(
      sickReportId: string,
      data: SickReportCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsRetrieve
     * @request GET:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsRetrieve: <T extends FetchKeys<SickReportComment>>(
      id: string,
      sickReportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsUpdate
     * @request PUT:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsUpdate: <T extends FetchKeys<SickReportComment>>(
      id: string,
      sickReportId: string,
      data: SickReportCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsPartialUpdate
     * @request PATCH:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsPartialUpdate: <
      T extends FetchKeys<SickReportComment>
    >(
      id: string,
      sickReportId: string,
      data: PatchedSickReportCommentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportComment, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCommentsDestroy
     * @request DELETE:/sick-reports/{sickReportId}/comments/{id}/
     */ sickReportsCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      sickReportId: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/${sickReportId}/comments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsList
     * @request GET:/sick-reports/appointments/
     */ sickReportsAppointmentsList: <T extends FetchKeys<Appointment[]>>(
      query?: {
        report?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment[], T>, any>({
        path: `/sick-reports/appointments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsCreate
     * @request POST:/sick-reports/appointments/
     */ sickReportsAppointmentsCreate: <T extends FetchKeys<Appointment>>(
      data: AppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsRetrieve
     * @request GET:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsRetrieve: <T extends FetchKeys<Appointment>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsUpdate
     * @request PUT:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsUpdate: <T extends FetchKeys<Appointment>>(
      id: number,
      data: AppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsPartialUpdate
     * @request PATCH:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsPartialUpdate: <
      T extends FetchKeys<Appointment>
    >(
      id: number,
      data: PatchedAppointmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Appointment, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsDestroy
     * @request DELETE:/sick-reports/appointments/{id}/
     */ sickReportsAppointmentsDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/sick-reports/appointments/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsCancelUpdate
     * @request PUT:/sick-reports/appointments/{id}/cancel/
     */ sickReportsAppointmentsCancelUpdate: <
      T extends FetchKeys<AppointmentCancellation>
    >(
      id: number,
      data: AppointmentCancellationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AppointmentCancellation, T>, any>({
        path: `/sick-reports/appointments/${id}/cancel/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsCancelPartialUpdate
     * @request PATCH:/sick-reports/appointments/{id}/cancel/
     */ sickReportsAppointmentsCancelPartialUpdate: <
      T extends FetchKeys<AppointmentCancellation>
    >(
      id: number,
      data: PatchedAppointmentCancellationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AppointmentCancellation, T>, any>({
        path: `/sick-reports/appointments/${id}/cancel/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsGetDiagnosisRetrieve
     * @request GET:/sick-reports/appointments/{id}/get-diagnosis/
     */ sickReportsAppointmentsGetDiagnosisRetrieve: <
      T extends FetchKeys<DoctorDiagnosis>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DoctorDiagnosis, T>, any>({
        path: `/sick-reports/appointments/${id}/get-diagnosis/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsSetDiagnosisUpdate
     * @request PUT:/sick-reports/appointments/{id}/set-diagnosis/
     */ sickReportsAppointmentsSetDiagnosisUpdate: <
      T extends FetchKeys<DoctorDiagnosis>
    >(
      id: number,
      data: DoctorDiagnosisRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DoctorDiagnosis, T>, any>({
        path: `/sick-reports/appointments/${id}/set-diagnosis/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsAppointmentsSetDiagnosisPartialUpdate
     * @request PATCH:/sick-reports/appointments/{id}/set-diagnosis/
     */ sickReportsAppointmentsSetDiagnosisPartialUpdate: <
      T extends FetchKeys<DoctorDiagnosis>
    >(
      id: number,
      data: PatchedDoctorDiagnosisRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DoctorDiagnosis, T>, any>({
        path: `/sick-reports/appointments/${id}/set-diagnosis/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsCloseReasonsList
     * @request GET:/sick-reports/close-reasons/
     */ sickReportsCloseReasonsList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/sick-reports/close-reasons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactRetrieve
     * @request GET:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactRetrieve: <T extends FetchKeys<FirstContact>>(
      reportId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactCreate
     * @request POST:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactCreate: <T extends FetchKeys<FirstContact>>(
      reportId: string,
      data: FirstContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactUpdate
     * @request PUT:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactUpdate: <T extends FetchKeys<FirstContact>>(
      reportId: string,
      data: FirstContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsFirstContactPartialUpdate
     * @request PATCH:/sick-reports/first-contact/{reportId}/
     */ sickReportsFirstContactPartialUpdate: <
      T extends FetchKeys<FirstContact>
    >(
      reportId: string,
      data: PatchedFirstContactRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FirstContact, T>, any>({
        path: `/sick-reports/first-contact/${reportId}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsOpenedCovidReportsRetrieve
     * @request GET:/sick-reports/opened-covid-reports/
     */ sickReportsOpenedCovidReportsRetrieve: <
      T extends FetchKeys<SimpleSickReport>
    >(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleSickReport, T>, any>({
        path: `/sick-reports/opened-covid-reports/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSickReportAbsenceDatesCreate
     * @request POST:/sick-reports/sick-report-absence-dates/
     */ sickReportsSickReportAbsenceDatesCreate: <
      T extends FetchKeys<SickReportAbsenceDates>
    >(
      data: SickReportAbsenceDatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportAbsenceDates, T>, any>({
        path: `/sick-reports/sick-report-absence-dates/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSickReportAbsenceDatesUpdate
     * @request PUT:/sick-reports/sick-report-absence-dates/{id}/
     */ sickReportsSickReportAbsenceDatesUpdate: <
      T extends FetchKeys<SickReportAbsenceDates>
    >(
      id: string,
      data: SickReportAbsenceDatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportAbsenceDates, T>, any>({
        path: `/sick-reports/sick-report-absence-dates/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSickReportAbsenceDatesPartialUpdate
     * @request PATCH:/sick-reports/sick-report-absence-dates/{id}/
     */ sickReportsSickReportAbsenceDatesPartialUpdate: <
      T extends FetchKeys<SickReportAbsenceDates>
    >(
      id: string,
      data: PatchedSickReportAbsenceDatesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SickReportAbsenceDates, T>, any>({
        path: `/sick-reports/sick-report-absence-dates/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsSymptomsList
     * @request GET:/sick-reports/symptoms/
     */ sickReportsSymptomsList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        house?: number
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        status?: string
        /** @format uuid */
        student?: string
        /**
         * * `regular` - Regular
         * * `med_only` - Med-only
         */
        type?: 'med_only' | 'regular'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/sick-reports/symptoms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags sick-reports
     * @name SickReportsTreatmentHistoryList
     * @request GET:/sick-reports/treatment-history/{studentId}/
     */ sickReportsTreatmentHistoryList: <
      T extends FetchKeys<TreatmentHistoryItem[]>
    >(
      studentId: string,
      query?: {
        /** @format date */
        history_created_after?: string
        /** @format date */
        history_created_before?: string
        /** Which field to use when ordering the results. */
        treatment_history_ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TreatmentHistoryItem[], T>, any>({
        path: `/sick-reports/treatment-history/${studentId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  studentApplications = {
    /**
     * No description
     *
     * @tags student-applications
     * @name StudentApplicationsCreate
     * @request POST:/student-applications/
     */
    studentApplicationsCreate: <T extends FetchKeys<StudentApplication>>(
      data: StudentApplicationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentApplication, T>, any>({
        path: `/student-applications/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student-applications
     * @name StudentApplicationsChoicesRetrieve
     * @request GET:/student-applications/choices/
     */ studentApplicationsChoicesRetrieve: <
      T extends FetchKeys<StudentApplication>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentApplication, T>, any>({
        path: `/student-applications/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student-applications
     * @name StudentApplicationsMedicationsList
     * @request GET:/student-applications/medications/
     */ studentApplicationsMedicationsList: <
      T extends FetchKeys<AdminStudentApplicationMedication[]>
    >(
      query?: {
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentApplicationMedication[], T>, any>({
        path: `/student-applications/medications/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student-applications
     * @name StudentApplicationsMedicationsRetrieve
     * @request GET:/student-applications/medications/{id}/
     */ studentApplicationsMedicationsRetrieve: <
      T extends FetchKeys<AdminStudentApplicationMedication>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentApplicationMedication, T>, any>({
        path: `/student-applications/medications/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student-applications
     * @name StudentApplicationsMedicationsIgnoreCreate
     * @request POST:/student-applications/medications/{id}/ignore/
     */ studentApplicationsMedicationsIgnoreCreate: <
      T extends FetchKeys<AdminStudentApplicationMedication>
    >(
      id: number,
      data: AdminStudentApplicationMedicationRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminStudentApplicationMedication, T>, any>({
        path: `/student-applications/medications/${id}/ignore/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  student = {
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksList
     * @request GET:/student/assignments/homeworks/
     */
    studentAssignmentsHomeworksList: <
      T extends FetchKeys<PaginatedStudentHomeworkList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentHomeworkList, T>, any>({
        path: `/student/assignments/homeworks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksRetrieve
     * @request GET:/student/assignments/homeworks/{id}/
     */ studentAssignmentsHomeworksRetrieve: <
      T extends FetchKeys<StudentHomework>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentHomework, T>, any>({
        path: `/student/assignments/homeworks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksMarkAsSentByStudentUpdate
     * @request PUT:/student/assignments/homeworks/{id}/mark-as-sent-by-student/
     */ studentAssignmentsHomeworksMarkAsSentByStudentUpdate: <
      T extends FetchKeys<MarkHomeworkAsSentStudent>
    >(
      id: string,
      data: MarkHomeworkAsSentStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MarkHomeworkAsSentStudent, T>, any>({
        path: `/student/assignments/homeworks/${id}/mark-as-sent-by-student/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksMarkAsSentByStudentPartialUpdate
     * @request PATCH:/student/assignments/homeworks/{id}/mark-as-sent-by-student/
     */ studentAssignmentsHomeworksMarkAsSentByStudentPartialUpdate: <
      T extends FetchKeys<MarkHomeworkAsSentStudent>
    >(
      id: string,
      data: PatchedMarkHomeworkAsSentStudentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MarkHomeworkAsSentStudent, T>, any>({
        path: `/student/assignments/homeworks/${id}/mark-as-sent-by-student/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksCurrentlyLateRetrieve
     * @request GET:/student/assignments/homeworks/currently-late/
     */ studentAssignmentsHomeworksCurrentlyLateRetrieve: <
      T extends FetchKeys<StudentHomeworkCurrentlyLate>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentHomeworkCurrentlyLate, T>, any>({
        path: `/student/assignments/homeworks/currently-late/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksNewlyReviewedRetrieve
     * @request GET:/student/assignments/homeworks/newly-reviewed/
     */ studentAssignmentsHomeworksNewlyReviewedRetrieve: <
      T extends FetchKeys<StudentHomeworkNewlyReviewed>
    >(
      query?: {
        course?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentHomeworkNewlyReviewed, T>, any>({
        path: `/student/assignments/homeworks/newly-reviewed/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentAssignmentsHomeworksToDoRetrieve
     * @request GET:/student/assignments/homeworks/to-do/
     */ studentAssignmentsHomeworksToDoRetrieve: <
      T extends FetchKeys<StudentHomeworkToDo>
    >(
      query?: {
        course?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentHomeworkToDo, T>, any>({
        path: `/student/assignments/homeworks/to-do/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentAttendancesAbsencesList
     * @request GET:/student/attendances/absences/
     */ studentAttendancesAbsencesList: <
      T extends FetchKeys<PaginatedStudentAcademicAttendanceList>
    >(
      query?: {
        /** @format uuid */
        course?: string
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentAcademicAttendanceList, T>, any>({
        path: `/student/attendances/absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesList
     * @request GET:/student/campus-absences/
     */ studentCampusAbsencesList: <
      T extends FetchKeys<PaginatedStudentCampusAbsenceList>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCampusAbsenceList, T>, any>({
        path: `/student/campus-absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesCreate
     * @request POST:/student/campus-absences/
     */ studentCampusAbsencesCreate: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      data: StudentCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesRetrieve
     * @request GET:/student/campus-absences/{id}/
     */ studentCampusAbsencesRetrieve: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesUpdate
     * @request PUT:/student/campus-absences/{id}/
     */ studentCampusAbsencesUpdate: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      id: string,
      data: StudentCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesPartialUpdate
     * @request PATCH:/student/campus-absences/{id}/
     */ studentCampusAbsencesPartialUpdate: <
      T extends FetchKeys<StudentCampusAbsence>
    >(
      id: string,
      data: PatchedStudentCampusAbsenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsence, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesDestroy
     * @request DELETE:/student/campus-absences/{id}/
     */ studentCampusAbsencesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/student/campus-absences/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesStatusesRetrieve
     * @request GET:/student/campus-absences/statuses/
     */ studentCampusAbsencesStatusesRetrieve: <
      T extends FetchKeys<StudentCampusAbsenceStatus>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsenceStatus, T>, any>({
        path: `/student/campus-absences/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesSubtypeChoicesRetrieve
     * @request GET:/student/campus-absences/subtype-choices/
     */ studentCampusAbsencesSubtypeChoicesRetrieve: <
      T extends FetchKeys<StudentCampusAbsenceSubtype>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsenceSubtype, T>, any>({
        path: `/student/campus-absences/subtype-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCampusAbsencesTypeChoicesList
     * @request GET:/student/campus-absences/type-choices/
     */ studentCampusAbsencesTypeChoicesList: <
      T extends FetchKeys<StudentCampusAbsenceType[]>
    >(
      query?: {
        /**
         * * `afternoon_ausgang` - Afternoon Ausgang
         * * `evening_ausgang` - Evening Ausgang
         * * `evening_campus_ausgang` - Evening - Campus Ausgang
         * * `birthday_ausgang` - Birthday Ausgang
         * * `family_visit` - Family visit
         * * `on_board` - On board Ausgang
         * * `prolonged_ausgang` - Prolonged Ausgang
         * * `campus` - Campus
         * * `offsite` - Offsite
         * * `offsite_overnight` - Offsite, Overnight
         * * `official_appointment` - Official appointment
         * * `specialist_doctor_appointment` - Specialist Doctor Appointment
         * * `programme` - Programme
         * * `house_evening` - House evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `book_the_cafe` - Book the Cafe
         * * `city_brunch_zurich` - City Brunch Zurich
         * * `additional_ausgang` - Additional Ausgang
         */
        campus_absence_subtype?: (
          | 'additional_ausgang'
          | 'afternoon_ausgang'
          | 'birthday_ausgang'
          | 'book_the_cafe'
          | 'campus'
          | 'city_brunch_zurich'
          | 'evening_ausgang'
          | 'evening_campus_ausgang'
          | 'family_visit'
          | 'house_evening'
          | 'official_appointment'
          | 'offsite'
          | 'offsite_overnight'
          | 'on_board'
          | 'other'
          | 'programme'
          | 'prolonged_ausgang'
          | 'school_trip'
          | 'specialist_doctor_appointment'
          | 'term_break'
          | 'weekend_programme'
        )[]
        /**
         * * `ausgang` - Ausgang
         * * `external_exam` - External exam
         * * `official` - Official
         * * `school_programme` - School Programme
         * * `extra_ausgang` - Extra Ausgang
         */
        campus_absence_type?: (
          | 'ausgang'
          | 'external_exam'
          | 'extra_ausgang'
          | 'official'
          | 'school_programme'
        )[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /** A search term. */
        search?: string
        /**
         * * `requested` - Pending
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `canceled_by_student` - Canceled by student
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?: (
          | 'approved'
          | 'canceled_by_student'
          | 'cancelled'
          | 'done'
          | 'rejected'
          | 'requested'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCampusAbsenceType[], T>, any>({
        path: `/student/campus-absences/type-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentCategoryRetrieve
     * @request GET:/student/category/
     */ studentCategoryRetrieve: <
      T extends FetchKeys<StudentCategoryTransition>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCategoryTransition, T>, any>({
        path: `/student/category/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCoursesList
     * @request GET:/student/courses/
     */ studentCoursesList: <T extends FetchKeys<PaginatedStudentCoursesList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentCoursesList, T>, any>({
        path: `/student/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCoursesRetrieve
     * @request GET:/student/courses/{courseId}/
     */ studentCoursesRetrieve: <T extends FetchKeys<StudentCourses>>(
      courseId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourses, T>, any>({
        path: `/student/courses/${courseId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentCoursesYearsList
     * @request GET:/student/courses/years/
     */ studentCoursesYearsList: <
      T extends FetchKeys<StudentCourseYearResponse[]>
    >(
      query?: {
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentCourseYearResponse[], T>, any>({
        path: `/student/courses/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentFavouritesList
     * @request GET:/student/favourites/
     */ studentFavouritesList: <T extends FetchKeys<FavouriteWidget[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteWidget[], T>, any>({
        path: `/student/favourites/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentFilesForStudentsList
     * @request GET:/student/files-for-students/
     */ studentFilesForStudentsList: <
      T extends FetchKeys<PaginatedStudentsFileList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentsFileList, T>, any>({
        path: `/student/files-for-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentImagesCreate
     * @request POST:/student/images/
     */ studentImagesCreate: <T extends FetchKeys<Image>>(
      data: ImageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Image, T>, any>({
        path: `/student/images/`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentInfoboardsFeedList
     * @request GET:/student/infoboards/feed/
     */ studentInfoboardsFeedList: <T extends FetchKeys<PaginatedFeedList>>(
      query?: {
        include_widgets?: (
          | 'BoardingAnnouncementWidget'
          | 'CelebrationWidget'
          | 'CongratulationsWidget'
          | 'EventAgendaWidget'
          | 'EventInfoWidget'
          | 'EventInfoWithPhotoWidget'
          | 'JournalWidget'
          | 'PosterWidget'
          | 'StudentArtGalleryWidget'
          | 'VariantManualCongratulationsWidget'
          | 'all'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedFeedList, T>, any>({
        path: `/student/infoboards/feed/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsMessagesList
     * @request GET:/student/infoboards/messages/
     */ studentInfoboardsMessagesList: <
      T extends FetchKeys<PaginatedStudentMessageList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentMessageList, T>, any>({
        path: `/student/infoboards/messages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsMessagesRetrieve
     * @request GET:/student/infoboards/messages/{id}/
     */ studentInfoboardsMessagesRetrieve: <
      T extends FetchKeys<StudentMessage>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMessage, T>, any>({
        path: `/student/infoboards/messages/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsList
     * @request GET:/student/infoboards/posts/
     */ studentInfoboardsPostsList: <
      T extends FetchKeys<PaginatedStudentContentList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentContentList, T>, any>({
        path: `/student/infoboards/posts/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsCreate
     * @request POST:/student/infoboards/posts/
     */ studentInfoboardsPostsCreate: <T extends FetchKeys<StudentContent>>(
      data: StudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentContent, T>, any>({
        path: `/student/infoboards/posts/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsRetrieve
     * @request GET:/student/infoboards/posts/{id}/
     */ studentInfoboardsPostsRetrieve: <T extends FetchKeys<StudentContent>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentContent, T>, any>({
        path: `/student/infoboards/posts/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsUpdate
     * @request PUT:/student/infoboards/posts/{id}/
     */ studentInfoboardsPostsUpdate: <T extends FetchKeys<StudentContent>>(
      id: string,
      data: StudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentContent, T>, any>({
        path: `/student/infoboards/posts/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsPartialUpdate
     * @request PATCH:/student/infoboards/posts/{id}/
     */ studentInfoboardsPostsPartialUpdate: <
      T extends FetchKeys<StudentContent>
    >(
      id: string,
      data: PatchedStudentContentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentContent, T>, any>({
        path: `/student/infoboards/posts/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsDestroy
     * @request DELETE:/student/infoboards/posts/{id}/
     */ studentInfoboardsPostsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/student/infoboards/posts/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentInfoboardsPostsPostsInfoRetrieve
     * @request GET:/student/infoboards/posts/posts-info/
     */ studentInfoboardsPostsPostsInfoRetrieve: <
      T extends FetchKeys<PostsInfo>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PostsInfo, T>, any>({
        path: `/student/infoboards/posts/posts-info/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesList
     * @request GET:/student/leaves/
     */ studentLeavesList: <
      T extends FetchKeys<PaginatedStudentLeaveRequestSwaggerList>
    >(
      query?: {
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /**
         * * `0` - Weekend
         * * `1` - Holiday
         * * `2` - Other
         */
        leave_type?: (0 | 1 | 2)[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?: (
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentLeaveRequestSwaggerList, T>, any>({
        path: `/student/leaves/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesCreate
     * @request POST:/student/leaves/
     */ studentLeavesCreate: <T extends FetchKeys<StudentLeaveRequest>>(
      data: StudentLeaveRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeaveRequest, T>, any>({
        path: `/student/leaves/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesRetrieve
     * @request GET:/student/leaves/{id}/
     */ studentLeavesRetrieve: <
      T extends FetchKeys<StudentLeaveRequestSwagger>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeaveRequestSwagger, T>, any>({
        path: `/student/leaves/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesUpdate
     * @request PUT:/student/leaves/{id}/
     */ studentLeavesUpdate: <T extends FetchKeys<StudentLeaveRequest>>(
      id: string,
      data: StudentLeaveRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeaveRequest, T>, any>({
        path: `/student/leaves/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesPartialUpdate
     * @request PATCH:/student/leaves/{id}/
     */ studentLeavesPartialUpdate: <T extends FetchKeys<StudentLeaveRequest>>(
      id: string,
      data: PatchedStudentLeaveRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeaveRequest, T>, any>({
        path: `/student/leaves/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesDestroy
     * @request DELETE:/student/leaves/{id}/
     */ studentLeavesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/student/leaves/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description This method is deprecated. Please use /student/students/clashes-number/
     *
     * @tags student
     * @name StudentLeavesCheckClashesCreate
     * @request POST:/student/leaves/check-clashes/
     * @deprecated
     */ studentLeavesCheckClashesCreate: <
      T extends FetchKeys<StudentClashesNumber>
    >(
      data: StudentClashesNumberRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentClashesNumber, T>, any>({
        path: `/student/leaves/check-clashes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentLeavesLeavesSummaryRetrieve
     * @request GET:/student/leaves/leaves-summary/
     */ studentLeavesLeavesSummaryRetrieve: <
      T extends FetchKeys<StudentLeavesSummarySwaggerList>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeavesSummarySwaggerList, T>, any>({
        path: `/student/leaves/leaves-summary/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesStatusesRetrieve
     * @request GET:/student/leaves/statuses/
     */ studentLeavesStatusesRetrieve: <
      T extends FetchKeys<StudentLeaveRequestStatus>
    >(
      query?: {
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /**
         * * `0` - Weekend
         * * `1` - Holiday
         * * `2` - Other
         */
        leave_type?: (0 | 1 | 2)[]
        /** A search term. */
        search?: string
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?: (
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeaveRequestStatus, T>, any>({
        path: `/student/leaves/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentLeavesTypeChoicesList
     * @request GET:/student/leaves/type-choices/
     */ studentLeavesTypeChoicesList: <
      T extends FetchKeys<StudentLeaveRequestType[]>
    >(
      query?: {
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        /**
         * * `0` - Weekend
         * * `1` - Holiday
         * * `2` - Other
         */
        leave_type?: (0 | 1 | 2)[]
        /** A search term. */
        search?: string
        /**
         * * `tentative` - Pending parental
         * * `confirmed_contact_person` - Pending academic
         * * `confirmed_academic` - Pending boarding
         * * `changed` - Changed
         * * `confirmed` - Approved
         * * `declined` - Rejected
         * * `declined_contact_person` - Rejected by contact person
         * * `declined_academic` - Rejected by academic
         * * `canceled` - Canceled by student
         * * `expired` - Expired
         */
        status?: (
          | 'canceled'
          | 'changed'
          | 'confirmed'
          | 'confirmed_academic'
          | 'confirmed_contact_person'
          | 'declined'
          | 'declined_academic'
          | 'declined_contact_person'
          | 'expired'
          | 'tentative'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentLeaveRequestType[], T>, any>({
        path: `/student/leaves/type-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentMenuList
     * @request GET:/student/menu/
     */ studentMenuList: <T extends FetchKeys<PaginatedStudentDayMenuList>>(
      query?: {
        /** @format date */
        date?: string
        is_filled?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        week?: number
        year?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentDayMenuList, T>, any>({
        path: `/student/menu/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsList
     * @request GET:/student/merits/
     */ studentMeritsList: <T extends FetchKeys<PaginatedStudentMeritListList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        type?: 'demerit' | 'merit'
        week?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentMeritListList, T>, any>({
        path: `/student/merits/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeList
     * @request GET:/student/merits-exchange/
     */ studentMeritsExchangeList: <
      T extends FetchKeys<PaginatedStudentMeritRewardList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentMeritRewardList, T>, any>({
        path: `/student/merits-exchange/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeCreate
     * @request POST:/student/merits-exchange/
     */ studentMeritsExchangeCreate: <T extends FetchKeys<StudentMeritReward>>(
      data: StudentMeritRewardRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMeritReward, T>, any>({
        path: `/student/merits-exchange/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeRetrieve
     * @request GET:/student/merits-exchange/{id}/
     */ studentMeritsExchangeRetrieve: <
      T extends FetchKeys<StudentMeritReward>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMeritReward, T>, any>({
        path: `/student/merits-exchange/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeDestroy
     * @request DELETE:/student/merits-exchange/{id}/
     */ studentMeritsExchangeDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/student/merits-exchange/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeAvailableAusgangsList
     * @request GET:/student/merits-exchange/available-ausgangs/
     */ studentMeritsExchangeAvailableAusgangsList: <
      T extends FetchKeys<SimpleStudentCampusAbsence[]>
    >(
      query?: {
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleStudentCampusAbsence[], T>, any>({
        path: `/student/merits-exchange/available-ausgangs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeAvailableStudentsList
     * @request GET:/student/merits-exchange/available-students/
     */ studentMeritsExchangeAvailableStudentsList: <
      T extends FetchKeys<AvailableStudents[]>
    >(
      query: {
        resourcetype: string
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AvailableStudents[], T>, any>({
        path: `/student/merits-exchange/available-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentMeritsExchangeParticipantsUpdate
     * @request PUT:/student/merits-exchange/participants/{id}/
     */ studentMeritsExchangeParticipantsUpdate: <
      T extends FetchKeys<MeritRewardParticipantUpdate>
    >(
      id: number,
      data: MeritRewardParticipantUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardParticipantUpdate, T>, any>({
        path: `/student/merits-exchange/participants/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentMeritsExchangeParticipantsPartialUpdate
     * @request PATCH:/student/merits-exchange/participants/{id}/
     */ studentMeritsExchangeParticipantsPartialUpdate: <
      T extends FetchKeys<MeritRewardParticipantUpdate>
    >(
      id: number,
      data: PatchedMeritRewardParticipantUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardParticipantUpdate, T>, any>({
        path: `/student/merits-exchange/participants/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeRewardTypesList
     * @request GET:/student/merits-exchange/reward-types/
     */ studentMeritsExchangeRewardTypesList: <
      T extends FetchKeys<RewardType[]>
    >(
      query?: {
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RewardType[], T>, any>({
        path: `/student/merits-exchange/reward-types/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsExchangeStatusCountsRetrieve
     * @request GET:/student/merits-exchange/status-counts/
     */ studentMeritsExchangeStatusCountsRetrieve: <
      T extends FetchKeys<MeritRewardStatusCounts>
    >(
      query?: {
        /**
         * * `pending` - Pending
         * * `pending_attendees` - Pending attendees
         * * `approved` - Approved
         * * `rejected` - Rejected
         * * `done` - Done
         * * `cancelled` - Cancelled
         */
        status?:
          | 'approved'
          | 'cancelled'
          | 'done'
          | 'pending'
          | 'pending_attendees'
          | 'rejected'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<MeritRewardStatusCounts, T>, any>({
        path: `/student/merits-exchange/status-counts/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsRetrieve
     * @request GET:/student/merits/{id}/
     */ studentMeritsRetrieve: <T extends FetchKeys<StudentMeritRetrieve>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMeritRetrieve, T>, any>({
        path: `/student/merits/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentMeritsCountRetrieve
     * @request GET:/student/merits/count/
     */ studentMeritsCountRetrieve: <T extends FetchKeys<StudentMeritCount>>(
      query?: {
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        /**
         * * `merit` - Merit
         * * `demerit` - Demerit
         */
        type?: 'demerit' | 'merit'
        week?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentMeritCount, T>, any>({
        path: `/student/merits/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentSchoolProgrammesList
     * @request GET:/student/school-programmes/
     */ studentSchoolProgrammesList: <
      T extends FetchKeys<PaginatedStudentSchoolProgrammeListList>
    >(
      query?: {
        can_enrol?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /**
         * * `pending` - Pending
         * * `requested` - Requested
         * * `waiting_parental_approve` - Waiting parental approve
         * * `rejected` - Rejected
         * * `approved` - Approved
         * * `leave_absent` - Leave - Absent
         */
        enrolment_status?: (
          | 'approved'
          | 'leave_absent'
          | 'pending'
          | 'rejected'
          | 'requested'
          | 'waiting_parental_approve'
        )[]
        my_programmes?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        programme_status?: ('approved' | 'rejected' | 'requested')[]
        /** A search term. */
        search?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?: (
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentSchoolProgrammeListList, T>, any>({
        path: `/student/school-programmes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentSchoolProgrammesRetrieve
     * @request GET:/student/school-programmes/{id}/
     */ studentSchoolProgrammesRetrieve: <
      T extends FetchKeys<StudentSchoolProgramme>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentSchoolProgramme, T>, any>({
        path: `/student/school-programmes/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentSchoolProgrammesEnrolInCreate
     * @request POST:/student/school-programmes/{id}/enrol-in/
     */ studentSchoolProgrammesEnrolInCreate: <
      T extends FetchKeys<StudentSchoolProgrammeList>
    >(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentSchoolProgrammeList, T>, any>({
        path: `/student/school-programmes/${id}/enrol-in/`,
        method: 'POST',
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentSchoolProgrammesEnrolmentStatusChoicesList
     * @request GET:/student/school-programmes/enrolment-status-choices/
     */ studentSchoolProgrammesEnrolmentStatusChoicesList: <
      T extends FetchKeys<StudentProgrammeEnrollmentStatusSwagger[]>
    >(
      query?: {
        can_enrol?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /**
         * * `pending` - Pending
         * * `requested` - Requested
         * * `waiting_parental_approve` - Waiting parental approve
         * * `rejected` - Rejected
         * * `approved` - Approved
         * * `leave_absent` - Leave - Absent
         */
        enrolment_status?: (
          | 'approved'
          | 'leave_absent'
          | 'pending'
          | 'rejected'
          | 'requested'
          | 'waiting_parental_approve'
        )[]
        my_programmes?: boolean
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        programme_status?: ('approved' | 'rejected' | 'requested')[]
        /** A search term. */
        search?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?: (
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProgrammeEnrollmentStatusSwagger[], T>, any>(
        {
          path: `/student/school-programmes/enrolment-status-choices/`,
          method: 'GET',
          query: postprocessQuery(query),
          format: 'json',
          ...params
        }
      ),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentSchoolProgrammesStatusChoicesRetrieve
     * @request GET:/student/school-programmes/status-choices/
     */ studentSchoolProgrammesStatusChoicesRetrieve: <
      T extends FetchKeys<StudentSchoolProgrammeStatusSwagger>
    >(
      query?: {
        can_enrol?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /**
         * * `pending` - Pending
         * * `requested` - Requested
         * * `waiting_parental_approve` - Waiting parental approve
         * * `rejected` - Rejected
         * * `approved` - Approved
         * * `leave_absent` - Leave - Absent
         */
        enrolment_status?: (
          | 'approved'
          | 'leave_absent'
          | 'pending'
          | 'rejected'
          | 'requested'
          | 'waiting_parental_approve'
        )[]
        my_programmes?: boolean
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        programme_status?: ('approved' | 'rejected' | 'requested')[]
        /** A search term. */
        search?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?: (
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentSchoolProgrammeStatusSwagger, T>, any>({
        path: `/student/school-programmes/status-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags student
     * @name StudentSchoolProgrammesTypeChoicesRetrieve
     * @request GET:/student/school-programmes/type-choices/
     */ studentSchoolProgrammesTypeChoicesRetrieve: <
      T extends FetchKeys<StudentSchoolProgrammeTypeSwagger>
    >(
      query?: {
        can_enrol?: boolean
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /**
         * * `pending` - Pending
         * * `requested` - Requested
         * * `waiting_parental_approve` - Waiting parental approve
         * * `rejected` - Rejected
         * * `approved` - Approved
         * * `leave_absent` - Leave - Absent
         */
        enrolment_status?: (
          | 'approved'
          | 'leave_absent'
          | 'pending'
          | 'rejected'
          | 'requested'
          | 'waiting_parental_approve'
        )[]
        my_programmes?: boolean
        /**
         * * `requested` - Requested
         * * `approved` - Approved
         * * `rejected` - Rejected
         */
        programme_status?: ('approved' | 'rejected' | 'requested')[]
        /** A search term. */
        search?: string
        /**
         * * `house_evening` - House Evening
         * * `term_break` - Term Break
         * * `school_trip` - School Trip
         * * `weekend_programme` - Weekend Programme
         * * `other` - Other
         * * `absence_recovery` - Absence Recovery
         * * `lunchtime_recovery` - Lunchtime Recovery
         */
        type?: (
          | 'absence_recovery'
          | 'house_evening'
          | 'lunchtime_recovery'
          | 'other'
          | 'school_trip'
          | 'term_break'
          | 'weekend_programme'
        )[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentSchoolProgrammeTypeSwagger, T>, any>({
        path: `/student/school-programmes/type-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentStudentsList
     * @request GET:/student/students/
     */ studentStudentsList: <T extends FetchKeys<StudentBasic[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentBasic[], T>, any>({
        path: `/student/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentStudentsRetrieve
     * @request GET:/student/students/{id}/
     */ studentStudentsRetrieve: <T extends FetchKeys<StudentBasic>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentBasic, T>, any>({
        path: `/student/students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentStudentsClashesNumberCreate
     * @request POST:/student/students/clashes-number/
     */ studentStudentsClashesNumberCreate: <
      T extends FetchKeys<StudentClashesNumber>
    >(
      data: CheckClashesRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentClashesNumber, T>, any>({
        path: `/student/students/clashes-number/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags student
     * @name StudentSurveysResponseCreate
     * @request POST:/student/surveys/response/
     */ studentSurveysResponseCreate: <
      T extends FetchKeys<StudentSurveyResponse>
    >(
      data: StudentSurveyResponseRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentSurveyResponse, T>, any>({
        path: `/student/surveys/response/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  students = {
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsList
     * @request GET:/students/
     */
    studentsList: <T extends FetchKeys<PaginatedStudentListList>>(
      query?: {
        activity__course?: string
        activity__quarter?: string
        activity__school_year?: string
        allergen_kind?: string
        birthday_in_week?: boolean
        birthday_today?: boolean
        boarding_status?: string
        category?: number
        chronic_condition_type?: string
        current_grade_level?: number
        current_location?: string
        exclude_day_student?: boolean
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        has_chronic_condition?: boolean
        homework_status?: string
        house?: string
        /** @format uuid */
        id?: string
        is_accommodated?: boolean
        is_at_home?: boolean
        is_on_senior_campus?: boolean
        is_sick_now?: boolean
        is_vaccinated?: boolean
        /**
         * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
         * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
         * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
         * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
         */
        meal_preference?: 'l1' | 'l2' | 'l3' | 'l4'
        no_lesson_at_the_moment?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: string
        room_code?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStudentListList, T>, any>({
        path: `/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsCreate
     * @request POST:/students/
     */ studentsCreate: <T extends FetchKeys<StudentDetail>>(
      data: StudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsRetrieve
     * @request GET:/students/{id}/
     */ studentsRetrieve: <T extends FetchKeys<StudentDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsUpdate
     * @request PUT:/students/{id}/
     */ studentsUpdate: <T extends FetchKeys<StudentDetail>>(
      id: string,
      data: StudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsPartialUpdate
     * @request PATCH:/students/{id}/
     */ studentsPartialUpdate: <T extends FetchKeys<StudentDetail>>(
      id: string,
      data: PatchedStudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsDestroy
     * @request DELETE:/students/{id}/
     */ studentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/students/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsAccountOverduePartialUpdate
     * @request PATCH:/students/{id}/account-overdue/
     */ studentsAccountOverduePartialUpdate: <
      T extends FetchKeys<StudentAccountOverdue>
    >(
      id: string,
      data: PatchedStudentAccountOverdueRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentAccountOverdue, T>, any>({
        path: `/students/${id}/account-overdue/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsBloodTypePartialUpdate
     * @request PATCH:/students/{id}/blood-type/
     */ studentsBloodTypePartialUpdate: <T extends FetchKeys<StudentBloodType>>(
      id: string,
      data: PatchedStudentBloodTypeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentBloodType, T>, any>({
        path: `/students/${id}/blood-type/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsExamRecommendationsPartialUpdate
     * @request PATCH:/students/{id}/exam-recommendations/
     */ studentsExamRecommendationsPartialUpdate: <
      T extends FetchKeys<StudentDetail>
    >(
      id: string,
      data: PatchedStudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/exam-recommendations/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsPotentialCategoryRetrieve
     * @request GET:/students/{id}/potential-category/
     */ studentsPotentialCategoryRetrieve: <T extends FetchKeys<StudentDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/potential-category/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsReportCardRetrieve
     * @request GET:/students/{id}/report-card/
     */ studentsReportCardRetrieve: <T extends FetchKeys<StudentDetail>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/report-card/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsSetPasswordCreate
     * @request POST:/students/{id}/set-password/
     */ studentsSetPasswordCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/students/${id}/set-password/`,
        method: 'POST',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsToggleActiveCreate
     * @request POST:/students/{id}/toggle-active/
     */ studentsToggleActiveCreate: <T extends FetchKeys<StudentDetail>>(
      id: string,
      data: StudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/toggle-active/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsToggleVaccinationPartialUpdate
     * @request PATCH:/students/{id}/toggle-vaccination/
     */ studentsToggleVaccinationPartialUpdate: <
      T extends FetchKeys<StudentDetail>
    >(
      id: string,
      data: PatchedStudentDetailRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/${id}/toggle-vaccination/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsAccessArrangementsChoicesRetrieve
     * @request GET:/students/access-arrangements-choices/
     */ studentsAccessArrangementsChoicesRetrieve: <
      T extends FetchKeys<StudentDetail>
    >(
      query?: {
        activity__course?: string
        activity__quarter?: string
        activity__school_year?: string
        allergen_kind?: string
        birthday_in_week?: boolean
        birthday_today?: boolean
        boarding_status?: string
        category?: number
        chronic_condition_type?: string
        current_grade_level?: number
        current_location?: string
        exclude_day_student?: boolean
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        has_chronic_condition?: boolean
        homework_status?: string
        house?: string
        /** @format uuid */
        id?: string
        is_accommodated?: boolean
        is_at_home?: boolean
        is_on_senior_campus?: boolean
        is_sick_now?: boolean
        is_vaccinated?: boolean
        /**
         * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
         * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
         * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
         * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
         */
        meal_preference?: 'l1' | 'l2' | 'l3' | 'l4'
        no_lesson_at_the_moment?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        room?: string
        room_code?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/access-arrangements-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Use with Generic DRF views, or trigger update_search_index method manually
     *
     * @tags students
     * @name StudentsAvailableFiltersRetrieve
     * @request GET:/students/available-filters/
     */ studentsAvailableFiltersRetrieve: <T extends FetchKeys<StudentDetail>>(
      query?: {
        activity__course?: string
        activity__quarter?: string
        activity__school_year?: string
        allergen_kind?: string
        birthday_in_week?: boolean
        birthday_today?: boolean
        boarding_status?: string
        category?: number
        chronic_condition_type?: string
        current_grade_level?: number
        current_location?: string
        exclude_day_student?: boolean
        /**
         * * `male` - Male
         * * `female` - Female
         */
        gender?: 'female' | 'male'
        has_chronic_condition?: boolean
        homework_status?: string
        house?: string
        /** @format uuid */
        id?: string
        is_accommodated?: boolean
        is_at_home?: boolean
        is_on_senior_campus?: boolean
        is_sick_now?: boolean
        is_vaccinated?: boolean
        /**
         * * `l1` - L1, Regular and vegetarian. Students can choose themselves.
         * * `l2` - L2, Specific, individual requirements. Kitchen adapts the existing menu to specific needs.
         * * `l3` - L3, Specific, very individual requirements. Kitchen creates new menu to specific needs, almost every meal has to be cooked individually.
         * * `l4` - L4, Highly specific. Severe allergic reaction to traces of certain food. Meals need to be ordered from outside supplier.
         */
        meal_preference?: 'l1' | 'l2' | 'l3' | 'l4'
        no_lesson_at_the_moment?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        room?: string
        room_code?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentDetail, T>, any>({
        path: `/students/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsCommentsList
     * @request GET:/students/comments/
     */ studentsCommentsList: <T extends FetchKeys<PaginatedCommentList>>(
      query?: {
        comment_type?: string
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        related_author?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCommentList, T>, any>({
        path: `/students/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsCommentsAddedByChoicesRetrieve
     * @request GET:/students/comments/added-by-choices/
     */ studentsCommentsAddedByChoicesRetrieve: <T extends FetchKeys<Comment>>(
      query?: {
        comment_type?: string
        /** @format date */
        created_after?: string
        /** @format date */
        created_before?: string
        /** @format uuid */
        related_author?: string
        /** @format uuid */
        student?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Comment, T>, any>({
        path: `/students/comments/added-by-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsCurrentLocationsList
     * @request GET:/students/current-locations/
     */ studentsCurrentLocationsList: <T extends FetchKeys<CurrentLocation[]>>(
      query?: {
        boarding_status?: string
        current_location?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CurrentLocation[], T>, any>({
        path: `/students/current-locations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsMeRetrieve
     * @request GET:/students/me/
     */ studentsMeRetrieve: <T extends FetchKeys<StudentProfile>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProfile, T>, any>({
        path: `/students/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsMeUpdate
     * @request PUT:/students/me/
     */ studentsMeUpdate: <T extends FetchKeys<StudentProfile>>(
      data: StudentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProfile, T>, any>({
        path: `/students/me/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags students
     * @name StudentsMePartialUpdate
     * @request PATCH:/students/me/
     */ studentsMePartialUpdate: <T extends FetchKeys<StudentProfile>>(
      data: PatchedStudentProfileRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StudentProfile, T>, any>({
        path: `/students/me/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  tasksManagement = {
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksList
     * @request GET:/tasks-management/tasks/
     */
    tasksManagementTasksList: <T extends FetchKeys<PaginatedTaskList>>(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `boardingdirector` - Boarding Director
         * * `headofhouse` - Head of House
         * * `healthcoordinator` - Health Coordinator
         * * `viewer` - View Only
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `propertymanager` - Property Manager
         * * `behaviourmanagement` - Behaviour Management
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTaskList, T>, any>({
        path: `/tasks-management/tasks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksCreate
     * @request POST:/tasks-management/tasks/
     */ tasksManagementTasksCreate: <T extends FetchKeys<Task>>(
      data: TaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksRetrieve
     * @request GET:/tasks-management/tasks/{id}/
     */ tasksManagementTasksRetrieve: <T extends FetchKeys<Task>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksUpdate
     * @request PUT:/tasks-management/tasks/{id}/
     */ tasksManagementTasksUpdate: <T extends FetchKeys<Task>>(
      id: string,
      data: TaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksPartialUpdate
     * @request PATCH:/tasks-management/tasks/{id}/
     */ tasksManagementTasksPartialUpdate: <T extends FetchKeys<Task>>(
      id: string,
      data: PatchedTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksDestroy
     * @request DELETE:/tasks-management/tasks/{id}/
     */ tasksManagementTasksDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/tasks-management/tasks/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksToggleIsDoneCreate
     * @request POST:/tasks-management/tasks/{id}/toggle-is-done/
     */ tasksManagementTasksToggleIsDoneCreate: <
      T extends FetchKeys<TaskToggleIsDone>
    >(
      id: string,
      data: TaskToggleIsDoneRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TaskToggleIsDone, T>, any>({
        path: `/tasks-management/tasks/${id}/toggle-is-done/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksToggleSubtaskIsDoneCreate
     * @request POST:/tasks-management/tasks/{id}/toggle-subtask-is-done/
     */ tasksManagementTasksToggleSubtaskIsDoneCreate: <
      T extends FetchKeys<TaskToggleSubtaskIsDone>
    >(
      id: string,
      data: TaskToggleSubtaskIsDoneRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TaskToggleSubtaskIsDone, T>, any>({
        path: `/tasks-management/tasks/${id}/toggle-subtask-is-done/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksAvailableFiltersRetrieve
     * @request GET:/tasks-management/tasks/available-filters/
     */ tasksManagementTasksAvailableFiltersRetrieve: <
      T extends FetchKeys<Task>
    >(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `boardingdirector` - Boarding Director
         * * `headofhouse` - Head of House
         * * `healthcoordinator` - Health Coordinator
         * * `viewer` - View Only
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `propertymanager` - Property Manager
         * * `behaviourmanagement` - Behaviour Management
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksCountIncompleteRetrieve
     * @request GET:/tasks-management/tasks/count-incomplete/
     */ tasksManagementTasksCountIncompleteRetrieve: <
      T extends FetchKeys<IncompleteTaskCount>
    >(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `boardingdirector` - Boarding Director
         * * `headofhouse` - Head of House
         * * `healthcoordinator` - Health Coordinator
         * * `viewer` - View Only
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `propertymanager` - Property Manager
         * * `behaviourmanagement` - Behaviour Management
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IncompleteTaskCount, T>, any>({
        path: `/tasks-management/tasks/count-incomplete/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags tasks-management
     * @name TasksManagementTasksOverdueCountRetrieve
     * @request GET:/tasks-management/tasks/overdue-count/
     */ tasksManagementTasksOverdueCountRetrieve: <T extends FetchKeys<Task>>(
      query?: {
        assignee?: string[]
        created_by?: string[]
        /** @format date-time */
        date_after?: string
        /** @format date-time */
        date_before?: string
        has_subtasks?: boolean
        is_cloned?: boolean
        is_recurring?: boolean
        /**
         * Ordering
         *
         * * `is_done` - Is done
         * * `-is_done` - Is done (descending)
         * * `deadline` - Deadline
         * * `-deadline` - Deadline (descending)
         * * `assignee` - Assignee
         * * `-assignee` - Assignee (descending)
         * * `team` - Team
         * * `-team` - Team (descending)
         */
        ordering?: (
          | '-assignee'
          | '-deadline'
          | '-is_done'
          | '-team'
          | 'assignee'
          | 'deadline'
          | 'is_done'
          | 'team'
        )[]
        /**
         * * `admin` - Admin
         * * `teacher` - Artisan
         * * `academichod` - Academic HOD
         * * `academicadmin` - Academic Admin
         * * `boardingdirector` - Boarding Director
         * * `headofhouse` - Head of House
         * * `healthcoordinator` - Health Coordinator
         * * `viewer` - View Only
         * * `campusheadteam` - Campus Head Team
         * * `infoboardeditor` - Infoboard Editor
         * * `propertymanager` - Property Manager
         * * `behaviourmanagement` - Behaviour Management
         * * `housetutor` - House Tutor
         * * `nightguard` - Night Guard
         * * `idpadmin` - IDP Admin
         */
        role?: (
          | 'academicadmin'
          | 'academichod'
          | 'admin'
          | 'behaviourmanagement'
          | 'boardingdirector'
          | 'campusheadteam'
          | 'headofhouse'
          | 'healthcoordinator'
          | 'housetutor'
          | 'idpadmin'
          | 'infoboardeditor'
          | 'nightguard'
          | 'propertymanager'
          | 'teacher'
          | 'viewer'
        )[]
        /**
         * * `done` - Done
         * * `done_manually` - Done manually
         * * `overdue` - Overdue
         * * `close_deadline` - Close deadline
         * * `unscheduled` - Unscheduled
         */
        status?: (
          | 'close_deadline'
          | 'done'
          | 'done_manually'
          | 'overdue'
          | 'unscheduled'
        )[]
        /** @format uuid */
        team?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Task, T>, any>({
        path: `/tasks-management/tasks/overdue-count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags tasks-management
     * @name TasksManagementUserTaskTitlesList
     * @request GET:/tasks-management/user-task-titles/
     */ tasksManagementUserTaskTitlesList: <
      T extends FetchKeys<UserTaskTitle[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTaskTitle[], T>, any>({
        path: `/tasks-management/user-task-titles/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags tasks-management
     * @name TasksManagementUserTaskTitlesCreate
     * @request POST:/tasks-management/user-task-titles/
     */ tasksManagementUserTaskTitlesCreate: <
      T extends FetchKeys<UserTaskTitle>
    >(
      data: UserTaskTitleRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTaskTitle, T>, any>({
        path: `/tasks-management/user-task-titles/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags tasks-management
     * @name TasksManagementUserTaskTitlesRetrieve
     * @request GET:/tasks-management/user-task-titles/{id}/
     */ tasksManagementUserTaskTitlesRetrieve: <
      T extends FetchKeys<UserTaskTitle>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTaskTitle, T>, any>({
        path: `/tasks-management/user-task-titles/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags tasks-management
     * @name TasksManagementUserTaskTitlesUpdate
     * @request PUT:/tasks-management/user-task-titles/{id}/
     */ tasksManagementUserTaskTitlesUpdate: <
      T extends FetchKeys<UserTaskTitle>
    >(
      id: string,
      data: UserTaskTitleRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTaskTitle, T>, any>({
        path: `/tasks-management/user-task-titles/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags tasks-management
     * @name TasksManagementUserTaskTitlesPartialUpdate
     * @request PATCH:/tasks-management/user-task-titles/{id}/
     */ tasksManagementUserTaskTitlesPartialUpdate: <
      T extends FetchKeys<UserTaskTitle>
    >(
      id: string,
      data: PatchedUserTaskTitleRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTaskTitle, T>, any>({
        path: `/tasks-management/user-task-titles/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags tasks-management
     * @name TasksManagementUserTaskTitlesDestroy
     * @request DELETE:/tasks-management/user-task-titles/{id}/
     */ tasksManagementUserTaskTitlesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/tasks-management/user-task-titles/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  teacher = {
    /**
     * No description
     *
     * @tags teacher
     * @name TeacherActivitiesLessonList
     * @request GET:/teacher/activities/lesson/
     */
    teacherActivitiesLessonList: <
      T extends FetchKeys<PaginatedTeacherTimetableListLessonList>
    >(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** @format uuid */
        teacher?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTeacherTimetableListLessonList, T>, any>({
        path: `/teacher/activities/lesson/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teacher
     * @name TeacherActivitiesLessonRetrieve
     * @request GET:/teacher/activities/lesson/{id}/
     */ teacherActivitiesLessonRetrieve: <
      T extends FetchKeys<TeacherTimetableLesson>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TeacherTimetableLesson, T>, any>({
        path: `/teacher/activities/lesson/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teacher
     * @name TeacherAssignmentsStudentsInCourseRetrieve
     * @request GET:/teacher/assignments/students-in-course/{id}/
     */ teacherAssignmentsStudentsInCourseRetrieve: <
      T extends FetchKeys<TeacherStudentWithAssignment>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TeacherStudentWithAssignment, T>, any>({
        path: `/teacher/assignments/students-in-course/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  teams = {
    /**
     * No description
     *
     * @tags teams
     * @name TeamsList
     * @request GET:/teams/
     */
    teamsList: <T extends FetchKeys<PaginatedTeamList>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTeamList, T>, any>({
        path: `/teams/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsCreate
     * @request POST:/teams/
     */ teamsCreate: <T extends FetchKeys<Team>>(
      data: TeamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsRetrieve
     * @request GET:/teams/{id}/
     */ teamsRetrieve: <T extends FetchKeys<Team>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsUpdate
     * @request PUT:/teams/{id}/
     */ teamsUpdate: <T extends FetchKeys<Team>>(
      id: string,
      data: TeamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsPartialUpdate
     * @request PATCH:/teams/{id}/
     */ teamsPartialUpdate: <T extends FetchKeys<Team>>(
      id: string,
      data: PatchedTeamRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Team, T>, any>({
        path: `/teams/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsDestroy
     * @request DELETE:/teams/{id}/
     */ teamsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/teams/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsChoicesList
     * @request GET:/teams/choices/
     */ teamsChoicesList: <T extends FetchKeys<StringOption[]>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/teams/choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsRotaRolesChoicesList
     * @request GET:/teams/rota-roles-choices/
     */ teamsRotaRolesChoicesList: <T extends FetchKeys<RotaRoleChoice[]>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<RotaRoleChoice[], T>, any>({
        path: `/teams/rota-roles-choices/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags teams
     * @name TeamsStaffListList
     * @request GET:/teams/staff-list/
     */ teamsStaffListList: <T extends FetchKeys<StaffMember[]>>(
      query?: {
        house?: number
        /** @format uuid */
        id?: string
        include_in_boarding_rota?: boolean
        /**
         * * `boarding_director` - Boarding Director
         * * `campus_head` - Campus Head
         * * `deputy_campus_head_main` - Deputy Campus Head (Main)
         * * `deputy_campus_head_senior` - Deputy Campus Head (Sen)
         * * `head_of_house` - Head of House
         * * `house_tutor` - House Tutor
         */
        members__rota_role?:
          | 'boarding_director'
          | 'campus_head'
          | 'deputy_campus_head_main'
          | 'deputy_campus_head_senior'
          | 'head_of_house'
          | 'house_tutor'
        /** @format uuid */
        members__user?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StaffMember[], T>, any>({
        path: `/teams/staff-list/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  timetable = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableList
     * @request GET:/timetable/
     */
    timetableList: <T extends FetchKeys<PaginatedTimetableList>>(
      query?: {
        /** @format uuid */
        idp_plans__idp_plan__student?: string
        is_hidden?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: number

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableList, T>, any>({
        path: `/timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableCreate
     * @request POST:/timetable/
     */ timetableCreate: <T extends FetchKeys<Timetable>>(
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableRetrieve
     * @request GET:/timetable/{id}/
     */ timetableRetrieve: <T extends FetchKeys<Timetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableUpdate
     * @request PUT:/timetable/{id}/
     */ timetableUpdate: <T extends FetchKeys<Timetable>>(
      id: number,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetablePartialUpdate
     * @request PATCH:/timetable/{id}/
     */ timetablePartialUpdate: <T extends FetchKeys<Timetable>>(
      id: number,
      data: PatchedTimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableDestroy
     * @request DELETE:/timetable/{id}/
     */ timetableDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/timetable/${id}/`,
        method: 'DELETE',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableBusyInSlotCreate
     * @request POST:/timetable/{id}/busy-in-slot/
     */ timetableBusyInSlotCreate: <T extends FetchKeys<BusyInSlotResponse>>(
      id: number,
      data: BusyInSlotRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BusyInSlotResponse, T>, any>({
        path: `/timetable/${id}/busy-in-slot/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableCollectPotentialOptimizationsCreate
     * @request POST:/timetable/{id}/collect-potential-optimizations/
     */ timetableCollectPotentialOptimizationsCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: number,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/collect-potential-optimizations/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableConsistencyRetrieve
     * @request GET:/timetable/{id}/consistency/
     */ timetableConsistencyRetrieve: <T extends FetchKeys<Timetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/consistency/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableFilterOptionsRetrieve
     * @request GET:/timetable/{id}/filter-options/
     */ timetableFilterOptionsRetrieve: <T extends FetchKeys<Timetable>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/filter-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableFreeSlotsRetrieve
     * @request GET:/timetable/{id}/free-slots/
     */ timetableFreeSlotsRetrieve: <T extends FetchKeys<FreeSlot>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FreeSlot, T>, any>({
        path: `/timetable/${id}/free-slots/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetablePreviewCreate
     * @request POST:/timetable/{id}/preview/
     */ timetablePreviewCreate: <T extends FetchKeys<DraftActivityList>>(
      id: number,
      data: DraftActivityListRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityList, T>, any>({
        path: `/timetable/${id}/preview/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableStatsRetrieve
     * @request GET:/timetable/{id}/stats/
     */ timetableStatsRetrieve: <T extends FetchKeys<TimetableStats>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableStats, T>, any>({
        path: `/timetable/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableTogglePreviewForTeachersCreate
     * @request POST:/timetable/{id}/toggle-preview-for-teachers/
     */ timetableTogglePreviewForTeachersCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: number,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/timetable/${id}/toggle-preview-for-teachers/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags timetable
     * @name TimetableCopyTalentEnrichmentCreate
     * @request POST:/timetable/copy-talent-enrichment/
     */ timetableCopyTalentEnrichmentCreate: <
      T extends FetchKeys<CopyTalentEnrichment>
    >(
      data: CopyTalentEnrichmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CopyTalentEnrichment, T>, any>({
        path: `/timetable/copy-talent-enrichment/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableCurrentLessonsList
     * @request GET:/timetable/current-lessons/
     */ timetableCurrentLessonsList: <T extends FetchKeys<CurrentLesson[]>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CurrentLesson[], T>, any>({
        path: `/timetable/current-lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableSourceIdpRetrieve
     * @request GET:/timetable/source-idp/{id}/
     */ timetableSourceIdpRetrieve: <T extends FetchKeys<SourceIDPPlan>>(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SourceIDPPlan, T>, any>({
        path: `/timetable/source-idp/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags timetable
     * @name TimetableSourceIdpRetrieve2
     * @request GET:/timetable/source-idp/{studentId}/{timetableId}/
     */ timetableSourceIdpRetrieve2: <T extends FetchKeys<SourceIDPPlan>>(
      studentId: string,
      timetableId: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SourceIDPPlan, T>, any>({
        path: `/timetable/source-idp/${studentId}/${timetableId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
  }
  userPreferences = {
    /**
     * No description
     *
     * @tags user-preferences
     * @name UserPreferencesStudentsViewSettingList
     * @request GET:/user-preferences/students-view-setting/
     */
    userPreferencesStudentsViewSettingList: <
      T extends FetchKeys<FavouriteStudentsViewSetting[]>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteStudentsViewSetting[], T>, any>({
        path: `/user-preferences/students-view-setting/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags user-preferences
     * @name UserPreferencesStudentsViewSettingCreate
     * @request POST:/user-preferences/students-view-setting/
     */ userPreferencesStudentsViewSettingCreate: <
      T extends FetchKeys<FavouriteStudentsViewSetting>
    >(
      data: FavouriteStudentsViewSettingRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteStudentsViewSetting, T>, any>({
        path: `/user-preferences/students-view-setting/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags user-preferences
     * @name UserPreferencesStudentsViewSettingRetrieve
     * @request GET:/user-preferences/students-view-setting/{id}/
     */ userPreferencesStudentsViewSettingRetrieve: <
      T extends FetchKeys<FavouriteStudentsViewSetting>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteStudentsViewSetting, T>, any>({
        path: `/user-preferences/students-view-setting/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags user-preferences
     * @name UserPreferencesStudentsViewSettingUpdate
     * @request PUT:/user-preferences/students-view-setting/{id}/
     */ userPreferencesStudentsViewSettingUpdate: <
      T extends FetchKeys<FavouriteStudentsViewSetting>
    >(
      id: number,
      data: FavouriteStudentsViewSettingRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteStudentsViewSetting, T>, any>({
        path: `/user-preferences/students-view-setting/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags user-preferences
     * @name UserPreferencesStudentsViewSettingPartialUpdate
     * @request PATCH:/user-preferences/students-view-setting/{id}/
     */ userPreferencesStudentsViewSettingPartialUpdate: <
      T extends FetchKeys<FavouriteStudentsViewSetting>
    >(
      id: number,
      data: PatchedFavouriteStudentsViewSettingRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FavouriteStudentsViewSetting, T>, any>({
        path: `/user-preferences/students-view-setting/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags user-preferences
     * @name UserPreferencesStudentsViewSettingDestroy
     * @request DELETE:/user-preferences/students-view-setting/{id}/
     */ userPreferencesStudentsViewSettingDestroy: <T extends FetchKeys<void>>(
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/user-preferences/students-view-setting/${id}/`,
        method: 'DELETE',
        ...params
      })
  }
  userTimetable = {
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags user-timetable
     * @name UserTimetableList
     * @request GET:/user-timetable/
     */
    userTimetableList: <T extends FetchKeys<UserTimetable[]>>(
      query?: {
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        students?: string[]
        teachers?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UserTimetable[], T>, any>({
        path: `/user-timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * @description [WARNING] AdminTimetableViewSet inherits from this!
     *
     * @tags user-timetable
     * @name UserTimetablePdfRetrieve
     * @request GET:/user-timetable/pdf/
     */ userTimetablePdfRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/user-timetable/pdf/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
  }
  users = {
    /**
     * No description
     *
     * @tags users
     * @name UsersRetrieve
     * @request GET:/users/{id}/
     */
    usersRetrieve: <T extends FetchKeys<User>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<User, T>, any>({
        path: `/users/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthEmailCodeRequestCreate
     * @request POST:/users/auth/email-code-request/
     * @secure
     */ usersAuthEmailCodeRequestCreate: <
      T extends FetchKeys<CodeRequestResponse>
    >(
      data: UserPhoneNumberRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CodeRequestResponse, T>, any>({
        path: `/users/auth/email-code-request/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLoginCreate
     * @request POST:/users/auth/login/
     * @secure
     */ usersAuthLoginCreate: <T extends FetchKeys<TwoFactorLogin>>(
      data: AuthTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TwoFactorLogin, T>, any>({
        path: `/users/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLoginValidateCodeCreate
     * @request POST:/users/auth/login/validate-code/
     * @secure
     */ usersAuthLoginValidateCodeCreate: <T extends FetchKeys<LoginResponse>>(
      data: ValidateCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LoginResponse, T>, any>({
        path: `/users/auth/login/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLoginValidateLinkCreate
     * @request POST:/users/auth/login/validate-link/
     * @secure
     */ usersAuthLoginValidateLinkCreate: <
      T extends FetchKeys<LoginLinkResponse>
    >(
      data: ValidateLoginLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<LoginLinkResponse, T>, any>({
        path: `/users/auth/login/validate-link/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthLogoutCreate
     * @request POST:/users/auth/logout/
     */ usersAuthLogoutCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/users/auth/logout/`,
        method: 'POST',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersAuthSmsCodeRequestCreate
     * @request POST:/users/auth/sms-code-request/
     * @secure
     */ usersAuthSmsCodeRequestCreate: <
      T extends FetchKeys<CodeRequestResponse>
    >(
      data: UserPhoneNumberRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CodeRequestResponse, T>, any>({
        path: `/users/auth/sms-code-request/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersChangeAvatarRetrieve
     * @request GET:/users/change-avatar/
     */ usersChangeAvatarRetrieve: <T extends FetchKeys<ChangeAvatar>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeAvatar, T>, any>({
        path: `/users/change-avatar/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersChangeAvatarUpdate
     * @request PUT:/users/change-avatar/
     */ usersChangeAvatarUpdate: <T extends FetchKeys<ChangeAvatar>>(
      data: ChangeAvatarRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeAvatar, T>, any>({
        path: `/users/change-avatar/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersChangeAvatarPartialUpdate
     * @request PATCH:/users/change-avatar/
     */ usersChangeAvatarPartialUpdate: <T extends FetchKeys<ChangeAvatar>>(
      data: PatchedChangeAvatarRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ChangeAvatar, T>, any>({
        path: `/users/change-avatar/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersMeRetrieve
     * @request GET:/users/me/
     */ usersMeRetrieve: <T extends FetchKeys<User>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<User, T>, any>({
        path: `/users/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersNotificationSettingsRetrieve
     * @request GET:/users/notification-settings/
     */ usersNotificationSettingsRetrieve: <
      T extends FetchKeys<NotificationSettings>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NotificationSettings, T>, any>({
        path: `/users/notification-settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersNotificationSettingsUpdate
     * @request PUT:/users/notification-settings/
     */ usersNotificationSettingsUpdate: <
      T extends FetchKeys<NotificationSettings>
    >(
      data: NotificationSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NotificationSettings, T>, any>({
        path: `/users/notification-settings/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersNotificationSettingsPartialUpdate
     * @request PATCH:/users/notification-settings/
     */ usersNotificationSettingsPartialUpdate: <
      T extends FetchKeys<NotificationSettings>
    >(
      data: PatchedNotificationSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<NotificationSettings, T>, any>({
        path: `/users/notification-settings/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersResetPinCodeCreate
     * @request POST:/users/reset-pin-code/
     */ usersResetPinCodeCreate: <T extends FetchKeys<ResetMobilePinCode>>(
      data: ResetMobilePinCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ResetMobilePinCode, T>, any>({
        path: `/users/reset-pin-code/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),
    /**
     * No description
     *
     * @tags users
     * @name UsersResetPinCodeConfirmCreate
     * @request POST:/users/reset-pin-code/confirm/
     */ usersResetPinCodeConfirmCreate: <
      T extends FetchKeys<ResetMobilePinCodeConfirm>
    >(
      data: ResetMobilePinCodeConfirmRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<ResetMobilePinCodeConfirm, T>, any>({
        path: `/users/reset-pin-code/confirm/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
}
