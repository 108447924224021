import { TABS } from './tabs'

export const infoboardsTabRoutes = [
  {
    path: 'staff-boards',
    icon: 'layout',
    name: 'staff-board-list',
    meta: {
      title: 'Boards',
      tab: TABS.administration
    },
    permissions: ['infoboards.view_board'],
    component: () => import('@/views/Infoboards/StaffBoards/StaffBoards.vue')
  },
  {
    path: 'students-boards',
    name: 'student-board-list',
    icon: 'carry-out',
    meta: {
      title: "Students' posts",
      tab: TABS.administration
    },
    permissions: ['infoboards.view_board'],
    component: () =>
      import('@/views/Infoboards/StudentsBoards/StudentsBoards.vue')
  },
  {
    path: 'alerts-and-notice',
    name: 'alert-and-notice-list',
    icon: 'safety',
    meta: {
      title: 'Alerts & notice',
      tab: TABS.administration
    },
    permissions: ['infoboards.view_message'],
    component: () => import('@/views/Infoboards/Messages/Messages.vue')
  },
  {
    path: 'schedule',
    name: 'infoboards-schedule-details',
    icon: 'calendar',
    meta: {
      title: 'Schedule',
      tab: TABS.administration
    },
    permissions: ['infoboards.view_boardschedule'],
    component: () => import('@/views/Infoboards/Schedule/Schedule.vue')
  },
  {
    path: 'settings',
    name: 'infoboards-settings-details',
    icon: 'setting',
    meta: {
      title: 'Infoboards settings',
      tab: TABS.administration
    },
    permissions: ['infoboards.view_infoboardssettings'],
    component: () => import('@/views/Infoboards/Settings/Settings.vue')
  }
]

const infoboardsNotTabRoutes = [
  {
    name: 'staff-board-add',
    path: 'staff-boards/create',
    meta: {
      title: 'Create Board',
      tab: TABS.administration
    },
    permissions: ['infoboards.add_board'],
    component: () =>
      import(
        '@/views/Infoboards/StaffBoards/StaffBoardsForm/StaffBoardsForm.vue'
      )
  },
  {
    name: 'staff-board-details',
    path: 'staff-boards/:id',
    meta: {
      title: 'Board Details',
      tab: TABS.administration
    },
    permissions: ['infoboards.view_board'],
    component: () =>
      import(
        '@/views/Infoboards/StaffBoards/StaffBoardsForm/StaffBoardsForm.vue'
      )
  },
  {
    path: 'students-boards/announcements/:itemId',
    name: 'student-board-announcement-details',
    component: () =>
      import(
        '@/views/Infoboards/StudentsBoards/PostDetails/StudentAnnouncementDetails.vue'
      ),
    permissions: ['students_announcement_widget.view_studentannouncement'],
    meta: {
      title: "Student's announcement details",
      tab: TABS.administration
    }
  },
  {
    path: 'students-boards/pics/:itemId',
    name: 'student-board-pic-details',
    component: () =>
      import(
        '@/views/Infoboards/StudentsBoards/PostDetails/StudentPicDetails.vue'
      ),
    permissions: ['students_pic_widget.view_studentpic'],
    meta: {
      title: "Student's pic details",
      tab: TABS.administration
    }
  },
  {
    path: 'students-boards/mystery-links/:itemId',
    name: 'student-board-mystery-link-details',
    component: () =>
      import(
        '@/views/Infoboards/StudentsBoards/PostDetails/StudentMysteryLinkDetails.vue'
      ),
    permissions: ['mystery_link_widget.view_studentmysterylink'],
    meta: {
      title: "Student's mystery link details",
      tab: TABS.administration
    }
  }
]

export const infoboardsRoutes = [
  {
    path: 'infoboards',
    redirect: 'infoboards/staff-boards',
    name: 'infoboard-list',
    component: () => import('@/views/Infoboards/Infoboards.vue'),
    meta: {
      title: 'Infoboards',
      tab: TABS.administration
    },
    permissions: ['infoboards.view_board'],
    children: infoboardsTabRoutes
  },
  {
    path: 'infoboards',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: TABS.administration
    },
    permissions: ['infoboards.view_board'],
    children: infoboardsNotTabRoutes
  }
]
