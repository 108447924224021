import { Route } from '@/types/route'

export const timetableTabRoutes: Route[] = [
  {
    path: ':timetableId/details/:rotaId?',
    component: () =>
      import('@/views/Planner/TimetableDetail/TimetableDetailsView.vue'),
    name: 'timetable-details',
    meta: {
      title: 'Timetable',
      tab: 'administration',
      permissions: ['timetable.view_timetable']
    }
  },
  {
    path: ':timetableId/boarding-rota/:rotaId?',
    component: () =>
      import('@/views/Planner/BoardingRota/BoardingRotaView.vue'),
    name: 'timetable-boarding-rota',
    meta: {
      title: 'Boarding Rotas',
      tab: 'administration',
      // TODO -change permission
      permissions: ['timetable.view_timetable']
    }
  }
]

const timetableNotTabRoutes = [
  {
    name: 'new-boarding-rota',
    path: 'new-boarding-rota/:timetableId/:quarter/:rotaId?',
    component: () =>
      import('@/views/Planner/BoardingRota/NewBoardingRotaView.vue'),
    meta: {
      title: 'Generate new Boarding Rota',
      tab: 'administration'
      // TODO - add permission
      // permissions: ['users.add_user']
    }
  }
]

export const timetableRoutes = [
  {
    path: 'timetables',
    component: () => import('@/views/Planner/TimetableAndBoardingRotaView.vue'),
    name: 'timetable',
    meta: {
      title: 'Timetable',
      tab: 'administration',
      permissions: ['timetable.view_timetable']
    },
    children: timetableTabRoutes
  },
  {
    path: 'timetables',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'administration'
    },
    children: timetableNotTabRoutes
  }
]
