<template>
  <a-spin :spinning="loading">
    <div class="spin-content" data-cy="form-content">
      <BaseAlert
        v-if="errors.non_field_errors"
        class="mb-24"
        message="Errors"
        :description="errors.non_field_errors"
      />
      <slot>
        <component
          :is="formComponent"
          ref="formComponent"
          :value="form"
          :instance="instance"
          :errors="errors"
          :is-dirty.sync="isDirty"
          :edited="edited"
          :extra-context="extraContext"
          v-bind="$attrs"
          @input="value => $emit('update:form', value)"
          @submit="onSubmit"
          @reload="fetchData"
          v-on="$listeners"
        />
      </slot>
    </div>
  </a-spin>
</template>
<script>
import { cloneDeep, isEqual, isFunction, reduce } from 'lodash'
import { isInViewport } from '@/functions/utils'
import BaseAlert from '@/components/base/BaseAlert.vue'

export default {
  components: {
    BaseAlert
  },
  props: {
    extraContext: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: [Object, Array],
      default: () => ({})
    },
    form: {
      type: [Object, Array],
      default: () => ({})
    },
    instance: {
      type: [Object, Array],
      default: () => ({})
    },
    objParams: {
      type: [Object, String, Number],
      required: false
    },
    formComponent: {
      type: Object,
      required: true
    },
    service: {
      type: [Object, Function],
      required: true
    },
    getSuccessRoute: {
      type: Function,
      required: false
    },
    successMessage: {
      type: String,
      default: 'Changes saved'
    },
    editedMessage: {
      type: String,
      default: ''
    },
    edited: {
      type: Boolean,
      default: false
    },
    shouldFetch: {
      type: Boolean,
      default: true
    },
    formParser: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      isDirty: false
    }
  },
  watch: {
    objParams: {
      immediate: true,
      handler(value) {
        if (value && this.shouldFetch) {
          this.fetchData()
        }
      }
    },
    form: {
      deep: true,
      handler() {
        this.isDirty = !isEqual(this.form, this.instance)
      }
    }
  },
  methods: {
    getChildrenComponents(vueComponent) {
      const components = [vueComponent]
      const children = vueComponent?.$children || []
      for (const child of children) {
        // eslint-disable-next-line prefer-spread
        components.push.apply(components, this.getChildrenComponents(child))
      }
      return components
    },
    validate() {
      const formComponent = this.$refs.formComponent
      if (!formComponent) {
        return
      }
      const allFormModels = this.getChildrenComponents(formComponent).filter(
        item => item.$options.name === 'AFormModel'
      )

      return reduce(
        allFormModels,
        function (result, formModel) {
          formModel?.validate(value => {
            // we want to validate all forms, but if one was invalid then we keep invalid value
            result = result && value
          })
          return result
        },
        true
      )
    },
    fetchData() {
      this.loading = true
      this.service
        .get(this.objParams)
        .then(res => {
          this.$emit('update:form', res.data)
          this.$emit('update:instance', cloneDeep(res.data))
          this.$emit('fetch', res.data)
        })
        .finally(() => {
          this.isDirty = false
          this.loading = false
        })
    },
    onSubmit() {
      if (!this.validate()) {
        setTimeout(() => {
          this.scrollToFirstError()
        }, 150)
        return
      }
      this.loading = true
      this.$emit('update:errors', {})
      this.getSavePromise()
        .then(this.success)
        .catch(err => {
          this.$emit('update:errors', err?.response?.data || {})
          this.$nextTick(() => this.scrollToFirstError())
        })
        .finally(() => {
          this.loading = false
          this.isDirty = false
        })
    },
    scrollToFirstError() {
      const errorField = document.querySelector(
        '.ant-form-item-control.has-error'
      )

      if (errorField) {
        if (isInViewport(errorField)) {
          return
        }
        errorField.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    },
    success(res) {
      this.isDirty = false
      if (this.successMessage) {
        if (this.edited)
          this.$notification.success({
            message: this.editedMessage || this.successMessage
          })
        else this.$notification.success({ message: this.successMessage })
      }
      if (this.getSuccessRoute && this.getSuccessRoute(res)) {
        this.$router.push(this.getSuccessRoute(res))
      }
      this.$emit('form-submitted', res)
    },
    getSavePromise() {
      let func = null
      if (isFunction(this.service)) {
        func = this.service
      } else {
        func = this.objParams ? this.service.update : this.service.save
      }
      const data = isFunction(this.formParser)
        ? this.formParser(this.form)
        : this.form
      return this.objParams ? func(this.objParams, data) : func(data)
    }
  }
}
</script>
