import { TABS } from './tabs'

const userRoutes = [
  {
    path: '/user',
    redirect: '/user/my-timetable',
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        name: 'my-timetable',
        path: 'my-timetable',
        component: () => import('@/views/Timetable/MyTimetableView.vue'),
        meta: {
          title: 'Timetable',
          tab: TABS.academics
        }
      }
    ]
  }
]

export default userRoutes
