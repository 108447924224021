import { TABS } from './tabs'

export const diningHallTabRoutes = [
  {
    path: 'menu-planner',
    name: 'menu-planner',
    meta: {
      title: 'Menu planner',
      tab: TABS.administration,
      permission: 'menu.view_daymenu'
    },
    component: () => import('@/views/DiningHall/MenuPlanner/MenuPlanner.vue')
  },
  {
    path: 'meal-attendance',
    name: 'meal-attendance',
    meta: {
      title: 'Meal attendance',
      tab: TABS.administration,
      permission: 'permissions.view_mealattendance'
    },
    component: () =>
      import('@/views/DiningHall/MealAttendance/MealAttendance.vue')
  }
]

export const diningHallRoutes = [
  {
    name: 'dining-hall',
    path: 'dining-hall',
    component: () => import('@/views/DiningHall/DiningHall.vue'),
    meta: {
      title: 'Dining hall',
      tab: TABS.administration
    },
    children: diningHallTabRoutes
  }
]
