<template>
  <div
    class="form-item-wrapper"
    :class="[
      {
        'form-item-wrapper--editable': editable,
        'form-item-wrapper--form-visible': isFormVisible,
        'form-item-wrapper--has-errors': hasErrors,
        'form-item-wrapper--no-wrap-text': noWrapText
      },
      `form-item-wrapper--${focusBackgroundColor}-hover-background-color`,
      `form-item-wrapper--${type}-wrapper-type`
    ]"
  >
    <div v-if="isFormVisible" class="form-item-input-wrapper">
      <slot
        name="input"
        :toggle-edit-mode="toggleEditMode"
        :set-edit-mode="setEditMode"
        :has-errors="hasErrors"
      ></slot>
    </div>
    <div
      v-else
      ref="formWrapper"
      class="form-item-text-wrapper"
      @click="toggleEditMode"
    >
      <slot name="text"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'input'
    },
    hasErrors: {
      type: Boolean,
      default: false
    },
    forceShowForm: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    noWrapText: {
      type: Boolean,
      default: false
    },
    querySelector: {
      type: String,
      default: 'input'
    },
    querySelectorFunction: {
      type: Function,
      default: item => item?.focus()
    },
    focusBackgroundColor: {
      type: String,
      default: 'white'
    }
  },
  data() {
    return {
      editMode: false
    }
  },
  computed: {
    isFormVisible() {
      return (
        this.editable && (this.forceShowForm || this.editMode || this.hasErrors)
      )
    }
  },
  created() {
    if (this.forceShowForm) {
      this.editMode = true
    }
  },
  methods: {
    toggleEditMode($event) {
      if (this.editable) {
        this.editMode = !this.editMode

        if (this.editMode) {
          const target = this.$refs.formWrapper || $event?.target
          if (target) {
            this.$nextTick(() => {
              this.querySelectorFunction(
                target.querySelector(this.querySelector)
              )
            })
          }
        }
      }
    },
    setEditMode(value) {
      this.editMode = !!value
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-wrapper {
  --wrapper-padding: 12px 0;
  --inner-wrapper-padding-top-bottom: 9px;
  --inner-wrapper-padding-left-right: 19px;
  --hover-background-color: #ffffff;
  --transition-time: 200ms;
  --gray-hover-background-color: var(--neutral-grey-color);
  --form-item-label-color: var(--gray-color);
  --form-item-label-font-size: 12px;
  --placeholder-color: var(--light-gray-color);

  display: flex;
  align-items: center;
  padding: var(--wrapper-padding);

  &--gray-hover-background-color {
    --hover-background-color: var(--gray-hover-background-color);
  }

  &--select-wrapper-type {
    --inner-wrapper-padding-top-bottom: 9px;
    --inner-wrapper-padding-left-right: 17px;
  }

  &--input-wrapper-type {
    --inner-wrapper-padding-top-bottom: 9px;
    --inner-wrapper-padding-left-right: 19px;
  }

  &--no-wrap-text {
    div.form-item-text-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--editable:not(&--form-visible) {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      ::v-deep {
        .ant-form-item,
        .ant-form-item-label,
        .ant-form-item-required {
          cursor: pointer;
        }
      }
      ::v-deep .form-item-text,
      .form-item-text {
        transition: background-color var(--transition-time);
        background: var(--hover-background-color);
        border-radius: 12px;
      }
    }
  }

  ::v-deep .ant-form-item-label {
    font-size: 12px;
    line-height: 20px;
    & > label {
      color: var(--form-item-label-color);
      font-size: var(--form-item-label-font-size);
      margin-left: 16px;
      &.ant-form-item-required {
        margin-left: 8px;
      }
    }
  }

  .form-item-text-wrapper {
    .form-item-text,
    ::v-deep .form-item-text {
      padding: var(--inner-wrapper-padding-top-bottom)
        var(--inner-wrapper-padding-left-right);
    }
  }

  .form-item-input-wrapper,
  .form-item-text-wrapper {
    ::v-deep {
      .ant-form-item {
        margin-bottom: 0;
        .ant-form-explain {
          padding-top: 4px;
          padding-left: var(--inner-wrapper-padding-left-right);
        }
        .ant-form-item-label > label::after {
          top: unset;
        }
        .ant-form-item-control {
          line-height: 40px;
        }
        .ant-form-item-children {
          line-height: 22px;
        }
      }
    }
  }

  .form-item-text-wrapper,
  .form-item-input-wrapper {
    width: 100%;
  }

  ::v-deep {
    .ant-input::placeholder,
    input::placeholder {
      color: var(--placeholder-color);
    }
  }
}
</style>
