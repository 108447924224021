import { TABS } from './tabs'

const teacherRoutes = [
  {
    path: '/teacher',
    redirect: '/user/my-timetable',
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        // redirect to new endpoint
        path: 'timetable',
        redirect: '/user/my-timetable',
        component: () => import('@/views/Layouts/RouterView.vue')
      },
      {
        name: 'ClassDetails',
        path: 'timetable/:lesson/',
        component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
        meta: {
          tab: TABS.academics,
          permissions: ['activities.view_lesson']
        }
      }
    ]
  }
]

export default teacherRoutes
