export const rulesMessages = {
  email: 'Enter a valid email address',
  required: 'This field is required.',
  str: {
    min: (num: number) => `Ensure this field has at least ${num} characters.`,
    max: (num: number) =>
      `Ensure this field has no more than ${num} characters.`
  },
  num: {
    max: (num: number) => `Ensure this value is less than or equal to ${num}.`,
    min: (num: number) =>
      `Ensure this value is greater than or equal to ${num}.`
  },
  unique: 'Please enter unique values.'
}

export const defaultErrorMessages = {
  number: {
    max: (_: unknown, max: number) => rulesMessages.num.max(max)
  },
  string: {
    max: (_: unknown, max: number) => rulesMessages.str.max(max)
  },
  required: rulesMessages.required,
  types: {
    email: rulesMessages.email
  }
}

export const DEFAULT_ERROR_MESSAGE = 'An error occurred. Try again later'

type CustomErrorLink = {
  route: string | { name: string; params: Record<string, string> }
  displayName: string
}

export const CUSTOM_ERRORS = {
  email_exists: ({ route, displayName }: CustomErrorLink) => (
    <span>
      A user with this email address exists:{' '}
      <router-link to={route} target="_blank">
        {displayName}
      </router-link>
      . Please change email address for one of the users.
    </span>
  ),
  phone_number_exists: ({ route, displayName }: CustomErrorLink) => (
    <span>
      A user with this phone number exists:{' '}
      <router-link to={route} target="_blank">
        {displayName}
      </router-link>
      . Please change phone number for one of the users.
    </span>
  )
}
