import { parse } from 'json2csv/dist/json2csv.umd.js'
import moment from 'moment'
import Vue from 'vue'

import { downloadXlsxResponse } from '@/utils/files'

Vue.mixin({
  beforeRouteLeave(to, from, next) {
    // if on page is ref=form check isDirty flag and avoid changes lost
    const form = this.$refs.form
    if (!form) {
      next()
      return
    }
    if (form.isDirty) {
      this.$confirm({
        title: 'Unsaved changes?',
        maskClosable: true,
        content:
          'To not lose changes, continue editing\nand click save at the bottom of the page.',
        okText: 'Continue editing',
        cancelText: "Don't save changes",
        onCancel() {
          next()
        }
      })
    } else {
      next()
    }
  },
  computed: {
    activeMainTab() {
      return this.$route.meta.tab
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    userProfiles() {
      return this.userInfo.profiles
    },
    isBoardingDirector() {
      return this.userProfiles.includes('BoardingDirector')
    },
    isHeadOfHouse() {
      return this.userProfiles.includes('HeadOfHouse')
    },
    backRoutePath() {
      return this.$route.fullPath
    },
    backRouteName() {
      return this.$route.meta?.title || this.$route.name
    },
    backParams() {
      return `?backPath=${this.backRoutePath}&backName=${encodeURIComponent(
        this.backRouteName
      )}`
    },
    backParamsObject() {
      return {
        backPath: this.backRoutePath,
        backName: this.backRouteName
      }
    },
    isAdmin() {
      return this.userProfiles.includes('admin') || this.isSuperUser
    },
    isSuperUser() {
      return this.userInfo.is_superuser
    },
    defaultRoute() {
      let url = '/'
      if (this.$route.query.next) {
        url = this.$route.query.next
      } else if (
        this.hasPermission('permissions.view_teachers_timetable') &&
        ((this.$store.getters.canSwitchMode &&
          this.$store.getters.isAdminMode) ||
          !this.$store.getters.canSwitchMode)
      ) {
        url = '/admin/timetable/teacher/'
      } else if (this.hasPermission('timetable.view_timetable')) {
        url = '/teacher/timetable/'
      } else if (
        this.hasPermission('permissions.view_students_timetable') &&
        ((this.$store.getters.canSwitchMode &&
          this.$store.getters.isAdminMode) ||
          !this.$store.getters.canSwitchMode)
      ) {
        url = 'admin/timetable/student/'
      } else if (
        this.hasPermission('boarding_attendances.view_boardingattendancereport')
      ) {
        url = '/attendance/boarding-attendance-reports/'
      } else if (this.hasPermission('students.view_student')) {
        url = '/students/'
      }

      return url
    }
  },
  beforeCreate() {
    // queryModels is list with dynamic query-model params
    const keys = this.$options.queryModels
    if (!keys) {
      return
    }
    keys.forEach(key => {
      this.$options.computed[key] = {
        get() {
          return this.getQueryParam(key)
        },
        set(value) {
          // reset page if filters changed
          if (key !== 'page') {
            this.setQueryParam('page')
          }
          this.setQueryParam(key, value)
        }
      }
    })
  },
  methods: {
    downloadCsv(rows, filename) {
      /*
       *  rows is list of lists
       * [
       *   ["Name", "Year"],
       *   ["John", 2020]
       * ]
       * */
      const csvMime = 'data:text/csv;charset=utf-8,'
      const parsedData = parse(rows, { header: false })
      const csvContent = csvMime + parsedData
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', filename)
      document.body.appendChild(link) // Required for FF

      link.click() // This will download the data file".
    },
    hasPermission(permission) {
      if (!permission) {
        return true
      }
      return this.userInfo.permissions.includes(permission)
    },
    hasSomePermission(permissions) {
      return this.userInfo.permissions.some(permission => {
        return permissions.includes(permission)
      })
    },
    changeRoute(route) {
      this.$router.push(route)
    },
    momentize(date) {
      return moment(date)
    },
    getQueryParam(name) {
      const value = this.$route.query[name]
      if (value === 'true') {
        return true
      } else if (value === 'false') {
        return false
      } else {
        const parseIntIfInt = val => (/^-?\d+$/.test(val) ? parseInt(val) : val)
        return Array.isArray(value)
          ? value.map(parseIntIfInt)
          : parseIntIfInt(value)
      }
    },
    setQueryParam(name, value) {
      if (this.getQueryParam(name) === value) {
        return
      }
      this.setQueryParams({ [name]: value })
    },
    setQueryParams(paramsKeyValueObject) {
      const newQueryParams = { ...this.$route.query }
      for (const [key, value] of Object.entries(paramsKeyValueObject)) {
        if (value === undefined || value === null) {
          delete newQueryParams[key]
        } else {
          newQueryParams[key] = Array.isArray(value)
            ? value.map(String)
            : value.toString()
        }
      }
      this.$router.replace({ query: newQueryParams })
    },
    formatDate(date, format = 'DD-MM-YYYY HH:mm') {
      return moment(date).format(format)
    },
    downloadBlobResponse(response) {
      const successStatusCodes = [201, 200]
      if (successStatusCodes.includes(response.status)) {
        const disposition = response.headers['content-disposition']
        const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/
        const filename = disposition
          .match(filenameRegex)[1]
          .replace(/['"]/g, '')
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      }
    },
    downloadXlsxResponse,
    openInternalTaskLink(url) {
      if (!this.$store.state.safeModeDisabled) {
        this.$store.commit('setSafeModeDisabled', true)
      }

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    }
  }
})
