<template>
  <form-item-wrapper
    type="select"
    :has-errors="hasErrors"
    force-show-form
    editable
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #input>
      <a-form-model-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :prop="prop"
      >
        <a-radio-group
          v-model="inputValue"
          v-bind="$attrs"
          :disabled="!editable || $attrs.disabled"
          v-on="$listeners"
        />
      </a-form-model-item>
    </template>
  </form-item-wrapper>
</template>
<script>
import FormItemWrapper from '@/components/common/forms/FormItemWrapper'
import HInputMixin from '@/mixins/HInputMixin'
export default {
  components: { FormItemWrapper },
  extends: HInputMixin,
  props: {
    value: {
      type: [Boolean, String, Number]
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.prop && this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
