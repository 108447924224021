import { Tab } from '@/types/route'

export const TABS: Record<Tab, string> = {
  dashboard: 'dashboard',
  academics: 'academics',
  boarding: 'boarding',
  administration: 'administration',
  students: 'students'
}

export type TabDetails = {
  name: Tab
  title: string
  defaultRoute: {
    path: string
  }
  count?: number
}
export const tabs: Record<Tab, TabDetails> = {
  dashboard: {
    name: 'dashboard',
    title: 'My dashboard',
    defaultRoute: {
      path: '/dashboard'
    }
  },
  academics: {
    name: 'academics',
    title: 'Academics',
    defaultRoute: {
      path: '/teacher/timetable/'
    }
  },
  boarding: {
    name: 'boarding',
    title: 'Boarding',
    defaultRoute: {
      path: '/attendance/boarding-attendance-reports/'
    }
  },
  administration: {
    name: 'administration',
    title: 'Administration',
    defaultRoute: {
      path: '/planner/timetables/'
    }
  },
  students: {
    name: 'students',
    title: 'Students',
    defaultRoute: {
      path: '/students/'
    }
  }
}
