<template>
  <form-item-wrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="true"
    :editable="true"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #input>
      <a-form-model-item
        ref="formModelItem"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :prop="prop"
      >
        <a-checkbox
          :disabled="!editable"
          :checked="inputValue"
          v-bind="$attrs"
          v-on="$listeners"
        >
          {{ label }}
        </a-checkbox>
      </a-form-model-item>
    </template>
  </form-item-wrapper>
</template>
<script>
import FormItemWrapper from '@/components/common/forms/FormItemWrapper'
import HInputMixin from '@/mixins/HInputMixin'
export default {
  components: { FormItemWrapper },
  extends: HInputMixin,
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.prop && this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
<style lang="scss" scoped>
textarea.ant-input {
  margin-bottom: 0;
}
</style>
