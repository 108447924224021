<template>
  <a-button type="link" class="back-to-top" @click="scrollToTop">
    Back to top
    <a-icon type="arrow-up" />
  </a-button>
</template>
<script>
export default {
  methods: {
    scrollToTop() {
      this.$nextTick(() =>
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.back-to-top {
  font-weight: 400;
  font-size: 15px;
  color: var(--light-gray-color);

  &:hover {
    color: var(--black-color);
  }
}
</style>
